import { createUseStyles } from 'react-jss';
import { chosenTheme } from '../styleHooks';

export const useTaskGroupStyles = createUseStyles({
  taskGroup: {
    borderRadius: 5,
    display: 'flex',
    flexDirection: 'column',
    flexGrow: 1,
    '& h1, h2': {
      textAlign: 'left',
    },
  },

  '@media only screen and (max-width: 1000px)': {
    taskGroup: {
      width: '100%',
    },
    taskNameContainer: {
      width: '100%',
      flex: 'auto',
    },
  },

  taskGroupOverview: {
    'background-color': chosenTheme.tasks.taskGroupBackgroundColour,
    padding: 10,
    maxWidth: 700,
  },

  taskGroupTodoList: {
    'background-color': chosenTheme.tasks.taskGroupBackgroundColour,
    padding: 10,
    maxWidth: 700,
  },

  taskGroupBoardPhase: {
    'background-color': chosenTheme.tasks.taskGroupBackgroundColour,
    padding: 3,
    width: 400,
  },

  taskGroupDraggingOver: {
    'background-color': chosenTheme.tasks.taskGroupDraggingOverBackgroundColour,
  },

  taskGroupBoardPhaseContainer: {
    marginRight: 10,
    width: 400,
  },

  taskGroupBoardContainer: {
    minHeight: 300,
    display: 'flex',
    flexDirection: 'column',
    marginRight: 50,
  },

  taskBoardContainer: {
    overflowX: 'scroll',
    overflowY: 'scroll',
    maxHeight: '80vh',
    display: 'flex',
    borderWidth: 1,
    borderColor: 'black',
    borderStyle: 'dashed',
    marginBottom: 30,
  },

  '@media only screen and (min-width: 320px) and (max-width: 480px)': {
    taskGroupBoardPhase: {
      width: 300,
    },
    taskGroupBoardPhaseContainer: {
      width: 300,
    },
  },
});

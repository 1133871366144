import { DateTime } from 'luxon';
import { Intent } from '@blueprintjs/core';
import { ITextChunk } from '../common-src/types/UiTypes';
import React from 'react';

function getIntentForProgressBar(
  completion: number,
  target?: number,
  dueDate?: DateTime
): Intent {
  const today = DateTime.now();
  if (dueDate && today > dueDate && completion < 1) {
    return Intent.DANGER;
  } else if (typeof target === 'number' && target !== 0) {
    const proportion = completion / target;

    if (proportion < 0.8) {
      return Intent.DANGER;
    } else if (proportion >= 0.8 && proportion < 1) {
      return Intent.WARNING;
    } else {
      return Intent.SUCCESS;
    }
  } else return Intent.NONE;
}

function getIntentForHabitProgressBar(consistency: number): Intent {
  if (consistency < 0.8) {
    return Intent.DANGER;
  } else if (consistency >= 0.8 && consistency < 1) {
    return Intent.WARNING;
  } else {
    return Intent.SUCCESS;
  }
}

function renderTextChunks(textChunks: ITextChunk[]): JSX.Element {
  return (
    <>
      {textChunks.map((chunk, index) => {
        return (
          <span key={`chunk-${index}`} style={{ color: chunk.colour }}>
            {chunk.precededByHorizontalLine && <hr style={{ width: '100%' }} />}
            {chunk.beginOnNewLine && <br></br>}
            {chunk.strong ? (
              <strong>{chunk.text}</strong>
            ) : chunk.italic ? (
              <em>{chunk.text}</em>
            ) : (
              <span>{chunk.text}</span>
            )}
          </span>
        );
      })}
    </>
  );
}

function generateColourFromPercentage(percentage: number): string {
  if (percentage < 0.8) {
    return 'red';
  } else if (percentage >= 0.8 && percentage < 1) {
    return '#FFA940';
  } else {
    return '#73D13D';
  }
}

export {
  getIntentForProgressBar,
  getIntentForHabitProgressBar,
  renderTextChunks,
  generateColourFromPercentage,
};

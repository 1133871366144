import React, { useContext, useEffect, useState } from 'react';

import { useLocation } from 'react-router-dom';
import { useAllPageStyles } from '../../style/components/pageStyles';
import { useLayoutStyles } from '../../style/components/layoutStyles';
import { GoalContext } from '../../state/GoalContext';
import { GoalType, IGoal } from '../../common-src/types/Goal';
import { OverallHabitSummary } from '../common/habits/OverallHabitSummary';
import { HabitSummary } from '../common/habits/HabitSummary';
import { EditHabitModal } from '../modals/EditHabitModal';
import { UserContext } from '../../state/UserContext';
import { AddHabitModal } from '../modals/AddHabitModal';
import { Button } from '@blueprintjs/core';
import { ModalContext } from '../../state/ModalContext';
import { HabitsPageOnboarding } from '../common/onboarding/HabitsPageOnboarding';

const HabitsPage: React.FunctionComponent = () => {
  const goalContext = useContext(GoalContext);
  const userContext = useContext(UserContext);
  const modalContext = useContext(ModalContext);

  const location = useLocation();
  const allPageStyles = useAllPageStyles();
  const layoutStyles = useLayoutStyles();

  const { goals, statusOfAllHabits, updateHabit, createHabit } = goalContext!;
  const { trackingPeriod, customTrackingPeriod } = userContext!;
  const { openDeleteHabitModal } = modalContext!;

  const [editHabitModalIsOpen, setEditHabitModalIsOpen] = useState(false);
  const [habitBeingEdited, setHabitBeingEdited] = useState<IGoal | null>(null);

  const [addHabitModalIsOpen, setAddHabitModalIsOpen] = useState(false);

  const habitGoals = goals?.filter(goal => goal.type === GoalType.HABIT);

  useEffect(() => {
    window.scrollTo(0, 0);
    document?.getElementById('main-scrollable')?.scrollTo(0, 0);
  }, [location]);

  const openEditHabitModal = (habit: IGoal) => {
    setEditHabitModalIsOpen(true);
    setHabitBeingEdited(habit);
  };

  const closeEditHabitModal = () => {
    setEditHabitModalIsOpen(false);
    setHabitBeingEdited(null);
  };

  const openAddHabitModal = () => {
    setAddHabitModalIsOpen(true);
  };

  const closeAddHabitModal = () => {
    setAddHabitModalIsOpen(false);
  };

  return (
    <div className={allPageStyles.genericPage}>
      <h1>Habits</h1>
      <div className={layoutStyles.toolbar}>
        <Button icon="plus" onClick={openAddHabitModal} minimal>
          Add habit
        </Button>
      </div>
      {habitGoals && statusOfAllHabits ? (
        <div className={layoutStyles.grid_3_col}>
          <div className={layoutStyles.col}>
            {trackingPeriod && (
              <div className={layoutStyles.col_cell}>
                <OverallHabitSummary
                  habitGoals={habitGoals}
                  overallHabitStatus={statusOfAllHabits}
                  trackingPeriod={trackingPeriod}
                  customTrackingPeriod={customTrackingPeriod}
                />
              </div>
            )}
            {trackingPeriod && (
              <>
                {habitGoals.map(habit => (
                  <div key={habit.id} className={layoutStyles.col_cell}>
                    <HabitSummary
                      habitGoal={habit}
                      habitSummary={
                        statusOfAllHabits?.summaries.find(
                          hs => hs.goalId === habit.id
                        )!
                      }
                      openEditHabitModal={openEditHabitModal}
                      trackingPeriod={trackingPeriod}
                      openDeleteHabitModal={openDeleteHabitModal}
                    />
                  </div>
                ))}
              </>
            )}
          </div>
        </div>
      ) : null}
      <HabitsPageOnboarding />
      <EditHabitModal
        habit={habitBeingEdited!}
        modalIsOpen={editHabitModalIsOpen}
        onRequestClose={closeEditHabitModal}
        saveHabit={updateHabit}
        habitSummary={
          statusOfAllHabits?.summaries.find(
            hs => hs.goalId === habitBeingEdited?.id
          )!
        }
      />
      <AddHabitModal
        createHabit={createHabit}
        modalIsOpen={addHabitModalIsOpen}
        onRequestClose={closeAddHabitModal}
      />
    </div>
  );
};

export { HabitsPage };

import _ from 'lodash';
import { ITask } from '../common-src/types/Task';
import {
  getCompletionForTrackingType,
  getTargetCompletionForPresentMoment,
} from '../common-src/productivity';
import { DateTime } from 'luxon';

export const getTrackingType = (
  task: ITask
): 'CHECKLIST_ONLY' | 'UNITS_ONLY' | 'BOTH' | 'NONE' => {
  let trackingType: 'CHECKLIST_ONLY' | 'UNITS_ONLY' | 'BOTH' | 'NONE' = 'NONE';

  if (task.subtasks && task.subtasks.length) {
    trackingType = 'CHECKLIST_ONLY';
  } else if (task.isTrackingUnits) {
    trackingType = 'UNITS_ONLY';
  }

  if (task.subtasks && task.subtasks.length && task.isTrackingUnits) {
    trackingType = 'BOTH';
  }

  return trackingType;
};

export const getWhatNeedsToBeDoneForTask = (task: ITask) => {
  const trackingType = getTrackingType(task);
  const taskCompletion = getCompletionForTrackingType(task, trackingType);

  let whatNeedsToBeDone = '';

  if (taskCompletion >= 1) {
    whatNeedsToBeDone = `You've completed all the constitutent parts of this task.`;
  } else if (trackingType === 'CHECKLIST_ONLY') {
    const numberOfSubtasksCompleted = task.subtasks!.filter(
      sub => sub.done
    ).length;
    if (task.startDate && task.dueDate) {
      const targetCompletion = getTargetCompletionForPresentMoment(
        DateTime.fromISO(task.startDate),
        DateTime.fromISO(task.dueDate)
      );
      const numberOfTasksThatShouldHaveBeenCompleted =
        targetCompletion * task.subtasks!.length;

      const finalMessage =
        numberOfSubtasksCompleted >= numberOfTasksThatShouldHaveBeenCompleted
          ? `Therefore, you're on target!`
          : `This means you need to complete ${(
              numberOfTasksThatShouldHaveBeenCompleted -
              numberOfSubtasksCompleted
            ).toFixed(2)} subtasks in order to be on track.`;

      whatNeedsToBeDone = `To be on track, you need to have completed ${numberOfTasksThatShouldHaveBeenCompleted.toFixed(
        2
      )} subtasks by now. You have completed ${numberOfSubtasksCompleted} subtasks. ${finalMessage}`;
    } else {
      const finalMessage =
        numberOfSubtasksCompleted >= task.subtasks!.length
          ? 'Well done!'
          : `You need to complete ${
              task.subtasks!.length - numberOfSubtasksCompleted
            } more subtasks to complete this task.`;

      whatNeedsToBeDone = `You've completed ${numberOfSubtasksCompleted} out of ${
        task.subtasks!.length
      } subtasks. ${finalMessage}`;
    }
  } else if (trackingType === 'UNITS_ONLY') {
    if (task.startDate && task.dueDate) {
      const targetCompletion = getTargetCompletionForPresentMoment(
        DateTime.fromISO(task.startDate),
        DateTime.fromISO(task.dueDate)
      );
      const unitsThatShouldHaveBeenCompleted =
        targetCompletion * task.unitTarget!;
      const finalMessage =
        task.unitsCompleted! >= unitsThatShouldHaveBeenCompleted
          ? `Therefore, you're on target!`
          : `This means you need to complete ${(
              unitsThatShouldHaveBeenCompleted - task.unitsCompleted!
            ).toFixed(2)} ${task.unitName || 'units'} to be on track.`;
      whatNeedsToBeDone = `To be on track, you need to have completed ${unitsThatShouldHaveBeenCompleted.toFixed(
        2
      )} ${task.unitName || 'units'} by now. You have completed ${
        task.unitsCompleted
      } ${task.unitName || 'units'}. ${finalMessage}`;
    } else {
      const finalMessage =
        task.unitsCompleted! >= task.unitTarget!
          ? 'Well done!'
          : `You need to complete ${(
              task.unitTarget! - task.unitsCompleted!
            ).toFixed(2)} more ${
              task.unitName || 'units'
            } to complete this task.`;
      whatNeedsToBeDone = `You've completed ${task.unitsCompleted} out of ${
        task.unitTarget
      } ${task.unitName || 'units'}. ${finalMessage}`;
    }
  } else if (trackingType === 'BOTH') {
    const numberOfSubtasksCompleted = task.subtasks!.filter(
      sub => sub.done
    ).length;
    if (task.startDate && task.dueDate) {
      const targetCompletion = getTargetCompletionForPresentMoment(
        DateTime.fromISO(task.startDate),
        DateTime.fromISO(task.dueDate)
      );
      const unitsThatShouldHaveBeenDone = targetCompletion * task.unitTarget!;
      const subtasksThatShouldHaveBeenDone =
        targetCompletion * task.subtasks!.length;

      const unitsToComplete =
        unitsThatShouldHaveBeenDone - task.unitsCompleted!;
      const subtasksToComplete =
        subtasksThatShouldHaveBeenDone - numberOfSubtasksCompleted;

      let finalMessage;

      if (unitsToComplete > 0 && subtasksToComplete > 0) {
        finalMessage = `Therefore, you need to complete ${unitsToComplete.toFixed(
          2
        )} ${task.unitName || 'units'} and ${subtasksToComplete.toFixed(
          2
        )} subtasks to be on track.`;
      } else if (unitsToComplete > 0) {
        finalMessage = `Therefore, you need to complete ${unitsToComplete.toFixed(
          2
        )} ${task.unitName || 'units'} to be on track.`;
      } else if (subtasksToComplete > 0) {
        finalMessage = `Therefore, you need to complete ${subtasksToComplete.toFixed(
          2
        )} subtasks to be on track.`;
      } else {
        finalMessage = `Therefore, you're on target!`;
      }

      if (taskCompletion >= targetCompletion) {
        whatNeedsToBeDone = `You're on track for this task!`;
      } else {
        whatNeedsToBeDone = `To be on track for this task, you need to have completed ${unitsThatShouldHaveBeenDone.toFixed(
          2
        )} ${
          task.unitName || 'units'
        } and ${subtasksThatShouldHaveBeenDone.toFixed(
          2
        )} subtasks. You have completed ${task.unitsCompleted} ${
          task.unitName || 'units'
        } and ${numberOfSubtasksCompleted} subtasks. ${finalMessage}`;
      }
    } else {
      const unitsToComplete = task.unitTarget! - task.unitsCompleted!;
      const subtasksToComplete =
        task.subtasks!.length - numberOfSubtasksCompleted;
      let finalMessage;
      if (unitsToComplete > 0 && subtasksToComplete > 0) {
        finalMessage = `You need to complete ${unitsToComplete} ${
          task.unitName || 'units'
        } and ${subtasksToComplete} subtasks to complete this task.`;
      } else if (unitsToComplete > 0) {
        finalMessage = `You need to complete ${unitsToComplete} ${
          task.unitName || 'units'
        } to complete this task.`;
      } else if (subtasksToComplete > 0) {
        finalMessage = `You need to complete ${subtasksToComplete} subtasks to complete this task.`;
      } else {
        finalMessage = 'Well done!';
      }

      whatNeedsToBeDone = `You've completed ${task.unitsCompleted} out of ${
        task.unitTarget
      } ${task.unitName || 'units'} and ${numberOfSubtasksCompleted} out of ${
        task.subtasks!.length
      } subtasks. ${finalMessage}`;
    }
  }

  return whatNeedsToBeDone;
};

import React, { ReactNode } from 'react';
import { Auth0Provider } from '@auth0/auth0-react';
import { UserContextProvider } from './UserContext';
import { ProjectsListContextProvider } from './ProjectsListContext';
import { TasksContextProvider } from './TasksContext';
import { SessionAndUnitContextProvider } from './SessionAndUnitContext';
import { LogContextProvider } from './LogContext';
import { TagsContextProvider } from './TagsContext';
import { AlertsContextProvider } from './AlertsContext';
import { WindowContextProvider } from './WindowContext';
import { TempMobileMenuContextProvider } from './TempMobileMenuContext';
import { PomodoroContextProvider } from './PomodoroContext';
import { ModalContextProvider } from './ModalContext';
import { AuthenticationContextProvider } from './AuthenticationContext';
import { FeatureContextProvider } from './FeatureContext';
import { getConfig } from '../config';
import { BrowserRouter } from 'react-router-dom';
import { ProjectContextProvider } from './ProjectContext';
import { GoalContextProvider } from './GoalContext';
import { ProductivityContextProvider } from './ProductivityContext';
import {
  QueryClient,
  QueryClientProvider,
  useQuery,
} from '@tanstack/react-query';
import { OnboardingContextProvider } from './OnboardingContext';

interface Props {
  children: ReactNode;
}

const AppStateProvider: React.FunctionComponent<Props> = (props: Props) => {
  const config = getConfig();
  const queryClient = new QueryClient();
  return (
    <BrowserRouter>
      <QueryClientProvider client={queryClient}>
        <FeatureContextProvider>
          <Auth0Provider
            domain={config.auth.domain}
            clientId={config.auth.clientId}
            audience={config.auth.audience}
            redirectUri={window.location.origin}
          >
            <AuthenticationContextProvider>
              <UserContextProvider>
                <OnboardingContextProvider>
                  <ProductivityContextProvider>
                    <GoalContextProvider>
                      <ProjectsListContextProvider>
                        <ProjectContextProvider>
                          <TasksContextProvider>
                            <SessionAndUnitContextProvider>
                              <LogContextProvider>
                                <PomodoroContextProvider>
                                  <TagsContextProvider>
                                    <ModalContextProvider>
                                      <AlertsContextProvider>
                                        <WindowContextProvider>
                                          <TempMobileMenuContextProvider>
                                            {props.children}
                                          </TempMobileMenuContextProvider>
                                        </WindowContextProvider>
                                      </AlertsContextProvider>
                                    </ModalContextProvider>
                                  </TagsContextProvider>
                                </PomodoroContextProvider>
                              </LogContextProvider>
                            </SessionAndUnitContextProvider>
                          </TasksContextProvider>
                        </ProjectContextProvider>
                      </ProjectsListContextProvider>
                    </GoalContextProvider>
                  </ProductivityContextProvider>
                </OnboardingContextProvider>
              </UserContextProvider>
            </AuthenticationContextProvider>
          </Auth0Provider>
        </FeatureContextProvider>
      </QueryClientProvider>
    </BrowserRouter>
  );
};

export default AppStateProvider;

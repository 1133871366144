import { DateTime } from 'luxon';
import React from 'react';
import { TaskContext } from '../common-src/types/UiTypes';

/**
 * Takes a number of minutes and converts it into a readable string (i.e. containing hours and minutes as appropriate).
 * @param inputMinutes
 */
export function makeMinutesReadable(inputMinutes: number): string {
  let readableTime = '';
  let hours: number, minutes: number;
  let minutesString = '';
  let hoursString = '';

  if (inputMinutes > 60) {
    minutes = inputMinutes % 60;
    hours = (inputMinutes - minutes) / 60;

    if (minutes > 1) {
      minutesString = 'and ' + minutes.toFixed(0) + ' minutes';
    } else if (minutes === 1) {
      minutesString = 'and 1 minute';
    }

    if (hours > 1) hoursString = hours.toFixed(0) + ' hours ';
    else hoursString = hours.toFixed(0) + ' hour ';

    readableTime = hoursString + minutesString;
  } else if (inputMinutes === 60) {
    readableTime = 'one hour';
  } else {
    readableTime = inputMinutes.toFixed(0) + ' minutes';
  }

  return readableTime;
}

/**
 * Same as makeMinutesReadable(), except adapted for the needs of individual sessions.
 * @param inputMinutes
 */
export function makeMinutesReadableForSession(inputMinutes: number) {
  let readableTime = '';
  let hours: number, minutes: number;
  let minutesString = '';
  let hoursString = '';

  if (!inputMinutes) {
    readableTime = '0 minutes';
  } else if (inputMinutes > 60) {
    minutes = inputMinutes % 60;
    hours = (inputMinutes - minutes) / 60;

    if (minutes > 1) {
      minutesString = 'and ' + minutes.toFixed(0) + ' minutes ';
    } else if (minutes === 1) {
      minutesString = 'and 1 minute ';
    }

    if (hours > 1) hoursString = hours.toFixed(0) + ' hours ';
    else hoursString = hours.toFixed(0) + ' hour ';

    readableTime = hoursString + minutesString;
  } else if (inputMinutes === 60) {
    readableTime = 'one hour';
  } else {
    readableTime = inputMinutes.toFixed(0) + ' minute';
  }

  return readableTime;
}

// TODO: write tests for this function
export function getReadableDueDate(
  dueDate: DateTime,
  taskContext: TaskContext
): JSX.Element {
  let readableDueDate: JSX.Element = <span />;

  const diffBetwixtDueDateAndStartOfDayToday = dueDate
    .startOf('day')
    .diff(DateTime.now().startOf('day'))
    .as('days');

  if (Math.round(diffBetwixtDueDateAndStartOfDayToday) === 0) {
    readableDueDate = <span>Due today</span>;
  } else if (Math.round(diffBetwixtDueDateAndStartOfDayToday) === 1) {
    readableDueDate = <span>Due tomorrow</span>;
  } else if (
    Math.round(diffBetwixtDueDateAndStartOfDayToday) >= 2 &&
    Math.round(diffBetwixtDueDateAndStartOfDayToday) <= 7 &&
    dueDate.startOf('day').weekday > DateTime.now().startOf('day').weekday
  ) {
    readableDueDate = <span>{`Due this ${dueDate.weekdayLong}`}</span>;
  } else if (diffBetwixtDueDateAndStartOfDayToday < 0) {
    const difference = Math.abs(
      Math.round(diffBetwixtDueDateAndStartOfDayToday)
    );
    readableDueDate = (
      <span style={{ color: 'darkred' }}>{`Due ${difference} days ago`}</span>
    );
  } else {
    readableDueDate = <span>{dueDate.toLocaleString(DateTime.DATE_MED)}</span>;
  }

  // if task is due today or tomorrow and the user is viewing tasks by due date, don't show the due date
  if (taskContext === 'due_date') {
    if (
      dueDate.startOf('day').equals(DateTime.now().startOf('day')) ||
      Math.round(diffBetwixtDueDateAndStartOfDayToday) === 1
    ) {
      readableDueDate = <span />;
    }
  }

  return readableDueDate;
}

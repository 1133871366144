export interface ILog {
  id: string;
  date: string;
  note: string;
  type: LogType;
  projectId?: string | number;
}

export interface IApiLog {
  id: string;
  datetime: string;
  note: string;
  type: LogType;
  project_id?: string;
}

export interface IApiCreateLogRequest {
  datetime: string;
  note: string;
  project_id?: string;
}

export interface IApiUpdateLogRequest {
  datetime?: string;
  note?: string;
  project_id?: string;
}

export enum LogType {
  LOG = 'log',
  TASK = 'task',
  SESSION = 'session',
}

import React, { useContext, useState, useEffect } from 'react';
import {
  Checkbox,
  RadioGroup,
  Radio,
  Button,
  Intent,
  Switch,
  Divider,
  NumericInput,
} from '@blueprintjs/core';
import { UserContext } from '../../state/UserContext';
import { IApiUpdateUserRequest } from '../../common-src/types/User';
import { AlertsContext } from '../../state/AlertsContext';
import { useLocation } from 'react-router-dom';
import { useSettingsPageStyles } from '../../style/pages/settingsPageStyles';
import { useAllPageStyles } from '../../style/components/pageStyles';
import { TrackingPeriod } from '../../common-src/types/User';

// @TODO: surface validation errors to the user

const Settings: React.FunctionComponent = () => {
  const userContext = useContext(UserContext);
  const alertsContext = useContext(AlertsContext);
  const allPageStyles = useAllPageStyles();
  const settingsPageStyles = useSettingsPageStyles();

  const location = useLocation();

  const [showCompletedTasks, setShowCompletedTasks] = useState(
    userContext?.showCompletedTasks
  );
  const [trackingPeriod, setTrackingPeriod] = useState(
    userContext?.trackingPeriod
  );
  const [customTrackingPeriod, setCustomTrackingPeriod] = useState(
    userContext?.customTrackingPeriod
  );
  const [isTrackingPeriodCustom, setIsTrackingPeriodCustom] = useState(
    userContext?.isTrackingPeriodCustom
  );
  const [shouldReceiveReminders, setShouldReceiveReminders] = useState(
    userContext?.shouldReceiveReminders
  );
  const [reminderType, setReminderType] = useState(userContext?.reminderType);
  const [phoneNumber, setPhoneNumber] = useState(
    userContext?.phoneNumber ?? ''
  );

  useEffect(() => {
    window.scrollTo(0, 0);
    document?.getElementById('main-scrollable')?.scrollTo(0, 0);
  }, [location]);

  useEffect(() => {
    setShowCompletedTasks(userContext?.showCompletedTasks);
  }, [userContext?.showCompletedTasks]);

  useEffect(() => {
    if (userContext?.trackingPeriod) {
      setTrackingPeriod(userContext.trackingPeriod);
    }
  }, [userContext?.trackingPeriod]);

  useEffect(() => {
    if (userContext?.customTrackingPeriod) {
      setCustomTrackingPeriod(userContext.customTrackingPeriod);
    }
  }, [userContext?.customTrackingPeriod]);

  useEffect(() => {
    setIsTrackingPeriodCustom(userContext?.isTrackingPeriodCustom);
  }, [userContext?.isTrackingPeriodCustom]);

  useEffect(() => {
    setShouldReceiveReminders(userContext?.shouldReceiveReminders);
  }, [userContext?.shouldReceiveReminders]);

  useEffect(() => {
    if (userContext?.reminderType) {
      setReminderType(userContext.reminderType);
    }
  }, [userContext?.reminderType]);

  useEffect(() => {
    if (userContext?.phoneNumber) {
      setPhoneNumber(userContext.phoneNumber);
    }
  }, [userContext?.phoneNumber]);

  if (!userContext) {
    return null;
  }

  const handleCheckCompletedTasks = () => {
    setShowCompletedTasks(!showCompletedTasks);
  };

  const handleTrackingPeriodChange = event => {
    setTrackingPeriod(event.target.value);
  };

  const handleCustomTrackingPeriodChange = value => {
    setCustomTrackingPeriod(value);
  };

  const handleIsTrackingPeriodCustomChange = event => {
    setIsTrackingPeriodCustom(event.target.checked);
  };

  const handleReceiveReminderChange = event => {
    setShouldReceiveReminders(event.target.checked);
  };

  const handleReminderTypeChange = event => {
    setReminderType(event.target.value);
  };

  const handlePhoneNumberChange = event => {
    setPhoneNumber(event.target.value);
  };

  const validatePhoneNumber = (phoneNumber: string) => {
    // @TODO!!!
  };

  const isSaveButtonDisabled = (): boolean => {
    if (reminderType === 'sms' && !phoneNumber) {
      return true;
    } else if (shouldReceiveReminders && !reminderType) {
      return true;
    } else return false;
  };

  const handleSaveSettings = async () => {
    const { updateUser } = userContext!;
    const newSettings: IApiUpdateUserRequest = {
      show_completed_tasks: showCompletedTasks,
      tracking_period: trackingPeriod,
      custom_tracking_period: customTrackingPeriod,
      is_tracking_period_custom: isTrackingPeriodCustom,
      should_receive_reminders: shouldReceiveReminders,
      reminder_type: reminderType,
      phone_number: phoneNumber,
    };
    try {
      await updateUser(newSettings);
      alertsContext!.addAlert('Successfully updated settings', Intent.SUCCESS);
    } catch (err) {
      alertsContext!.addAlert('Error updating settings', Intent.DANGER);
    }
  };

  const handleSubscribeToNotifications = async () => {
    try {
      await userContext!.subscribeToNotifications();
      alertsContext!.addAlert(
        'Successfully subscribed to notifications',
        Intent.SUCCESS
      );
    } catch (err) {
      alertsContext!.addAlert(
        'Error subscribing to notifications',
        Intent.DANGER
      );
    }
  };

  if (!showCompletedTasks && !trackingPeriod) {
    return null;
  }

  return (
    <div
      className={`${allPageStyles.genericPage} ${settingsPageStyles.settingsPage}`}
    >
      <div className={settingsPageStyles.settingsForm}>
        <h1>Settings</h1>
        <div style={{ textAlign: 'left' }}>
          <Checkbox
            checked={showCompletedTasks}
            label="Show completed tasks?"
            onChange={handleCheckCompletedTasks}
            inline={true}
          />
        </div>
        <Divider />
        <div>
          <h2>Tracking period</h2>
          <label className="bp4-label">
            <div className="bp4-select">
              <select
                value={trackingPeriod}
                onChange={handleTrackingPeriodChange}
                disabled={isTrackingPeriodCustom}
              >
                <option value={TrackingPeriod.TODAY}>Today</option>
                <option value={TrackingPeriod.LAST_7_DAYS}>Last 7 days</option>
                <option value={TrackingPeriod.THIS_WEEK}>This week</option>
                <option value={TrackingPeriod.THIS_MONTH}>This month</option>
                <option value={TrackingPeriod.THIS_QUARTER}>
                  This quarter
                </option>
                <option value={TrackingPeriod.THIS_YEAR}>This year</option>
                <option value={TrackingPeriod.ALL_TIME}>All time</option>
                <option value={TrackingPeriod.CUSTOM}>Custom</option>
              </select>
            </div>
          </label>
        </div>
        <div>
          <Switch
            checked={isTrackingPeriodCustom}
            label="Custom tracking period (in days)"
            onChange={handleIsTrackingPeriodCustomChange}
          />
          <NumericInput
            value={customTrackingPeriod || 1}
            onValueChange={handleCustomTrackingPeriodChange}
            disabled={!isTrackingPeriodCustom}
            allowNumericCharactersOnly
            min={1}
            max={365}
            clampValueOnBlur={true}
          />
        </div>
        <Divider />
        <h2>Reminders</h2>
        <div style={{ textAlign: 'left' }}>
          <Switch
            checked={shouldReceiveReminders}
            label="Receive reminders"
            onChange={handleReceiveReminderChange}
          />

          {shouldReceiveReminders && (
            <RadioGroup
              label="How would you like to receive reminders?"
              onChange={handleReminderTypeChange}
              selectedValue={reminderType}
            >
              <Radio label="Text" value="sms" />
              <Radio label="Email" value="email" />
              <Radio label="Browser notification" value="push" />
            </RadioGroup>
          )}

          <label className="bp4-label" style={{ marginTop: 10 }}>
            Phone number (for text reminders):
            <input
              className="bp4-input bp4-fill"
              type="text"
              onChange={handlePhoneNumberChange}
              value={phoneNumber}
            />
          </label>
        </div>
        <Divider />
        {/* <Button
          onClick={handleSubscribeToNotifications}
          intent={Intent.PRIMARY}
        >
          Get push notifications
        </Button> */}
        <Button
          onClick={handleSaveSettings}
          intent={Intent.PRIMARY}
          disabled={isSaveButtonDisabled()}
        >
          Save
        </Button>
      </div>

      <div className={settingsPageStyles.settingsInfo} />
    </div>
  );
};

export { Settings };

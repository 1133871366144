import { Button } from '@blueprintjs/core';
import React, { useContext, useEffect, useState } from 'react';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';

import { ModalContext } from '../../state/ModalContext';
import { UserContext } from '../../state/UserContext';
import { useLayoutStyles } from '../../style/components/layoutStyles';
import { useAllPageStyles } from '../../style/components/pageStyles';
import { UnitType } from '../common/unitTypes/UnitType';
import { handleGenericStringIdsDragDrop } from '../../func/task-ui-utils';

const UnitTypesPage: React.FunctionComponent = () => {
  const userContext = useContext(UserContext);
  const modalContext = useContext(ModalContext);

  const allPageStyles = useAllPageStyles();
  const layoutStyles = useLayoutStyles();

  const { unitTypes, updateUnitTypesOrder } = userContext!;
  const {
    openAddUnitTypeModal,
    openEditUnitTypeModal,
    openDeleteUnitTypeModal,
  } = modalContext!;

  const [unitTypesToShow, setUnitTypesToShow] = useState(unitTypes);

  useEffect(() => {
    setUnitTypesToShow(unitTypes);
  }, [unitTypes]);

  const onDragEnd = (result: any) => {
    if (unitTypesToShow) {
      const unitTypeIds = unitTypesToShow.map(unitType => unitType.id);
      const newUnitTypeIds = handleGenericStringIdsDragDrop(
        result,
        unitTypeIds
      );
      if (newUnitTypeIds) {
        setUnitTypesToShow(
          newUnitTypeIds.map(
            (newUnitTypeId: string) =>
              unitTypesToShow.find(unitType => unitType.id === newUnitTypeId)!
          )
        );
        updateUnitTypesOrder(newUnitTypeIds);
      }
    }
  };

  return (
    <div className={allPageStyles.genericPage}>
      <DragDropContext onDragEnd={onDragEnd}>
        <h1>Unit types</h1>
        <p>
          <i>
            Unit types can be used to measure the amount of work you do. You can
            create unit types like "miles" or "pages" and then associate them
            with other things, like projects, sessions, and tasks.
          </i>
        </p>
        <div className={layoutStyles.toolbar}>
          <Button icon="plus" onClick={openAddUnitTypeModal} minimal>
            Add unit type
          </Button>
        </div>
        <div className={layoutStyles.grid_3_col}>
          <div className={layoutStyles.col}>
            <Droppable droppableId="user-unit-types" type="unit-types">
              {provided => (
                <div
                  ref={provided.innerRef}
                  {...provided.droppableProps}
                  className={layoutStyles.col_cell}
                >
                  <>
                    {unitTypesToShow?.map((unitType, index) => (
                      <div className={layoutStyles.col_cell} key={unitType.id}>
                        <Draggable
                          draggableId={unitType.id}
                          index={index}
                          key={unitType.id}
                        >
                          {(provided, snapshot) => (
                            <div
                              ref={provided.innerRef}
                              {...provided.draggableProps}
                              {...provided.dragHandleProps}
                            >
                              <UnitType
                                unitType={unitType}
                                openEditUnitTypeModal={openEditUnitTypeModal}
                                openDeleteUnitTypeModal={
                                  openDeleteUnitTypeModal
                                }
                              />
                            </div>
                          )}
                        </Draggable>
                      </div>
                    ))}
                  </>
                </div>
              )}
            </Droppable>
          </div>
        </div>
      </DragDropContext>
    </div>
  );
};

export { UnitTypesPage };

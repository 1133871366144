import React, { useState, useContext } from 'react';
import { Dialog, Button, ButtonGroup, Intent } from '@blueprintjs/core';
import { AlertsContext } from '../../state/AlertsContext';
import { useModalStyles } from '../../style/components/genericStyles';

interface Props {
  level: 'user' | 'project';
  projectId?: string;
  onRequestClose: () => void;
  modalIsOpen: boolean;
  addProjectPhase?: (projectId: string, phaseName: string) => Promise<void>;
  addUserPhase?: (phaseName: string) => Promise<void>;
}

const AddPhaseModal: React.FunctionComponent<Props> = (props: Props) => {
  const alertsContext = useContext(AlertsContext);
  const modalStyles = useModalStyles();

  const [phaseName, setPhaseName] = useState<string>('');
  const [isSaving, setIsSaving] = useState<boolean>(false);

  const handleTextChange = event => {
    setPhaseName(event.target.value);
  };

  const handleClose = () => {
    setPhaseName('');
    props.onRequestClose();
  };

  const handleAddPhase = async event => {
    event.preventDefault();
    setIsSaving(true);
    const { projectId, level } = props;

    if (phaseName !== '') {
      try {
        if (level === 'project' && projectId && props.addProjectPhase) {
          await props.addProjectPhase(projectId, phaseName);
          alertsContext!.addAlert(
            `Successfully added phase "${phaseName}"`,
            Intent.SUCCESS
          );
        } else if (level === 'user' && props.addUserPhase) {
          await props.addUserPhase(phaseName);
          alertsContext!.addAlert(
            `Successfully added phase "${phaseName}"`,
            Intent.SUCCESS
          );
        }
      } catch (err) {
        alertsContext!.addAlert(
          `Error adding phase "${phaseName}"`,
          Intent.DANGER
        );
      } finally {
        setIsSaving(false);
        handleClose();
      }
    }
  };

  if (props.level === 'project' && !props.projectId) {
    return null;
  }

  return (
    <Dialog
      isOpen={props.modalIsOpen}
      onClose={props.onRequestClose}
      title="Add a phase"
      canOutsideClickClose={false}
    >
      <div className={`bp4-dialog-body ${modalStyles.body}`}>
        <label className="bp4-label">
          Enter phase name here:
          <input
            className="bp4-input bp4-fill"
            type="text"
            onChange={handleTextChange}
            autoFocus
          />
        </label>
      </div>
      <div className={`bp4-dialog-footer ${modalStyles.footer}`}>
        <ButtonGroup>
          <Button onClick={handleClose}>Cancel</Button>
          <Button
            onClick={handleAddPhase}
            intent={Intent.PRIMARY}
            loading={isSaving}
          >
            Add
          </Button>
        </ButtonGroup>
      </div>
    </Dialog>
  );
};

export { AddPhaseModal };

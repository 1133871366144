import { Menu, MenuItem, Popover } from '@blueprintjs/core';
import React from 'react';
import { IPhase } from '../../../common-src/types/Project';
import { usePhaseStyles } from '../../../style/components/phaseStyles';
import { makeMinutesReadable } from '../../../func/readability-utils';
import { Badge } from '../Badge';

interface IProps {
  isTruePhase: boolean;
  phase?: IPhase;
  openEditPhaseModal?: (phase: IPhase) => void;
  openDeletePhaseModal?: (phaseToDelete: IPhase) => void;
  dragHandleProps?: any;
  numberOfTasks?: number;
}

const PhaseHeader: React.FunctionComponent<IProps> = (props: IProps) => {
  const {
    isTruePhase,
    phase,
    openEditPhaseModal,
    openDeletePhaseModal,
    dragHandleProps,
    numberOfTasks,
  } = props;
  const phaseStyles = usePhaseStyles();

  const phaseTitle = phase?.name;

  const estimateString = phase?.estimatedTimeToCompleteAllTasks
    ? `Estimated time to complete: ${makeMinutesReadable(
        phase.estimatedTimeToCompleteAllTasks
      )}`
    : '';

  const handleClickEditPhase = () => {
    if (openEditPhaseModal && phase) {
      openEditPhaseModal(phase);
    }
  };

  const handleClickDeletePhase = () => {
    if (openDeletePhaseModal && phase) {
      openDeletePhaseModal(phase);
    }
  };

  const dropdown = (
    <Menu>
      <MenuItem icon="cog" text="Edit phase" onClick={handleClickEditPhase} />
      <MenuItem
        icon="trash"
        text="Delete phase"
        onClick={handleClickDeletePhase}
      />
    </Menu>
  );

  return (
    <div
      className={phaseStyles.phaseHeader}
      {...dragHandleProps}
      style={{ position: 'sticky', top: 0, zIndex: 1 }}
    >
      {isTruePhase && phase ? (
        <Popover content={dropdown}>
          <>
            <div className={phaseStyles.phaseTitle}>
              <span>{phaseTitle}</span>
              {numberOfTasks ? (
                <Badge
                  text={numberOfTasks?.toString()!}
                  backgroundColour="rgb(245,140,130)"
                />
              ) : null}
            </div>
            <div style={{ fontSize: '0.8em' }}>{estimateString}</div>
          </>
        </Popover>
      ) : (
        <>Uncategorised tasks</>
      )}
    </div>
  );
};

export { PhaseHeader };

import React, { useEffect, useState } from 'react';
import TaskGroup from '../tasks/TaskGroup';
import { useLayoutStyles } from '../../../style/components/layoutStyles';
import { ITask } from '../../../common-src/types/Task';
import { ITag } from '../../../common-src/types/Tag';
import {
  getOverdueTasks,
  getTasksDueLater,
  getTasksDueLaterThisWeek,
  getTasksDueNextWeek,
  getTasksDueToday,
  getTasksDueTomorrow,
} from '../../../func/functions';
import { DragDropContext, Droppable } from 'react-beautiful-dnd';
import { IOpenAddSessionModalOpts } from '../../../common-src/types/UiTypes';

interface ITasksWithDueDatesProps {
  tasks: ITask[];
  openEditTaskModal: (taskId: ITask) => void;
  openAddSessionModal: (opts?: IOpenAddSessionModalOpts) => void;
}

const TasksWithDueDates: React.FunctionComponent<ITasksWithDueDatesProps> = (
  props: ITasksWithDueDatesProps
) => {
  const { tasks, openEditTaskModal, openAddSessionModal } = props;

  const layoutStyles = useLayoutStyles();
  const [tasksToShow, setTasksToShow] = useState<ITask[]>([]);
  const [filterTags, setFilterTags] = useState<ITag[]>([]);

  useEffect(() => {
    if (tasks) {
      setTasksToShow(tasks);
    }
  }, [tasks]);

  const overdueTasks = getOverdueTasks(tasksToShow);
  const tasksDueToday = getTasksDueToday(tasksToShow);
  const tasksDueTomorrow = getTasksDueTomorrow(tasksToShow);
  const tasksDueLaterThisWeek = getTasksDueLaterThisWeek(tasksToShow);
  const tasksDueNextWeek = getTasksDueNextWeek(tasksToShow);
  const tasksDueLater = getTasksDueLater(tasksToShow);

  const onDragEnd = (_result: any) => {
    return null;
  };

  return (
    <div>
      <DragDropContext onDragEnd={onDragEnd}>
        {overdueTasks.length > 0 && (
          <Droppable droppableId="overdue-tasks">
            {provided => (
              <div
                ref={provided.innerRef}
                {...provided.droppableProps}
                className={layoutStyles.col_cell}
              >
                <TaskGroup
                  tasks={overdueTasks}
                  level="user"
                  type="due_date"
                  title="Overdue tasks"
                  openEditTaskModal={openEditTaskModal}
                  openAddSessionModal={openAddSessionModal}
                />
                {provided.placeholder}
              </div>
            )}
          </Droppable>
        )}
        {tasksDueToday.length > 0 && (
          <Droppable droppableId="tasks-due-today">
            {provided => (
              <div
                ref={provided.innerRef}
                {...provided.droppableProps}
                className={layoutStyles.col_cell}
              >
                <TaskGroup
                  tasks={tasksDueToday}
                  level="user"
                  type="due_date"
                  title="Today"
                  openEditTaskModal={openEditTaskModal}
                  openAddSessionModal={openAddSessionModal}
                />
                {provided.placeholder}
              </div>
            )}
          </Droppable>
        )}
        {tasksDueTomorrow.length > 0 && (
          <Droppable droppableId="tasks-due-tomorrow">
            {provided => (
              <div
                ref={provided.innerRef}
                {...provided.droppableProps}
                className={layoutStyles.col_cell}
              >
                <TaskGroup
                  tasks={tasksDueTomorrow}
                  level="user"
                  type="due_date"
                  title="Tomorrow"
                  openEditTaskModal={openEditTaskModal}
                  openAddSessionModal={openAddSessionModal}
                />
                {provided.placeholder}
              </div>
            )}
          </Droppable>
        )}
        {tasksDueLaterThisWeek.length > 0 && (
          <Droppable droppableId="tasks-due-later-this-week">
            {provided => (
              <div
                ref={provided.innerRef}
                {...provided.droppableProps}
                className={layoutStyles.col_cell}
              >
                <TaskGroup
                  tasks={tasksDueLaterThisWeek}
                  level="user"
                  type="due_date"
                  title="Later this week"
                  openEditTaskModal={openEditTaskModal}
                  openAddSessionModal={openAddSessionModal}
                />
                {provided.placeholder}
              </div>
            )}
          </Droppable>
        )}
        {tasksDueNextWeek.length > 0 && (
          <Droppable droppableId="tasks-due-next-week">
            {provided => (
              <div
                ref={provided.innerRef}
                {...provided.droppableProps}
                className={layoutStyles.col_cell}
              >
                <TaskGroup
                  tasks={tasksDueNextWeek}
                  level="user"
                  type="due_date"
                  title="Next week"
                  openEditTaskModal={openEditTaskModal}
                  openAddSessionModal={openAddSessionModal}
                />
                {provided.placeholder}
              </div>
            )}
          </Droppable>
        )}
        {tasksDueLater.length > 0 && (
          <Droppable droppableId="tasks-due-later">
            {provided => (
              <div
                ref={provided.innerRef}
                {...provided.droppableProps}
                className={layoutStyles.col_cell}
              >
                <TaskGroup
                  tasks={tasksDueLater}
                  level="user"
                  type="due_date"
                  title="Even later"
                  openEditTaskModal={openEditTaskModal}
                  openAddSessionModal={openAddSessionModal}
                />
                {provided.placeholder}
              </div>
            )}
          </Droppable>
        )}
      </DragDropContext>
    </div>
  );
};

export { TasksWithDueDates };

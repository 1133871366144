import React, { ReactNode } from 'react';
import { Toaster, Position, Intent } from '@blueprintjs/core';

interface IAlertsContext {
  addAlert: (message: string, intent: Intent) => void;
}

export const AlertsContext = React.createContext<IAlertsContext | null>(null);

interface Props {
  children?: ReactNode;
}

export const AlertToaster = Toaster.create({
  className: 'alert-toaster',
  position: Position.BOTTOM_RIGHT,
});

const AlertsContextProvider: React.FunctionComponent<Props> = (
  props: Props
) => {
  const addAlert = (message: string, intent: Intent) => {
    AlertToaster.show({ message, intent });
  };

  return (
    <AlertsContext.Provider
      value={{
        addAlert,
      }}
    >
      {props.children}
    </AlertsContext.Provider>
  );
};

export { AlertsContextProvider };
export type { IAlertsContext };

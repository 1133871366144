import React, { useState, useContext, useEffect } from 'react';
import _ from 'lodash';
import { Button, Intent } from '@blueprintjs/core';
import { LogItem } from '../common/logs/LogItem';
import { IProject } from '../../common-src/types/Project';
import { LogContext } from '../../state/LogContext';
import { Loading } from '../common/Loading';
import { ILog } from '../../common-src/types/Log';
import { useLogStyles } from '../../style/components/logStyles';
import { DateTime } from 'luxon';

interface Props {
  openAddLogModal: () => void;
  project: IProject;
}

const ProjectTabLog: React.FunctionComponent<Props> = (props: Props) => {
  const logContext = useContext(LogContext);
  const [sortOrder, setSortOrder] = useState<'asc' | 'desc'>('desc');
  const [logList, setLogList] = useState<ILog[] | null>(null);
  const logStyles = useLogStyles();

  const sortLogs = async (project: IProject, sortOrder: 'asc' | 'desc') => {
    const sortedLogList: any[] = [];

    const projLogs = await logContext?.getLogsByProjectId(project.id);
    projLogs!.forEach(log => {
      if (log.date !== '' && log.note !== '') {
        sortedLogList.push(log);
      }
    });

    if (sortOrder === 'asc') {
      setLogList(
        _.sortBy(sortedLogList, log => {
          const date = log.date || log.completionDateTime;
          return DateTime.fromISO(date).valueOf();
        })
      );
    } else {
      setLogList(
        _.reverse(
          _.sortBy(sortedLogList, log => {
            const date = log.date || log.completionDateTime;
            return DateTime.fromISO(date).valueOf();
          })
        )
      );
    }
  };

  useEffect(() => {
    if (logContext) {
      sortLogs(props.project, sortOrder);
    }
  }, [logContext, props.project, sortOrder]);

  const changeSortOrder = () => {
    const newSortOrder = sortOrder === 'asc' ? 'desc' : 'asc';
    setSortOrder(newSortOrder);
  };

  const { openAddLogModal, project } = props;
  if (!logContext || logContext.allLogs == null) {
    return <Loading />;
  }

  const sortIcon = sortOrder === 'asc' ? 'sort-desc' : 'sort-asc';

  if (!logList) {
    return null;
  }

  return (
    <div style={{ minHeight: 300 }}>
      <Button icon="plus" onClick={openAddLogModal} intent={Intent.PRIMARY}>
        Add log
      </Button>
      <Button onClick={changeSortOrder} icon={sortIcon}>
        {sortOrder === 'asc' ? 'Sort descending' : 'Sort ascending'}
      </Button>
      <div className={logStyles.logListWrapper}>
        {logList &&
          logList.map(log => {
            if (log.date) {
              return <LogItem log={log} key={`${log.date}-${log.note}`} />;
            }
          })}
      </div>
    </div>
  );
};

export { ProjectTabLog };

import React, { ReactNode, useState } from 'react';
import { IProject, ISimplifiedProject } from '../common-src/types/Project';
import { ITask } from '../common-src/types/Task';
import { ILog, LogType } from '../common-src/types/Log';
import { ISession } from '../common-src/types/Session';
import { IGoal } from '../common-src/types/Goal';
import { IUnitType } from '../common-src/types/UnitOfLabour';
import { IOpenAddSessionModalOpts } from '../common-src/types/UiTypes';
import { ITag } from '../common-src/types/Tag';

interface IModalContext {
  taskToDelete: ITask | null;
  projectToDelete?: IProject;
  projectToArchive?: IProject;
  sessionToDelete: ISession | null;
  logToDelete: ILog | null;
  goalToDelete: IGoal | null;
  habitToDelete: IGoal | null;
  logToEdit: ILog | null;
  sessionToEdit: ISession | null;
  taskToEdit: ITask | null;
  goalToEdit: IGoal | null;
  goalToAddSessionTo: IGoal | null;
  taskToAddSessionTo: ITask | null;
  projectToAddSessionTo: ISimplifiedProject | null;
  unitTypeToEdit: IUnitType | null;
  unitTypeToDelete: IUnitType | null;
  tagToDelete: ITag | null;

  addProjectModalIsOpen: boolean;
  addLabourTypeModalIsOpen: boolean;
  addUnitTypeModalIsOpen: boolean;
  editUnitTypeModalIsOpen: boolean;
  addGoalModalIsOpen: boolean;
  editGoalModalIsOpen: boolean;
  addSessionModalIsOpen: boolean;
  editSessionModalIsOpen: boolean;
  editTaskModalIsOpen: boolean;
  deleteTaskModalIsOpen: boolean;
  deleteProjectModalIsOpen: boolean;
  archiveProjectModalIsOpen: boolean;
  deleteSessionModalIsOpen: boolean;
  deleteLogModalIsOpen: boolean;
  deleteGoalModalIsOpen: boolean;
  deleteHabitModalIsOpen: boolean;
  editLogModalIsOpen: boolean;
  deleteUnitTypeModalIsOpen: boolean;
  deleteTagModalIsOpen: boolean;

  openAddProjectModal: () => void;
  closeAddProjectModal: () => void;
  openAddLabourTypeModal: () => void;
  closeAddLabourTypeModal: () => void;
  openAddUnitTypeModal: () => void;
  closeAddUnitTypeModal: () => void;
  openEditUnitTypeModal: (unitType: IUnitType) => void;
  closeEditUnitTypeModal: () => void;
  openDeleteUnitTypeModal: (unitType: IUnitType) => void;
  closeDeleteUnitTypeModal: () => void;
  openAddSessionModal: (opts?: IOpenAddSessionModalOpts) => void;
  closeAddSessionModal: () => void;
  openEditSessionModal: (session: ISession) => void;
  closeEditSessionModal: () => void;
  openEditTaskModal: (task: ITask) => void;
  closeEditTaskModal: () => void;
  openAddGoalModal: () => void;
  closeAddGoalModal: () => void;
  openEditGoalModal: (goal: IGoal) => void;
  closeEditGoalModal: () => void;
  openDeleteTaskModal: (task: ITask) => void;
  closeDeleteTaskModal: () => void;
  openDeleteProjectModal: (project?: IProject) => void;
  closeDeleteProjectModal: () => void;
  openArchiveProjectModal: (project?: IProject) => void;
  closeArchiveProjectModal: () => void;
  openDeleteSessionModal: (session: ISession) => void;
  closeDeleteSessionModal: () => void;
  openDeleteLogModal: (
    logId: string,
    date: Date,
    text: string,
    associatedProjectId: string | number
  ) => void;
  closeDeleteLogModal: () => void;
  openEditLogModal: (
    logId: string,
    date: Date,
    text: string,
    associatedProjectId: string | number
  ) => void;
  closeEditLogModal: () => void;
  openDeleteGoalModal: (goal: IGoal) => void;
  closeDeleteGoalModal: () => void;
  openDeleteHabitModal: (habit: IGoal) => void;
  closeDeleteHabitModal: () => void;
  openDeleteTagModal: (tag: ITag) => void;
  closeDeleteTagModal: () => void;
}

export const ModalContext = React.createContext<IModalContext | null>(null);

interface Props {
  children?: ReactNode;
}

const ModalContextProvider: React.FunctionComponent<Props> = (props: Props) => {
  const [addProjectModalIsOpen, setAddProjectModalIsOpen] = useState(false);
  const [addLabourTypeModalIsOpen, setAddLabourTypeModalIsOpen] =
    useState(false);
  const [addUnitTypeModalIsOpen, setAddUnitTypeModalIsOpen] = useState(false);
  const [editUnitTypeModalIsOpen, setEditUnitTypeModalIsOpen] = useState(false);
  const [addGoalModalIsOpen, setAddGoalModalIsOpen] = useState(false);
  const [editGoalModalIsOpen, setEditGoalModalIsOpen] = useState(false);
  const [addSessionModalIsOpen, setAddSessionModalIsOpen] = useState(false);
  const [editSessionModalIsOpen, setEditSessionModalIsOpen] = useState(false);
  const [editTaskModalIsOpen, setEditTaskModalIsOpen] = useState(false);
  const [deleteTaskModalIsOpen, setDeleteTaskModalIsOpen] = useState(false);
  const [taskToDelete, setTaskToDelete] = useState<ITask | null>(null);
  const [projectToDelete, setProjectToDelete] = useState<IProject | undefined>(
    undefined
  );
  const [projectToArchive, setProjectToArchive] = useState<
    IProject | undefined
  >(undefined);
  const [sessionToDelete, setSessionToDelete] = useState<ISession | null>(null);
  const [goalToDelete, setGoalToDelete] = useState<IGoal | null>(null);
  const [habitToDelete, setHabitToDelete] = useState<IGoal | null>(null);
  const [deleteLogModalIsOpen, setDeleteLogModalIsOpen] = useState(false);
  const [logToDelete, setLogToDelete] = useState<ILog | null>(null);
  const [editLogModalIsOpen, setEditLogModalIsOpen] = useState(false);
  const [logToEdit, setLogToEdit] = useState<ILog | null>(null);
  const [sessionToEdit, setSessionToEdit] = useState<ISession | null>(null);
  const [taskToEdit, setTaskToEdit] = useState<ITask | null>(null);
  const [goalToEdit, setGoalToEdit] = useState<IGoal | null>(null);
  const [taskToAddSessionTo, setTaskToAddSessionTo] = useState<ITask | null>(
    null
  );
  const [goalToAddSessionTo, setGoalToAddSessionTo] = useState<IGoal | null>(
    null
  );
  const [projectToAddSessionTo, setProjectToAddSessionTo] =
    useState<ISimplifiedProject | null>(null);

  const [unitTypeToEdit, setUnitTypeToEdit] = useState<IUnitType | null>(null);
  const [unitTypeToDelete, setUnitTypeToDelete] = useState<IUnitType | null>(
    null
  );

  const [tagToDelete, setTagToDelete] = useState<ITag | null>(null);

  const [deleteProjectModalIsOpen, setDeleteProjectModalIsOpen] =
    useState(false);
  const [archiveProjectModalIsOpen, setArchiveProjectModalIsOpen] =
    useState(false);
  const [deleteSessionModalIsOpen, setDeleteSessionModalIsOpen] =
    useState(false);
  const [deleteGoalModalIsOpen, setDeleteGoalModalIsOpen] = useState(false);
  const [deleteHabitModalIsOpen, setDeleteHabitModalIsOpen] = useState(false);
  const [deleteUnitTypeModalIsOpen, setDeleteUnitTypeModalIsOpen] =
    useState(false);
  const [deleteTagModalIsOpen, setDeleteTagModalIsOpen] = useState(false);

  const openAddProjectModal = () => {
    setAddProjectModalIsOpen(true);
  };

  const closeAddProjectModal = () => {
    setAddProjectModalIsOpen(false);
  };

  const openAddLabourTypeModal = () => {
    setAddLabourTypeModalIsOpen(true);
  };

  const closeAddLabourTypeModal = () => {
    setAddLabourTypeModalIsOpen(false);
  };

  const openAddUnitTypeModal = () => {
    setAddUnitTypeModalIsOpen(true);
  };

  const closeAddUnitTypeModal = () => {
    setAddUnitTypeModalIsOpen(false);
  };

  const openEditUnitTypeModal = (unitType: IUnitType) => {
    setUnitTypeToEdit(unitType);
    setEditUnitTypeModalIsOpen(true);
  };

  const closeEditUnitTypeModal = () => {
    setUnitTypeToEdit(null);
    setEditUnitTypeModalIsOpen(false);
  };

  const openAddSessionModal = (opts?: IOpenAddSessionModalOpts) => {
    setGoalToAddSessionTo(null);
    setTaskToAddSessionTo(null);
    setProjectToAddSessionTo(null);
    if (opts) {
      if (opts.associatedGoal) {
        setGoalToAddSessionTo(opts.associatedGoal);
      }
      if (opts.associatedTask) {
        setTaskToAddSessionTo(opts.associatedTask);
      }
      if (opts.associatedProject) {
        setProjectToAddSessionTo(opts.associatedProject);
      }
    }
    setAddSessionModalIsOpen(true);
  };

  const closeAddSessionModal = () => {
    setGoalToAddSessionTo(null);
    setTaskToAddSessionTo(null);
    setProjectToAddSessionTo(null);
    setAddSessionModalIsOpen(false);
  };

  const openEditSessionModal = (session: ISession) => {
    setSessionToEdit(session);
    setEditSessionModalIsOpen(true);
  };

  const closeEditSessionModal = () => {
    setSessionToEdit(null);
    setEditSessionModalIsOpen(false);
  };

  const openEditTaskModal = (task: ITask) => {
    setEditTaskModalIsOpen(true);
    setTaskToEdit(task);
  };

  const closeEditTaskModal = () => {
    setEditTaskModalIsOpen(false);
    setTaskToEdit(null);
  };

  const openAddGoalModal = () => {
    setAddGoalModalIsOpen(true);
  };

  const closeAddGoalModal = () => {
    setAddGoalModalIsOpen(false);
  };

  const openEditGoalModal = (goal: IGoal) => {
    setGoalToEdit(goal);
    setEditGoalModalIsOpen(true);
  };

  const closeEditGoalModal = () => {
    setGoalToEdit(null);
    setEditGoalModalIsOpen(false);
  };

  const openDeleteTaskModal = (task: ITask) => {
    setTaskToDelete(task);
    setDeleteTaskModalIsOpen(true);
  };

  const closeDeleteTaskModal = () => {
    setTaskToDelete(null);
    setDeleteTaskModalIsOpen(false);
  };

  const openDeleteProjectModal = (project?: IProject) => {
    setProjectToDelete(project);
    setDeleteProjectModalIsOpen(true);
  };

  const closeDeleteProjectModal = () => {
    setProjectToDelete(undefined);
    setDeleteProjectModalIsOpen(false);
  };

  const openArchiveProjectModal = (project?: IProject) => {
    setProjectToArchive(project);
    setArchiveProjectModalIsOpen(true);
  };

  const closeArchiveProjectModal = () => {
    setProjectToArchive(undefined);
    setArchiveProjectModalIsOpen(false);
  };

  const openDeleteSessionModal = (session: ISession) => {
    setSessionToDelete(session);
    setDeleteSessionModalIsOpen(true);
  };

  const closeDeleteSessionModal = () => {
    setDeleteSessionModalIsOpen(false);
  };

  const openDeleteLogModal = (
    logId: string,
    date: Date,
    text: string,
    associatedProjectId: string | number
  ) => {
    setLogToDelete({
      id: logId,
      date: date.toISOString(),
      note: text,
      type: LogType.LOG,
      projectId: associatedProjectId,
    });
    setDeleteLogModalIsOpen(true);
  };

  const closeDeleteLogModal = () => {
    setLogToDelete(null);
    setDeleteLogModalIsOpen(false);
  };

  const openDeleteUnitTypeModal = (unitType: IUnitType) => {
    setUnitTypeToDelete(unitType);
    setDeleteUnitTypeModalIsOpen(true);
  };

  const closeDeleteUnitTypeModal = () => {
    setUnitTypeToDelete(null);
    setDeleteUnitTypeModalIsOpen(false);
  };

  const openEditLogModal = (
    logId: string,
    date: Date,
    text: string,
    associatedProjectId: string | number
  ) => {
    setLogToEdit({
      id: logId,
      date: date.toISOString(),
      note: text,
      type: LogType.LOG,
      projectId: associatedProjectId,
    });
    setEditLogModalIsOpen(true);
  };

  const closeEditLogModal = () => {
    setLogToEdit(null);
    setEditLogModalIsOpen(false);
  };

  const openDeleteGoalModal = (goal: IGoal) => {
    setGoalToDelete(goal);
    setDeleteGoalModalIsOpen(true);
  };

  const closeDeleteGoalModal = () => {
    setGoalToDelete(null);
    setDeleteGoalModalIsOpen(false);
  };

  const openDeleteHabitModal = (habit: IGoal) => {
    setHabitToDelete(habit);
    setDeleteHabitModalIsOpen(true);
  };

  const closeDeleteHabitModal = () => {
    setHabitToDelete(null);
    setDeleteHabitModalIsOpen(false);
  };

  const openDeleteTagModal = (tag: ITag) => {
    setTagToDelete(tag);
    setDeleteTagModalIsOpen(true);
  };

  const closeDeleteTagModal = () => {
    setTagToDelete(null);
    setDeleteTagModalIsOpen(false);
  };

  return (
    <ModalContext.Provider
      value={{
        taskToDelete,
        logToDelete,
        projectToDelete,
        projectToArchive,
        sessionToDelete,
        goalToDelete,
        habitToDelete,
        logToEdit,
        sessionToEdit,
        taskToEdit,
        goalToEdit,
        goalToAddSessionTo,
        taskToAddSessionTo,
        projectToAddSessionTo,
        unitTypeToEdit,
        unitTypeToDelete,
        tagToDelete,
        addProjectModalIsOpen,
        addLabourTypeModalIsOpen,
        addUnitTypeModalIsOpen,
        editUnitTypeModalIsOpen,
        addGoalModalIsOpen,
        editGoalModalIsOpen,
        addSessionModalIsOpen,
        editSessionModalIsOpen,
        editTaskModalIsOpen,
        deleteTaskModalIsOpen,
        deleteProjectModalIsOpen,
        archiveProjectModalIsOpen,
        deleteSessionModalIsOpen,
        deleteLogModalIsOpen,
        editLogModalIsOpen,
        deleteGoalModalIsOpen,
        deleteHabitModalIsOpen,
        deleteUnitTypeModalIsOpen,
        deleteTagModalIsOpen,
        openAddProjectModal,
        openAddLabourTypeModal,
        openAddUnitTypeModal,
        closeAddUnitTypeModal,
        openEditUnitTypeModal,
        closeEditUnitTypeModal,
        openDeleteUnitTypeModal,
        closeDeleteUnitTypeModal,
        closeAddProjectModal,
        closeAddLabourTypeModal,
        openAddSessionModal,
        closeAddSessionModal,
        openEditSessionModal,
        closeEditSessionModal,
        openEditTaskModal,
        closeEditTaskModal,
        openAddGoalModal,
        closeAddGoalModal,
        openEditGoalModal,
        closeEditGoalModal,
        openDeleteTaskModal,
        closeDeleteTaskModal,
        openDeleteProjectModal,
        openArchiveProjectModal,
        closeDeleteProjectModal,
        closeArchiveProjectModal,
        openDeleteSessionModal,
        closeDeleteSessionModal,
        openDeleteLogModal,
        closeDeleteLogModal,
        openEditLogModal,
        closeEditLogModal,
        openDeleteGoalModal,
        closeDeleteGoalModal,
        openDeleteHabitModal,
        closeDeleteHabitModal,
        openDeleteTagModal,
        closeDeleteTagModal,
      }}
    >
      {props.children}
    </ModalContext.Provider>
  );
};

export { ModalContextProvider };
export type { IModalContext };

import {
  Button,
  Dialog,
  Divider,
  Intent,
  NumericInput,
} from '@blueprintjs/core';
import { DateTime } from 'luxon';
import React, {
  BaseSyntheticEvent,
  useContext,
  useEffect,
  useState,
} from 'react';
import { HabitCadenceUnit, IGoal } from '../../common-src/types/Goal';
import { IHabitSummary } from '../../common-src/types/Reporting';
import { AlertsContext } from '../../state/AlertsContext';
import { useModalStyles } from '../../style/components/genericStyles';
import {
  DatetimeFormat,
  DatetimePicker,
  DatetimePickerType,
} from '../common/DatetimePicker/DatetimePicker';
import { HabitProgressBar } from '../common/HabitProgressBar';
import { HabitCompletions } from '../common/tasks/HabitCompletions';

interface IProps {
  habit: IGoal;
  habitSummary: IHabitSummary;
  onRequestClose: () => void;
  modalIsOpen: boolean;
  saveHabit: (goal: IGoal) => Promise<void>;
}

const EditHabitModal: React.FunctionComponent<IProps> = (props: IProps) => {
  const { habit, habitSummary, onRequestClose, modalIsOpen, saveHabit } = props;
  const alertsContext = useContext(AlertsContext);
  const modalStyles = useModalStyles();

  const [isSaving, setIsSaving] = useState(false);

  const [isStartDateFocused, setIsStartDateFocused] = useState(false);
  const [isEndDateFocused, setIsEndDateFocused] = useState(false);

  const [habitName, setHabitName] = useState<string>('');

  // completions
  const [completions, setCompletions] = useState<string[]>([]);
  const [inAddCompletionMode, setInAddCompletionMode] = useState(false);
  const [inEditCompletionMode, setInEditCompletionMode] = useState(false);
  const [completionDateToAdd, setCompletionDateToAdd] =
    useState<DateTime | null>(DateTime.now());
  const [completionDateToEdit, setCompletionDateToEdit] = useState<string>();

  // cadence and dates
  const [cadenceUnit, setCadenceUnit] = useState<HabitCadenceUnit | undefined>(
    undefined
  );
  const [cadenceRegularity, setCadenceRegularity] = useState<
    number | undefined
  >(undefined);
  const [startDate, setStartDate] = useState<string | undefined>();
  const [endDate, setEndDate] = useState<string | undefined>();

  const handleClose = () => {
    onRequestClose();
  };

  useEffect(() => {
    if (habit) {
      if (habit.completions) {
        setCompletions(habit.completions);
      }
      if (habit.cadence) {
        setCadenceUnit(habit.cadence.unit);
        setCadenceRegularity(+habit.cadence.regularity);
      }
      if (habit.startDate) {
        setStartDate(habit.startDate);
      }
      if (habit.endDate) {
        setEndDate(habit.endDate);
      }
      if (habit.name) {
        setHabitName(habit.name);
      }
    }
  }, [habit]);

  if (!habit || !habitSummary || !cadenceUnit || !cadenceRegularity) {
    return null;
  }

  const actualRegularity = habitSummary.habitScore * cadenceRegularity;

  const handleTextChange = (event: BaseSyntheticEvent) => {
    setHabitName(event.target.value);
  };

  const handleEnterAddCompletionMode = () => {
    setInAddCompletionMode(true);
  };

  const handleEnterEditCompletionMode = (dateString: string) => {
    setInEditCompletionMode(true);
    setCompletionDateToEdit(dateString);
  };

  const handleNewCompletionChange = (newCompletion?: DateTime) => {
    setCompletionDateToAdd(newCompletion ?? null);
  };

  const handleNewCompletionError = () => {
    setCompletionDateToAdd(null);
  };

  const handleAddNewCompletion = () => {
    if (completionDateToAdd) {
      const newCompletionString = completionDateToAdd.toUTC().toISO();
      const newCompletions = completions.slice();
      newCompletions.push(newCompletionString);
      setCompletions(newCompletions.slice());
      setInAddCompletionMode(false);
    }
  };

  const handleSaveEditedCompletion = (
    originalDateString: string,
    newDateString: string
  ) => {
    if (newDateString) {
      const newCompletions = completions.slice();
      const completionIndex = completions.findIndex((completion: string) =>
        DateTime.fromISO(completion).equals(
          DateTime.fromISO(originalDateString)
        )
      );

      if (completionIndex < 0) {
        return;
      }

      newCompletions[completionIndex] = newDateString;
      setCompletions(newCompletions.slice());
      setInEditCompletionMode(false);
    }
  };

  const handleDismissEditingCompletion = () => {
    setInEditCompletionMode(false);
  };

  const handleRegularityChange = value => {
    setCadenceRegularity(value);
  };

  const handleCadenceUnitChange = event => {
    setCadenceUnit(event.target.value);
  };

  const handleStartDateChange = (date?: DateTime) => {
    setStartDate(date?.toISO());
  };

  const handleStartDateError = (date: Date) => {
    setStartDate(undefined);
  };

  const handleStartDateFocusChange = (arg: { focused: boolean }) => {
    setIsStartDateFocused(arg.focused);
  };

  const handleEndDateChange = (date?: DateTime) => {
    setEndDate(date?.toISO());
  };

  const handleEndDateFocusChange = (arg: { focused: boolean }) => {
    setIsEndDateFocused(arg.focused);
  };

  const handleSaveHabit = async event => {
    event.preventDefault();
    if (cadenceRegularity && startDate) {
      try {
        setIsSaving(true);
        const newHabit = {
          ...habit,
          cadence: {
            regularity: cadenceRegularity,
            unit: cadenceUnit,
          },
          completions,
          startDate,
          endDate,
        };
        await saveHabit(newHabit);
        alertsContext!.addAlert('Succesfully updated habit', Intent.SUCCESS);
      } catch (err) {
        alertsContext!.addAlert(
          'There was an error updating the habit',
          Intent.DANGER
        );
      } finally {
        setIsSaving(false);
        handleClose();
      }
    }
  };

  return (
    <Dialog
      isOpen={modalIsOpen}
      onClose={handleClose}
      title="Edit your habit"
      canOutsideClickClose={false}
    >
      <div className={`bp4-dialog-body ${modalStyles.body}`}>
        <div>
          <label className="bp4-label">
            Goal name:
            <input
              className="bp4-input bp4-fill"
              type="text"
              onChange={handleTextChange}
              value={habitName}
            />
          </label>
        </div>
        <>
          <Divider />
          <div>
            <strong>Habit info</strong>
          </div>
          <div>
            <HabitProgressBar
              habitConsistency={habitSummary.habitScore ?? 0}
              tooltipText={`On average, you do this ${actualRegularity.toFixed(
                1
              )} times per ${cadenceUnit.toLocaleLowerCase()}. Your goal is to do it ${cadenceRegularity.toFixed(
                1
              )} times per ${cadenceUnit.toLocaleLowerCase()}.`}
            />
          </div>
          {cadenceUnit && (
            <div>
              <label className="bp4-label">
                Cadence:
                <div className="bp4-select">
                  <select
                    value={cadenceUnit}
                    onChange={handleCadenceUnitChange}
                  >
                    <option
                      value={HabitCadenceUnit.MINUTE}
                      key={HabitCadenceUnit.MINUTE}
                    >
                      Minute-by-minute
                    </option>
                    <option
                      value={HabitCadenceUnit.HOUR}
                      key={HabitCadenceUnit.HOUR}
                    >
                      Hourly
                    </option>
                    <option
                      value={HabitCadenceUnit.DAY}
                      key={HabitCadenceUnit.DAY}
                    >
                      Daily
                    </option>
                    <option
                      value={HabitCadenceUnit.WEEK}
                      key={HabitCadenceUnit.WEEK}
                    >
                      Weekly
                    </option>
                    <option
                      value={HabitCadenceUnit.MONTH}
                      key={HabitCadenceUnit.MONTH}
                    >
                      Monthly
                    </option>
                    <option
                      value={HabitCadenceUnit.YEAR}
                      key={HabitCadenceUnit.YEAR}
                    >
                      Annually
                    </option>
                  </select>
                </div>
              </label>
              <NumericInput
                min={0}
                value={cadenceRegularity}
                fill
                onValueChange={handleRegularityChange}
              />
              <p>{`You plan on doing this task ${cadenceRegularity} time(s) per ${cadenceUnit.toLowerCase()}.`}</p>

              <DatetimePicker
                id="habit_start_date_picker"
                type={DatetimePickerType.date}
                label="Habit start date:"
                placeholder="Enter the start date here"
                datetime={startDate ? DateTime.fromISO(startDate) : undefined}
                onDatetimeChange={handleStartDateChange}
                handleFocusChange={handleStartDateFocusChange}
                isFocused={isStartDateFocused}
                datetimeFormat={DatetimeFormat.DATE}
                canClear={false}
              />
              <DatetimePicker
                id="habit_end_date_picker"
                type={DatetimePickerType.date}
                label="Habit end date:"
                placeholder="Enter the end date here (optional)"
                datetime={endDate ? DateTime.fromISO(endDate) : undefined}
                onDatetimeChange={handleEndDateChange}
                handleFocusChange={handleEndDateFocusChange}
                isFocused={isEndDateFocused}
                datetimeFormat={DatetimeFormat.DATE}
                canClear={false}
              />
            </div>
          )}
          <HabitCompletions
            completions={completions}
            onEnterAddCompletionMode={handleEnterAddCompletionMode}
            onEnterEditCompletionMode={handleEnterEditCompletionMode}
            onNewCompletionChange={handleNewCompletionChange}
            onNewCompletionError={handleNewCompletionError}
            onEditCompletionError={() => {}}
            onAddNewCompletion={handleAddNewCompletion}
            onSaveEditedCompletion={handleSaveEditedCompletion}
            inAddCompletionMode={inAddCompletionMode}
            inEditCompletionMode={inEditCompletionMode}
            completionDateToAdd={completionDateToAdd ?? undefined}
            completionDateToEdit={completionDateToEdit}
            onDismissEditingCompletion={handleDismissEditingCompletion}
          />
          <Divider />
        </>
        <div className={`bp4-dialog-footer ${modalStyles.footer}`}>
          <Button onClick={handleClose}>Cancel</Button>
          <Button
            onClick={handleSaveHabit}
            intent={Intent.PRIMARY}
            loading={isSaving}
          >
            Save
          </Button>
        </div>
      </div>
    </Dialog>
  );
};

export { EditHabitModal };

import React from 'react';
import { DateTime } from 'luxon';
import { ProgressBar, Intent, Position, Tooltip } from '@blueprintjs/core';
import { ITask } from '../../common-src/types/Task';
import {
  getWhatNeedsToBeDoneForTask,
  getTrackingType,
} from '../../func/whatNeedsToBeDoneForTask';
import { getIntentForProgressBar } from '../../func/ui-utils';
import {
  getTargetCompletionForPresentMoment,
  getCompletionForTrackingType,
} from '../../common-src/productivity';
import { useTaskStyles } from '../../style/components/taskStyles';

interface Props {
  task: ITask;
  trackingType?: 'CHECKLIST_ONLY' | 'UNITS_ONLY' | 'BOTH' | 'NONE';
}

const TaskProgressBar: React.FunctionComponent<Props> = (props: Props) => {
  const { task } = props;
  const taskStyles = useTaskStyles();
  const trackingType = props.trackingType || getTrackingType(task);
  if (trackingType === 'NONE') {
    return null;
  }

  const shouldShowTargetBar = Boolean(task.startDate && task.dueDate);
  const targetCompletion = shouldShowTargetBar
    ? getTargetCompletionForPresentMoment(
        DateTime.fromISO(task.startDate!),
        DateTime.fromISO(task.dueDate!)
      )
    : undefined;

  const completion = getCompletionForTrackingType(task, trackingType);
  const intent = getIntentForProgressBar(
    completion,
    targetCompletion,
    DateTime.fromISO(task.dueDate!)
  );

  const whatNeedsToBeDone = (
    <div className={taskStyles.taskProgressPopoverText}>
      {getWhatNeedsToBeDoneForTask(task)}
    </div>
  );

  return (
    <div>
      <Tooltip
        targetClassName={taskStyles.taskProgressWrapper}
        popoverClassName={taskStyles.taskProgressPopover}
        content={whatNeedsToBeDone}
        position={Position.TOP}
      >
        <div>
          {shouldShowTargetBar && (
            <div
              style={{
                marginTop: 5,
                marginBottom: 5,
                display: 'flex',
              }}
            >
              <div style={{ flex: 1, paddingRight: 10 }} />
              <div style={{ flex: 11, paddingTop: 5 }}>
                <ProgressBar
                  intent={Intent.NONE}
                  value={targetCompletion}
                  animate={false}
                  stripes={false}
                />
              </div>
            </div>
          )}
          <div style={{ marginTop: 5, marginBottom: 5, display: 'flex' }}>
            <div
              style={{ flex: 1, paddingRight: 10, textAlign: 'left' }}
            >{`${Math.round(completion * 100)}%`}</div>
            <div style={{ flex: 11, paddingTop: 5 }}>
              <ProgressBar
                intent={intent}
                value={completion}
                animate={false}
                stripes={false}
              />
            </div>
          </div>
        </div>
      </Tooltip>
    </div>
  );
};

export default TaskProgressBar;

import { createUseStyles } from 'react-jss';

export const useSettingsPageStyles = createUseStyles({
  settingsPage: {
    display: 'flex',
  },
  settingsForm: {
    flex: 2,
  },
  settingsInfo: {
    flex: 3,
  },
});

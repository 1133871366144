import React, { ReactNode, useEffect, useState } from 'react';

interface IWindowContext {
  isMobile: boolean;
  currentWidth: number;
}

export const WindowContext = React.createContext<IWindowContext | null>(null);

interface Props {
  children?: ReactNode;
}

const WindowContextProvider: React.FunctionComponent<Props> = (
  props: Props
) => {
  const [currentWidth, setCurrentWidth] = useState<number>(0);

  useEffect(() => {
    const windowWidth = window.innerWidth;
    setCurrentWidth(windowWidth);
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  const handleResize = () => {
    const windowWidth = window.innerWidth;
    setCurrentWidth(windowWidth);
    const shouldBeMobile = determineIfMobile();
    setIsMobile(shouldBeMobile);
  };

  const determineIfMobile = () => {
    const width = window.innerWidth;
    if (width >= 320 && width <= 480) {
      return true;
    } else {
      return false;
    }
  };

  const [isMobile, setIsMobile] = useState(determineIfMobile());

  return (
    <WindowContext.Provider
      value={{
        isMobile,
        currentWidth,
      }}
    >
      {props.children}
    </WindowContext.Provider>
  );
};

export { WindowContextProvider };
export type { IWindowContext };

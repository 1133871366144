export function isEqual(arg1, arg2) {
  if (!arg1 || !arg2) {
    return false;
  } else if (typeof arg1 === typeof arg2) {
    return arg1 === arg2;
  } else {
    return arg1.toString() === arg2.toString();
  }
}

export function isArraysEqual(arr1: any[], arr2: any[]) {
  if (!arr1 || !arr2) {
    return false;
  }
  if (arr1.length !== arr2.length) {
    return false;
  }
  for (let i = 0; i < arr1.length; i++) {
    if (!isEqual(arr1[i], arr2[i])) {
      return false;
    }
  }
  return true;
}

export function capitalise(word: string) {
  return word.charAt(0).toUpperCase() + word.slice(1);
}

import React from 'react';
import { IUiError } from '../../common-src/types/UiTypes';
import { ModalError } from './ModalError';

interface IProps {
  errors: IUiError[];
}

const ModalErrorList: React.FunctionComponent<IProps> = (props: IProps) => {
  const { errors } = props;

  return (
    <div>
      {errors &&
        errors.map(error => (
          <ModalError key={error.message} error={error} type="warning" />
        ))}
    </div>
  );
};

export { ModalErrorList };

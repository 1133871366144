import React, { ReactNode, useState, useEffect, useContext } from 'react';
import _ from 'lodash';
import {
  IApiCreateTaskRequest,
  ITask,
  IApiUpdateTaskRequest,
} from '../common-src/types/Task';
import { IReminderOptions } from '../common-src/types/Reminder';
import { AuthenticationContext } from './AuthenticationContext';
import {
  apiCreateTask,
  apiDeleteTask,
  apiGetTasks,
  apiUpdateTask,
  apiUpdateTaskOrderInProject,
} from '../client/apiClient';
import { useQuery, useQueryClient } from '@tanstack/react-query';

interface ITasksContext {
  tasks?: ITask[];
  saveTask:
    | ((task: ITask, reminderOptions?: IReminderOptions) => Promise<void>)
    | null;
  saveMultipleTasksInProject:
    | ((projectId: string, tasks: ITask[]) => Promise<void>)
    | null;
  addStandardTask:
    | ((taskname: string, projectId: string) => Promise<void>)
    | null;
  deleteTask: (task: ITask) => Promise<void>;
  isFetchingTasks: boolean;
  isSavingTask: boolean;
  isAddingTask: boolean;
  isDeletingTask: boolean;
}

export const TasksContext = React.createContext<ITasksContext | null>(null);

interface Props {
  children: ReactNode;
}

const TasksContextProvider: React.FunctionComponent<Props> = (props: Props) => {
  const queryClient = useQueryClient();

  const authenticationContext = useContext(AuthenticationContext);

  const [isSavingTask, setIsSavingTask] = useState(false);
  const [isAddingTask, setIsAddingTask] = useState(false);
  const [isDeletingTask, setIsDeletingTask] = useState(false);

  const {
    isLoading: isFetchingTasks,
    isError: isFetchTasksError,
    data: tasks,
    error: fetchTasksError,
  } = useQuery({
    queryKey: ['getTasks'],
    queryFn: async () => {
      try {
        if (
          authenticationContext &&
          authenticationContext.isUserAuthenticated
        ) {
          const accessToken = await authenticationContext.getToken();
          const tasks = await apiGetTasks(accessToken);
          return tasks;
        }
      } catch (err) {
        throw err;
      }
    },
    enabled: !!authenticationContext?.isUserAuthenticated,
  });

  const saveTask = async (task: ITask) => {
    setIsSavingTask(true);
    try {
      if (authenticationContext && authenticationContext.isUserAuthenticated) {
        const accessToken = await authenticationContext.getToken();
        const updateTaskRequest: IApiUpdateTaskRequest = {
          name: task.name,
          project_id: task.projectId,
          deleted: task.deleted,

          // completion
          done: task.done,
          completion_datetime: task.completionDateTime,

          // dates and reminders
          due_date: task.dueDate,
          start_date: task.startDate,
          reminder_time: task.reminderTime,

          // notes
          notes: task.notes,

          // checklists and subtasks
          is_tracking_units: task.isTrackingUnits,
          subtasks: task.subtasks,
          unit_name: task.unitName || undefined,
          unit_target: task.unitTarget || undefined,
          units_completed: task.unitsCompleted || undefined,

          // tags
          tag_ids: task.tagIds,

          // estimates
          estimated_minutes: task.estimatedMinutes,
        };
        const newTask = await apiUpdateTask(
          task.id.toString(),
          updateTaskRequest,
          accessToken
        );
        queryClient.invalidateQueries({ queryKey: ['getTasks'] });
        queryClient.invalidateQueries({ queryKey: ['getPhases'] });
        queryClient.invalidateQueries({
          queryKey: ['getProjectById', task.projectId],
        });
      }
    } catch (err) {
      throw err;
    } finally {
      setIsSavingTask(false);
    }
  };

  const deleteTask = async (task: ITask) => {
    setIsDeletingTask(true);
    try {
      if (authenticationContext && authenticationContext.isUserAuthenticated) {
        const accessToken = await authenticationContext.getToken();
        await apiDeleteTask(task.id, accessToken);
        queryClient.invalidateQueries({ queryKey: ['getTasks'] });
        queryClient.invalidateQueries({ queryKey: ['getPhases'] });
        queryClient.invalidateQueries({
          queryKey: ['getProjectById', task.projectId],
        });
      }
    } catch (err) {
      throw err;
    } finally {
      setIsDeletingTask(false);
    }
  };

  const saveMultipleTasksInProject = async (
    projectId: string,
    tasks: ITask[]
  ) => {
    try {
      if (authenticationContext && authenticationContext.isUserAuthenticated) {
        const taskIds = tasks.map(t => t.id);
        const accessToken = await authenticationContext.getToken();
        await apiUpdateTaskOrderInProject(accessToken, projectId, taskIds);
        queryClient.invalidateQueries({ queryKey: ['getTasks'] });
        queryClient.invalidateQueries({ queryKey: ['getPhases'] });
        queryClient.invalidateQueries({
          queryKey: ['getProjectById', projectId],
        });
      }
    } catch (err) {
      throw err;
    }
  };

  const addStandardTask = async (taskName: string, projectId: string) => {
    setIsAddingTask(true);
    try {
      if (authenticationContext && authenticationContext.isUserAuthenticated) {
        const accessToken = await authenticationContext.getToken();
        const createTaskRequest: IApiCreateTaskRequest = {
          name: taskName,
          project_id: projectId,
        };
        const newTask = await apiCreateTask(createTaskRequest, accessToken);
        queryClient.invalidateQueries({ queryKey: ['getTasks'] });
        queryClient.invalidateQueries({ queryKey: ['getPhases'] });
        queryClient.invalidateQueries({ queryKey: ['getProjects'] });
        queryClient.invalidateQueries({
          queryKey: ['getProjectById', projectId],
        });
      }
    } catch (err) {
      throw err;
    } finally {
      setIsAddingTask(false);
    }
  };

  const getTasksContext = () => {
    return {
      tasks,
      saveTask,
      saveMultipleTasksInProject,
      addStandardTask,
      deleteTask,
      isFetchingTasks,
      isSavingTask,
      isAddingTask,
      isDeletingTask,
    };
  };

  const tasksContext = getTasksContext();

  return (
    <TasksContext.Provider value={tasksContext}>
      {props.children}
    </TasksContext.Provider>
  );
};

export { TasksContextProvider };
export type { ITasksContext };

import React, { useContext } from 'react';
import { useGenericStyles } from '../../style/components/genericStyles';
import { useDashboardStyles } from '../../style/pages/dashboardPageStyles';
import { useProjectPageStyles } from '../../style/pages/projectPageStyles';
import { useMainStyles } from '../../style/styleHooks';

export enum ComponentType {
  TaskBoard,
  TodoList,
  GenericBoxWithText,
  DashboardSummary,
  PageTitle,
  ProjectSummary,
}

interface IProps {
  type: ComponentType;
  maxWidth?: number | string;
  maxHeight?: number | string;
  minWidth?: number | string;
  minHeight?: number | string;
}

const LoadingSkeleton: React.FunctionComponent<IProps> = (props: IProps) => {
  const { type, maxWidth, maxHeight, minWidth, minHeight } = props;

  const genericStyles = useGenericStyles();
  const mainStyles = useMainStyles();
  const dashboardStyles = useDashboardStyles();
  const projectPageStyles = useProjectPageStyles();

  const style = {
    maxWidth: maxWidth ? maxWidth : '100%',
    maxHeight: maxHeight ? maxHeight : '100%',
    minWidth: minWidth ? minWidth : '100%',
    minHeight: minHeight ? minHeight : '100%',
    padding: 10,
  };

  switch (type) {
    case ComponentType.TaskBoard:
      return (
        <div className={genericStyles.genericBox} style={style}>
          <div
            className="bp4-skeleton"
            style={{ maxWidth: '30%', minHeight: 20 }}
          />
          <div
            className="bp4-skeleton"
            style={{ maxWidth: '30%', minHeight: 20, marginTop: 10 }}
          />
          <div
            className="bp4-skeleton"
            style={{ maxWidth: '30%', minHeight: 20, marginTop: 10 }}
          />
          <div
            className="bp4-skeleton"
            style={{ maxWidth: '30%', minHeight: 20, marginTop: 10 }}
          />
          <div
            className="bp4-skeleton"
            style={{ maxWidth: '30%', minHeight: 20, marginTop: 10 }}
          />
        </div>
      );
    case ComponentType.TodoList:
      const todoListStyle = {
        ...style,
        maxWidth: 690,
        minWidth: 690,
      };
      return (
        <div className={genericStyles.genericBox} style={todoListStyle}>
          <div
            className="bp4-skeleton"
            style={{ maxWidth: '30%', minHeight: 20 }}
          />
          <div
            className="bp4-skeleton"
            style={{ maxWidth: '30%', minHeight: 20, marginTop: 10 }}
          />
          <div
            className="bp4-skeleton"
            style={{ maxWidth: '30%', minHeight: 20, marginTop: 10 }}
          />
          <div
            className="bp4-skeleton"
            style={{ maxWidth: '30%', minHeight: 20, marginTop: 10 }}
          />
        </div>
      );
    case ComponentType.GenericBoxWithText:
      return (
        <div className={genericStyles.genericBox} style={style}>
          <h2
            className={`${mainStyles.alignLeft} bp4-skeleton`}
            style={{ maxWidth: '40%', marginTop: 20 }}
          >
            Heading
          </h2>
          <div
            className="bp4-skeleton"
            style={{ maxWidth: '70%', minHeight: 20 }}
          />
        </div>
      );
    case ComponentType.DashboardSummary:
      return (
        <div className={`${dashboardStyles.summary}`} style={style}>
          <h2
            className={`${mainStyles.alignLeft} bp4-skeleton`}
            style={{ maxWidth: '40%' }}
          >
            Overall productivity
          </h2>
          <div
            className="bp4-skeleton"
            style={{ maxWidth: '70%', minHeight: 200 }}
          />
          <h2
            className={`${mainStyles.alignLeft} bp4-skeleton`}
            style={{ maxWidth: '40%', marginTop: 20 }}
          >
            What needs to be done
          </h2>
        </div>
      );
    case ComponentType.ProjectSummary:
      return (
        <div className={`${projectPageStyles.projectSummaryBox}`} style={style}>
          <div
            className="bp4-skeleton"
            style={{ maxWidth: '30%', minHeight: 40 }}
          />
          <div
            className="bp4-skeleton"
            style={{ maxWidth: '30%', minHeight: 20, marginTop: 30 }}
          />
          <div
            className="bp4-skeleton"
            style={{ maxWidth: '60%', minHeight: 20, marginTop: 10 }}
          />
          <div
            className="bp4-skeleton"
            style={{ maxWidth: '30%', minHeight: 20, marginTop: 10 }}
          />
          <div
            className="bp4-skeleton"
            style={{ maxWidth: '50%', minHeight: 20, marginTop: 10 }}
          />
        </div>
      );
    case ComponentType.PageTitle:
      return (
        <div style={style}>
          <h1
            className="bp4-skeleton"
            style={{
              maxWidth: '30%',
              height: 36,
              marginBottom: 14,
              paddingTop: 0,
            }}
          >
            Page title
          </h1>
        </div>
      );
    default:
      return null;
  }
};

export { LoadingSkeleton };

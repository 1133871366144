import React, { useContext, useState, useEffect } from 'react';
import _ from 'lodash';
import { Button, Intent } from '@blueprintjs/core';

import { ProjectsListContext } from '../../state/ProjectsListContext';
import { TasksContext } from '../../state/TasksContext';
import { LogContext } from '../../state/LogContext';
import { LogItem } from '../common/logs/LogItem';
import { AddLogModalWithinLogPage } from '../modals/AddLogModalWithinLogPage';
import { ILog } from '../../common-src/types/Log';
import { useLocation } from 'react-router-dom';
import { Loading } from '../common/Loading';
import { useLogStyles } from '../../style/components/logStyles';
import { useAllPageStyles } from '../../style/components/pageStyles';
import { DateTime } from 'luxon';
import { LogPageOnboarding } from '../common/onboarding/LogPageOnboarding';

const LogPage: React.FunctionComponent = () => {
  const projectsContext = useContext(ProjectsListContext);
  const tasksContext = useContext(TasksContext);
  const logContext = useContext(LogContext);
  const allPageStyles = useAllPageStyles();
  const logStyles = useLogStyles();

  const [addLogModalIsOpen, setAddLogModalIsOpen] = useState(false);
  const [sortOrder, setSortOrder] = useState('desc');

  const location = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
    document?.getElementById('main-scrollable')?.scrollTo(0, 0);
  }, [location]);

  if (!projectsContext || !tasksContext || !logContext) {
    return null;
  }

  const openAddLogModal = () => {
    setAddLogModalIsOpen(true);
  };

  const closeAddLogModal = () => {
    setAddLogModalIsOpen(false);
  };

  const getSortedLogs = sortOrder => {
    const logListToSort: Array<ILog> = [];

    logListToSort.push(...logContext?.allLogs!);

    if (sortOrder === 'asc') {
      return _.sortBy(logListToSort, log => {
        const date: string = log.date;
        return DateTime.fromISO(date).valueOf();
      });
    } else {
      return _.reverse(
        _.sortBy(logListToSort, log => {
          const date: string = log.date;
          return DateTime.fromISO(date).valueOf();
        })
      );
    }
  };

  const changeSortOrder = () => {
    const newSortOrder = sortOrder === 'asc' ? 'desc' : 'asc';
    setSortOrder(newSortOrder);
  };

  const { projects } = projectsContext;

  if (!projects) return null;

  if (logContext?.allLogs == null) return <Loading />;

  const logList = projects ? getSortedLogs(sortOrder) : null;
  const projectsArr = _.values(projects);
  const sortIcon = sortOrder === 'asc' ? 'sort-desc' : 'sort-asc';

  return (
    <div className={allPageStyles.genericPage}>
      <h1>Log</h1>
      <AddLogModalWithinLogPage
        modalIsOpen={addLogModalIsOpen}
        onRequestClose={closeAddLogModal}
        projects={projectsArr}
      />
      <LogPageOnboarding />
      <div>
        <Button
          icon="plus"
          onClick={openAddLogModal}
          style={{ marginBottom: 10 }}
          intent={Intent.PRIMARY}
        >
          Add log
        </Button>
        <Button
          onClick={changeSortOrder}
          style={{ marginBottom: 10 }}
          icon={sortIcon}
        >
          {sortOrder === 'asc' ? 'Sort descending' : 'Sort ascending'}
        </Button>
      </div>
      <div className={logStyles.logListWrapper}>
        {logList &&
          logList.map(log => {
            if (log.date) {
              return (
                <LogItem
                  log={log as ILog}
                  key={`${(log as ILog).date}-${(log as ILog).note}`}
                />
              );
            }
          })}
      </div>
    </div>
  );
};

export { LogPage };

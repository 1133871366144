import { createUseStyles } from 'react-jss';
import { chosenTheme } from '../styleHooks';

export const usePhaseStyles = createUseStyles({
  phaseHeader: {
    padding: 20,
    height: 83,
    fontSize: 18,
    cursor: 'pointer',
    backgroundColor: chosenTheme.mainBackgroundColour,
  },
  phaseTitle: {
    display: 'flex',
    alignItems: 'center',
  },
});

import React, { useState, useContext } from 'react';
import { Link } from 'react-router-dom';
import { useAuth0 } from '@auth0/auth0-react';
import { Popover, Position, Menu, MenuItem, Intent } from '@blueprintjs/core';
import { UserContext } from '../../../state/UserContext';
import { generateInspirationalQuote } from '../../../func/inspirationalQuotes';
import { chosenTheme } from '../../../style/styleHooks';
import { useNavbarTopStyles } from '../../../style/components/navbarStyles';

interface Props {
  toggleMobileMenu: () => void;
}

interface InspirationalQuote {
  words: string;
  who?: string;
}

const NavBarTop: React.FunctionComponent<Props> = (props: Props) => {
  const userContext = useContext(UserContext);
  const navbarTopStyles = useNavbarTopStyles();
  const { logout } = useAuth0();

  const [inspirationalQuote, setInspirationalQuote] =
    useState<InspirationalQuote>(generateInspirationalQuote());

  const { toggleMobileMenu } = props;
  const { showCompletedTasks, updateShowCompletedTasks } = userContext!;

  const handleSignOut = () => {
    logout();
  };

  const settingsMenu = (
    <Menu>
      <MenuItem
        text={
          showCompletedTasks
            ? `Don't show completed tasks`
            : `Show completed tasks`
        }
        onClick={() => updateShowCompletedTasks(!showCompletedTasks)}
      />
    </Menu>
  );

  return (
    <nav
      className={`bp4-navbar bp4-fixed-top ${navbarTopStyles.navbarTop}`}
      style={{ boxShadow: 'none', height: 55 }}
    >
      <div>
        <div className="bp4-navbar-group bp4-align-left">
          <div>
            <Link to="/">
              <img
                src="/favicon.png"
                style={{ height: 30, width: 30, marginRight: 6 }}
                alt="Chekhov"
              />
            </Link>
          </div>
          <div
            className={`bp4-navbar-heading ${navbarTopStyles.navbarTopFont}`}
          >
            Chekhov
          </div>
          <div
            className={`${navbarTopStyles.navQuote} ${navbarTopStyles.navbarTopFont}`}
          >
            {inspirationalQuote && (
              <>
                {inspirationalQuote!.words!} &nbsp;-&nbsp;
                <em>{inspirationalQuote!.who!}</em>
              </>
            )}
          </div>
        </div>
        <div className="bp4-navbar-group bp4-align-right">
          <span className="bp4-navbar-divider" />

          <button
            className={`bp4-button bp4-minimal bp4-icon-cog ${navbarTopStyles.navbarTopFont}`}
          >
            <Link
              className={navbarTopStyles.navSettings}
              style={{ textDecoration: 'none' }}
              to="/settings"
            >
              Settings
            </Link>
          </button>
          <Popover
            autoFocus={false}
            content={settingsMenu}
            position={Position.BOTTOM_RIGHT}
          >
            <button
              className={`bp4-button bp4-minimal bp4-icon-chevron-down ${navbarTopStyles.navbarTopFont}`}
            />
          </Popover>
          <button
            className={`bp4-button bp4-minimal bp4-icon-log-out ${navbarTopStyles.navbarTopFont} ${navbarTopStyles.navSignOut}`}
            onClick={handleSignOut}
            style={{ color: chosenTheme.navbarTop.fontColour }}
          >
            Sign out
          </button>
          <button
            className={`${navbarTopStyles.navMenuButton} bp4-button bp4-minimal bp4-icon-menu ${navbarTopStyles.navbarTopFont}`}
            onClick={toggleMobileMenu}
          />
        </div>
      </div>
    </nav>
  );
};

export default NavBarTop;

import React, { useEffect, useState, useContext } from 'react';

import { Navigate, Route, Routes } from 'react-router-dom';

import 'react-dates/initialize';
import 'react-dates/lib/css/_datepicker.css';

import { TasksPage } from './components/TasksPage/TasksPage';
import { TodoListPage } from './components/TodoListPage/TodoListPage';
import { Landing } from './components/Landing/Landing';
import { LogPage } from './components/LogPage/LogPage';
import { GoalsPage } from './components/GoalsPage/GoalsPage';
import { Tags } from './components/Tags/Tags';
import { ProjectPage } from './components/ProjectPage/ProjectPage';
import { Settings } from './components/Settings/Settings';

import NavBarTop from './components/common/nav/NavBarTop';
import NavBarSide from './components/common/nav/NavBarSide';
import { NavBarMobile } from './components/common/nav/NavBarMobile';

import { AddProjectModal } from './components/modals/AddProjectModal';
import CompleteSessionModal from './components/modals/CompleteSessionModal';
import { DeleteTaskModal } from './components/modals/DeleteTaskModal';
import DeleteProjectModal from './components/modals/DeleteProjectModal';
import { DeleteLogModal } from './components/modals/DeleteLogModal';
import { EditLogModal } from './components/modals/EditLogModal';

import { UserContext } from './state/UserContext';
import { ProjectsListContext } from './state/ProjectsListContext';
import { TasksContext } from './state/TasksContext';
import { TempMobileMenuContext } from './state/TempMobileMenuContext';
import { Dashboard } from './components/Dashboard/Dashboard';
import { StatusBar } from './components/common/StatusBar';
import { PomodoroContext } from './state/PomodoroContext';
import { ModalContext } from './state/ModalContext';
import { Loading } from './components/common/Loading';
import { LogContext } from './state/LogContext';
import { SessionAndUnitContext } from './state/SessionAndUnitContext';
import { useMainStyles, useMainViewStyles } from './style/styleHooks';
import { useNavbarSideStyles } from './style/components/navbarStyles';
import { useAuth0 } from '@auth0/auth0-react';
import { HabitsPage } from './components/HabitsPage/HabitsPage';
import { DeleteHabitModal } from './components/modals/DeleteHabitModal';
import { GoalContext } from './state/GoalContext';
import { ArchiveProjectModal } from './components/modals/ArchiveProjectModal';
import { SessionsPage } from './components/SessionsPage/SessionsPage';
import { AddLabourTypeModal } from './components/modals/AddLabourTypeModal';
import { AddSessionModal } from './components/modals/AddSessionModal';
import { EditSessionModal } from './components/modals/EditSessionModal';
import { AddGoalModal } from './components/modals/AddGoalModal';
import { EditGoalModal } from './components/modals/EditGoalModal';
import { GoalPage } from './components/GoalPage/GoalPage';
import { OnboardingContext } from './state/OnboardingContext';
import { UnitTypesPage } from './components/UnitTypesPage/UnitTypesPage';
import { AddUnitTypeModal } from './components/modals/AddUnitTypeModal';
import { EditUnitTypeModal } from './components/modals/EditUnitTypeModal';
import { DeleteUnitTypeModal } from './components/modals/DeleteUnitTypeModal';
import { ProjectContext } from './state/ProjectContext';
import { DeleteGoalModal } from './components/modals/DeleteGoalModal';
import { EditTaskModal } from './components/modals/EditTaskModal';
import { TagsContext } from './state/TagsContext';
import { DeleteSessionModal } from './components/modals/DeleteSessionModal';

interface IProps {
  children: React.ReactNode;
}

const App: React.FunctionComponent<IProps> = (props: IProps) => {
  const { user, isAuthenticated, isLoading } = useAuth0();

  const userContext = useContext(UserContext);
  const projectsContext = useContext(ProjectsListContext);
  const projectContext = useContext(ProjectContext);
  const tasksContext = useContext(TasksContext);
  const logContext = useContext(LogContext);
  const sessionAndUnitContext = useContext(SessionAndUnitContext);
  const pomodoroContext = useContext(PomodoroContext);
  const goalContext = useContext(GoalContext);
  const modalContext = useContext(ModalContext);
  const onboardingContext = useContext(OnboardingContext);
  const tagsContext = useContext(TagsContext);

  const mainStyles = useMainStyles();
  const mainViewStyles = useMainViewStyles();
  const navbarSideStyles = useNavbarSideStyles();

  const [componentToShow, setComponentToShow] = useState<
    'ToDo' | 'Landing' | 'Loading' | null
  >(null);

  useEffect(() => {
    if (isLoading) {
      setComponentToShow('Loading');
    } else if (!isLoading && isAuthenticated && user) {
      setComponentToShow('ToDo');
    } else {
      setComponentToShow('Landing');
    }
  }, [isAuthenticated, isLoading, user]);

  const openAddProjectModal = () => {
    modalContext?.openAddProjectModal();
  };

  const closeAddProjectModal = () => {
    modalContext?.closeAddProjectModal();
  };

  if (componentToShow === 'Loading') {
    return <Loading />;
  } else if (componentToShow === 'ToDo') {
    if (
      !projectsContext ||
      !projectContext ||
      !userContext ||
      !tasksContext ||
      !logContext ||
      !pomodoroContext ||
      !modalContext ||
      !sessionAndUnitContext ||
      !userContext ||
      !goalContext ||
      !onboardingContext ||
      !tagsContext
    ) {
      return <Loading />;
    }
    const {
      deleteTaskModalIsOpen,
      closeDeleteTaskModal,
      deleteProjectModalIsOpen,
      archiveProjectModalIsOpen,
      closeDeleteProjectModal,
      closeArchiveProjectModal,
      deleteLogModalIsOpen,
      closeDeleteLogModal,
      addLabourTypeModalIsOpen,
      closeAddLabourTypeModal,
      addUnitTypeModalIsOpen,
      closeAddUnitTypeModal,
      editUnitTypeModalIsOpen,
      closeEditUnitTypeModal,
      deleteUnitTypeModalIsOpen,
      closeDeleteUnitTypeModal,
      addSessionModalIsOpen,
      closeAddSessionModal,
      editSessionModalIsOpen,
      deleteSessionModalIsOpen,
      closeEditSessionModal,
      closeDeleteSessionModal,
      logToDelete,
      sessionToEdit,
      sessionToDelete,
      unitTypeToEdit,
      unitTypeToDelete,
      editLogModalIsOpen,
      closeEditLogModal,
      logToEdit,
      deleteGoalModalIsOpen,
      deleteHabitModalIsOpen,
      closeDeleteGoalModal,
      closeDeleteHabitModal,
      addGoalModalIsOpen,
      closeAddGoalModal,
      editGoalModalIsOpen,
      closeEditGoalModal,
      goalToEdit,
      taskToEdit,
      editTaskModalIsOpen,
      closeEditTaskModal,
    } = modalContext;
    const { isCompleteSessionModalOpen, closeCompleteSessionModal } =
      pomodoroContext;
    const { tasks, deleteTask, saveTask } = tasksContext;
    const { goals, deleteGoal, deleteHabit } = goalContext;
    const { projects } = projectsContext;

    const { addLabourType, labourTypes, unitTypes, addUnitType } = userContext;
    const { tags } = tagsContext;

    return (
      <div>
        <TempMobileMenuContext.Consumer>
          {mobileMenuContext => (
            <>
              <NavBarMobile
                isOpen={mobileMenuContext!.isMobileMenuOpen}
                openAddProjectModal={openAddProjectModal}
              />
              <NavBarTop
                toggleMobileMenu={mobileMenuContext!.toggleMobileMenu}
              />
            </>
          )}
        </TempMobileMenuContext.Consumer>
        <div className={mainStyles.main}>
          <div className={navbarSideStyles.navbar}>
            <NavBarSide openAddProjectModal={openAddProjectModal} />
          </div>
          <div className={mainViewStyles.mainView} id="main-scrollable">
            <Routes>
              <Route path="/" element={<Dashboard />} />
              <Route path="/todo" element={<TodoListPage />}></Route>
              <Route path="/tasks" element={<TasksPage />}></Route>
              <Route path="/landing" element={<Landing />}></Route>
              <Route path="/goals" element={<GoalsPage />}></Route>
              <Route path="/habits" element={<HabitsPage />}></Route>
              <Route path="/log" element={<LogPage />}></Route>
              <Route path="/tags" element={<Tags />}></Route>
              <Route path="/unit_types" element={<UnitTypesPage />}></Route>
              <Route path="/sessions" element={<SessionsPage />}></Route>
              <Route
                path="/projects/:projectId"
                element={<ProjectPage />}
              ></Route>
              <Route path="/goals/:goalId" element={<GoalPage />}></Route>
              <Route path="/settings" element={<Settings />}></Route>
              {/* <Route
                render={function () {
                  return <p>Not Found</p>;
                }}
              /> */}
            </Routes>
          </div>
        </div>
        <StatusBar />
        <AddProjectModal
          modalIsOpen={modalContext!.addProjectModalIsOpen}
          onRequestClose={closeAddProjectModal}
        />
        <CompleteSessionModal
          modalIsOpen={isCompleteSessionModalOpen}
          onRequestClose={closeCompleteSessionModal}
        />
        <DeleteTaskModal
          modalIsOpen={deleteTaskModalIsOpen}
          onRequestClose={closeDeleteTaskModal}
          deleteTask={deleteTask}
        />
        <DeleteProjectModal
          modalIsOpen={deleteProjectModalIsOpen}
          onRequestClose={closeDeleteProjectModal}
          deleteProject={projectsContext.deleteProject}
        />
        <ArchiveProjectModal
          modalIsOpen={archiveProjectModalIsOpen}
          onRequestClose={closeArchiveProjectModal}
          archiveProject={projectContext.archiveProject}
        />
        <DeleteGoalModal
          modalIsOpen={deleteGoalModalIsOpen}
          onRequestClose={closeDeleteGoalModal}
          deleteGoal={deleteGoal}
        />
        <DeleteHabitModal
          modalIsOpen={deleteHabitModalIsOpen}
          onRequestClose={closeDeleteHabitModal}
          deleteHabit={deleteHabit}
        />
        <AddLabourTypeModal
          modalIsOpen={addLabourTypeModalIsOpen}
          onRequestClose={closeAddLabourTypeModal}
          addLabourType={addLabourType}
        />
        <AddUnitTypeModal
          modalIsOpen={addUnitTypeModalIsOpen}
          onRequestClose={closeAddUnitTypeModal}
          addUnitType={addUnitType}
        />
        {unitTypeToEdit && (
          <EditUnitTypeModal
            modalIsOpen={editUnitTypeModalIsOpen}
            onRequestClose={closeEditUnitTypeModal}
            unitType={unitTypeToEdit}
          />
        )}
        {unitTypeToDelete && (
          <DeleteUnitTypeModal
            modalIsOpen={deleteUnitTypeModalIsOpen}
            onRequestClose={closeDeleteUnitTypeModal}
            unitTypeToDelete={unitTypeToDelete}
          />
        )}
        <AddSessionModal
          modalIsOpen={addSessionModalIsOpen}
          onRequestClose={closeAddSessionModal}
          goals={goals}
          simplifiedProjectList={projects}
          tasks={tasks}
          labourTypes={labourTypes}
          tags={tags ?? []}
        />
        <AddGoalModal
          modalIsOpen={addGoalModalIsOpen}
          onRequestClose={closeAddGoalModal}
          simplifiedProjectList={projects}
          labourTypes={labourTypes ?? []}
          unitTypes={unitTypes ?? []}
        />
        {goalToEdit && (
          <EditGoalModal
            goalToEdit={goalToEdit}
            modalIsOpen={editGoalModalIsOpen}
            onRequestClose={closeEditGoalModal}
            simplifiedProjectList={projects}
            labourTypes={labourTypes ?? []}
            unitTypes={unitTypes ?? []}
          />
        )}
        {sessionToEdit && tasks ? (
          <EditSessionModal
            modalIsOpen={editSessionModalIsOpen}
            onRequestClose={closeEditSessionModal}
            session={sessionToEdit}
            simplifiedProjectList={projects}
            tasks={tasks}
            goals={goals}
            labourTypes={labourTypes}
            tags={tags ?? []}
          />
        ) : null}
        {sessionToDelete && (
          <DeleteSessionModal
            modalIsOpen={deleteSessionModalIsOpen}
            onRequestClose={closeDeleteSessionModal}
            session={sessionToDelete}
          />
        )}
        {logToDelete && (
          <DeleteLogModal
            modalIsOpen={deleteLogModalIsOpen}
            onRequestClose={closeDeleteLogModal}
            logDate={logToDelete.date}
            associatedProjectId={logToDelete.projectId!}
            // @ts-ignore
            deleteLog={logContext.deleteLog}
          />
        )}
        {logToEdit && (
          <EditLogModal
            modalIsOpen={editLogModalIsOpen}
            onRequestClose={closeEditLogModal}
            saveLog={logContext.saveLog}
          />
        )}
        {taskToEdit && (
          <EditTaskModal
            modalIsOpen={editTaskModalIsOpen}
            onRequestClose={closeEditTaskModal}
            task={taskToEdit!}
            saveTask={saveTask!}
            tags={tags ?? []}
            simplifiedProjectList={projects ?? []}
          />
        )}
      </div>
    );
  } else if (componentToShow === 'Landing') {
    return (
      <div>
        <Routes>
          <Route path="/" element={<Landing />} />
          <Route path="*" element={<Navigate to="/" />} />
        </Routes>
      </div>
    );
  } else {
    return null;
    // TODO: should we display something here?
  }
};

export default App;

import React, { useContext } from 'react';
import { IGoal } from '../../../common-src/types/Goal';
import { ILabourType } from '../../../common-src/types/LabourType';
import { ISimplifiedProject } from '../../../common-src/types/Project';
import { ISession } from '../../../common-src/types/Session';
import { ITask } from '../../../common-src/types/Task';
import { ModalContext } from '../../../state/ModalContext';
import { useCalendarStyles } from '../../../style/components/calendarStyles';
import { ColouredTag } from '../tags/Tag';
import { makeMinutesReadable } from '../../../func/readability-utils';
import { ITag } from '../../../common-src/types/Tag';

interface ISessionCalendarItemProps {
  session: ISession;
  associatedProject?: ISimplifiedProject;
  associatedTask?: ITask;
  associatedGoal?: IGoal;
  associatedLabourType?: ILabourType;
  associatedTags?: ITag[];
}

const SessionCalendarItem: React.FunctionComponent<
  ISessionCalendarItemProps
> = (props: ISessionCalendarItemProps) => {
  const {
    session,
    associatedProject,
    associatedTask,
    associatedGoal,
    associatedLabourType,
    associatedTags,
  } = props;
  const modalContext = useContext(ModalContext);
  const calendarStyles = useCalendarStyles();

  const { openEditSessionModal } = modalContext!;

  const handleClick = () => {
    openEditSessionModal(session);
  };

  return (
    <div className={calendarStyles.sessionCalendarItem} onClick={handleClick}>
      <div style={{ width: '100%' }}>
        <div>{makeMinutesReadable(session.minutes)}</div>
        {associatedProject ? (
          <div>
            Project: <strong>{associatedProject.name}</strong>
          </div>
        ) : null}
        {associatedTask ? (
          <div>
            Task: <strong>{associatedTask.name}</strong>
          </div>
        ) : null}
        {associatedGoal ? (
          <div>
            Goal: <strong>{associatedGoal.name}</strong>
          </div>
        ) : null}
        {associatedLabourType ? (
          <div>
            Work type: <strong>{associatedLabourType.name}</strong>
          </div>
        ) : null}
        {associatedTags?.length ? (
          <div className={calendarStyles.sessionCalendarItemTagsContainer}>
            {associatedTags.map(tag => (
              <span key={tag.id}>
                <ColouredTag
                  tagName={tag.name}
                  tagColour={tag.colour || 'grey'}
                  marginRight
                />
              </span>
            ))}
          </div>
        ) : null}
      </div>
    </div>
  );
};

export { SessionCalendarItem };

import React, { ReactNode, useState } from 'react';

interface IFeature {
  name: string;
  enabled: boolean;
}

interface IFeatureContextType {
  features: IFeature[];
  isEnabled: (featureName: string) => boolean;
}

export const FeatureContext = React.createContext<IFeatureContextType | null>(
  null
);

interface Props {
  children: ReactNode;
}

const FeatureContextProvider: React.FunctionComponent<Props> = (
  props: Props
) => {
  const [features, setFeatures] = useState<IFeature[]>([
    { name: 'use_auth_0', enabled: true },
  ]);

  const isEnabled = (featureName: string): boolean => {
    return Boolean(
      features.find(feature => feature.name === featureName)?.enabled
    );
  };

  const getFeatureContext = () => {
    return {
      features,
      isEnabled,
    };
  };

  const featureContext = getFeatureContext();

  return (
    <FeatureContext.Provider value={featureContext}>
      {props.children}
    </FeatureContext.Provider>
  );
};

export { FeatureContextProvider };

import React, { useContext } from 'react';
import { getAverageProductivityTooltipForMultipleGoalsInPieChart } from '../../common-src/productivity';
import { ProductivityContext } from '../../state/ProductivityContext';
import { UserContext } from '../../state/UserContext';
import { WindowContext } from '../../state/WindowContext';
import { useDashboardStyles } from '../../style/pages/dashboardPageStyles';
import { useMainStyles } from '../../style/styleHooks';
import { WhatNeedsToBeDone } from '../common/WhatNeedsToBeDone';
import {
  IButlerAssessment,
  IStatusOfAllGoals,
} from '../../common-src/types/Reporting';
import { PieChartType, PieChart } from '../common/charts/PieChart';
import { getDashboardSummaryTooltip } from '../../func/textChunks';

interface IDashboardSummaryProps {
  overallProductivityString: string;
  statusOfAllGoals: IStatusOfAllGoals;
  butlerAssessment: IButlerAssessment;
}

const DashboardSummary: React.FunctionComponent<IDashboardSummaryProps> = (
  props: IDashboardSummaryProps
) => {
  const { butlerAssessment, overallProductivityString, statusOfAllGoals } =
    props;
  const userContext = useContext(UserContext);
  const windowContext = useContext(WindowContext);
  const productivityContext = useContext(ProductivityContext);

  const dashboardStyles = useDashboardStyles();
  const mainStyles = useMainStyles();

  const { trackingPeriod, customTrackingPeriod } = userContext!;
  const { isFetchingButlerAssessment } = productivityContext!;

  if (!statusOfAllGoals || !trackingPeriod) {
    return null;
  }

  const pieChartTooltip = getDashboardSummaryTooltip(
    trackingPeriod,
    customTrackingPeriod
  );

  return (
    <div className={`${dashboardStyles.summary}`}>
      <div
        style={{
          padding: windowContext?.isMobile
            ? '20px 20px 20px 20px'
            : '0 20px 20px 20px',
        }}
      >
        <h2 className={mainStyles.alignLeft}>{overallProductivityString}</h2>
        <div>
          {/* {trackingPeriod && (
            <>
              <PieChart
                type={PieChartType.SINGLE_METRIC}
                tooltip={pieChartTooltip}
                chartMetricsForPrimaryPie={[
                  {
                    id: 'productivity_score',
                    label: 'Productivity score',
                    value: butlerAssessment.overallScore,
                    tooltip:
                      getAverageProductivityTooltipForMultipleGoalsInPieChart(
                        statusOfAllGoals,
                        trackingPeriod,
                        customTrackingPeriod
                      ),
                  },
                  {
                    id: 'anti_productivity_score',
                    label: '',
                    value: 1 - butlerAssessment.overallScore,
                    tooltip:
                      getAverageProductivityTooltipForMultipleGoalsInPieChart(
                        statusOfAllGoals,
                        trackingPeriod,
                        customTrackingPeriod
                      ),
                    colour: 'rgb(255 255 255 / .1)',
                  },
                ]}
                trackingPeriod={trackingPeriod}
                customTrackingPeriodInDays={customTrackingPeriod}
                width={windowContext?.isMobile ? 300 : 400}
                height={windowContext?.isMobile ? 200 : 300}
                margin={
                  windowContext?.isMobile
                    ? { top: 0, right: 0, bottom: 0, left: 0 }
                    : { top: 20, right: 20, bottom: 20, left: 20 }
                }
                animate={true}
                centreText={`${Math.round(
                  butlerAssessment.overallScore * 100
                )}%`}
              />
            </>
          )} */}
        </div>
        {trackingPeriod && (
          <div
            style={{ display: 'flex' }}
            className={isFetchingButlerAssessment ? 'bp4-skeleton' : ''}
          >
            <WhatNeedsToBeDone
              trackingPeriod={trackingPeriod}
              butlerAssessment={butlerAssessment}
              customTrackingPeriodInDays={customTrackingPeriod}
              type="OVERALL"
              statusOfAllGoals={
                productivityContext?.butlerAssessment?.goalsStatus
              }
            />
          </div>
        )}
      </div>
    </div>
  );
};

export { DashboardSummary };

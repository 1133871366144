import React, { useState, useContext } from 'react';
import { Dialog, Button, Intent } from '@blueprintjs/core';
import { IProject } from '../../common-src/types/Project';
import { PomodoroContext } from '../../state/PomodoroContext';
import { useModalStyles } from '../../style/components/genericStyles';

interface Props {
  project: IProject;
  onRequestClose: () => void;
  modalIsOpen: boolean;
}

const PomodoroModal: React.FunctionComponent<Props> = (props: Props) => {
  const pomodoroContext = useContext(PomodoroContext);
  const modalStyles = useModalStyles();

  const [workDuration, setWorkDuration] = useState<number | string | undefined>(
    25
  );
  const [breakDuration, setBreakDuration] = useState<
    number | string | undefined
  >(5);

  const handleWorkChange = event => {
    const possibleWorkDuration = event.target.value;
    if (possibleWorkDuration === 'test') {
      setWorkDuration(0.1);
      setBreakDuration(0.1);
    } else {
      setWorkDuration(+possibleWorkDuration);
    }
  };

  const handleBreakChange = event => {
    const possibleBreakDuration = event.target.value;
    setBreakDuration(+possibleBreakDuration);
  };

  const handleStartPomodoro = () => {
    if (typeof workDuration === 'number' && typeof breakDuration === 'number')
      pomodoroContext!.startPomodoro(
        props.project,
        workDuration * 60 * 1000,
        breakDuration * 60 * 1000
      );
    props.onRequestClose();
    // TODO: add validation
  };

  const handleClose = () => {
    setWorkDuration(undefined);
    setBreakDuration(undefined);
    props.onRequestClose();
  };

  const { project } = props;
  if (!project) {
    return null;
  }

  return (
    <Dialog
      isOpen={props.modalIsOpen}
      onClose={handleClose}
      title={`Start a session for "${project.name}"`}
      canOutsideClickClose={false}
    >
      <div className={`bp4-dialog-body ${modalStyles.body}`}>
        <div>
          <label className="bp4-label">
            Duration of work (in minutes):
            <input
              className="bp4-input bp4-fill"
              type="text"
              onChange={handleWorkChange}
              value={workDuration}
              placeholder="This is 25 minutes by default"
            />
          </label>
        </div>

        <div>
          <label className="bp4-label">
            Duration of break (in minutes):
            <input
              className="bp4-input bp4-fill"
              type="text"
              onChange={handleBreakChange}
              value={breakDuration}
              placeholder="This is 5 minutes by default"
            />
          </label>
        </div>
      </div>
      <div className={`bp4-dialog-footer ${modalStyles.footer}`}>
        <Button onClick={handleClose}>Close</Button>
        <Button onClick={handleStartPomodoro} intent={Intent.PRIMARY}>
          Start session
        </Button>
      </div>
    </Dialog>
  );
};

export default PomodoroModal;

import React from 'react';
import NavBarProjectList from './NavBarProjectList';
import NavBarItem from './NavBarItem';

import { Menu, MenuDivider, MenuItem } from '@blueprintjs/core';
import { useNavbarSideStyles } from '../../../style/components/navbarStyles';

interface Props {
  openAddProjectModal: () => void;
}

const NavBarSide: React.FunctionComponent<Props> = (props: Props) => {
  const navbarSideStyles = useNavbarSideStyles();

  const { openAddProjectModal } = props;

  return (
    <div>
      <Menu
        style={{
          backgroundColor: 'rgba(0,0,0,0)',
          color: 'white',
        }}
      >
        <NavBarItem to="/" text="Dashboard" />
        <NavBarItem to="/tasks" text="Tasks" id="tasks-page-button" />
        <NavBarItem to="/goals" text="Goals" id="goals-page-button" />
        <NavBarItem to="/habits" text="Habits" id="habits-page-button" />
        <NavBarItem to="/log" text="Log" id="log-page-button" />
        <NavBarItem to="/tags" text="Tags" id="tag-page-button" />
        <NavBarItem
          to="/unit_types"
          text="Unit types"
          id="unit-types-page-button"
        />
        <NavBarItem to="/sessions" text="Sessions" id="sessions-page-button" />
        <MenuDivider />
        <MenuItem
          text="Add project"
          icon="plus"
          onClick={openAddProjectModal}
          className={navbarSideStyles.navbarItem}
          id="add-project-button"
        />
        <MenuDivider />
        <div style={{ marginBottom: 30 }}>
          <NavBarProjectList />
        </div>
      </Menu>
    </div>
  );
};

export default NavBarSide;

import React, { useEffect, useState } from 'react';
import { IPhase } from '../../../common-src/types/Project';
import { ITask } from '../../../common-src/types/Task';
import { useTaskGroupStyles } from '../../../style/components/taskGroupStyles';
import { PhaseHeader } from '../phases/PhaseHeader';
import TaskGroup from '../tasks/TaskGroup';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import { ComponentType, LoadingSkeleton } from '../LoadingSkeleton';
import { DragDropType } from '../../../common-src/types/enum';
import { IOpenAddSessionModalOpts } from '../../../common-src/types/UiTypes';

interface ITaskBoardProps {
  level: 'user' | 'project';
  tasks: ITask[];
  projectId?: string | number;
  phases: IPhase[];
  onDragEnd: (result: any) => void;
  openEditTaskModal: (taskId: ITask) => void;
  openEditPhaseModal: (phase: IPhase) => void;
  openDeletePhaseModal: (phaseToDelete: IPhase) => void;
  openAddSessionModal: (opts?: IOpenAddSessionModalOpts) => void;
  isLoading: boolean;
}

const TaskBoard: React.FunctionComponent<ITaskBoardProps> = (
  props: ITaskBoardProps
) => {
  const [tasksToShow, setTasksToShow] = useState<ITask[]>([]);
  const [phasesToShow, setPhasesToShow] = useState<IPhase[]>([]);

  const [boardHeight, setBoardHeight] = useState(600);

  const {
    level,
    phases,
    onDragEnd,
    openEditTaskModal,
    openEditPhaseModal,
    openDeletePhaseModal,
    openAddSessionModal,
    isLoading,
    tasks,
  } = props;

  const taskGroupStyles = useTaskGroupStyles();

  useEffect(() => {
    setTasksToShow(tasks);
  }, [tasks]);

  useEffect(() => {
    setPhasesToShow(phases);
  }, [phases]);

  const doPhasesExist = (): boolean => {
    return phasesToShow &&
      phasesToShow.length ===
        phasesToShow.filter(phase => phase.id && phase.name).length
      ? true
      : false;
  };

  /**
   * Determine which tasks do not have associated phases. To do this, get a list of taskIds from each phases's
   * orderedTaskIds property. Collate all the taskIds and compare them with the taskIds of the tasks in the project.
   * Any task that is in a project but not in any orderedTaskIds gets put in the "orphan" column
   * (which disappears once all tasks have been moved from it)
   * TODO: this function should be refactored so it does something like this:
   * 1. Get a (deduplicated?) list of all the ids of the tasks in this project
   * 2. Get a (deduplicated?) list of all the ids of the tasks in the project's phases
   * 3. Compare them!
   * @returns
   * An array of all the unphased tasks in the project.
   */
  const getUnphasedTasks = (): ITask[] => {
    const taskIdsWithAssignedPhases: string[] = [];
    if (phasesToShow.length === 0) {
      return []; // no phases, so no unphased tasks
    }
    phasesToShow
      .filter(phase => !!phase.tasks && phase.tasks?.length > 0)
      .forEach(phase => {
        if (phase.tasks) {
          taskIdsWithAssignedPhases.push(...phase.tasks.map(task => task.id));
        }
      });
    if (tasksToShow.length === taskIdsWithAssignedPhases.length) {
      return [];
    } else {
      return tasksToShow.filter(
        task => !taskIdsWithAssignedPhases.includes(task.id)
      );
    }
  };

  const unphasedTasks = getUnphasedTasks();

  if (isLoading) {
    return (
      <div>
        <LoadingSkeleton type={ComponentType.TaskBoard} />
      </div>
    );
  }

  return (
    <div>
      <DragDropContext onDragEnd={onDragEnd}>
        <Droppable
          droppableId="all-phases"
          direction="horizontal"
          type={DragDropType.PHASE}
        >
          {(provided, snapshot) => (
            <>
              {doPhasesExist() ? (
                <div
                  className={taskGroupStyles.taskBoardContainer}
                  id="task-board-container"
                  {...provided.droppableProps}
                  ref={provided.innerRef}
                >
                  {unphasedTasks.length > 0 && (
                    <>
                      <div style={{ marginRight: 50 }}>
                        <PhaseHeader isTruePhase={false} />
                        <Droppable
                          droppableId={`kanban-phase-uncategorised`}
                          type={DragDropType.TASK}
                        >
                          {(provided, snapshot) => (
                            <div
                              ref={provided.innerRef}
                              {...provided.droppableProps}
                            >
                              <TaskGroup
                                tasks={unphasedTasks}
                                type="board"
                                level={level}
                                openEditTaskModal={openEditTaskModal}
                                openAddSessionModal={openAddSessionModal}
                                isDraggingOver={snapshot.isDraggingOver}
                                isKanban={true}
                              />
                              {provided.placeholder}
                            </div>
                          )}
                        </Droppable>
                      </div>
                    </>
                  )}
                  {phasesToShow?.map((phase, index) => (
                    <Draggable
                      draggableId={phase.id}
                      index={index}
                      key={phase.id}
                    >
                      {provided => (
                        <div
                          className={
                            taskGroupStyles.taskGroupBoardPhaseContainer
                          }
                          ref={provided.innerRef}
                          {...provided.draggableProps}
                        >
                          <PhaseHeader
                            isTruePhase={true}
                            phase={phase}
                            openEditPhaseModal={openEditPhaseModal}
                            openDeletePhaseModal={openDeletePhaseModal}
                            dragHandleProps={provided.dragHandleProps}
                            numberOfTasks={phase.tasks.length}
                          />
                          <Droppable
                            droppableId={phase.id}
                            type={DragDropType.TASK}
                          >
                            {(provided, snapshot) => (
                              <div
                                ref={provided.innerRef}
                                {...provided.droppableProps}
                                className={
                                  taskGroupStyles.taskGroupBoardContainer
                                }
                                styles={{ height: boardHeight }}
                              >
                                <TaskGroup
                                  tasks={phase.tasks ?? []}
                                  type="board"
                                  level={level}
                                  openEditTaskModal={openEditTaskModal}
                                  openAddSessionModal={openAddSessionModal}
                                  isDraggingOver={snapshot.isDraggingOver}
                                  isKanban={true}
                                />
                                {provided.placeholder}
                              </div>
                            )}
                          </Droppable>
                        </div>
                      )}
                    </Draggable>
                  ))}
                </div>
              ) : (
                <>
                  {level === 'user' ? (
                    <>You don't have any phases at the user level.</>
                  ) : (
                    <>You don't have any phases in this project.</>
                  )}
                </>
              )}
            </>
          )}
        </Droppable>
      </DragDropContext>
    </div>
  );
};

export { TaskBoard };

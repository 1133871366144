import React from 'react';
import { makeMinutesReadableForSession } from '../../func/readability-utils';

import {
  Card,
  Elevation,
  Popover,
  Button,
  Position,
  Menu,
  MenuItem,
} from '@blueprintjs/core';
import { ISession } from '../../common-src/types/Session';
import { useProjectPageStyles } from '../../style/pages/projectPageStyles';
import { DateTime } from 'luxon';

interface IProps {
  session: ISession;
  openEditSessionModal: (session: ISession) => void;
  openDeleteSessionModal: (session: ISession) => void;
}

const SessionListElement: React.FunctionComponent<IProps> = (props: IProps) => {
  const { session, openEditSessionModal, openDeleteSessionModal } = props;
  const projectPageStyles = useProjectPageStyles();
  const sessionDate = DateTime.fromISO(session.date);
  const sessionText = `A ${makeMinutesReadableForSession(
    session.minutes
  )}-long session at ${sessionDate.toLocaleString(DateTime.TIME_24_SIMPLE)}`;
  const dropdown = (
    <Menu>
      <MenuItem
        icon="cog"
        text="Edit session"
        onClick={() => openEditSessionModal(session)}
      />
      <MenuItem
        icon="trash"
        text="Delete session"
        onClick={() => openDeleteSessionModal(session)}
      />
    </Menu>
  );
  return (
    <Card
      elevation={Elevation.ZERO}
      className={projectPageStyles.sessionListElement}
    >
      <div className={projectPageStyles.sessionListElementText}>
        {sessionText}
      </div>
      <div className={projectPageStyles.sessionListElementMenu}>
        <Popover content={dropdown} position={Position.RIGHT_BOTTOM}>
          <Button icon="more" minimal={true} />
        </Popover>
      </div>
    </Card>
  );
};

export { SessionListElement };

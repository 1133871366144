import React from 'react';
import { Spinner } from '@blueprintjs/core';
import { useLoadingStyles } from '../../style/pages/loadingPageStyles';

const Loading: React.FunctionComponent = () => {
  const loadingStyles = useLoadingStyles();
  return (
    <div className={loadingStyles.loadingPage}>
      <Spinner />
    </div>
  );
};

export { Loading };

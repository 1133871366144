import {
  Button,
  Dialog,
  Divider,
  Intent,
  NumericInput,
} from '@blueprintjs/core';
import { DateTime } from 'luxon';
import React, { BaseSyntheticEvent, useContext, useState } from 'react';
import { HabitCadenceUnit, IGoal } from '../../common-src/types/Goal';
import { AlertsContext } from '../../state/AlertsContext';
import { useModalStyles } from '../../style/components/genericStyles';
import {
  DatetimeFormat,
  DatetimePicker,
  DatetimePickerType,
} from '../common/DatetimePicker/DatetimePicker';

interface IProps {
  onRequestClose: () => void;
  modalIsOpen: boolean;
  createHabit: (
    habitName: string,
    cadenceUnit: HabitCadenceUnit,
    habitRegularity: number,
    habitStartDate: DateTime,
    habitEndDate?: DateTime
  ) => Promise<void>;
}

const AddHabitModal: React.FunctionComponent<IProps> = (props: IProps) => {
  const { onRequestClose, modalIsOpen, createHabit } = props;
  const alertsContext = useContext(AlertsContext);
  const modalStyles = useModalStyles();

  const [isSaving, setIsSaving] = useState(false);

  const [isStartDateFocused, setIsStartDateFocused] = useState(false);
  const [isEndDateFocused, setIsEndDateFocused] = useState(false);

  const [habitName, setHabitName] = useState<string>('');

  // cadence and dates
  const [cadenceUnit, setCadenceUnit] = useState<HabitCadenceUnit | undefined>(
    HabitCadenceUnit.DAY
  );
  const [cadenceRegularity, setCadenceRegularity] = useState<
    number | undefined
  >(1);
  const [startDate, setStartDate] = useState<string | undefined>(
    DateTime.now().toISO()
  );
  const [endDate, setEndDate] = useState<string | undefined>();

  const handleClose = () => {
    onRequestClose();
  };

  const handleTextChange = (event: BaseSyntheticEvent) => {
    setHabitName(event.target.value);
  };

  const handleRegularityChange = value => {
    setCadenceRegularity(value);
  };

  const handleCadenceUnitChange = event => {
    setCadenceUnit(event.target.value);
  };

  const handleStartDateChange = (date?: DateTime) => {
    setStartDate(date?.toISO());
  };

  const handleStartDateError = (date: Date) => {
    setStartDate(undefined);
  };

  const handleStartDateFocusChange = (arg: { focused: boolean }) => {
    setIsStartDateFocused(arg.focused);
  };

  const handleEndDateChange = (date?: DateTime) => {
    setEndDate(date?.toISO());
  };

  const handleEndDateFocusChange = (arg: { focused: boolean }) => {
    setIsEndDateFocused(arg.focused);
  };

  const handleAddHabit = async event => {
    event.preventDefault();
    if (cadenceRegularity && cadenceUnit && startDate) {
      try {
        setIsSaving(true);
        await createHabit(
          habitName,
          cadenceUnit,
          cadenceRegularity,
          DateTime.fromISO(startDate),
          endDate ? DateTime.fromISO(endDate) : undefined
        );
        alertsContext!.addAlert('Succesfully updated habit', Intent.SUCCESS);
      } catch (err) {
        alertsContext!.addAlert(
          'There was an error adding the habit',
          Intent.DANGER
        );
      } finally {
        setIsSaving(false);
        handleClose();
      }
    }
  };

  const isDisabled = !habitName;

  return (
    <Dialog
      isOpen={modalIsOpen}
      onClose={handleClose}
      title="Add a new habit"
      canOutsideClickClose={false}
    >
      <div className={`bp4-dialog-body ${modalStyles.body}`}>
        <div>
          <label className="bp4-label">
            Goal name:
            <input
              className="bp4-input bp4-fill"
              type="text"
              onChange={handleTextChange}
              value={habitName}
            />
          </label>
        </div>
        <>
          {cadenceUnit && (
            <div>
              <label className="bp4-label">
                Cadence:
                <div className="bp4-select">
                  <select
                    value={cadenceUnit}
                    onChange={handleCadenceUnitChange}
                  >
                    <option
                      value={HabitCadenceUnit.MINUTE}
                      key={HabitCadenceUnit.MINUTE}
                    >
                      Minute-by-minute
                    </option>
                    <option
                      value={HabitCadenceUnit.HOUR}
                      key={HabitCadenceUnit.HOUR}
                    >
                      Hourly
                    </option>
                    <option
                      value={HabitCadenceUnit.DAY}
                      key={HabitCadenceUnit.DAY}
                    >
                      Daily
                    </option>
                    <option
                      value={HabitCadenceUnit.WEEK}
                      key={HabitCadenceUnit.WEEK}
                    >
                      Weekly
                    </option>
                    <option
                      value={HabitCadenceUnit.MONTH}
                      key={HabitCadenceUnit.MONTH}
                    >
                      Monthly
                    </option>
                    <option
                      value={HabitCadenceUnit.YEAR}
                      key={HabitCadenceUnit.YEAR}
                    >
                      Annually
                    </option>
                  </select>
                </div>
              </label>
              <NumericInput
                min={0}
                value={cadenceRegularity}
                fill
                onValueChange={handleRegularityChange}
              />
              <p>{`You plan on doing this task ${cadenceRegularity} time(s) per ${cadenceUnit.toLowerCase()}.`}</p>

              <DatetimePicker
                id="habit_start_date_picker"
                type={DatetimePickerType.date}
                label="Habit start date:"
                placeholder="Enter the start date here"
                datetime={startDate ? DateTime.fromISO(startDate) : undefined}
                onDatetimeChange={handleStartDateChange}
                handleFocusChange={handleStartDateFocusChange}
                isFocused={isStartDateFocused}
                datetimeFormat={DatetimeFormat.DATE}
                canClear={false}
              />
              <DatetimePicker
                id="habit_end_date_picker"
                type={DatetimePickerType.date}
                label="Habit end date:"
                placeholder="Enter the end date here (optional)"
                datetime={endDate ? DateTime.fromISO(endDate) : undefined}
                onDatetimeChange={handleEndDateChange}
                handleFocusChange={handleEndDateFocusChange}
                isFocused={isEndDateFocused}
                datetimeFormat={DatetimeFormat.DATE}
                canClear={false}
              />
            </div>
          )}
          <Divider />
        </>
        <div className={`bp4-dialog-footer ${modalStyles.footer}`}>
          <Button onClick={handleClose}>Cancel</Button>
          <Button
            onClick={handleAddHabit}
            intent={Intent.PRIMARY}
            loading={isSaving}
            disabled={isDisabled}
          >
            Add
          </Button>
        </div>
      </div>
    </Dialog>
  );
};

export { AddHabitModal };

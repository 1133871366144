import React from 'react';
import { IGoal } from '../../../common-src/types/Goal';
import { IStatusOfAllHabits } from '../../../common-src/types/Reporting';
import { TrackingPeriod } from '../../../common-src/types/User';
import { useGenericStyles } from '../../../style/components/genericStyles';
import { useLayoutStyles } from '../../../style/components/layoutStyles';
import { HabitProgressBar } from '../HabitProgressBar';

interface IOverallHabitSummaryProps {
  habitGoals?: IGoal[];
  overallHabitStatus?: IStatusOfAllHabits;
  trackingPeriod: TrackingPeriod;
  customTrackingPeriod?: number;
}

const getTextForTrackingPeriod = (
  overallHabitScorePercent: number,
  trackingPeriod: TrackingPeriod,
  customTrackingPeriod?: number
): string => {
  let text = '';
  switch (trackingPeriod) {
    case TrackingPeriod.CUSTOM:
      text = `Over the past ${customTrackingPeriod} days, you've been about ${Math.round(
        overallHabitScorePercent
      )}% consistent with your habit goals.`;
      break;
    case TrackingPeriod.TODAY:
      text = `So far today, you've been about ${Math.round(
        overallHabitScorePercent
      )}% consistent with your habit goals.`;
      break;
    case TrackingPeriod.LAST_7_DAYS:
      text = `Over the past 7 days, you've been about ${Math.round(
        overallHabitScorePercent
      )}% consistent with your habit goals.`;
      break;
    case TrackingPeriod.THIS_WEEK:
      text = `So far this week, you've been about ${Math.round(
        overallHabitScorePercent
      )}% consistent with your habit goals.`;
      break;
    case TrackingPeriod.THIS_MONTH:
      text = `So far this month, you've been about ${Math.round(
        overallHabitScorePercent
      )}% consistent with your habit goals.`;
      break;
    case TrackingPeriod.THIS_QUARTER:
      text = `So far this quarter, you've been about ${Math.round(
        overallHabitScorePercent
      )}% consistent with your habit goals.`;
      break;
    case TrackingPeriod.THIS_YEAR:
      text = `So far this year, you've been about ${Math.round(
        overallHabitScorePercent
      )}% consistent with your habit goals.`;
      break;
    case TrackingPeriod.ALL_TIME:
      text = `Across the whole of time, you've been about ${Math.round(
        overallHabitScorePercent
      )}% consistent with your habit goals.`;
      break;
    default:
      text = '';
      break;
  }
  return text;
};

const OverallHabitSummary: React.FunctionComponent<
  IOverallHabitSummaryProps
> = (props: IOverallHabitSummaryProps) => {
  const {
    habitGoals,
    overallHabitStatus,
    trackingPeriod,
    customTrackingPeriod,
  } = props;

  const genericStyles = useGenericStyles();
  const layoutStyles = useLayoutStyles();

  const overallHabitConsistency = overallHabitStatus?.score || 0;
  const textForTrackingPeriod = getTextForTrackingPeriod(
    overallHabitConsistency * 100,
    trackingPeriod,
    customTrackingPeriod
  );

  return (
    <div className={genericStyles.genericBox}>
      <h2>Your habits</h2>
      <>
        {!habitGoals || habitGoals.length === 0 ? (
          <div>You haven't set any habits yet.</div>
        ) : (
          <div>
            Your overall habit consistency:
            <div className={layoutStyles.grid_2_thirds_col}>
              <div>
                <HabitProgressBar
                  habitConsistency={overallHabitConsistency}
                  tooltipText={textForTrackingPeriod}
                />
              </div>
            </div>
          </div>
        )}
      </>
    </div>
  );
};

export { OverallHabitSummary };

import { createUseStyles } from 'react-jss';

export const useLoadingStyles = createUseStyles({
  loadingPage: {
    height: '100vh',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
  },
});

import React from 'react';
import {
  DateAndValueTuple,
  IAreaChartData,
  ISessionData,
  ISessionDataV2,
  IUnitDataV2,
} from '../../../common-src/types/ChartTypes';
import { DateTime } from 'luxon';
import {
  getYScaleForAllSessions,
  getYScaleForAllSessionsV2,
  getYScaleForSessions,
  getYScaleForSessionsV2,
  getYScaleForUnitAndTarget,
  getYScaleForUnitsV2,
} from '../../../func/chart-utils';
import { chosenTheme } from '../../../style/styleHooks';
import { GridColumns, GridRows } from '@visx/grid';
import { ScaleTime, curveMonotoneX } from 'd3';
import { scaleTime } from '@visx/scale';
import { AreaClosed, Bar, Line, LinePath } from '@visx/shape';
import { AxisBottom, AxisLeft, Orientation } from '@visx/axis';
import {
  Tooltip,
  defaultStyles,
  useTooltip,
  useTooltipInPortal,
} from '@visx/tooltip';
import { Group } from '@visx/group';
import { localPoint } from '@visx/event';
import { getDatesInRange } from '../../../common-src/productivity';
import { makeMinutesReadable } from '../../../func/readability-utils';
import { capitalise } from '../../../func/utils';
import { LinearGradient } from '@visx/gradient';

const background = chosenTheme.areaCharts.backgroundColour;
const background2 = chosenTheme.areaCharts.backgroundColour2;
const accentColour = chosenTheme.areaCharts.accentColour;
const accentColourDark = chosenTheme.areaCharts.accentColourDark;
const sessionsColour = chosenTheme.areaCharts.sessionsColour;
const sessionsColourDark = chosenTheme.areaCharts.sessionsColourDark;
const unitsColour = chosenTheme.areaCharts.unitsColour;
const unitsColourDark = chosenTheme.areaCharts.unitsColourDark;

function getYAxisLabel(areaChartData: IAreaChartData): string {
  const { metrics } = areaChartData;
  const sessionMetrics = metrics.filter(metric => metric.type === 'SESSIONS');
  const unitMetrics = metrics.filter(metric => metric.type === 'UNITS');
  if (sessionMetrics.length === metrics.length && sessionMetrics.length > 0) {
    return 'Sessions';
  } else if (
    unitMetrics.length === metrics.length &&
    (unitMetrics.length === 1 || unitMetrics.length === 2)
  ) {
    return capitalise(unitMetrics[0].name);
  } else if (unitMetrics.length === metrics.length && unitMetrics.length > 2) {
    return 'Units';
  } else {
    return '';
  }
}

interface IProps {
  areaChartData: IAreaChartData;
  handleEditDateChange: (newDate: DateTime) => void;
  startDate: DateTime; // TODO: start and end dates should be optional (they should only be required if the data is over a range of dates)
  endDate: DateTime;
  width: number;
  height: number;
}

interface IAreaChartTooltipData {
  time?: number;
  timeBySessionGoal?: { sessionTime: number; goalName: string }[];
  units?: number;
  unitsByUnitGoal?: { units: number; goalName: string }[];
  unitName?: string;
  yCoordForSessions?: number;
  yCoordForUnits?: number;
  date?: DateTime;
}

const AreaChartV2: React.FunctionComponent<IProps> = (props: IProps) => {
  const {
    areaChartData,
    handleEditDateChange,
    startDate,
    endDate,
    width,
    height,
  } = props;
  const margin = { top: 40, right: 40, bottom: 40, left: 40 };
  const xMax = width - margin.left - margin.right;
  const yMax = height - margin.top - margin.bottom;
  const allSessionData: ISessionDataV2[] | undefined = areaChartData
    ? areaChartData.metrics
        .filter(metric => metric.type === 'SESSIONS')
        .map(metric => {
          return {
            sessions: metric.dateValueTuples,
          };
        })
    : undefined;
  const yScaleForAllSessions = allSessionData
    ? getYScaleForAllSessionsV2(allSessionData, yMax)
    : undefined;
  const firstUnitData: IUnitDataV2 = areaChartData?.metrics
    ?.filter(metric => metric.type === 'UNITS' && !metric.isTarget)
    .map(metric => {
      return {
        units: metric.dateValueTuples,
        unitName: metric.name,
      };
    })[0];
  const firstUnitTargetData: IUnitDataV2 = areaChartData?.metrics
    ?.filter(metric => metric.type === 'UNITS' && metric.isTarget)
    .map(metric => {
      return {
        units: metric.dateValueTuples,
        unitName: metric.name,
      };
    })[0];
  const firstUnitTarget = firstUnitTargetData
    ? firstUnitTargetData.units[0][1]
    : undefined;
  const yScaleForUnitAndTarget = firstUnitTarget
    ? getYScaleForUnitAndTarget(firstUnitData, firstUnitTarget, yMax)
    : undefined;

  const diff = endDate.diff(startDate, 'days').toObject().days;

  const {
    tooltipData,
    tooltipLeft = 0,
    tooltipTop = 0,
    showTooltip,
    hideTooltip,
  } = useTooltip<IAreaChartTooltipData>();

  const { containerRef } = useTooltipInPortal({
    scroll: true,
    detectBounds: true,
  });

  let xScale: ScaleTime<number, number, never> | null = null;
  xScale = scaleTime({
    domain: [
      startDate.startOf('day').toJSDate(),
      diff && Math.floor(diff) === 0
        ? endDate.endOf('day').toJSDate()
        : endDate.startOf('day').toJSDate(),
    ],
    range: [0, xMax],
  });

  const sessionDataWithYScales = areaChartData?.metrics
    .filter(metric => metric.type === 'SESSIONS')
    .map(metric => {
      const sessionData = {
        sessions: metric.dateValueTuples,
      };
      return {
        ...metric,
        yScale: getYScaleForSessionsV2(sessionData, yMax),
      };
    });

  const unitDataWithYScales = areaChartData?.metrics
    .filter(metric => metric.type === 'UNITS')
    .map(metric => {
      const unitData = {
        units: metric.dateValueTuples,
        unitName: metric.name,
      };
      return {
        ...metric,
        yScale: getYScaleForUnitsV2(unitData, yMax),
      };
    });

  const dateSeries = getDatesInRange(startDate, endDate);

  const getNearestDateInRange = (date: DateTime): DateTime => {
    const nearestDate = dateSeries.reduce((prev, curr) => {
      return Math.abs(curr.valueOf() - date.valueOf()) <
        Math.abs(prev.valueOf() - date.valueOf())
        ? curr
        : prev;
    });
    return nearestDate;
  };

  const handleTooltip = (event: React.SyntheticEvent) => {
    // @ts-ignore
    const { x, y } = localPoint(event) || { x: 0, y: 0 };
    const adjustedXCoord = x - margin.left;
    const adjustedYCoord = y - margin.top;
    const exactXValue = xScale?.invert(adjustedXCoord);
    if (!exactXValue) return;
    const nearestDate = getNearestDateInRange(
      DateTime.fromJSDate(exactXValue)
    ).startOf('day');
    let data: IAreaChartTooltipData = {};
    let sessionCurrent: DateAndValueTuple;
    let unitCurrent: DateAndValueTuple;

    const sessionDateValueTuples = sessionDataWithYScales
      ?.filter(metric => metric.type === 'SESSIONS')
      .flatMap(metric => metric.dateValueTuples);

    const units = {
      dateValueTuples:
        unitDataWithYScales?.filter(metric => metric.type === 'UNITS')[0]
          ?.dateValueTuples ?? [],
      name: unitDataWithYScales?.filter(metric => metric.type === 'UNITS')[0]
        ?.name,
    };

    if (
      sessionDateValueTuples &&
      sessionDateValueTuples.length &&
      !!yScaleForAllSessions
    ) {
      // get index of session based on nearest date
      const sessionIndex = sessionDateValueTuples.findIndex(
        session => session[0].startOf('day').toISO() === nearestDate.toISO()
      );
      if (sessionIndex === -1) return;
      // get session based on index
      sessionCurrent = sessionDateValueTuples[sessionIndex];
      const yCoordForSessions = yScaleForAllSessions(sessionCurrent[1]);
      if (sessionCurrent) {
        data = {
          time: sessionCurrent[1],
          yCoordForSessions,
          date: sessionCurrent[0],
        };
        showTooltip({
          tooltipData: data,
          tooltipLeft: adjustedXCoord,
          tooltipTop: yCoordForSessions,
        });
      }
    } else if (
      units.dateValueTuples &&
      units.dateValueTuples.length &&
      !!yScaleForUnitAndTarget
    ) {
      // get index of unit based on nearest date
      const unitIndex = units.dateValueTuples.findIndex(
        unit => unit[0].startOf('day').toISO() === nearestDate.toISO()
      );
      if (unitIndex === -1) return;
      unitCurrent = units.dateValueTuples[unitIndex];
      const yCoordForUnits = yScaleForUnitAndTarget(unitCurrent[1]);
      if (unitCurrent) {
        data = {
          units: unitCurrent[1],
          unitName: units.name,
          yCoordForUnits,
          date: unitCurrent[0],
        };
        showTooltip({
          tooltipData: data,
          tooltipLeft: adjustedXCoord,
          tooltipTop: yCoordForUnits,
        });
      }
    } else {
      hideTooltip();
    }
  };

  const tooltipStyles = {
    ...defaultStyles,
    background: chosenTheme.areaCharts.tooltipBackground,
    border: '1px solid white',
    color: 'white',
    padding: 20,
    maxWidth: 200,
    zIndex: 1000,
  };

  const shouldShowSessionLines = Boolean(!!xScale && !!sessionDataWithYScales);
  const shouldShowUnitLines = Boolean(!!xScale && !!unitDataWithYScales);

  const yAxisLabel = getYAxisLabel(areaChartData);

  return (
    <div
      id="area-chart"
      style={{ height, width }}
      className="svg-container"
      ref={containerRef}
    >
      <svg width={width} height={height}>
        {/* background shaded rounded rectangle */}
        <rect
          x={0}
          y={0}
          width={width}
          height={height}
          fill="url(#area-background-gradient)"
          rx={14}
        />
        <Group top={margin.top} left={margin.left}>
          <LinearGradient
            id="area-background-gradient"
            from={background}
            to={background2}
          />
          <LinearGradient
            id="area-gradient-for-sessions"
            from={sessionsColour}
            to={sessionsColourDark}
            toOpacity={0.2}
          />
          <LinearGradient
            id="area-gradient-for-units"
            from={unitsColour}
            to={unitsColourDark}
            toOpacity={0.2}
          />
          {/* Grids */}
          {yScaleForAllSessions && (
            <GridRows
              scale={yScaleForAllSessions}
              width={xMax}
              strokeDasharray="1,3"
              stroke={accentColour}
              strokeOpacity={0.2}
              pointerEvents="none"
            />
          )}
          {xScale && (
            <GridColumns
              scale={xScale}
              height={yMax}
              strokeDasharray="1,3"
              stroke={accentColour}
              strokeOpacity={0.2}
              pointerEvents="none"
            />
          )}
          {/* Sessions line(s) */}
          {shouldShowSessionLines ? (
            <>
              {sessionDataWithYScales?.map((metric, index) => {
                return (
                  <React.Fragment key={`${metric.name}-${index}`}>
                    {metric.isTarget ? (
                      <LinePath<[DateTime, number]>
                        curve={curveMonotoneX}
                        data={metric.dateValueTuples}
                        x={d => xScale!(d[0])}
                        y={d => yScaleForAllSessions!(d[1])}
                        stroke={sessionsColour}
                        strokeWidth={1}
                        strokeOpacity={1}
                        shapeRendering="geometricPrecision"
                        markerMid="url(#marker-circle)"
                        strokeDasharray={4}
                      />
                    ) : (
                      <AreaClosed<[DateTime, number]>
                        data={metric.dateValueTuples}
                        x={d => xScale!(d[0])}
                        y={d => yScaleForAllSessions!(d[1])}
                        yScale={yScaleForAllSessions!} // TODO: fix exclam
                        strokeWidth={3}
                        strokeOpacity={1}
                        stroke="url(#area-gradient-for-sessions)"
                        fill="transparent"
                        curve={curveMonotoneX}
                      />
                    )}

                    {metric.dateValueTuples.map((d, index) => (
                      <circle
                        key={`${d[0].valueOf()}-${index}`}
                        r={4}
                        cx={xScale!(d[0])}
                        cy={yScaleForAllSessions!(d[1])}
                        stroke={sessionsColour}
                        fill={sessionsColour}
                      />
                    ))}
                  </React.Fragment>
                );
              })}
            </>
          ) : null}
          {/* Units line(s) */}
          {shouldShowUnitLines && (
            <>
              {unitDataWithYScales?.map((metric, index) => {
                return (
                  <React.Fragment key={`${metric.name}-${index}`}>
                    {metric.isTarget ? (
                      <LinePath<[DateTime, number]>
                        curve={curveMonotoneX}
                        data={metric.dateValueTuples}
                        x={d => xScale!(d[0])}
                        y={d => yScaleForUnitAndTarget!(d[1])}
                        stroke={unitsColour}
                        strokeWidth={1}
                        strokeOpacity={1}
                        shapeRendering="geometricPrecision"
                        markerMid="url(#marker-circle)"
                        strokeDasharray={4}
                      />
                    ) : (
                      <AreaClosed<[DateTime, number]>
                        data={metric.dateValueTuples}
                        x={d => xScale!(d[0])}
                        y={d => yScaleForUnitAndTarget!(d[1])}
                        yScale={yScaleForUnitAndTarget!} // TODO: fix exclam
                        strokeWidth={3}
                        strokeOpacity={1}
                        stroke="url(#area-gradient-for-units)"
                        fill="transparent"
                        curve={curveMonotoneX}
                      />
                    )}

                    {metric.dateValueTuples.map((d, index) => (
                      <circle
                        key={`${d[0].valueOf()}-${index}`}
                        r={4}
                        cx={xScale!(d[0])}
                        cy={yScaleForUnitAndTarget!(d[1])}
                        stroke={unitsColour}
                        fill={unitsColour}
                      />
                    ))}
                  </React.Fragment>
                );
              })}
            </>
          )}
          {/* an invisible "bar" used for detecting mouse events */}
          <Bar
            x={0}
            y={0}
            width={xMax}
            height={yMax}
            fill="transparent"
            rx={14}
            onTouchStart={handleTooltip}
            onTouchMove={handleTooltip}
            onMouseMove={handleTooltip}
            onMouseLeave={() => hideTooltip()}
            onMouseEnter={handleTooltip}
          />
          {/* Axes */}
          {xScale && (
            <AxisBottom
              key="axis-date"
              orientation={Orientation.bottom}
              top={yMax}
              scale={xScale}
              label="Date"
              labelProps={{
                textAnchor: 'middle',
                y: -10,
              }}
            />
          )}
          {yScaleForAllSessions && (
            <AxisLeft
              key="axis-session"
              orientation={Orientation.left}
              scale={yScaleForAllSessions!}
              label={yAxisLabel}
              labelProps={{
                x: -150,
                y: 20,
                textAnchor: 'middle',
              }}
            />
          )}
          {tooltipData && (
            <g>
              <Line
                from={{ x: tooltipLeft, y: 0 }}
                to={{ x: tooltipLeft, y: yMax }}
                stroke="red"
                strokeWidth={2}
                pointerEvents="none"
                strokeDasharray="5,2"
              />
            </g>
          )}
        </Group>
      </svg>
      {tooltipData && (
        <div>
          <Tooltip
            key={Math.random()}
            top={tooltipTop}
            left={tooltipLeft}
            style={tooltipStyles}
          >
            {typeof tooltipData.time !== 'undefined' ? (
              <div>
                On{' '}
                <strong>
                  {tooltipData.date?.toLocaleString(DateTime.DATE_MED)}
                </strong>{' '}
                you worked for{' '}
                <strong>{`${makeMinutesReadable(tooltipData.time)}.`}</strong>
              </div>
            ) : null}
            {typeof tooltipData.units !== 'undefined' ? (
              <div>
                On{' '}
                <strong>
                  {tooltipData.date?.toLocaleString(DateTime.DATE_MED)}
                </strong>{' '}
                you completed{' '}
                <strong>{`${tooltipData.units} ${tooltipData.unitName}.`}</strong>
              </div>
            ) : null}
          </Tooltip>
        </div>
      )}
    </div>
  );
};

export { AreaChartV2 };

import React from 'react';
import { createRoot } from 'react-dom/client';
import App from './App';
import AppStateProvider from './state/AppStateProvider';

// http://patorjk.com/software/taag/#p=display&f=Fire%20Font-k&t=Chekhov

// (       )           )     )
// )\   ( /(    (   ( /(  ( /(        )
// (((_)  )\())  ))\  )\()) )\())  (   /((
// )\___ ((_)\  /((_)((_)\ ((_)\   )\ (_))\
// ((/ __|| |(_)(_))  | |(_)| |(_) ((_)_)((_)
// | (__ | ' \ / -_) | / / | ' \ / _ \\ V /
// \___||_||_|\___| |_\_\ |_||_|\___/ \_/

// the below should always be imported last
import './index.scss';

const container = document.getElementById('root');
const root = createRoot(container);

root.render(
  <AppStateProvider>
    <App />
  </AppStateProvider>
);

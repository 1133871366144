import React from 'react';
import { ITag } from '../../common-src/types/Tag';
import { TaskViewPreference } from '../../common-src/types/User';
import { useTaskFilterStyles } from '../../style/components/taskFilterStyles';
import { useGenericStyles } from '../../style/components/genericStyles';
import { useMainStyles } from '../../style/styleHooks';
import { TagSelector } from '../common/tags/TagSelector';

interface ITaskFilterProps {
  taskView: TaskViewPreference;
  onTaskViewChange: (event: any) => void;
  onTagSelect: (tag: ITag) => void;
  onTagRemove: (tagName: string) => void;
  tagsArr: ITag[];
  filterTags: ITag[];
}

const TaskFilter: React.FunctionComponent<ITaskFilterProps> = (
  props: ITaskFilterProps
) => {
  const {
    taskView,
    onTaskViewChange,
    onTagSelect,
    onTagRemove,
    tagsArr,
    filterTags,
  } = props;

  const taskFilterStyles = useTaskFilterStyles();
  const mainStyles = useMainStyles();
  const genericStyles = useGenericStyles();

  return (
    <div className={genericStyles.genericBox}>
      <h3 className={mainStyles.alignLeft}>Task filters</h3>
      <div className={taskFilterStyles.taskFilter}>
        <div className={taskFilterStyles.taskFilterChild}>
          <div className={taskFilterStyles.taskFilterChildSection}>
            <label className="bp4-label">
              Task view:
              <div className="bp4-select">
                <select value={taskView} onChange={onTaskViewChange}>
                  <option
                    value={TaskViewPreference.TODO}
                    key={TaskViewPreference.TODO}
                  >
                    Todo
                  </option>
                  <option
                    value={TaskViewPreference.DUE_DATE}
                    key={TaskViewPreference.DUE_DATE}
                  >
                    Due date
                  </option>
                  {/* <option
                    value={TaskViewPreference.BOARD}
                    key={TaskViewPreference.BOARD}
                  >
                    Board
                  </option> */}
                </select>
              </div>
            </label>
          </div>
        </div>
        <div className={taskFilterStyles.taskFilterChild}>
          <div className={taskFilterStyles.taskFilterChildHeading}>Tags</div>
          <div className={taskFilterStyles.taskFilterChildSection}>
            <span>
              <TagSelector
                tags={tagsArr}
                selectedTags={filterTags}
                onTagSelect={onTagSelect}
                onTagRemove={onTagRemove}
              />
            </span>
          </div>
        </div>
      </div>
    </div>
  );
};

export { TaskFilter };

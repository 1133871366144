import React, { useState } from 'react';
import {
  DatetimeFormat,
  DatetimePicker,
  DatetimePickerType,
} from '../DatetimePicker/DatetimePicker';
import { Button, Intent } from '@blueprintjs/core';
import { DateTime } from 'luxon';

interface ICompletionProps {
  inEditCompletionMode: boolean;
  completionDatetimeString: string;
  onSaveEditedCompletion: (
    originalDateString: string,
    newDateString: string
  ) => void;
  onDismissEditingCompletion: () => void;
}

const Completion: React.FunctionComponent<ICompletionProps> = (
  props: ICompletionProps
) => {
  const {
    inEditCompletionMode,
    completionDatetimeString,
    onSaveEditedCompletion,
    onDismissEditingCompletion,
  } = props;
  const completionDateTime = DateTime.fromISO(completionDatetimeString || '');
  const [currentCompletionDateTime, setCurrentCompletionDateTime] =
    useState(completionDateTime);

  const onCompletionChange = (newCompletion?: DateTime) => {
    if (newCompletion) {
      setCurrentCompletionDateTime(newCompletion);
    }
  };

  return (
    <div style={{ marginBottom: 10 }}>
      {inEditCompletionMode ? (
        <>
          <DatetimePicker
            id={`habit_completion_edit_datetime_picker_${completionDateTime.toString()}`}
            type={DatetimePickerType.datetime}
            label="Edit completion:"
            datetime={currentCompletionDateTime}
            onDatetimeChange={onCompletionChange}
            datetimeFormat={DatetimeFormat.DATETIME}
            canClear={true}
          />
          <Button
            intent={Intent.SUCCESS}
            onClick={() =>
              onSaveEditedCompletion(
                completionDatetimeString,
                currentCompletionDateTime.toString()
              )
            }
          >
            Save
          </Button>
          <Button
            onClick={onDismissEditingCompletion}
            icon="cross"
            minimal={true}
          />
        </>
      ) : (
        completionDateTime.toLocaleString(DateTime.DATETIME_MED_WITH_WEEKDAY)
      )}
    </div>
  );
};

export { Completion };

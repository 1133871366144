import React from 'react';
import { Divider, Button, Intent } from '@blueprintjs/core';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import TaskProgressBar from '../TaskProgressBar';
import { ITask } from '../../../common-src/types/Task';
import { ISubtask } from '../../../common-src/types/Subtask';
import { DateTime } from 'luxon';
import { Subtask } from './Subtask';
import { PortalWrapper } from '../PortalWrapper';

interface IProps {
  task: ITask;
  subtasks: ISubtask[];
  subtaskBeingEditedId: string;
  editedSubtaskName: string;
  newSubtaskName: string;
  startDate: DateTime;
  dueDate: DateTime;
  inEditSubtaskMode: boolean;
  inAddSubtaskMode: boolean;
  onEditNewSubtaskName: (event: any) => void;
  onAddNewSubtask: () => void;
  onDismissNewSubtask: () => void;
  onEnterSubtaskMode: () => void;
  onDeleteChecklist: () => void;
  onCheckSubtask: (event: any) => void;
  onSubtaskClick: (event: any) => void;
  onSaveEditedSubtask: () => void;
  onDismissEditingSubtask: () => void;
  onEditOldSubtaskName: (event: any) => void;
  onDeleteSubtask: (event: any) => void;
  onDragSubtaskEnd: (result: any) => void;
}

const TaskChecklist: React.FunctionComponent<IProps> = (props: IProps) => {
  const {
    task,
    subtasks,
    subtaskBeingEditedId,
    editedSubtaskName,
    newSubtaskName,
    startDate,
    dueDate,
    inEditSubtaskMode,
    inAddSubtaskMode,
    onEditNewSubtaskName,
    onAddNewSubtask,
    onDismissNewSubtask,
    onEnterSubtaskMode,
    onDeleteChecklist,
    onCheckSubtask,
    onSubtaskClick,
    onSaveEditedSubtask,
    onDismissEditingSubtask,
    onEditOldSubtaskName,
    onDeleteSubtask,
    onDragSubtaskEnd,
  } = props;

  const taskContainsSubtasks = Boolean(subtasks && subtasks.length);

  return (
    <React.Fragment>
      <Divider />
      <div style={{ display: 'flex' }}>
        <h3 style={{ flex: 1 }}>Checklist</h3>
        <div style={{ flex: 1 }}>
          <Button
            intent={Intent.DANGER}
            minimal={true}
            onClick={onDeleteChecklist}
            style={{ float: 'right' }}
            icon="cross"
          >
            Delete
          </Button>
        </div>
      </div>
      <TaskProgressBar
        task={Object.assign(task, {
          startDate: startDate,
          dueDate: dueDate,
          subtasks: subtasks,
        })}
        trackingType="CHECKLIST_ONLY"
      />
      <DragDropContext onDragEnd={onDragSubtaskEnd}>
        {taskContainsSubtasks ? (
          <Droppable droppableId="checklist-for-task">
            {(droppableProvided, droppableSnapshot) => (
              <div
                ref={droppableProvided.innerRef}
                {...droppableProvided.droppableProps}
              >
                {subtasks!.map((subtask: ISubtask, index) => (
                  <Draggable
                    draggableId={subtask.id.toString()}
                    index={index}
                    key={subtask.id.toString()}
                  >
                    {(draggableProvided, draggableSnapshot) => (
                      <PortalWrapper
                        draggableProvided={draggableProvided}
                        draggableSnapshot={draggableSnapshot}
                        key={`subtask-${subtask.id}`}
                      >
                        <Subtask
                          subtask={subtask}
                          index={index}
                          onCheckSubtask={onCheckSubtask}
                          inEditSubtaskMode={inEditSubtaskMode}
                          subtaskBeingEditedId={subtaskBeingEditedId}
                          onEditOldSubtaskName={onEditOldSubtaskName}
                          editedSubtaskName={editedSubtaskName}
                          onDeleteSubtask={onDeleteSubtask}
                          onSaveEditedSubtask={onSaveEditedSubtask}
                          onDismissEditingSubtask={onDismissEditingSubtask}
                          onSubtaskClick={onSubtaskClick}
                          innerRef={draggableProvided.innerRef}
                          draggableProvided={draggableProvided}
                          draggableSnapshot={draggableSnapshot}
                        />
                      </PortalWrapper>
                    )}
                  </Draggable>
                ))}
                {droppableProvided.placeholder}
              </div>
            )}
          </Droppable>
        ) : null}
      </DragDropContext>
      {!inAddSubtaskMode && (
        <Button onClick={onEnterSubtaskMode}>Add subtask</Button>
      )}
      {inAddSubtaskMode && (
        <div>
          <label className="bp4-label">
            New subtask:
            <input
              className="bp4-input bp4-fill"
              type="text"
              onChange={onEditNewSubtaskName}
              value={newSubtaskName || ''}
            />
          </label>
          <Button onClick={onAddNewSubtask} intent={Intent.SUCCESS}>
            Add
          </Button>
          <Button onClick={onDismissNewSubtask} icon="cross" minimal={true} />
        </div>
      )}
      <Divider />
    </React.Fragment>
  );
};

export { TaskChecklist };

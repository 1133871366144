import React, { useContext, useEffect, useState } from 'react';
import { CSSTransition } from 'react-transition-group';
import { DateTime } from 'luxon';
import { UserContext } from '../../../state/UserContext';
import TaskProgressBar from '../TaskProgressBar';
import { ColouredTag } from '../tags/Tag';

import {
  Button,
  Card,
  Elevation,
  Popover,
  Tooltip,
  Menu,
  MenuItem,
  Checkbox,
  Position,
  Icon,
  Intent,
} from '@blueprintjs/core';
import { ITask } from '../../../common-src/types/Task';
import { ITag } from '../../../common-src/types/Tag';
import { IReminderOptions } from '../../../common-src/types/Reminder';
import { AlertsContext } from '../../../state/AlertsContext';
import { useTaskStyles } from '../../../style/components/taskStyles';
import { getIsTaskTodo } from '../../../func/functions';
import { getReadableDueDate } from '../../../func/readability-utils';
import {
  IOpenAddSessionModalOpts,
  TaskContext,
} from '../../../common-src/types/UiTypes';
import { TaskEstimates } from './TaskEstimates';
import { ISimplifiedProject } from '../../../common-src/types/Project';

interface Props {
  task: ITask;
  type: TaskContext;
  showProjectName: boolean;
  /**
   * Whether or not the task belongs to an archived project
   */
  archived?: boolean;
  openEditTaskModal: (task: ITask) => void;
  allTags?: ITag[];
  associatedProjectName: string;
  associatedProject: ISimplifiedProject;
  saveTask: (task: ITask, reminderOptions?: IReminderOptions) => Promise<void>;
  openDeleteTaskModal: (task: ITask) => void;
  openAddSessionModal: (opts?: IOpenAddSessionModalOpts) => void;
  isDragging?: boolean;
}

const Task: React.FunctionComponent<Props> = (props: Props) => {
  const userContext = useContext(UserContext);
  const alertsContext = useContext(AlertsContext);
  const taskStyles = useTaskStyles();

  const { openAddSessionModal } = props;

  const { addTaskToTodoList, removeTaskFromTodoList } = userContext!;

  const [appearDone, setAppearDone] = useState(props.task.done);

  const [isTaskTodo, setIsTaskTodo] = useState(false);

  const handleAddTaskToTodoList = async () => {
    await addTaskToTodoList(props.task.id);
    alertsContext?.addAlert(
      `Moved task "${task.name}" to your TODO list`,
      Intent.SUCCESS
    );
  };

  const handleRemoveTaskFromTodoList = async () => {
    await removeTaskFromTodoList(props.task.id);
    alertsContext?.addAlert(
      `Removed task "${task.name}" from your TODO list`,
      Intent.SUCCESS
    );
  };

  const handleCheck = async () => {
    const { task, saveTask } = props;
    task.done = !task.done;
    setAppearDone(task.done);

    if (task.done) {
      task.completionDateTime = DateTime.now().toISO();
      try {
        await saveTask(task);
        alertsContext!.addAlert(
          `Completed task "${task.name}"`,
          Intent.SUCCESS
        );
      } catch (err) {
        alertsContext!.addAlert(
          `Failed to mark task "${task.name}" as complete`,
          Intent.DANGER
        );
      }
    } else {
      try {
        await saveTask(task);
        alertsContext!.addAlert(
          `Marked task "${task.name}" as not done`,
          Intent.SUCCESS
        );
        task.completionDateTime = null;
      } catch (err) {
        alertsContext!.addAlert(
          `Failed to mark task "${task.name}" as not done`,
          Intent.DANGER
        );
      }
    }
  };

  const makeDueToday = () => {
    const today = DateTime.now().startOf('day');
    updateTaskDueDate(today);
  };

  const makeDueTomorrow = () => {
    const tomorrow = DateTime.now().plus({ days: 1 }).startOf('day');
    updateTaskDueDate(tomorrow);
  };

  const handleOpenAddSessionModal = () => {
    openAddSessionModal({
      associatedTask: task,
      associatedProject: associatedProject,
    });
  };

  const updateTaskDueDate = async (newDueDate: DateTime) => {
    const { task, saveTask } = props;
    task.dueDate = newDueDate.toJSDate().toISOString();
    if (task.reminderTime) {
      const newReminderTime = DateTime.fromISO(task.reminderTime).set({
        year: newDueDate.year,
        month: newDueDate.month,
        day: newDueDate.day,
      });
      task.reminderTime = newReminderTime.toISO();
    }
    try {
      await saveTask(task);
      alertsContext!.addAlert(
        `Updated due date for task "${task.name}"`,
        Intent.SUCCESS
      );
    } catch (err) {
      alertsContext!.addAlert(
        `Failed to update due date for task "${task.name}"`,
        Intent.DANGER
      );
    }
  };

  const deleteTask = () => {
    const { task } = props;
    const { openDeleteTaskModal } = props;
    openDeleteTaskModal(task);
  };

  const {
    task,
    openEditTaskModal,
    type,
    archived,
    allTags,
    associatedProject,
    associatedProjectName,
    showProjectName,
  } = props;
  const { name, reminderTime, tagIds, notes } = task;

  useEffect(() => {
    if (task && userContext?.todoListTaskIds) {
      setIsTaskTodo(getIsTaskTodo(task, userContext!.todoListTaskIds));
    }
  }, [task, userContext?.todoListTaskIds]);

  const readableDueDate = task.dueDate
    ? getReadableDueDate(DateTime.fromISO(task.dueDate), type)
    : '';

  const readableReminderTime = reminderTime
    ? `Reminder set for ${DateTime.fromISO(reminderTime).toLocaleString(
        DateTime.DATETIME_MED_WITH_WEEKDAY
      )}`
    : '';
  const done = task.done;
  const checked = done ? true : false;
  const associatedTags = tagIds
    ? allTags?.filter((tag: ITag) => tagIds.includes(tag.id))
    : [];

  const dropdown = (
    <Menu>
      <MenuItem
        icon="cog"
        text="Edit task"
        onClick={() => openEditTaskModal(task)}
      />
      <MenuItem icon="calendar" text="Due today" onClick={makeDueToday} />
      <MenuItem icon="calendar" text="Due tomorrow" onClick={makeDueTomorrow} />
      <MenuItem
        icon="pulse"
        text="Add session for this task"
        onClick={handleOpenAddSessionModal}
      />
      <MenuItem icon="trash" text="Delete task" onClick={deleteTask} />
      {isTaskTodo ? (
        <MenuItem
          icon="add-to-folder"
          text="Remove task from TODO list"
          onClick={handleRemoveTaskFromTodoList}
        />
      ) : (
        <MenuItem
          icon="add-to-folder" // TODO: change?
          text="Add task to TODO list"
          onClick={handleAddTaskToTodoList}
        />
      )}
    </Menu>
  );

  let className = taskStyles.task;
  if (props.isDragging) {
    className = className.concat(` ${taskStyles.taskDragging}`);
  }
  if (archived) {
    className = className.concat(` ${taskStyles.archivedTask}`);
  }

  return (
    <div>
      <CSSTransition
        timeout={500}
        // @ts-ignore
        in={!appearDone && !userContext.showCompletedTasks}
        classNames="gp-btn-anim"
      >
        <div>
          <Card className={className} elevation={Elevation.ZERO}>
            {readableDueDate && (
              <div className={taskStyles.dueDateContainer}>
                {readableDueDate}
              </div>
            )}
            <div className={taskStyles.taskNameContainer}>
              <div className={taskStyles.checkboxContainer}>
                <Checkbox
                  checked={checked}
                  onChange={handleCheck}
                  style={{ paddingTop: 8 }}
                  inline={true}
                />
              </div>
              <div
                className={
                  archived
                    ? `${taskStyles.taskNameItself} ${taskStyles.archivedTaskNameItself}`
                    : taskStyles.taskNameItself
                }
              >
                {name}
              </div>
              <div className={taskStyles.taskSettingsContainer}>
                <Popover content={dropdown} position={Position.RIGHT_BOTTOM}>
                  <Button icon="more" minimal={true} />
                </Popover>
              </div>
            </div>
            <div className={taskStyles.taskProgressContainer}>
              <TaskProgressBar task={task} />
            </div>
            {task.estimatedMinutes ? (
              <div className={taskStyles.estimatesContainer}>
                <TaskEstimates
                  estimatedMinutes={task.estimatedMinutes}
                  totalMinutesTracked={task.totalMinutesTracked ?? 0}
                  isTaskDone={task.done}
                />
              </div>
            ) : null}
            <div className={taskStyles.taskTagsContainer}>
              {showProjectName && (
                <span
                  style={{
                    marginRight: 20,
                    fontVariant: 'small-caps',
                    letterSpacing: 2,
                    fontSize: 16,
                    color: 'green',
                  }}
                >
                  <strong>{associatedProjectName}</strong>
                </span>
              )}
              {associatedTags &&
                associatedTags.map(tag => (
                  <span key={tag.id}>
                    <ColouredTag
                      tagName={tag.name}
                      tagColour={tag.colour || 'grey'}
                      marginRight
                    />
                  </span>
                ))}
              <div
                style={{
                  float: 'right',
                  paddingRight: 25,
                  color: 'rgba(80, 21, 49, 0.75)',
                }}
              >
                {notes && (
                  <span style={{ paddingLeft: 10 }}>
                    <Popover position={Position.RIGHT}>
                      <Tooltip
                        content={'This task contains a note'}
                        position={Position.RIGHT}
                      >
                        <Icon icon="annotation" />
                      </Tooltip>
                    </Popover>
                  </span>
                )}
                {reminderTime && (
                  <span style={{ paddingLeft: 10 }}>
                    <Popover position={Position.RIGHT}>
                      <Tooltip
                        content={readableReminderTime}
                        position={Position.RIGHT}
                      >
                        <Icon icon="notifications" />
                      </Tooltip>
                    </Popover>
                  </span>
                )}
              </div>
            </div>
          </Card>
        </div>
      </CSSTransition>
    </div>
  );
};

export default Task;

import React from 'react';
import { IUiError } from '../../common-src/types/UiTypes';

interface IProps {
  error: IUiError;
  type: 'error' | 'warning' | 'info';
}

const ModalError: React.FunctionComponent<IProps> = (props: IProps) => {
  const { error } = props;
  const { message, description } = error;

  let intent: string;
  switch (props.type) {
    case 'error':
      intent = 'bp4-intent-danger';
      break;
    case 'warning':
      intent = 'bp4-intent-warning';
      break;
    case 'info':
      intent = 'bp4-intent-primary';
      break;
  }

  return (
    <div className={`bp4-callout ${intent} bp4-icon-info-sign .modifier`}>
      <h4>{message}</h4>
      {description && <p>{description}</p>}
    </div>
  );
};

export { ModalError };

import React from 'react';
import {
  dynamicWhatNeedsToBeDoneForAllHabits,
  dynamicWhatNeedsToBeDoneForAllProjects,
  dynamicWhatNeedsToBeDoneForAllTasks,
  dynamicWhatNeedsToBeDoneForGoal,
  dynamicWhatNeedsToBeDoneForMultipleGoals,
  getWhatNeedsToBeDoneForAllGoals,
} from '../../func/textChunks';
import { IProject, ISimplifiedProject } from '../../common-src/types/Project';
import { TrackingPeriod } from '../../common-src/types/User';
import { ITextChunk } from '../../common-src/types/UiTypes';
import {
  IButlerAssessment,
  IGoalSummary,
  IProjectSummary,
  IStatusOfAllGoals,
} from '../../common-src/types/Reporting';
import { renderTextChunks } from '../../func/ui-utils';

interface Props {
  type: 'PROJECT' | 'MULTIPLE_PROJECTS' | 'GOAL' | 'MULTIPLE_GOALS' | 'OVERALL';
  butlerAssessment?: IButlerAssessment;
  statusOfAllGoals?: IStatusOfAllGoals;
  goalSummary?: IGoalSummary;
  project?: ISimplifiedProject | IProject;
  projectSummary?: IProjectSummary;
  trackingPeriod: TrackingPeriod;
  customTrackingPeriodInDays?: number;
}

const WhatNeedsToBeDone: React.FunctionComponent<Props> = (props: Props) => {
  const {
    type,
    project,
    projectSummary,
    trackingPeriod,
    butlerAssessment,
    customTrackingPeriodInDays,
    statusOfAllGoals,
    goalSummary,
  } = props;

  if (
    (type === 'GOAL' && !goalSummary) ||
    (type === 'MULTIPLE_GOALS' && !statusOfAllGoals) ||
    (type === 'OVERALL' && !butlerAssessment)
  ) {
    return null;
  }

  let whatNeedsToBeDoneStringArray: ITextChunk[] = [];

  if (type === 'OVERALL' && butlerAssessment) {
    const projectsAdvice = dynamicWhatNeedsToBeDoneForAllProjects(
      butlerAssessment.projectsAdvice,
      butlerAssessment.projectsStatus
    );
    const tasksAdvice = dynamicWhatNeedsToBeDoneForAllTasks(
      butlerAssessment.tasksAdvice,
      butlerAssessment.tasksStatus
    );
    const goalsAdvice = getWhatNeedsToBeDoneForAllGoals(
      butlerAssessment.goalsAdvice,
      butlerAssessment.goalsStatus,
      trackingPeriod,
      customTrackingPeriodInDays
    );
    const habitsAdvice = dynamicWhatNeedsToBeDoneForAllHabits(
      butlerAssessment.habitsAdvice,
      butlerAssessment.habitsStatus,
      trackingPeriod,
      customTrackingPeriodInDays
    );
    whatNeedsToBeDoneStringArray = [
      ...projectsAdvice,
      { text: '', beginOnNewLine: true },
      ...tasksAdvice,
      { text: '', beginOnNewLine: true },
      ...goalsAdvice,
      { text: '', beginOnNewLine: true },
      { text: '', beginOnNewLine: true },
      ...habitsAdvice,
    ];
  } else if (type === 'PROJECT' && project && projectSummary) {
    // do nothing for now
  } else if (type === 'MULTIPLE_PROJECTS' && statusOfAllGoals) {
    whatNeedsToBeDoneStringArray =
      dynamicWhatNeedsToBeDoneForMultipleGoals(statusOfAllGoals);
  } else if (type === 'GOAL' && goalSummary) {
    whatNeedsToBeDoneStringArray = dynamicWhatNeedsToBeDoneForGoal(
      goalSummary.goalType,
      goalSummary,
      trackingPeriod,
      customTrackingPeriodInDays
    );
  } else if (type === 'MULTIPLE_GOALS' && statusOfAllGoals) {
    whatNeedsToBeDoneStringArray =
      dynamicWhatNeedsToBeDoneForMultipleGoals(statusOfAllGoals);
  }

  return <p>{renderTextChunks(whatNeedsToBeDoneStringArray)}</p>;
};

export { WhatNeedsToBeDone };

import { DateTime } from 'luxon';
import React, { useContext, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import {
  getSessionsForDate,
  getStartAndEndDatesForTrackingPeriod,
} from '../../common-src/productivity';
import { GoalType, IGoal } from '../../common-src/types/Goal';
import { ISession } from '../../common-src/types/Session';
import { getAreaChartData } from '../../func/chart-utils';
import { GoalContext } from '../../state/GoalContext';
import { ModalContext } from '../../state/ModalContext';
import { ProductivityContext } from '../../state/ProductivityContext';
import { SessionAndUnitContext } from '../../state/SessionAndUnitContext';
import { UserContext } from '../../state/UserContext';
import { WindowContext } from '../../state/WindowContext';
import { useLayoutStyles } from '../../style/components/layoutStyles';
import { useAllPageStyles } from '../../style/components/pageStyles';
import { useProjectPageStyles } from '../../style/pages/projectPageStyles';
import { AreaChartWithPanel } from '../common/charts/AreaChartWithPanel';
import { EditUnits } from '../ProjectPage/EditUnits';
import { ProductivityDateSelect } from '../ProjectPage/ProductivityDateSelect';
import { SessionList } from '../ProjectPage/SessionList';
import { GoalSummaryBox } from './GoalSummaryBox';
import { IGoalSummary } from '../../common-src/types/Reporting';

const GoalPage: React.FunctionComponent = () => {
  const goalContext = useContext(GoalContext);
  const productivityContext = useContext(ProductivityContext);
  const sessionAndUnitContext = useContext(SessionAndUnitContext);
  const userContext = useContext(UserContext);
  const modalContext = useContext(ModalContext);
  const windowContext = useContext(WindowContext);

  const allPageStyles = useAllPageStyles();
  const layoutStyles = useLayoutStyles();
  const projectPageStyles = useProjectPageStyles();

  const { goalId } = useParams<{ goalId: string }>();

  const [goal, setGoal] = useState<IGoal | null>(null);
  const [goalSummary, setGoalSummary] = useState<IGoalSummary | null>(null);
  const [startDate, setStartDate] = useState<DateTime>();
  const [endDate, setEndDate] = useState<DateTime>();
  const [editDate, setEditDate] = useState(DateTime.now());

  const { trackingPeriod, customTrackingPeriod } = userContext!;
  const { goals } = goalContext!;
  const { butlerAssessment } = productivityContext!;
  const {
    createUnitOutputAssociatedWithDay,
    updateOrCreateUnitOutputAssociatedWithDay,
  } = sessionAndUnitContext!;
  const {
    openEditGoalModal,
    openAddSessionModal,
    openEditSessionModal,
    closeEditSessionModal,
    openDeleteSessionModal,
    closeDeleteSessionModal,
  } = modalContext!;

  useEffect(() => {
    if (trackingPeriod) {
      const startAndEndDates = getStartAndEndDatesForTrackingPeriod(
        trackingPeriod,
        customTrackingPeriod
      );
      const start = startAndEndDates[0];
      const end = startAndEndDates[1];
      setStartDate(start);
      setEndDate(end);
    }
  }, [userContext?.trackingPeriod]);

  useEffect(() => {
    if (goals?.length && butlerAssessment?.goalsStatus?.summaries?.length) {
      const goal = goals.find(g => g.id === goalId);
      if (goal) {
        setGoal(goal);
      }
      const goalSummary = butlerAssessment?.goalsStatus.summaries.find(
        p => p.goalId === goalId
      );
      if (goalSummary) {
        setGoalSummary(goalSummary);
      }
    }
  }, [goalId, goals, butlerAssessment]);

  const handleStartDateChange = (newStartDate?: DateTime) => {
    setStartDate(newStartDate);
  };

  const handleEndDateChange = (newEndDate?: DateTime) => {
    setEndDate(newEndDate);
  };

  const handleEditDateChange = editDate => {
    setEditDate(editDate);
  };

  if (!goal || !goalSummary || !startDate || !endDate) {
    return null;
  }

  let chartWidth = 1200;

  if (windowContext?.currentWidth) {
    if (windowContext.isMobile) {
      chartWidth = windowContext.currentWidth - 100;
    } else if (windowContext.currentWidth > 1200) {
      chartWidth = windowContext.currentWidth - 400;
    } else if (windowContext.currentWidth > 1000) {
      chartWidth = windowContext.currentWidth - 350;
    } else if (windowContext.currentWidth > 600) {
      chartWidth = windowContext.currentWidth - 250;
    } else if (windowContext.currentWidth > 300) {
      chartWidth = windowContext.currentWidth - 200;
    }
  }
  const chartHeight = windowContext?.isMobile ? 150 : 400;

  const startOfToday = editDate.startOf('day');

  const sessionsOnEditDate = getSessionsForDate(goal.sessions!, editDate);

  const correspondingUnitObject = goal.units?.find(u =>
    DateTime.fromISO(u.date).startOf('day').equals(startOfToday)
  );
  const mostUpToDateUnits = correspondingUnitObject
    ? correspondingUnitObject.units
    : 0;

  const handleOpenAddSessionModal = () => {
    openAddSessionModal({ associatedGoal: goal });
  };

  const handleOpenEditSessionModal = (session: ISession) => {
    openEditSessionModal(session);
  };

  const handleCloseEditSessionModal = () => {
    closeEditSessionModal();
  };

  const handleOpenDeleteSessionModal = (session: ISession) => {
    openDeleteSessionModal(session);
  };

  const handleCloseDeleteSessionModal = () => {
    closeDeleteSessionModal();
  };

  const areaChartData = getAreaChartData(startDate, endDate, {
    sessions: goal.type === GoalType.SESSION ? goal.sessions : undefined,
    sessionsTarget: goal.type === GoalType.SESSION ? goal.minutesPerDay : 0,
    units: goal.type === GoalType.UNIT ? goal.units : undefined,
    unitsTarget: goal.type === GoalType.UNIT ? goal.unitsPerDay : 0,
    name: goal.unitTypeName,
  });

  if (!areaChartData) {
    return null;
  }

  return (
    <div className={allPageStyles.genericPage}>
      <div>
        {trackingPeriod && (
          <GoalSummaryBox
            goal={goal}
            goalSummary={goalSummary}
            trackingPeriod={trackingPeriod}
            customTrackingPeriod={customTrackingPeriod}
            openEditGoalModal={openEditGoalModal}
          />
        )}
        <AreaChartWithPanel
          areaChartData={areaChartData}
          handleEditDateChange={handleEditDateChange}
          startDate={startDate}
          endDate={endDate}
          width={chartWidth}
          height={chartHeight}
          handleStartDateChange={handleStartDateChange}
          handleEndDateChange={handleEndDateChange}
        />
        <div className={layoutStyles.grid_3_col}>
          <div
            style={{ flex: 1 }}
            className={projectPageStyles.fecundityDateSelect}
          >
            <ProductivityDateSelect
              handleEditDateChange={handleEditDateChange}
              initialDate={editDate}
            />
          </div>
          {goal.type === GoalType.UNIT ? (
            <div style={{ flex: 1 }} className={projectPageStyles.editUnits}>
              <div>
                <EditUnits
                  date={editDate}
                  // project={project}
                  mostUpToDateUnits={mostUpToDateUnits}
                  unitOfLabour={correspondingUnitObject}
                  createUnitOutputAssociatedWithDay={
                    createUnitOutputAssociatedWithDay
                  }
                  updateOrCreateUnitOutputAssociatedWithDay={
                    updateOrCreateUnitOutputAssociatedWithDay
                  }
                  unitName={goal.unitTypeName ?? ''}
                  unitTypeId={goal.unitTypeId ?? ''}
                />
              </div>
            </div>
          ) : null}

          {goal.type === GoalType.SESSION ? (
            <div style={{ flex: 1 }} className={projectPageStyles.sessionList}>
              <div>
                <SessionList
                  date={editDate}
                  openAddSessionModal={handleOpenAddSessionModal}
                  openEditSessionModal={handleOpenEditSessionModal}
                  closeEditSessionModal={handleCloseEditSessionModal}
                  openDeleteSessionModal={handleOpenDeleteSessionModal}
                  closeDeleteSessionModal={handleCloseDeleteSessionModal}
                  sessions={sessionsOnEditDate}
                />
              </div>
            </div>
          ) : null}
        </div>
      </div>
    </div>
  );
};

export { GoalPage };

import React, { ReactNode, useContext } from 'react';
import {
  IApiCreateTagRequest,
  ITag,
  IApiUpdateTagRequest,
} from '../common-src/types/Tag';
import { AuthenticationContext } from './AuthenticationContext';
import { useAuth0 } from '@auth0/auth0-react';
import {
  apiCreateTag,
  apiDeleteTag,
  apiGetTags,
  apiUpdateTag,
} from '../client/apiClient';
import { useQuery, useQueryClient } from '@tanstack/react-query';

interface ITagsContext {
  tags?: ITag[];
  addTag: (tagName: string) => Promise<void>;
  saveTag: (tag: ITag) => Promise<void>;
  deleteTag: (tag: ITag) => Promise<void>;
}

export const TagsContext = React.createContext<ITagsContext | null>(null);

interface Props {
  children: ReactNode;
}

const TagsContextProvider: React.FunctionComponent<Props> = (props: Props) => {
  const { user, isAuthenticated, isLoading, getAccessTokenSilently } =
    useAuth0();
  const authenticationContext = useContext(AuthenticationContext);

  const queryClient = useQueryClient();

  const {
    isLoading: isFetchingTags,
    isError: isFetchTagsError,
    data: tags,
    error: fetchTagsError,
  } = useQuery({
    queryKey: ['getTags'],
    queryFn: async () => {
      try {
        if (
          authenticationContext &&
          authenticationContext.isUserAuthenticated
        ) {
          const accessToken = await authenticationContext.getToken();
          const tags = await apiGetTags(accessToken);
          return tags;
        }
      } catch (err) {
        throw err;
      }
    },
    enabled: !!authenticationContext?.isUserAuthenticated,
  });

  const addTag = async (tagName: string) => {
    try {
      if (authenticationContext && authenticationContext.isUserAuthenticated) {
        const accessToken = await authenticationContext.getToken();
        const createTagRequest: IApiCreateTagRequest = {
          name: tagName,
        };
        const newTag = await apiCreateTag(accessToken, createTagRequest);
        queryClient.invalidateQueries({ queryKey: ['getTags'] });
      }
    } catch (err) {
      // TODO: handle error
    }
  };

  const saveTag = async (tag: ITag) => {
    try {
      if (authenticationContext && authenticationContext.isUserAuthenticated) {
        const accessToken = await authenticationContext.getToken();
        const updateTagRequest: IApiUpdateTagRequest = {
          name: tag.name,
          colour: tag.colour,
        };
        const newTag = await apiUpdateTag(
          accessToken,
          tag.id,
          updateTagRequest
        );
        queryClient.invalidateQueries({ queryKey: ['getTags'] });
      }
    } catch (err) {
      console.error(err);
    }
  };

  const deleteTag = async (tag: ITag) => {
    try {
      if (authenticationContext && authenticationContext.isUserAuthenticated) {
        const accessToken = await authenticationContext.getToken();
        await apiDeleteTag(accessToken, tag.id);
        queryClient.invalidateQueries({ queryKey: ['getTags'] });
      }
    } catch (err) {
      console.error(err);
    }
  };

  const getTagsContext = () => {
    return {
      tags,
      addTag,
      saveTag,
      deleteTag,
    };
  };

  const tagsContext = getTagsContext();

  return (
    <TagsContext.Provider value={tagsContext}>
      {props.children}
    </TagsContext.Provider>
  );
};

export { TagsContextProvider };
export type { ITagsContext };

import React, { useState, useContext, useEffect } from 'react';
import { Dialog, Button, ButtonGroup, Intent } from '@blueprintjs/core';
import { AlertsContext } from '../../state/AlertsContext';
import { useModalStyles } from '../../style/components/genericStyles';
import { WindowContext } from '../../state/WindowContext';
import { IApiCreateUnitTypeRequest } from '../../common-src/types/UnitOfLabour';

interface IProps {
  onRequestClose: () => void;
  modalIsOpen: boolean;
  addUnitType: (
    createUnitTypeRequest: IApiCreateUnitTypeRequest
  ) => Promise<void>;
}

const AddUnitTypeModal: React.FunctionComponent<IProps> = (props: IProps) => {
  const alertsContext = useContext(AlertsContext);
  const windowContext = useContext(WindowContext);
  const modalStyles = useModalStyles();

  const [unitTypeName, setUnitTypeName] = useState<string>('');

  const [isFormValid, setIsFormValid] = useState<boolean>(false);

  const [isSaving, setIsSaving] = useState<boolean>(false);

  useEffect(() => {
    if (unitTypeName === '') {
      setIsFormValid(false);
    } else {
      setIsFormValid(true);
    }
  }, [unitTypeName]);

  if (!alertsContext || !windowContext) {
    return null;
  }

  const handleTextChange = event => {
    setUnitTypeName(event.target.value);
  };

  const handleAddUnitType = async event => {
    event.preventDefault();

    setIsSaving(true);

    const { addUnitType } = props;

    const name = unitTypeName;

    if (name !== '') {
      try {
        const createUnitTypeRequest: IApiCreateUnitTypeRequest = {
          name,
        };
        await addUnitType(createUnitTypeRequest);
        alertsContext.addAlert(
          `Successfully added unit type "${name}"`,
          Intent.SUCCESS
        );
      } catch (error) {
        alertsContext.addAlert(
          `Failed to add unit type "${name}"`,
          Intent.DANGER
        );
      } finally {
        setIsSaving(false);
        handleClose();
      }
    }
  };

  const handleClose = () => {
    setUnitTypeName('');
    props.onRequestClose();
  };

  return (
    <Dialog
      isOpen={props.modalIsOpen}
      onClose={props.onRequestClose}
      title="Add a unit type"
      canOutsideClickClose={false}
    >
      <div className={`bp4-dialog-body ${modalStyles.body}`}>
        <label className="bp4-label">
          A name for this unit type (e.g. "pages", "words", "miles", "reps"):
          <input
            className="bp4-input bp4-fill"
            type="text"
            onChange={handleTextChange}
            autoFocus
          />
        </label>
      </div>
      <div className={`bp4-dialog-footer ${modalStyles.footer}`}>
        <ButtonGroup>
          <Button onClick={handleClose}>Cancel</Button>
          <Button
            onClick={handleAddUnitType}
            intent={Intent.PRIMARY}
            disabled={!isFormValid}
            loading={isSaving}
          >
            Add
          </Button>
        </ButtonGroup>
      </div>
    </Dialog>
  );
};

export { AddUnitTypeModal };

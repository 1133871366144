import React, { useContext, useEffect, useState } from 'react';
import { Dialog, ButtonGroup, Button, Intent } from '@blueprintjs/core';
import { AlertsContext } from '../../state/AlertsContext';
import { useModalStyles } from '../../style/components/genericStyles';
import { GoalContext } from '../../state/GoalContext';
import {
  GoalType,
  IApiUpdateGoalRequest,
  IGoal,
} from '../../common-src/types/Goal';
import { DateTime } from 'luxon';
import {
  DatetimeFormat,
  DatetimePicker,
  DatetimePickerType,
} from '../common/DatetimePicker/DatetimePicker';
import { ISimplifiedProject } from '../../common-src/types/Project';
import { ILabourType } from '../../common-src/types/LabourType';
import { IUnitType } from '../../common-src/types/UnitOfLabour';

interface Props {
  modalIsOpen: boolean;
  goalToEdit: IGoal | undefined;
  onRequestClose: () => any;
  simplifiedProjectList?: ISimplifiedProject[];
  labourTypes: ILabourType[];
  unitTypes: IUnitType[];
}

const EditGoalModal: React.FunctionComponent<Props> = (props: Props) => {
  const { goalToEdit, simplifiedProjectList, labourTypes, unitTypes } = props;
  const goalContext = useContext(GoalContext);
  const alertsContext = useContext(AlertsContext);
  const modalStyles = useModalStyles();

  const { updateGoal } = goalContext!;

  const [goalName, setGoalName] = useState<string>('');
  const [goalType, setGoalType] = useState<GoalType>();
  const [goalStartDate, setGoalStartDate] = useState<string | undefined>();
  const [goalEndDate, setGoalEndDate] = useState<string | undefined>();
  const [goalLabourTypeId, setGoalLabourTypeId] = useState<
    string | undefined
  >();
  const [goalMinutesPerDay, setGoalMinutesPerDay] = useState<
    number | undefined
  >();
  const [goalUnitTypeId, setGoalUnitTypeId] = useState<string | undefined>();
  const [goalUnitsPerDay, setGoalUnitsPerDay] = useState<number | undefined>();
  const [goalIsEnabled, setGoalIsEnabled] = useState<boolean>(true);

  const [isSaving, setIsSaving] = useState(false);

  const [isGoalStartDateFocused, setIsGoalStartDateFocused] = useState(false);
  const [isGoalEndDateFocused, setIsGoalEndDateFocused] = useState(false);

  useEffect(() => {
    if (goalToEdit) {
      setGoalName(goalToEdit.name);
      setGoalType(goalToEdit.type);
      setGoalStartDate(goalToEdit.startDate);
      setGoalEndDate(goalToEdit.endDate);
      setGoalLabourTypeId(goalToEdit.labourTypeId);
      setGoalMinutesPerDay(goalToEdit.minutesPerDay);
      setGoalUnitTypeId(goalToEdit.unitTypeId);
      setGoalUnitsPerDay(goalToEdit.unitsPerDay);
      setGoalIsEnabled(goalToEdit.isActive);
    }
  }, [goalToEdit]);

  const handleUpdateGoal = async event => {
    event.preventDefault();
    setIsSaving(true);

    if (goalName !== '' && goalType) {
      try {
        const updateGoalRequest: IApiUpdateGoalRequest = {
          name: goalName,
          goal_type: goalType,
          start_date: goalStartDate,
          end_date: goalEndDate,
          minutes_per_day: goalMinutesPerDay,
          labour_type_id: goalLabourTypeId,
          unit_type_id: goalUnitTypeId,
          units_per_day: goalUnitsPerDay,
          is_active: goalIsEnabled,
        };
        await updateGoal(goalToEdit!.id, updateGoalRequest);

        alertsContext!.addAlert(`Updated goal "${goalName}"`, Intent.SUCCESS);
      } catch (err) {
        alertsContext!.addAlert(
          `Error updating goal "${goalName}"`,
          Intent.DANGER
        );
      } finally {
        setIsSaving(false);
        handleClose();
      }
    }
  };

  const handleGoalNameChange = event => {
    setGoalName(event.target.value);
  };

  const handleGoalTypeChange = event => {
    setGoalType(event.target.value);
  };

  const handleStartDateChange = (date?: DateTime) => {
    setGoalStartDate(date?.toISO());
  };

  const handleStartDateError = (date: Date) => {
    setGoalStartDate(undefined);
  };

  const handleStartDateFocusChange = (arg: { focused: boolean }) => {
    setIsGoalStartDateFocused(arg.focused);
  };

  const handleEndDateChange = (date?: DateTime) => {
    setGoalEndDate(date?.toISO());
  };

  const handleEndDateFocusChange = (arg: { focused: boolean }) => {
    setIsGoalEndDateFocused(arg.focused);
  };

  const handleLabourTypeChange = event => {
    setGoalLabourTypeId(event.target.value);
  };

  const handleMinutesPerDayChange = event => {
    setGoalMinutesPerDay(event.target.value);
  };

  const handleUnitTypeChange = event => {
    setGoalUnitTypeId(event.target.value);
  };

  const handleUnitsPerDayChange = event => {
    setGoalUnitsPerDay(event.target.value);
  };

  const handleIsEnabledChange = event => {
    setGoalIsEnabled(event.target.checked);
  };

  const handleClose = () => {
    setGoalName('');
    props.onRequestClose();
  };

  const isDisabled =
    !goalName || (goalType === GoalType.UNIT && !goalUnitTypeId);

  const projectsToShow: JSX.Element[] = [];

  projectsToShow.push(
    <option value="" key="None">
      None
    </option>
  );

  simplifiedProjectList?.forEach((project: ISimplifiedProject) => {
    projectsToShow.push(
      <option value={project.id} key={project.id}>
        {project.name}
      </option>
    );
  });

  const labourTypesToShow: JSX.Element[] = [];

  labourTypesToShow.push(
    <option value="" key="None">
      None
    </option>
  );

  labourTypes.forEach((labourType: ILabourType) => {
    labourTypesToShow.push(
      <option value={labourType.id} key={labourType.id}>
        {labourType.name}
      </option>
    );
  });

  const unitTypesToShow: JSX.Element[] = [];

  unitTypesToShow.push(
    <option value="" key="None">
      None
    </option>
  );

  unitTypes.forEach((unitType: IUnitType) => {
    unitTypesToShow.push(
      <option value={unitType.id} key={unitType.id}>
        {unitType.name}
      </option>
    );
  });

  return (
    <Dialog
      isOpen={props.modalIsOpen}
      onClose={props.onRequestClose}
      title="Edit goal"
      canOutsideClickClose={false}
    >
      <div className={`bp4-dialog-body ${modalStyles.body}`}>
        <label className="bp4-label">
          Name
          <input
            className="bp4-input bp4-fill"
            type="text"
            onChange={handleGoalNameChange}
            value={goalName}
          />
        </label>
        <label className="bp4-label">
          Goal type
          <div className="bp4-select">
            <select value={goalType} onChange={handleGoalTypeChange} disabled>
              <option value={GoalType.SESSION} key={GoalType.SESSION}>
                Session
              </option>
              <option value={GoalType.UNIT} key={GoalType.UNIT}>
                Unit
              </option>
            </select>
          </div>
        </label>
        <DatetimePicker
          id="goal_start_date_picker"
          type={DatetimePickerType.date}
          label="Start date"
          placeholder="Enter the start date here (optional)"
          datetime={goalStartDate ? DateTime.fromISO(goalStartDate) : undefined}
          onDatetimeChange={handleStartDateChange}
          handleFocusChange={handleStartDateFocusChange}
          isFocused={isGoalStartDateFocused}
          datetimeFormat={DatetimeFormat.DATE}
          canClear={false}
        />
        <DatetimePicker
          id="goal_end_date_picker"
          type={DatetimePickerType.date}
          label="End date"
          placeholder="Enter the end date here (optional)"
          datetime={goalEndDate ? DateTime.fromISO(goalEndDate) : undefined}
          onDatetimeChange={handleEndDateChange}
          handleFocusChange={handleEndDateFocusChange}
          isFocused={isGoalEndDateFocused}
          datetimeFormat={DatetimeFormat.DATE}
          canClear={false}
        />
        {labourTypes && labourTypes.length > 0 && (
          <label className="bp4-label">
            Associated work type
            <div className="bp4-select">
              <select
                value={goalLabourTypeId ?? ''}
                onChange={handleLabourTypeChange}
              >
                {labourTypesToShow}
              </select>
            </div>
          </label>
        )}

        {goalType === GoalType.SESSION ? (
          <div>
            <label className="bp4-label">
              Choose target daily minutes:
              <input
                className="bp4-input bp4-fill"
                type="text"
                onChange={handleMinutesPerDayChange}
                value={goalMinutesPerDay}
              />
            </label>
          </div>
        ) : null}
        {goalType === GoalType.UNIT ? (
          <div>
            {unitTypes && unitTypes.length > 0 && (
              <label className="bp4-label">
                Associated unit type
                <div className="bp4-select">
                  <select
                    value={goalUnitTypeId ?? ''}
                    onChange={handleUnitTypeChange}
                  >
                    {unitTypesToShow}
                  </select>
                </div>
              </label>
            )}
            <label className="bp4-label">
              Choose target daily units:
              <input
                className="bp4-input bp4-fill"
                type="text"
                onChange={handleUnitsPerDayChange}
                value={goalUnitsPerDay}
              />
            </label>
          </div>
        ) : null}
        <label className="bp4-control bp4-checkbox">
          <input
            type="checkbox"
            checked={goalIsEnabled}
            onChange={handleIsEnabledChange}
          />
          <span className="bp4-control-indicator" />
          Enabled
        </label>
      </div>
      <div className={`bp4-dialog-footer ${modalStyles.footer}`}>
        <ButtonGroup>
          <Button onClick={handleClose}>Cancel</Button>
          <Button
            onClick={handleUpdateGoal}
            intent={Intent.PRIMARY}
            loading={isSaving}
            disabled={isDisabled}
          >
            Save
          </Button>
        </ButtonGroup>
      </div>
    </Dialog>
  );
};

export { EditGoalModal };

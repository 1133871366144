interface IConfig {
  apiUrl: string;
  auth: {
    domain: string;
    clientId: string;
    audience: string;
  };
  pushNotifications: {
    publicKey?: string;
  };
}

function getConfig(): IConfig {
  let config: IConfig;

  const dev = {
    apiUrl: 'http://localhost:8001/',
    auth: {
      domain: 'dev-u351kplm.us.auth0.com',
      clientId: 'SNacBzfhIscV8NoXYnB7SW6kKrn6hIup',
      audience: 'https://dev-u351kplm.us.auth0.com/api/v2/',
    },
    pushNotifications: {
      publicKey:
        'BAYwbFMzcwaQDUWtA0gKK7y2dc2q_0aFt-447XV9T-VnNjR_XmOrlwRksqqSw1scvDQJSF0HWBdDqRbP65QlM4M',
    },
  };
  const test = {
    apiUrl: 'https://seal-app-p9qrr.ondigitalocean.app/chekhov-api/',
    auth: {
      domain: 'dev-u351kplm.us.auth0.com',
      clientId: 'SNacBzfhIscV8NoXYnB7SW6kKrn6hIup',
      audience: 'https://dev-u351kplm.us.auth0.com/api/v2/',
    },
    pushNotifications: {
      publicKey:
        'BD1TgukOvKaJMZNUmwgH8aTvmtPrQ4VvHsEZvxQjceom9CKy6yKx55mnKPyp957bu3WspkRw_navp3P6G6-gFnk',
    },
  };
  const prod = {
    apiUrl: 'https://gosplan-api-prod-h5kcd.ondigitalocean.app/',
    auth: {
      domain: 'app-production.us.auth0.com',
      clientId: 'lQL7tIxh096aAcayBfS0dD5UBlwOf6m6',
      audience: 'https://app-production.us.auth0.com/api/v2/',
    },
    pushNotifications: {
      publicKey:
        'BFfJf492HIucSoletDtmgiL3TwfEVOPeW9t8bTpTPDXXr8-rINZsFKQ20XzAopVU3CoxLrsPnoS17h5bHNTokqc',
    },
  };

  if (process.env.REACT_APP_STAGE === 'prod') {
    config = prod;
  } else if (process.env.REACT_APP_STAGE === 'test') {
    config = test;
  } else {
    config = dev;
  }

  return config;
}

export { getConfig };

import { createUseStyles } from 'react-jss';
import { chosenTheme } from '../styleHooks';

export const useGoalPageStyles = createUseStyles({
  goalSummaryBox: {
    backgroundColor: chosenTheme.genericComponentBackgroundColour,
    color: chosenTheme.genericComponentPrimaryFontColour,
    borderRadius: 5,
    padding: 10,
    marginBottom: 10,
    WebkitBoxShadow: '6px 22px 39px -14px rgba(0, 0, 0, 0.15)',
    MozBoxShadow: '6px 22px 39px -14px rgba(0, 0, 0, 0.15)',
    boxShadow: '6px 22px 39px -14px rgba(0, 0, 0, 0.15)',
    '& h1, h2, p': {
      textAlign: 'left',
      marginBottom: 2,
    },
    '& h1': {
      marginBottom: 2,
    },
    zIndex: 1,
  },
});

import { createUseStyles } from 'react-jss';
import { chosenTheme } from '../styleHooks';

export const useLogStyles = createUseStyles({
  individualLog: {
    display: 'flex',
    padding: '10px 0',
    borderBottomColor: chosenTheme.log.borderBottomColour,
    borderBottomStyle: 'solid',
    borderBottomWidth: '1px',
  },
  individualLogText: {
    flex: 1,
  },
  individualLogMeta: {
    flex: 1,
    textAlign: 'right',
  },
  logListWrapper: {
    width: '75%',
    margin: 'auto',
    marginTop: 20,
    '& p': {
      fontSize: 16,
      textAlign: 'left',
    },
  },
  '@media only screen and (min-width: 320px) and (max-width: 480px)': {
    individualLogText: {
      flex: 2,
      overflowX: 'clip',
    },
    individualLogMeta: {
      flex: 1,
      overflowX: 'clip',
    },
    logListWrapper: {
      width: '100%',
      margin: 'auto',
      marginTop: 20,
    },
  },
});

import { createUseStyles } from 'react-jss';

export const useLandingStyles = createUseStyles({
  landingBody: {
    backgroundColor: 'rgb(247, 233, 217)',
  },

  logoHeader: {
    height: 150,
    padding: 20,
    color: 'black',
  },

  landingSection: {
    padding: '25px 50px 10px 25px',
  },

  sectionHeader: {
    textAlign: 'left',
    fontSize: 24,
  },

  landingHeader: {
    textAlign: 'center',
    fontSize: 48,
  },

  landingSubheader: {
    textAlign: 'center',
    fontSize: 24,
  },

  landingFormWrapper: {
    padding: '20px 50px 20px 50px',
    border: 'solid red',
    width: '75%',
    margin: 'auto',
  },

  landingFormInstructions: {
    padding: '20px 50px 20px 50px',
    width: '50%',
    margin: 'auto',
  },

  '@media only screen and (max-width: 750px)': {
    landingSection: {
      padding: 10,
    },
    landingFormWrapper: {
      width: '100%',
      padding: 10,
    },
    landingFormInstructions: {
      width: '100%',
      padding: 20,
    },
  },
});

import React, { useContext, useEffect, useState } from 'react';
import { DateTime } from 'luxon';
import { IProject } from '../../common-src/types/Project';
import { ProductivityDateSelect } from './ProductivityDateSelect';
import { SessionList } from './SessionList';
import { ISession } from '../../common-src/types/Session';
import { getAreaChartData } from '../../func/chart-utils';
import { WindowContext } from '../../state/WindowContext';
import { useProjectPageStyles } from '../../style/pages/projectPageStyles';
import { AreaChartWithPanel } from '../common/charts/AreaChartWithPanel';
import { useLayoutStyles } from '../../style/components/layoutStyles';
import { ProjectContext } from '../../state/ProjectContext';
import {
  getSessionsForDate,
  getStartAndEndDatesForTrackingPeriod,
} from '../../common-src/productivity';
import { UserContext } from '../../state/UserContext';
import { IOpenAddSessionModalOpts } from '../../common-src/types/UiTypes';

interface IProps {
  project: IProject;
  handleEditDateChange: (editDate?: DateTime) => void;
  editDate: DateTime;
  openAddSessionModal: (opts?: IOpenAddSessionModalOpts) => void;
  openEditSessionModal: (session: ISession) => void;
  closeEditSessionModal: () => void;
  openDeleteSessionModal: (session: ISession) => void;
  closeDeleteSessionModal: () => void;
}

const ProjectTabReporting: React.FunctionComponent<IProps> = (
  props: IProps
) => {
  const projectContext = useContext(ProjectContext);
  const userContext = useContext(UserContext);
  const windowContext = useContext(WindowContext);

  const projectPageStyles = useProjectPageStyles();
  const layoutStyles = useLayoutStyles();

  const [startDate, setStartDate] = useState<DateTime>();
  const [endDate, setEndDate] = useState<DateTime>();

  useEffect(() => {
    if (trackingPeriod) {
      const startAndEndDates = getStartAndEndDatesForTrackingPeriod(
        trackingPeriod,
        customTrackingPeriod
      );
      const start = startAndEndDates[0];
      const end = startAndEndDates[1];
      setStartDate(start);
      setEndDate(end);
    }
  }, [userContext?.trackingPeriod]);

  const {
    project,
    handleEditDateChange,
    editDate,
    openAddSessionModal,
    openEditSessionModal,
    closeEditSessionModal,
    openDeleteSessionModal,
    closeDeleteSessionModal,
  } = props;

  if (!projectContext || !userContext || !windowContext) {
    return null;
  }

  const { sessions } = projectContext;
  const { trackingPeriod, customTrackingPeriod } = userContext;

  const projectSessions = project.sessions;

  const projectSessionsOnEditDate = projectSessions
    ? getSessionsForDate(projectSessions, editDate)
    : [];

  const handleStartDateChange = (newStartDate?: DateTime) => {
    setStartDate(newStartDate);
  };

  const handleEndDateChange = (newEndDate?: DateTime) => {
    setEndDate(newEndDate);
  };

  if (startDate && endDate) {
    let chartWidth = 1200;

    if (windowContext?.currentWidth) {
      if (windowContext.isMobile) {
        chartWidth = windowContext.currentWidth - 100;
      } else if (windowContext.currentWidth > 1200) {
        chartWidth = windowContext.currentWidth - 400;
      } else if (windowContext.currentWidth > 1000) {
        chartWidth = windowContext.currentWidth - 350;
      } else if (windowContext.currentWidth > 600) {
        chartWidth = windowContext.currentWidth - 250;
      } else if (windowContext.currentWidth > 300) {
        chartWidth = windowContext.currentWidth - 200;
      }
    }
    const chartHeight = windowContext?.isMobile ? 150 : 400;

    const areaChartData = getAreaChartData(startDate, endDate, {
      sessions,
      // title: 'Time spent on project',
    });

    if (!areaChartData) {
      return null;
    }

    return (
      <div style={{ minHeight: 450 }}>
        <div>
          <AreaChartWithPanel
            areaChartData={areaChartData}
            handleEditDateChange={handleEditDateChange}
            startDate={startDate}
            endDate={endDate}
            width={chartWidth}
            height={chartHeight}
            handleStartDateChange={handleStartDateChange}
            handleEndDateChange={handleEndDateChange}
          />
        </div>
        {/* TODO: below should be abstracted into a separate DaySummary (or similar) component */}
        <div className={layoutStyles.grid_3_col}>
          <div
            style={{ flex: 1 }}
            className={projectPageStyles.fecundityDateSelect}
          >
            <ProductivityDateSelect
              handleEditDateChange={handleEditDateChange}
              initialDate={editDate}
            />
          </div>
          {/* {mostUpToDateUnits ? (
            <div style={{ flex: 1 }} className={projectPageStyles.editUnits}>
              {projectUnitsByGoal.map((units, i) => {
                return (
                  <div key={i}>
                    <EditUnits
                      date={editDate}
                      project={project}
                      mostUpToDateUnits={mostUpToDateUnits}
                      unitOfLabour={correspondingUnitObject}
                      createUnitOutputAssociatedWithDay={
                        createUnitOutputAssociatedWithDay
                      }
                      updateOrCreateUnitOutputAssociatedWithDay={
                        updateOrCreateUnitOutputAssociatedWithDay
                      }
                      unitName={units.unitName ?? ''}
                      unitTypeId={units.unitTypeId ?? ''}
                    />
                  </div>
                );
              })}
            </div>
          ) : null} */}
          <div style={{ flex: 1 }} className={projectPageStyles.sessionList}>
            <SessionList
              date={editDate}
              openAddSessionModal={openAddSessionModal}
              openEditSessionModal={openEditSessionModal}
              closeEditSessionModal={closeEditSessionModal}
              openDeleteSessionModal={openDeleteSessionModal}
              closeDeleteSessionModal={closeDeleteSessionModal}
              sessions={projectSessionsOnEditDate}
            />
          </div>
        </div>
      </div>
    );
  } else {
    return (
      <div>
        There's nothing to see here. If you would like to track your
        productivity, click "Edit" above.
      </div>
    );
  }
};

export { ProjectTabReporting };

import React, { useContext, useState } from 'react';
import { Dialog, Button, ButtonGroup, Intent } from '@blueprintjs/core';
import { AlertsContext } from '../../state/AlertsContext';
import { ModalContext } from '../../state/ModalContext';
import { useModalStyles } from '../../style/components/genericStyles';
import { IGoal } from '../../common-src/types/Goal';

interface Props {
  onRequestClose: () => void;
  modalIsOpen: boolean;
  deleteGoal: (goal: IGoal) => Promise<void>;
}

const DeleteGoalModal: React.FunctionComponent<Props> = (props: Props) => {
  const alertsContext = useContext(AlertsContext);
  const modalContext = useContext(ModalContext);
  const modalStyles = useModalStyles();

  const [isDeleting, setIsDeleting] = useState<boolean>(false);

  if (!modalContext || !alertsContext) {
    return null;
  }
  const { goalToDelete } = modalContext;

  const handleDeleteGoal = async event => {
    event.preventDefault();
    setIsDeleting(true);
    const { deleteGoal } = props;

    if (!goalToDelete) {
      return;
    }

    try {
      await deleteGoal(goalToDelete);
      alertsContext.addAlert(
        `Deleted goal "${goalToDelete.name}"`,
        Intent.SUCCESS
      );
    } catch (err) {
      alertsContext.addAlert(
        `Error deleting goal "${goalToDelete.name}"`,
        Intent.DANGER
      );
    } finally {
      setIsDeleting(false);
      handleClose();
    }
  };

  const handleClose = () => {
    props.onRequestClose();
  };

  return (
    <Dialog
      isOpen={props.modalIsOpen}
      onClose={props.onRequestClose}
      title={goalToDelete && `Delete goal "${goalToDelete.name}"`}
      canOutsideClickClose={false}
    >
      <div className={`bp4-dialog-body ${modalStyles.body}`}>
        <p>Are you sure you want to delete this goal?</p>
      </div>
      <div className={`bp4-dialog-footer ${modalStyles.footer}`}>
        <ButtonGroup>
          <Button onClick={handleClose}>Cancel</Button>
          <Button
            onClick={handleDeleteGoal}
            intent={Intent.DANGER}
            loading={isDeleting}
          >
            Do it!
          </Button>
        </ButtonGroup>
      </div>
    </Dialog>
  );
};

export { DeleteGoalModal };

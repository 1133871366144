import { Button, ButtonGroup, Dialog, Intent } from '@blueprintjs/core';
import React, { useContext, useState } from 'react';
import { IPhase, ISimplifiedPhase } from '../../common-src/types/Project';
import { AlertsContext } from '../../state/AlertsContext';
import { useModalStyles } from '../../style/components/genericStyles';
import { ProjectContext } from '../../state/ProjectContext';

interface IProps {
  modalIsOpen: boolean;
  onRequestClose: () => void;
  phase: IPhase;
  currentPhases: IPhase[];
}

const EditPhaseModal: React.FunctionComponent<IProps> = (props: IProps) => {
  const projectContext = useContext(ProjectContext);
  const alertsContext = useContext(AlertsContext);
  const modalStyles = useModalStyles();
  const { modalIsOpen, onRequestClose, phase } = props;

  const [phaseName, setPhaseName] = useState(phase.name);
  const [isSaving, setIsSaving] = useState<boolean>(false);

  const handlePhaseNameChange = event => {
    setPhaseName(event.target.value);
  };

  const handleClose = () => {
    setPhaseName('');
    onRequestClose();
  };

  const handleEditPhase = async event => {
    event.preventDefault();
    setIsSaving(true);

    const updatedPhase: ISimplifiedPhase = {
      id: phase.id,
      name: phaseName,
    };

    if (phaseName !== '') {
      try {
        await projectContext?.updatePhase(updatedPhase);
        alertsContext!.addAlert(
          `Updated the phase name to "${phaseName}"`,
          Intent.SUCCESS
        );
      } catch (err) {
        alertsContext!.addAlert(
          `Error updating phase "${phaseName}"`,
          Intent.DANGER
        );
      } finally {
        setIsSaving(false);
        handleClose();
      }
    }
  };

  return (
    <Dialog
      isOpen={modalIsOpen}
      onClose={onRequestClose}
      title={`Edit phase "${phase.name}"`}
      canOutsideClickClose={false}
    >
      <div className={`bp4-dialog-body ${modalStyles.body}`}>
        <label className="bp4-label">
          Phase name:
          <input
            className="bp4-input bp4-fill"
            type="text"
            onChange={handlePhaseNameChange}
            value={phaseName}
          />
        </label>
      </div>

      <div className={`bp4-dialog-footer ${modalStyles.footer}`}>
        <ButtonGroup>
          <Button onClick={handleClose}>Cancel</Button>
          <Button
            onClick={handleEditPhase}
            intent={Intent.PRIMARY}
            loading={isSaving}
          >
            Save
          </Button>
        </ButtonGroup>
      </div>
    </Dialog>
  );
};

export { EditPhaseModal };

import React, { useState, ReactNode } from 'react';
import { ITag } from '../../../common-src/types/Tag';
import { MultiSelect, ItemRenderer } from '@blueprintjs/select';
import { MenuItem } from '@blueprintjs/core';

interface Props {
  tags: ITag[];
  selectedTags: ITag[];
  onTagSelect: (tag: ITag) => void;
  onTagRemove: (tagName: string, index: number) => void;
}

const TagSelector: React.FunctionComponent<Props> = (props: Props) => {
  const { tags } = props;
  const [tagIds, setTagIds] = useState<string[] | undefined>(undefined);

  const sortedTags = tags?.sort((a, b) => a.name.localeCompare(b.name));

  const TagMultiSelect = MultiSelect.ofType<ITag>();
  const renderTag = (tag: ITag) => tag.name;

  const handleTagSelect = (tag: ITag) => {
    props.onTagSelect(tag);
  };

  const handleTagRemove = (tagName: ReactNode, index: number) => {
    props.onTagRemove(tagName!.toString(), index);
  };

  const renderTagInMenu: ItemRenderer<ITag> = (tag, { handleClick }) => {
    return (
      <MenuItem
        active={tagIds && tagIds!.includes(tag.id)}
        icon={isTagSelected(tag) ? 'tick' : 'blank'}
        key={tag.id}
        onClick={handleClick}
        text={`${tag.name}`}
        shouldDismissPopover={false}
        style={{ marginTop: 5, marginBottom: 5 }}
      />
    );
  };

  const isTagSelected = (tag: ITag) => {
    const selectedTags = props.selectedTags;
    return selectedTags.filter(aTag => aTag.id === tag.id).length > 0;
  };

  return (
    <TagMultiSelect
      tagRenderer={renderTag}
      items={sortedTags}
      onItemSelect={handleTagSelect}
      itemRenderer={renderTagInMenu}
      selectedItems={props.selectedTags}
      tagInputProps={{ onRemove: handleTagRemove }}
      placeholder="Select tags"
    />
  );
};

export { TagSelector };

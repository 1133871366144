import _ from 'lodash';
import { isTask, ITask } from '../common-src/types/Task';
import { IPhase, isPhase } from '../common-src/types/Project';
import { List } from 'immutable';
import { isEqual } from './utils';
import { DragDropType } from '../common-src/types/enum';
import { ITag } from '../common-src/types/Tag';

export const handleGenericStringIdsDragDrop = (
  result: any,
  idList: string[]
): string[] | null => {
  const { source, destination, draggableId } = result;

  if (!destination) {
    return null;
  }

  if (
    destination.droppableId === source.droppableId &&
    destination.index === source.index
  ) {
    return null;
  }

  const movedId = draggableId;

  const newIdList = Array.from(idList);
  newIdList.splice(source.index, 1);
  newIdList.splice(destination.index, 0, movedId);

  return newIdList;
};

export const handleProjectLevelTodoDragDrop = (
  result: any,
  tasks: ITask[]
): ITask[] | null => {
  const { destination, source, draggableId } = result;
  const sortedTasks = tasks.slice();

  if (!destination) {
    return null;
  }

  if (
    destination.droppableId === source.droppableId &&
    destination.index === source.index
  ) {
    return null;
  }

  const movedTask = sortedTasks.find(task => isEqual(task.id, draggableId));

  const tasksInNewOrder = Array.from(sortedTasks);
  tasksInNewOrder.splice(source.index, 1);
  tasksInNewOrder.splice(destination.index, 0, movedTask!);

  return tasksInNewOrder;
};

// TODO: Ultimately make this function more generic
export const handleBoardDragDrop = (
  result: {
    destination: any;
    source: any;
    draggableId: string;
    type: DragDropType;
  },
  originalPhases: IPhase[],
  movedItem: ITask | IPhase
): IPhase[] | null => {
  const phases = originalPhases.slice();
  const { destination, source, draggableId, type } = result;

  if (!destination) {
    return null;
  }

  if (
    destination.droppableId === source.droppableId &&
    destination.index === source.index
  ) {
    return null;
  }

  const allTasks: ITask[] = [];
  phases.forEach(phase => {
    if (phase.tasks) {
      phase.tasks.forEach(task => {
        allTasks.push(task);
      });
    }
  });

  if (!movedItem) {
    return null;
  }

  if (type === DragDropType.PHASE && isPhase(movedItem)) {
    // moving phases
    const phaseBeingMoved: IPhase = phases[source.index];
    const reorderedPhases = List(phases).toArray();
    reorderedPhases.splice(source.index, 1);
    reorderedPhases.splice(destination.index, 0, phaseBeingMoved);
    return reorderedPhases;
  } else if (type === DragDropType.TASK && isTask(movedItem)) {
    // moving tasks
    const startPhase = phases.find(phase => phase.id === source.droppableId);
    const endPhase = phases.find(phase => phase.id === destination.droppableId);

    const startPhaseIndex = phases.findIndex(
      phase => phase.id === source.droppableId
    );
    const endPhaseIndex = phases.findIndex(
      phase => phase.id === destination.droppableId
    );

    if (endPhaseIndex === -1) {
      return null;
    }

    if (startPhase?.id === endPhase?.id) {
      // reordering within a phase
      const newPhases: IPhase[] = Array.from(phases);

      const phaseNewOrderedTasks: ITask[] = endPhase?.tasks ?? [];
      phaseNewOrderedTasks.splice(source.index, 1);
      phaseNewOrderedTasks.splice(destination.index, 0, movedItem);
      newPhases[endPhaseIndex] = Object.assign({}, endPhase, {
        tasks: phaseNewOrderedTasks,
      });
      return newPhases;
    } else {
      // moving between phases
      const newPhases = Array.from(phases);
      if (startPhaseIndex !== -1) {
        // There is a start phase (i.e. the task was moved from a real phase and not "uncategorised").
        // In this case we update the start phase so it no longer has the moved task.
        const startPhaseNewOrderedTasks = Array.from(startPhase?.tasks ?? []);
        startPhaseNewOrderedTasks.splice(source.index, 1);
        newPhases[startPhaseIndex] = Object.assign({}, startPhase, {
          tasks: startPhaseNewOrderedTasks,
        });
      }
      const endPhaseNewOrderedTasks = Array.from(endPhase?.tasks ?? []);
      endPhaseNewOrderedTasks.splice(destination.index, 0, movedItem);
      newPhases[endPhaseIndex] = Object.assign({}, endPhase, {
        tasks: endPhaseNewOrderedTasks,
      });
      return newPhases;
    }
  } else {
    return null;
  }
};

export function filterTasksByTags(tasks: ITask[], filterTags: ITag[]): ITask[] {
  if (filterTags.length === 0) {
    return tasks ?? [];
  } else {
    const filterTagIds = filterTags.map(tag => tag.id);
    const filteredTasks: ITask[] =
      tasks?.filter(
        task =>
          task.tagIds && _.intersection(task.tagIds, filterTagIds)?.length > 0
      ) ?? [];
    return filteredTasks;
  }
}

import { createUseStyles } from 'react-jss';

export const useAllPageStyles = createUseStyles({
  genericPage: {
    padding: '20px 20px 40px 20px',
    '& h1, h2, p, span': {
      textAlign: 'left',
    },
    '& h1': {
      fontWeight: 600,
    },
  },
});

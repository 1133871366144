import React, { useEffect, useState, useContext } from 'react';
import { DateTime } from 'luxon';
import { useGenericStyles } from '../../style/components/genericStyles';
import {
  DatetimeFormat,
  DatetimePicker,
  DatetimePickerType,
} from '../common/DatetimePicker/DatetimePicker';

interface Props {
  handleEditDateChange: (newDate?: DateTime) => void;
  initialDate: DateTime;
}

const ProductivityDateSelect: React.FunctionComponent<Props> = (
  props: Props
) => {
  const genericStyles = useGenericStyles();

  const [editDate, setEditDate] = useState<DateTime | undefined>(
    props.initialDate
  );
  const [focused, setFocused] = useState(false);

  useEffect(() => {
    if (props.initialDate !== editDate) {
      setEditDate(props.initialDate);
    }
  }, [props, editDate]);

  const handleEditDateChange = (date?: DateTime) => {
    const newDate = date ?? undefined;
    setEditDate(newDate);
    props.handleEditDateChange(newDate);
  };

  const handleEditDateFocusChange = (arg: { focused: boolean }) => {
    setFocused(arg.focused);
  };

  return (
    <div className={genericStyles.genericBox}>
      <h3>Date</h3>
      <DatetimePicker
        id="project_productivity_date_picker"
        type={DatetimePickerType.date}
        label="Choose date to view/edit:"
        datetime={editDate}
        onDatetimeChange={handleEditDateChange}
        handleFocusChange={handleEditDateFocusChange}
        isFocused={focused}
        datetimeFormat={DatetimeFormat.DATE}
        canClear={false}
        openDirection="up"
      />
    </div>
  );
};

export { ProductivityDateSelect };

import { createUseStyles } from 'react-jss';
import { chosenTheme } from '../styleHooks';

export const useTaskStyles = createUseStyles({
  task: {
    width: '100%',
    padding: 7,
    backgroundColor: chosenTheme.tasks.taskBackgroundColour,
    display: 'flex',
    flexWrap: 'wrap',
    WebkitBoxShadow: '6px 22px 39px -14px rgba(0, 0, 0, 0.15)',
    MozBoxShadow: '6px 22px 39px -14px rgba(0, 0, 0, 0.15)',
    boxShadow: '6px 22px 39px -14px rgba(0, 0, 0, 0.15)',
    marginBottom: 3,
    '&:hover': {
      backgroundColor: chosenTheme.tasks.taskHoverBackgroundColour,
    },
    '& p, div': {
      color: chosenTheme.genericComponentPrimaryFontColour,
    },
  },
  archivedTask: {
    backgroundColor: chosenTheme.tasks.archivedTaskBackgroundColour,
    background: `repeating-linear-gradient( -45deg, ${chosenTheme.tasks.archivedTaskHoverBackgroundColour}, ${chosenTheme.tasks.archivedTaskHoverBackgroundColour} 5px, ${chosenTheme.tasks.archivedTaskBackgroundColour} 5px, ${chosenTheme.tasks.archivedTaskBackgroundColour} 25px )`,
    '&:hover': {
      backgroundColor: chosenTheme.tasks.archivedTaskHoverBackgroundColour,
      opacity: 0.8,
    },
  },
  taskDragging: {
    backgroundColor: chosenTheme.tasks.taskDraggingBackgroundColour,
  },
  dueDateContainer: {
    fontSize: '9pt',
    width: '100%',
    textAlign: 'left',
    paddingLeft: '50px',
  },
  taskNameContainer: {
    width: '100%',
    flex: 'auto',
    display: 'flex',
    textAlign: 'left',
    paddingTop: '7px',
  },
  checkboxContainer: {
    flex: 1,
  },
  taskNameItself: {
    flex: 11,
    overflowX: 'auto',
    overflowWrap: 'break-word',
    wordBreak: 'break-word',
    wordWrap: 'break-word',
    paddingTop: 5,
    paddingRight: 20,
    paddingBottom: 10,
    fontSize: 16,
  },
  archivedTaskNameItself: {
    textDecoration: 'line-through',
  },
  taskSettingsContainer: {
    flex: 1,
  },
  taskProgressContainer: {
    width: '75%',
  },
  taskTagsContainer: {
    width: '100%',
    textAlign: 'left',
    paddingTop: 1,
    paddingBottom: 7,
    paddingLeft: 50,
  },
  taskProgressWrapper: {
    width: '100%',
  },
  taskProgressPopover: {
    width: 440,
  },
  taskProgressPopoverText: {
    width: '100%',
  },
  reminderInput: {
    width: '100%',
  },
  '@media only screen and (max-width: 930px)': {
    trackingPeriod: {
      paddingTop: 40,
    },
  },
  '@media only screen and (min-width: 320px) and (max-width: 480px)': {
    taskProgressPopover: {
      width: 200,
    },
  },
  overallTaskProgress: {
    padding: '20px 0 20px 0',
    width: 700,
  },
  estimatesContainer: {
    width: '100%',
    textAlign: 'left',
    paddingLeft: 47,
    paddingTop: 1,
    paddingBottom: 7,
  },
});

import {
  IApiGoal,
  IApiCreateGoalRequest,
  IGoal,
  IApiUpdateGoalRequest,
} from '../common-src/types/Goal';
import {
  IApiCreateLabourTypeRequest,
  IApiLabourType,
} from '../common-src/types/LabourType';
import {
  IApiLog,
  IApiCreateLogRequest,
  ILog,
  IApiUpdateLogRequest,
} from '../common-src/types/Log';
import {
  IApiPhase,
  IApiPhaseInRequest,
  IApiProject,
  IApiCreatePhaseRequest,
  IApiCreateProjectRequest,
  IApiGetPhasesResponse,
  IApiGetProjectsResponse,
  IPhase,
  IProject,
  IApiUpdatePhaseRequest,
  IApiUpdateProjectRequest,
  ISimplifiedProject,
  IApiUpdatePhasesRequest,
} from '../common-src/types/Project';
import {
  IApiButlerAssessment,
  IApiProjectSummary,
  IApiStatusOfAllGoals,
  IApiStatusOfAllHabits,
  IApiStatusOfAllProjects,
  IButlerAssessment,
  IProjectSummary,
  IStatusOfAllGoals,
  IStatusOfAllHabits,
  IStatusOfAllProjects,
} from '../common-src/types/Reporting';
import {
  IApiSession,
  IApiCreateSessionRequest,
  ISession,
  IApiUpdateSessionRequest,
} from '../common-src/types/Session';
import { IApiUpdateUserRequest } from '../common-src/types/User';
import {
  IApiCreateTagRequest,
  ITag,
  IApiUpdateTagRequest,
  IApiTag,
} from '../common-src/types/Tag';
import {
  IApiTask,
  IApiCreateTaskRequest,
  IApiGetTasksResponse,
  ITask,
  IApiUpdateTaskRequest,
} from '../common-src/types/Task';
import {
  IApiUnitOfLabour,
  IApiCreateUnitOfLabourRequest,
  IUnitOfLabour,
  IApiUpdateUnitOfLabourRequest,
  IUnitOfLabourWithType,
  IApiUnitOfLabourWithType,
  IUnitType,
  IApiUnitType,
  IApiCreateUnitTypeRequest,
  IApiUpdateUnitTypeRequest,
  IApiUpdateUnitTypesOrderRequest,
} from '../common-src/types/UnitOfLabour';
import { IApiUser, IUser } from '../common-src/types/User';
import { getConfig } from '../config';
import {
  api2domain__ButlerAssessment,
  api2domain__ProjectSummary,
  api2domain__StatusOfAllGoals,
  api2domain__StatusOfAllProjects,
  mapApiGoalToGoal,
  mapApiLabourTypeToLabourType,
  mapApiLogToLog,
  mapApiPhaseToPhase,
  mapApiProjectToProject,
  mapApiSessionToSession,
  mapApiSimplifiedProjectToSimplifiedProject,
  mapApiStatusOfAllHabitsToStatusOfAllHabits,
  mapApiTagToTag,
  mapApiTaskToTask,
  mapApiUnitOfLabourToUnitOfLabour,
  mapApiUnitOfLabourWithTypeToUnitOfLabourWithType,
  mapApiUnitTypeToUnitType,
  api2Domain__User,
} from './apiMapper';
import { ApiErrors } from '../error/errors';

export const apiGetCurrentUser = async (token: string): Promise<IUser> => {
  try {
    const config = getConfig();
    const res = await fetch(`${config.apiUrl}users`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    if (!res.ok) {
      throw new Error(ApiErrors.GET_CURRENT_USER_ERROR);
    }
    const apiUser = (await res.json()) as IApiUser;
    const user = api2Domain__User(apiUser);
    return user;
  } catch (err) {
    throw err;
  }
};

export const apiCreateUser = async (
  firstName: string,
  lastName: string,
  email: string,
  token: string
): Promise<IUser> => {
  try {
    const config = getConfig();
    const createUserRequest = {
      firstName,
      lastName,
      email,
    };
    const res = await fetch(`${config.apiUrl}users`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify(createUserRequest),
    });
    if (!res.ok) {
      throw new Error(ApiErrors.CREATE_USER_ERROR);
    }
    const apiCreatedUser = (await res.json()) as IApiUser;
    const createdUser = api2Domain__User(apiCreatedUser);
    return createdUser;
  } catch (err) {
    throw err;
  }
};

export const apiSubscribeToNotifications = async (
  token: string,
  subscription: PushSubscription
) => {
  try {
    const config = getConfig();
    const res = await fetch(`${config.apiUrl}users/subscribe`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify(subscription),
    });
    if (!res.ok) {
      throw new Error(ApiErrors.SUBSCRIBE_TO_NOTIFICATIONS_ERROR);
    }
  } catch (err) {
    throw err;
  }
};

export const apiGetProjects = async (
  token: string
): Promise<ISimplifiedProject[]> => {
  try {
    const config = getConfig();
    const res = await fetch(`${config.apiUrl}projects/simplified`, {
      headers: { Authorization: `Bearer ${token}` },
    });
    if (!res.ok) {
      throw new Error(ApiErrors.GET_PROJECTS_ERROR);
    }
    const getProjectsResponse = (await res.json()) as IApiGetProjectsResponse;
    const simplifiedProjects = getProjectsResponse.projects.map(
      mapApiSimplifiedProjectToSimplifiedProject
    );
    return simplifiedProjects;
  } catch (err) {
    throw err;
  }
};

// TODO: verify this!!!
export const apiUpdateUserProjects = async (
  token: string,
  projectIds: string[]
) => {
  try {
    const config = getConfig();
    const res = await fetch(`${config.apiUrl}projects`, {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify({ projectIds }),
    });
    if (!res.ok) {
      throw new Error(ApiErrors.UPDATE_PROJECTS_ERROR);
    }
    const user = await res.json();
    // TODO: return updated projects
  } catch (err) {
    throw err;
  }
};

export const apiGetProjectById = async (
  projectId: string,
  token: string
): Promise<IProject> => {
  try {
    const config = getConfig();
    const res = await fetch(`${config.apiUrl}projects/${projectId}`, {
      headers: { Authorization: `Bearer ${token}` },
    });
    if (!res.ok) {
      throw new Error(ApiErrors.GET_PROJECT_ERROR);
    }
    const apiProject = (await res.json()) as IApiProject;
    const project = mapApiProjectToProject(apiProject);
    return project;
  } catch (err) {
    throw err;
  }
};

export const apiCreateProject = async (
  createProjectRequest: IApiCreateProjectRequest,
  token: string
): Promise<IProject> => {
  try {
    const config = getConfig();
    const res = await fetch(`${config.apiUrl}projects`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify(createProjectRequest),
    });
    if (!res.ok) {
      throw new Error(ApiErrors.CREATE_PROJECT_ERROR);
    }
    const apiCreatedProject = (await res.json()) as IApiProject;
    const createdProject = mapApiProjectToProject(apiCreatedProject);
    return createdProject;
  } catch (err) {
    throw err;
  }
};

export const apiUpdateProject = async (
  projectId: string,
  updateProjectRequest: IApiUpdateProjectRequest,
  token: string
): Promise<IProject> => {
  try {
    const config = getConfig();
    const res = await fetch(`${config.apiUrl}projects/${projectId}`, {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify(updateProjectRequest),
    });
    if (!res.ok) {
      throw new Error(ApiErrors.UPDATE_PROJECT_ERROR);
    }
    const apiUpdatedProject = (await res.json()) as IApiProject;
    const updatedProject = mapApiProjectToProject(apiUpdatedProject);
    return updatedProject;
  } catch (err) {
    throw err;
  }
};

export const apiDeleteProject = async (projectId: string, token: string) => {
  try {
    const config = getConfig();
    const res = await fetch(`${config.apiUrl}projects/${projectId}`, {
      method: 'DELETE',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
    });
    if (!res.ok) {
      throw new Error(ApiErrors.DELETE_PROJECT_ERROR);
    }
  } catch (err) {
    throw err;
  }
};

export const apiGetTasksInProject = async (
  projectId: string,
  token: string
): Promise<ITask[]> => {
  try {
    const config = getConfig();
    const res = await fetch(`${config.apiUrl}projects/${projectId}/tasks`, {
      headers: { Authorization: `Bearer ${token}` },
    });
    if (!res.ok) {
      throw new Error(ApiErrors.GET_TASKS_IN_PROJECT_ERROR);
    }
    const getTasksResponse = (await res.json()) as IApiGetTasksResponse;
    const tasks = getTasksResponse.tasks.map(mapApiTaskToTask);
    return tasks as ITask[];
  } catch (err) {
    throw err;
  }
};

export const apiGetTasks = async (token: string): Promise<ITask[]> => {
  try {
    const config = getConfig();
    const res = await fetch(`${config.apiUrl}tasks`, {
      headers: { Authorization: `Bearer ${token}` },
    });
    if (!res.ok) {
      throw new Error(ApiErrors.GET_TASKS_ERROR);
    }
    const getTasksResponse = (await res.json()) as IApiGetTasksResponse;
    const tasks = getTasksResponse.tasks.map(mapApiTaskToTask);
    return tasks as ITask[];
  } catch (err) {
    throw err;
  }
};

export const apiCreateTask = async (
  createTaskRequest: IApiCreateTaskRequest,
  token: string
): Promise<ITask> => {
  try {
    const config = getConfig();
    const res = await fetch(`${config.apiUrl}tasks`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify(createTaskRequest),
    });
    if (!res.ok) {
      throw new Error(ApiErrors.CREATE_TASK_ERROR);
    }
    const apiCreatedTask = (await res.json()) as IApiTask;
    const createdTask = mapApiTaskToTask(apiCreatedTask);
    return createdTask;
  } catch (err) {
    throw err;
  }
};

export const apiUpdateTask = async (
  taskId: string,
  updateTaskRequest: IApiUpdateTaskRequest,
  token: string
): Promise<ITask> => {
  try {
    const config = getConfig();
    const res = await fetch(`${config.apiUrl}tasks/${taskId}`, {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify(updateTaskRequest),
    });
    if (!res.ok) {
      throw new Error(ApiErrors.UPDATE_TASK_ERROR);
    }
    const apiUpdatedTask = (await res.json()) as IApiTask;
    const updatedTask = mapApiTaskToTask(apiUpdatedTask);
    return updatedTask;
  } catch (err) {
    throw err;
  }
};

export const apiDeleteTask = async (taskId: string, token: string) => {
  try {
    const config = getConfig();
    const res = await fetch(`${config.apiUrl}tasks/${taskId}`, {
      method: 'DELETE',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
    });
    if (!res.ok) {
      throw new Error(ApiErrors.DELETE_TASK_ERROR);
    }
  } catch (err) {
    throw err;
  }
};

export const apiGetTodoList = async (token: string): Promise<ITask[]> => {
  try {
    const config = getConfig();
    const res = await fetch(`${config.apiUrl}users/todolist`, {
      headers: { Authorization: `Bearer ${token}` },
    });
    if (!res.ok) {
      throw new Error(ApiErrors.GET_TODO_LIST_ERROR);
    }
    const getTasksResponse = (await res.json()) as IApiGetTasksResponse;
    const tasks = getTasksResponse.tasks.map(mapApiTaskToTask);
    return tasks as ITask[];
  } catch (err) {
    throw err;
  }
};

export const apiUpdateTodoList = async (
  token: string,
  todoListTaskIds: string[]
): Promise<ITask[]> => {
  try {
    const config = getConfig();
    const res = await fetch(`${config.apiUrl}users/todolist`, {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify({ taskIds: todoListTaskIds }),
    });
    if (!res.ok) {
      throw new Error(ApiErrors.UPDATE_TODO_LIST_ERROR);
    }
    const getTasksResponse = (await res.json()) as IApiGetTasksResponse;
    const tasks = getTasksResponse.tasks.map(mapApiTaskToTask);
    return tasks as ITask[];
  } catch (err) {
    throw err;
  }
};

export const apiGetGoals = async (token: string): Promise<IGoal[]> => {
  try {
    const config = getConfig();
    const res = await fetch(`${config.apiUrl}goals`, {
      headers: { Authorization: `Bearer ${token}` },
    });
    if (!res.ok) {
      throw new Error(ApiErrors.GET_GOALS_ERROR);
    }
    const getGoalsResponse = (await res.json()) as { goals: IApiGoal[] };
    const goals = getGoalsResponse.goals.map(mapApiGoalToGoal);
    return goals;
  } catch (err) {
    throw err;
  }
};

export const apiGetOverallHabitStatus = async (
  token: string,
  startDate: string,
  endDate: string
): Promise<IStatusOfAllHabits> => {
  try {
    const config = getConfig();
    const res = await fetch(
      `${config.apiUrl}goals/habit_performance?start_date=${startDate}&end_date=${endDate}`,
      {
        headers: { Authorization: `Bearer ${token}` },
      }
    );
    if (!res.ok) {
      throw new Error(ApiErrors.GET_OVERALL_HABIT_STATUS_ERROR);
    }
    const apiOverallHabitStatus = (await res.json()) as {
      habit_status: IApiStatusOfAllHabits;
    };
    const overallHabitStatus = mapApiStatusOfAllHabitsToStatusOfAllHabits(
      apiOverallHabitStatus.habit_status
    );
    return overallHabitStatus;
  } catch (err) {
    throw err;
  }
};

export const apiCreateGoal = async (
  createGoalRequest: IApiCreateGoalRequest,
  token: string
): Promise<IGoal> => {
  try {
    const config = getConfig();
    const res = await fetch(`${config.apiUrl}goals`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify(createGoalRequest),
    });
    if (!res.ok) {
      throw new Error(ApiErrors.CREATE_GOAL_ERROR);
    }
    const apiCreatedGoal = (await res.json()) as IApiGoal;
    const createdGoal = mapApiGoalToGoal(apiCreatedGoal);
    return createdGoal;
  } catch (err) {
    throw err;
  }
};

export const apiUpdateGoal = async (
  goalId: string,
  updateGoalRequest: IApiUpdateGoalRequest,
  token: string
): Promise<IGoal> => {
  try {
    const config = getConfig();
    const res = await fetch(`${config.apiUrl}goals/${goalId}`, {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify(updateGoalRequest),
    });
    if (!res.ok) {
      throw new Error(ApiErrors.UPDATE_GOAL_ERROR);
    }
    const apiUpdatedGoal = (await res.json()) as IApiGoal;
    const updatedGoal = mapApiGoalToGoal(apiUpdatedGoal);
    return updatedGoal;
  } catch (err) {
    throw err;
  }
};

export const apiDeleteGoal = async (goalId: string, token: string) => {
  try {
    const config = getConfig();
    const res = await fetch(`${config.apiUrl}goals/${goalId}`, {
      method: 'DELETE',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
    });
    if (!res.ok) {
      throw new Error(ApiErrors.DELETE_GOAL_ERROR);
    }
  } catch (err) {
    throw err;
  }
};

export const apiGetPhases = async (token: string): Promise<IPhase[]> => {
  try {
    const config = getConfig();
    const res = await fetch(`${config.apiUrl}phases`, {
      headers: { Authorization: `Bearer ${token}` },
    });
    if (!res.ok) {
      throw new Error(ApiErrors.GET_PHASES_ERROR);
    }
    const phasesResponse = (await res.json()) as IApiGetPhasesResponse;
    const phases = phasesResponse.phases.map(mapApiPhaseToPhase);
    return phases;
  } catch (err) {
    throw err;
  }
};

export const apiCreatePhase = async (
  createPhaseRequest: IApiCreatePhaseRequest,
  token: string
): Promise<IPhase> => {
  try {
    const config = getConfig();
    const res = await fetch(`${config.apiUrl}phases`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify(createPhaseRequest),
    });
    if (!res.ok) {
      throw new Error(ApiErrors.CREATE_PHASE_ERROR);
    }
    const apiCreatedPhase = (await res.json()) as IApiPhase;
    const createdPhase = mapApiPhaseToPhase(apiCreatedPhase);
    return createdPhase;
  } catch (err) {
    throw err;
  }
};

export const apiUpdatePhase = async (
  phaseId: string,
  updatePhaseRequest: IApiUpdatePhaseRequest,
  token: string
): Promise<IPhase> => {
  try {
    const config = getConfig();
    const res = await fetch(`${config.apiUrl}phases/${phaseId}`, {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify(updatePhaseRequest),
    });
    if (!res.ok) {
      throw new Error(ApiErrors.UPDATE_PHASE_ERROR);
    }
    const apiUpdatedPhase = (await res.json()) as IApiPhase;
    const updatedPhase = mapApiPhaseToPhase(apiUpdatedPhase);
    return updatedPhase;
  } catch (err) {
    throw err;
  }
};

export const apiUpdateProjectPhases = async (
  token: string,
  projectId: string,
  updatePhasesRequest: IApiUpdatePhasesRequest
): Promise<IPhase[]> => {
  try {
    const config = getConfig();
    const res = await fetch(`${config.apiUrl}projects/${projectId}/phases`, {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify(updatePhasesRequest),
    });
    if (!res.ok) {
      throw new Error(ApiErrors.UPDATE_PROJECT_PHASES_ERROR);
    }
    const apiUpdatedPhases = (await res.json()) as { phases: IApiPhase[] };
    const updatedPhases = apiUpdatedPhases.phases.map(mapApiPhaseToPhase);
    return updatedPhases;
  } catch (err) {
    throw err;
  }
};

export const apiUpdateUserPhases = async (
  token: string,
  phases: IApiUpdatePhaseRequest[]
): Promise<IPhase[]> => {
  try {
    const config = getConfig();
    const res = await fetch(`${config.apiUrl}phases`, {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify({ phases }),
    });
    if (!res.ok) {
      throw new Error(ApiErrors.UPDATE_USER_PHASES_ERROR);
    }
    const apiUpdatedPhases = (await res.json()) as { phases: IApiPhase[] };
    const updatedPhases = apiUpdatedPhases.phases.map(mapApiPhaseToPhase);
    return updatedPhases;
  } catch (err) {
    throw err;
  }
};

export const apiDeletePhase = async (phaseId: string, token: string) => {
  try {
    const config = getConfig();
    const res = await fetch(`${config.apiUrl}phases/${phaseId}`, {
      method: 'DELETE',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
    });
    if (!res.ok) {
      throw new Error(ApiErrors.DELETE_PHASE_ERROR);
    }
  } catch (err) {
    throw err;
  }
};

export const apiUpdateTaskOrderInProject = async (
  token: string,
  projectId: string,
  orderedTaskIds: string[]
) => {
  try {
    const config = getConfig();
    const res = await fetch(`${config.apiUrl}projects/${projectId}/tasks`, {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify({ orderedTaskIds }),
    });
    if (!res.ok) {
      throw new Error(ApiErrors.UPDATE_TASK_ORDER_IN_PROJECT_ERROR);
    }
    // TODO: return updated tasks
  } catch (err) {
    throw err;
  }
};

export const apiGetLogs = async (token: string): Promise<ILog[]> => {
  try {
    const config = getConfig();
    const res = await fetch(`${config.apiUrl}logs`, {
      headers: { Authorization: `Bearer ${token}` },
    });
    if (!res.ok) {
      throw new Error(ApiErrors.GET_LOGS_ERROR);
    }
    const getLogsResponse = (await res.json()) as {
      logs: IApiLog[];
    };
    const apiLogs = getLogsResponse.logs;
    const logs = apiLogs.map(mapApiLogToLog);
    return logs;
  } catch (err) {
    throw err;
  }
};

export const apiCreateLog = async (
  token: string,
  createLogRequest: IApiCreateLogRequest
): Promise<ILog> => {
  try {
    const config = getConfig();
    const res = await fetch(`${config.apiUrl}logs`, {
      method: 'POST',
      headers: {
        Authorization: `Bearer ${token}`,
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(createLogRequest),
    });
    if (!res.ok) {
      throw new Error(ApiErrors.CREATE_LOG_ERROR);
    }
    const apiCreatedLog = (await res.json()) as IApiLog;
    const createdLog = mapApiLogToLog(apiCreatedLog);
    return createdLog;
  } catch (err) {
    throw err;
  }
};

export const apiUpdateLog = async (
  token: string,
  logId: string,
  updateLogRequest: IApiUpdateLogRequest
): Promise<ILog> => {
  try {
    const config = getConfig();
    const res = await fetch(`${config.apiUrl}logs/${logId}`, {
      method: 'PUT',
      headers: {
        Authorization: `Bearer ${token}`,
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(updateLogRequest),
    });
    if (!res.ok) {
      throw new Error(ApiErrors.UPDATE_LOG_ERROR);
    }
    const apiUpdatedLog = (await res.json()) as IApiLog;
    const updatedLog = mapApiLogToLog(apiUpdatedLog);
    return updatedLog;
  } catch (err) {
    throw err;
  }
};

export const apiDeleteLog = async (token: string, logId: string) => {
  try {
    const config = getConfig();
    const res = await fetch(`${config.apiUrl}logs/${logId}`, {
      method: 'DELETE',
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    if (!res.ok) {
      throw new Error(ApiErrors.DELETE_LOG_ERROR);
    }
  } catch (err) {
    throw err;
  }
};

export const apiGetTags = async (token: string): Promise<ITag[]> => {
  try {
    const config = getConfig();
    const res = await fetch(`${config.apiUrl}tags`, {
      headers: { Authorization: `Bearer ${token}` },
    });
    if (!res.ok) {
      throw new Error(ApiErrors.GET_TAGS_ERROR);
    }
    const getTagsResponse = (await res.json()) as {
      tags: IApiTag[];
    };
    const apiTags = getTagsResponse.tags;
    const tags = apiTags.map(mapApiTagToTag);
    return tags;
  } catch (err) {
    throw err;
  }
};

export const apiCreateTag = async (
  token: string,
  createTagRequest: IApiCreateTagRequest
): Promise<ITag> => {
  try {
    const config = getConfig();
    const res = await fetch(`${config.apiUrl}tags`, {
      method: 'POST',
      headers: {
        Authorization: `Bearer ${token}`,
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(createTagRequest),
    });
    if (!res.ok) {
      throw new Error(ApiErrors.CREATE_TAG_ERROR);
    }
    const apiCreatedTag = (await res.json()) as IApiTag;
    const createdTag = mapApiTagToTag(apiCreatedTag);
    return createdTag;
  } catch (err) {
    throw err;
  }
};

export const apiUpdateTag = async (
  token: string,
  tagId: string,
  updateTagRequest: IApiUpdateTagRequest
): Promise<ITag> => {
  try {
    const config = getConfig();
    const res = await fetch(`${config.apiUrl}tags/${tagId}`, {
      method: 'PUT',
      headers: {
        Authorization: `Bearer ${token}`,
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(updateTagRequest),
    });
    if (!res.ok) {
      throw new Error(ApiErrors.UPDATE_TAG_ERROR);
    }
    const apiUpdatedTag = (await res.json()) as IApiTag;
    const updatedTag = mapApiTagToTag(apiUpdatedTag);
    return updatedTag;
  } catch (err) {
    throw err;
  }
};

export const apiDeleteTag = async (token: string, tagId: string) => {
  try {
    const config = getConfig();
    const res = await fetch(`${config.apiUrl}tags/${tagId}`, {
      method: 'DELETE',
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    if (!res.ok) {
      throw new Error(ApiErrors.DELETE_TAG_ERROR);
    }
  } catch (err) {
    throw err;
  }
};

export const apiGetLogsInProject = async (
  projectId: string,
  token: string
): Promise<ILog[]> => {
  try {
    const config = getConfig();
    const res = await fetch(`${config.apiUrl}projects/${projectId}/logs`, {
      headers: { Authorization: `Bearer ${token}` },
    });
    if (!res.ok) {
      throw new Error(ApiErrors.GET_LOGS_IN_PROJECT_ERROR);
    }
    const getLogsResponse = (await res.json()) as { logs: IApiLog[] };
    const apiLogs = getLogsResponse.logs;
    const logs = apiLogs.map(mapApiLogToLog);
    return logs;
  } catch (err) {
    throw err;
  }
};

export const apiGetSessions = async (token: string): Promise<ISession[]> => {
  try {
    const config = getConfig();
    const res = await fetch(`${config.apiUrl}sessions`, {
      headers: { Authorization: `Bearer ${token}` },
    });
    if (!res.ok) {
      throw new Error(ApiErrors.GET_SESSIONS_ERROR);
    }
    const getSessionsResponse = (await res.json()) as {
      sessions: IApiSession[];
    };
    const apiSessions = getSessionsResponse.sessions;
    const sessions = apiSessions.map(mapApiSessionToSession);
    return sessions;
  } catch (err) {
    throw err;
  }
};

export const apiGetSessionsInProject = async (
  projectId: string,
  token: string
): Promise<ISession[]> => {
  try {
    const config = getConfig();
    const res = await fetch(`${config.apiUrl}projects/${projectId}/sessions`, {
      headers: { Authorization: `Bearer ${token}` },
    });
    if (!res.ok) {
      throw new Error(ApiErrors.GET_SESSIONS_IN_PROJECT_ERROR);
    }
    const getSessionsResponse = (await res.json()) as {
      sessions: IApiSession[];
    };
    const apiSessions = getSessionsResponse.sessions;
    const sessions = apiSessions.map(mapApiSessionToSession);
    return sessions;
  } catch (err) {
    throw err;
  }
};

export const apiCreateSession = async (
  token: string,
  createSessionRequest: IApiCreateSessionRequest
): Promise<ISession> => {
  try {
    const config = getConfig();
    const res = await fetch(`${config.apiUrl}sessions`, {
      method: 'POST',
      headers: {
        Authorization: `Bearer ${token}`,
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(createSessionRequest),
    });
    if (!res.ok) {
      throw new Error(ApiErrors.CREATE_SESSION_ERROR);
    }
    const apiSession = (await res.json()) as IApiSession;
    const session = mapApiSessionToSession(apiSession);
    return session;
  } catch (err) {
    throw err;
  }
};

export const apiUpdateSession = async (
  token: string,
  sessionId: string,
  updateSessionRequest: IApiUpdateSessionRequest
): Promise<ISession> => {
  try {
    const config = getConfig();
    const res = await fetch(`${config.apiUrl}sessions/${sessionId}`, {
      method: 'PUT',
      headers: {
        Authorization: `Bearer ${token}`,
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(updateSessionRequest),
    });
    if (!res.ok) {
      throw new Error(ApiErrors.UPDATE_SESSION_ERROR);
    }
    const apiSession = (await res.json()) as IApiSession;
    const session = mapApiSessionToSession(apiSession);
    return session;
  } catch (err) {
    throw err;
  }
};

export const apiDeleteSession = async (token: string, sessionId: string) => {
  try {
    const config = getConfig();
    const res = await fetch(`${config.apiUrl}sessions/${sessionId}`, {
      method: 'DELETE',
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    if (!res.ok) {
      throw new Error(ApiErrors.DELETE_SESSION_ERROR);
    }
  } catch (err) {
    throw err;
  }
};

export const apiGetUnitTypes = async (token: string): Promise<IUnitType[]> => {
  try {
    const config = getConfig();
    const res = await fetch(`${config.apiUrl}units/unitType`, {
      headers: { Authorization: `Bearer ${token}` },
    });
    if (!res.ok) {
      throw new Error(ApiErrors.GET_UNIT_TYPES_ERROR);
    }
    const getUnitTypesResponse = (await res.json()) as {
      unitTypes: IApiUnitType[];
    };
    const apiUnitTypes = getUnitTypesResponse.unitTypes;
    const unitTypes = apiUnitTypes.map(mapApiUnitTypeToUnitType);
    return unitTypes;
  } catch (err) {
    throw err;
  }
};

export const apiCreateUnitType = async (
  token: string,
  createUnitTypeRequest: IApiCreateUnitTypeRequest
): Promise<IUnitType> => {
  try {
    const config = getConfig();
    const res = await fetch(`${config.apiUrl}units/unitType`, {
      method: 'POST',
      headers: {
        Authorization: `Bearer ${token}`,
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(createUnitTypeRequest),
    });
    if (!res.ok) {
      throw new Error(ApiErrors.CREATE_UNIT_TYPE_ERROR);
    }
    const apiUnitType = (await res.json()) as IApiUnitType;
    const unitType = mapApiUnitTypeToUnitType(apiUnitType);
    return unitType;
  } catch (err) {
    throw err;
  }
};

export const apiUpdateUnitType = async (
  token: string,
  unitTypeId: string,
  updateUnitTypeRequest: IApiUpdateUnitTypeRequest
): Promise<IUnitType> => {
  try {
    const config = getConfig();
    const res = await fetch(`${config.apiUrl}units/unitType/${unitTypeId}`, {
      method: 'PUT',
      headers: {
        Authorization: `Bearer ${token}`,
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(updateUnitTypeRequest),
    });
    if (!res.ok) {
      throw new Error(ApiErrors.UPDATE_UNIT_TYPE_ERROR);
    }
    const apiUnitType = (await res.json()) as IApiUnitType;
    const unitType = mapApiUnitTypeToUnitType(apiUnitType);
    return unitType;
  } catch (err) {
    throw err;
  }
};

export const apiUpdateUnitTypesOrder = async (
  token: string,
  updateUnitTypesOrderRequest: IApiUpdateUnitTypesOrderRequest
): Promise<IUnitType[]> => {
  try {
    const config = getConfig();
    const res = await fetch(`${config.apiUrl}units/unitType`, {
      method: 'PUT',
      headers: {
        Authorization: `Bearer ${token}`,
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(updateUnitTypesOrderRequest),
    });
    if (!res.ok) {
      throw new Error(ApiErrors.UPDATE_UNIT_TYPES_ORDER_ERROR);
    }
    const apiUnitTypes = (await res.json()) as {
      updatedUnitTypes: IApiUnitType[];
    };
    const unitTypes = apiUnitTypes.updatedUnitTypes.map(
      mapApiUnitTypeToUnitType
    );
    return unitTypes;
  } catch (err) {
    throw err;
  }
};

export const apiDeleteUnitType = async (token: string, unitTypeId: string) => {
  try {
    const config = getConfig();
    const res = await fetch(`${config.apiUrl}units/unitType/${unitTypeId}`, {
      method: 'DELETE',
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    if (!res.ok) {
      throw new Error(ApiErrors.DELETE_UNIT_TYPE_ERROR);
    }
  } catch (err) {
    throw err;
  }
};

export const apiGetUnits = async (
  token: string
): Promise<IUnitOfLabourWithType[]> => {
  try {
    const config = getConfig();
    const res = await fetch(`${config.apiUrl}units`, {
      headers: { Authorization: `Bearer ${token}` },
    });
    if (!res.ok) {
      throw new Error(ApiErrors.GET_UNITS_ERROR);
    }
    const getUnitsResponse = (await res.json()) as {
      units: IApiUnitOfLabourWithType[];
    };
    const apiUnits = getUnitsResponse.units;
    const units = apiUnits.map(
      mapApiUnitOfLabourWithTypeToUnitOfLabourWithType
    );
    return units;
  } catch (err) {
    throw err;
  }
};

export const apiGetUnitsInProject = async (
  token: string,
  projectId: string
): Promise<IUnitOfLabour[]> => {
  try {
    const config = getConfig();
    const res = await fetch(`${config.apiUrl}projects/${projectId}/units`, {
      headers: { Authorization: `Bearer ${token}` },
    });
    if (!res.ok) {
      throw new Error(ApiErrors.GET_UNITS_IN_PROJECT_ERROR);
    }
    const getUnitsResponse = (await res.json()) as {
      units: IApiUnitOfLabour[];
    };
    const apiUnits = getUnitsResponse.units;
    const units = apiUnits.map(mapApiUnitOfLabourToUnitOfLabour);
    return units;
  } catch (err) {
    throw err;
  }
};

export const apiCreateUnitOfLabour = async (
  token: string,
  createUnitOfLabourRequest: IApiCreateUnitOfLabourRequest
): Promise<IUnitOfLabour> => {
  try {
    const config = getConfig();
    const res = await fetch(`${config.apiUrl}units/labour`, {
      method: 'POST',
      headers: {
        Authorization: `Bearer ${token}`,
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(createUnitOfLabourRequest),
    });
    if (!res.ok) {
      throw new Error(ApiErrors.CREATE_UNIT_ERROR);
    }
    const apiCreatedUnit = (await res.json()) as IApiUnitOfLabour;
    const createdUnit = mapApiUnitOfLabourToUnitOfLabour(apiCreatedUnit);
    return createdUnit;
  } catch (err) {
    throw err;
  }
};

export const apiUpdateUnitOfLabour = async (
  token: string,
  unitId: string,
  updateUnitRequest: IApiUpdateUnitOfLabourRequest
): Promise<IUnitOfLabour> => {
  try {
    const config = getConfig();
    const res = await fetch(`${config.apiUrl}units/labour/${unitId}`, {
      method: 'PUT',
      headers: {
        Authorization: `Bearer ${token}`,
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(updateUnitRequest),
    });
    if (!res.ok) {
      throw new Error(ApiErrors.UPDATE_UNIT_ERROR);
    }
    const apiUpdatedUnit = (await res.json()) as IApiUnitOfLabour;
    const updatedUnit = mapApiUnitOfLabourToUnitOfLabour(apiUpdatedUnit);
    return updatedUnit;
  } catch (err) {
    throw err;
  }
};

export const apiUpdateUser = async (
  token: string,
  updateUserRequest: IApiUpdateUserRequest
): Promise<IUser> => {
  try {
    const config = getConfig();
    const res = await fetch(`${config.apiUrl}users`, {
      method: 'PUT',
      headers: {
        Authorization: `Bearer ${token}`,
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(updateUserRequest),
    });
    if (!res.ok) {
      throw new Error(ApiErrors.UPDATE_USER_ERROR);
    }
    const apiUpdatedUser = (await res.json()) as IApiUser;
    const updatedUser = api2Domain__User(apiUpdatedUser);
    return updatedUser;
  } catch (err) {
    throw err;
  }
};

export const apiGetButlerAssessment = async (
  token: string
): Promise<IButlerAssessment> => {
  try {
    const config = getConfig();
    const res = await fetch(`${config.apiUrl}productivity`, {
      headers: { Authorization: `Bearer ${token}` },
    });
    if (!res.ok) {
      throw new Error(ApiErrors.GET_BUTLER_ASSESSMENT_ERROR);
    }
    const apiResponse = (await res.json()) as {
      butler_assessment: IApiButlerAssessment;
    };
    const butlerAssessment = api2domain__ButlerAssessment(
      apiResponse.butler_assessment
    );
    return butlerAssessment;
  } catch (err) {
    throw err;
  }
};

// TODO: remove the below? It is not currently being used anywhere.
export const apiGetProductivityForAllProjects = async (
  token: string
): Promise<IStatusOfAllProjects> => {
  try {
    const config = getConfig();
    const res = await fetch(`${config.apiUrl}productivity/projects`, {
      headers: { Authorization: `Bearer ${token}` },
    });
    if (!res.ok) {
      throw new Error(ApiErrors.GET_RAW_PRODUCTIVITY_ERROR);
    }
    const apiRawProductivity = (await res.json()) as IApiStatusOfAllProjects;
    const rawProductivity = api2domain__StatusOfAllProjects(apiRawProductivity);
    return rawProductivity;
  } catch (err) {
    throw err;
  }
};

export const apiGetProductivityForProject = async (
  token: string,
  projectId: string,
  startDate: string,
  endDate: string
): Promise<IProjectSummary> => {
  try {
    const config = getConfig();
    const res = await fetch(
      `${config.apiUrl}productivity/projects/${projectId}?start_date=${startDate}&end_date=${endDate}`,
      {
        headers: { Authorization: `Bearer ${token}` },
      }
    );
    if (!res.ok) {
      throw new Error(ApiErrors.GET_RAW_PRODUCTIVITY_FOR_PROJECT_GOALS_ERROR);
    }
    const apiProjectSummary = (await res.json()) as IApiProjectSummary;
    const projectSummary = api2domain__ProjectSummary(apiProjectSummary);
    return projectSummary;
  } catch (err) {
    throw err;
  }
};

export const apiGetRawProductivityForGoals = async (
  token: string,
  startDate: string,
  endDate: string
): Promise<IStatusOfAllGoals> => {
  try {
    const config = getConfig();
    const res = await fetch(
      `${config.apiUrl}productivity/goals?start_date=${startDate}&end_date=${endDate}`,
      {
        headers: { Authorization: `Bearer ${token}` },
      }
    );
    if (!res.ok) {
      throw new Error(ApiErrors.GET_RAW_PRODUCTIVITY_FOR_GOALS_ERROR);
    }
    const apiStatusOfAllGoals = (await res.json()) as IApiStatusOfAllGoals;
    const statusOfAllGoals = api2domain__StatusOfAllGoals(apiStatusOfAllGoals);
    return statusOfAllGoals;
  } catch (err) {
    throw err;
  }
};

export const apiGetLabourTypes = async (token: string) => {
  try {
    const config = getConfig();
    const res = await fetch(`${config.apiUrl}labour_types`, {
      headers: { Authorization: `Bearer ${token}` },
    });
    if (!res.ok) {
      throw new Error(ApiErrors.GET_LABOUR_TYPES_ERROR);
    }
    const getLabourTypesResponse = (await res.json()) as {
      labourTypes: IApiLabourType[];
    };
    const apiLabourTypes = getLabourTypesResponse.labourTypes;
    const labourTypes = apiLabourTypes.map(mapApiLabourTypeToLabourType);
    return labourTypes;
  } catch (err) {
    throw err;
  }
};

export const apiAddLabourType = async (
  token: string,
  addLabourTypeRequest: IApiCreateLabourTypeRequest
) => {
  try {
    const config = getConfig();
    const res = await fetch(`${config.apiUrl}labour_types`, {
      method: 'POST',
      headers: {
        Authorization: `Bearer ${token}`,
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(addLabourTypeRequest),
    });
    if (!res.ok) {
      throw new Error(ApiErrors.CREATE_LABOUR_TYPE_ERROR);
    }
    const apiLabourType = (await res.json()) as { labourType: IApiLabourType };
    const labourType = mapApiLabourTypeToLabourType(apiLabourType.labourType);
    return labourType;
  } catch (err) {
    throw err;
  }
};

import React, { useContext, useState } from 'react';
import Joyride, { ACTIONS, EVENTS, STATUS } from 'react-joyride';
import { OnboardingContext } from '../../../state/OnboardingContext';
import { UserContext } from '../../../state/UserContext';

const TasksPageOnboarding: React.FunctionComponent = () => {
  const onboardingContext = useContext(OnboardingContext);
  const userContext = useContext(UserContext);

  const [stepIndex, setStepIndex] = useState(0);

  const handleJoyrideCallback = data => {
    const { action, index, status, type } = data;

    if ([EVENTS.STEP_AFTER, EVENTS.TARGET_NOT_FOUND].includes(type)) {
      setStepIndex(index + (action === ACTIONS.PREV ? -1 : 1));
    } else if ([STATUS.FINISHED, STATUS.SKIPPED].includes(status)) {
      updateIsTasksPageOnboardingRunning(false);
      userContext?.updateUser({ viewed_tasks_page_onboarding: true });
    } else {
    }
  };

  if (!onboardingContext || !userContext) {
    return null;
  }

  const {
    tasksPageOnboardingSteps,
    isTasksPageOnboardingRunning,
    updateIsTasksPageOnboardingRunning,
  } = onboardingContext;

  return (
    <Joyride
      steps={tasksPageOnboardingSteps}
      continuous={true}
      showSkipButton={true}
      hideCloseButton={true}
      run={isTasksPageOnboardingRunning}
      stepIndex={stepIndex}
      callback={handleJoyrideCallback}
      locale={{
        back: 'Back',
        close: 'Close',
        last: 'Get started',
        next: 'Next',
        open: 'Open the dialog',
        skip: 'Skip',
      }}
      styles={{
        options: {
          width: 550,
          zIndex: 1000,
        },
      }}
    />
  );
};

export { TasksPageOnboarding };

import React from 'react';
import { Button, Intent, FormGroup } from '@blueprintjs/core';
import { useLandingStyles } from '../../style/pages/landingPageStyles';
import { useAuth0 } from '@auth0/auth0-react';
import { LandingSection } from './LandingSection';

const Landing: React.FunctionComponent = () => {
  const landingStyles = useLandingStyles();
  const { loginWithRedirect } = useAuth0();

  return (
    <div className={landingStyles.landingBody}>
      <div className={landingStyles.logoHeader}>
        <img src="favicon.png" className="App-logo" alt="logo" />
        <h2>Chekhov</h2>
      </div>
      <div className={landingStyles.landingSection}>
        <h1 className={landingStyles.landingHeader}>Welcome to Chekhov.</h1>
        <h2 className={landingStyles.landingSubheader}>
          Chekhov is an all-in-one productivity app that helps you to track
          projects, tasks, goals, and habits meaningfully.
        </h2>
      </div>
      <LandingSection
        text="Keep projects on track"
        textAlignment="left"
        imgSrc="add-task.gif"
        imgAlt="landing-project"
        height={500}
      />
      <LandingSection
        text="Organise your tasks"
        textAlignment="right"
        imgSrc="edit-task.gif"
        imgAlt="landing-task"
        height={700}
      />
      <LandingSection
        text="Hold yourself accountable with goals and habits"
        textAlignment="left"
        imgSrc="add-session.gif"
        imgAlt="landing-goal"
        height={500}
      />
      <LandingSection text="And much more!" textAlignment="right" />
      <div className={landingStyles.landingSection}>
        <div className={landingStyles.landingFormWrapper}>
          <FormGroup>
            <p>Click here to start your journey.</p>
            <Button intent={Intent.PRIMARY} onClick={loginWithRedirect}>
              Sign up/in
            </Button>
          </FormGroup>
        </div>
      </div>
    </div>
  );
};

export { Landing };

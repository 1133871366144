import { createUseStyles } from 'react-jss';
import { chosenTheme } from '../styleHooks';

export const useProjectPageStyles = createUseStyles({
  projectSummaryBox: {
    backgroundColor: chosenTheme.genericComponentBackgroundColour,
    color: chosenTheme.genericComponentPrimaryFontColour,
    borderRadius: 5,
    padding: 10,
    marginBottom: 10,
    WebkitBoxShadow: '6px 22px 39px -14px rgba(0, 0, 0, 0.15)',
    MozBoxShadow: '6px 22px 39px -14px rgba(0, 0, 0, 0.15)',
    boxShadow: '6px 22px 39px -14px rgba(0, 0, 0, 0.15)',
    '& h1, h2, p': {
      textAlign: 'left',
      marginBottom: 2,
    },
    '& h1': {
      marginBottom: 2,
    },
    zIndex: 1,
  },
  projectHeader: {
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    whiteSpace: 'nowrap',
  },
  fecundityDateSelect: { padding: 7 },
  editUnits: { padding: 7 },
  sessionList: { padding: 7 },
  sessionListElement: {
    backgroundColor: chosenTheme.tasks.taskBackgroundColour,
    display: 'flex',
    '&:hover': {
      backgroundColor: chosenTheme.tasks.taskHoverBackgroundColour,
      cursor: 'pointer',
    },
  },
  sessionListElementText: { flex: 4, paddingTop: 7 },
  sessionListElementMenu: { flex: 1 },
  projectNotesTextAreaWrapper: {
    height: 350,
    padding: '10px 10px 10px 10px',
  },
  '@media only screen and (min-width: 320px) and (max-width: 990px)': {
    projectNotesTextAreaWrapper: { padding: '10px 10px 10px 10px' },
  },
  projectNotesTextArea: { height: '100%' },
});

import React from 'react';
import { DraggableProvided, DraggableStateSnapshot } from 'react-beautiful-dnd';
import { ISubtask } from '../../../common-src/types/Subtask';
import { Checkbox, Button, Intent, Icon } from '@blueprintjs/core';
import { isEqual } from '../../../func/utils';

interface IProps {
  subtask: ISubtask;
  index: number;
  onCheckSubtask: (event: any) => void;
  inEditSubtaskMode: boolean;
  subtaskBeingEditedId: string;
  onEditOldSubtaskName: (event: any) => void;
  editedSubtaskName: string;
  onDeleteSubtask: (event: any) => void;
  onSaveEditedSubtask: () => void;
  onDismissEditingSubtask: () => void;
  onSubtaskClick: (event: any) => void;
  draggableProvided: DraggableProvided;
  draggableSnapshot: DraggableStateSnapshot;
  innerRef?: string;
}

const Subtask: React.FunctionComponent<IProps> = (props: IProps) => {
  const {
    subtask,
    onCheckSubtask,
    inEditSubtaskMode,
    subtaskBeingEditedId,
    onEditOldSubtaskName,
    editedSubtaskName,
    onDeleteSubtask,
    onSaveEditedSubtask,
    onDismissEditingSubtask,
    onSubtaskClick,
    draggableProvided,
    draggableSnapshot,
  } = props;

  const normalStyles = {
    display: 'flex',
    backgroundColor: 'rgb(236, 241 245)',
  };
  const draggingStyles = {
    display: 'flex',
    backgroundColor: 'moccasin',
  };

  const draggableStyles = draggableSnapshot.isDragging
    ? draggingStyles
    : normalStyles;

  return (
    <div ref={props.innerRef} {...draggableProvided.draggableProps}>
      <div style={draggableStyles}>
        <div style={{ flex: 1 }}>
          <Checkbox
            checked={subtask.done}
            onChange={onCheckSubtask}
            style={{ marginTop: 10 }}
            name={subtask.id}
            inline={true}
          />
        </div>
        {inEditSubtaskMode && isEqual(subtaskBeingEditedId, subtask.id) ? (
          <div
            style={{
              marginBottom: 10,
              flex: 10,
            }}
          >
            <div>
              <input
                className="bp4-input bp4-fill"
                type="text"
                onChange={onEditOldSubtaskName}
                value={editedSubtaskName}
                style={{ marginTop: 10, marginBottom: 10 }}
              />
              <Button
                onClick={onDeleteSubtask}
                icon="trash"
                minimal={true}
                intent={Intent.DANGER}
                style={{ float: 'right' }}
              />
            </div>
            <Button onClick={onSaveEditedSubtask} intent={Intent.SUCCESS}>
              Save
            </Button>
            <Button
              onClick={onDismissEditingSubtask}
              icon="cross"
              minimal={true}
            />
          </div>
        ) : (
          <div
            style={{
              display: 'flex',
              flex: 10,
              overflowX: 'auto',
              overflowWrap: 'break-word',
              wordBreak: 'break-word',
              wordWrap: 'break-word',
              paddingTop: 10,
              paddingRight: 20,
              paddingBottom: 10,
            }}
          >
            <div
              onClick={onSubtaskClick}
              id={`${subtask.id}-text`}
              style={{ flex: 10 }}
            >
              {subtask.name}
            </div>
            <div {...draggableProvided.dragHandleProps}>
              <Icon icon="drag-handle-vertical" style={{ flex: 1 }}></Icon>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export { Subtask };

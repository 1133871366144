import React, { useContext, useState, useEffect } from 'react';
import { DragDropContext, Droppable } from 'react-beautiful-dnd';
import _ from 'lodash';
import { ITask } from '../../common-src/types/Task';
import TaskGroup from '../common/tasks/TaskGroup';
import { TagSelector } from '../common/tags/TagSelector';
import { TagsContext } from '../../state/TagsContext';
import { ITag } from '../../common-src/types/Tag';
import { Button, Intent } from '@blueprintjs/core';
import { TasksContext } from '../../state/TasksContext';
import {
  IProject,
  IPhase,
  IApiPhaseInRequest,
  PhasesUpdateType,
  IApiUpdatePhasesRequest,
} from '../../common-src/types/Project';
import { UserContext } from '../../state/UserContext';
import {
  handleProjectLevelTodoDragDrop,
  handleBoardDragDrop,
  filterTasksByTags,
} from '../../func/task-ui-utils';
import { isArraysEqual } from '../../func/utils';
import { TaskBoard } from '../common/TaskBoard/TaskBoard';
import { ProjectsListContext } from '../../state/ProjectsListContext';
import { useLayoutStyles } from '../../style/components/layoutStyles';
import { ProjectContext } from '../../state/ProjectContext';
import { IOpenAddSessionModalOpts } from '../../common-src/types/UiTypes';
import { AlertsContext } from '../../state/AlertsContext';

interface Props {
  tasks: ITask[];
  project: IProject;
  openAddTaskModal: () => void;
  openEditTaskModal: (taskId: ITask) => void;
  openAddPhaseModal: () => void;
  openEditPhaseModal: (phase: IPhase) => void;
  openDeletePhaseModal: (phaseToDelete: IPhase) => void;
  openAddSessionModal: (opts?: IOpenAddSessionModalOpts) => void;
}

const ProjectTabTasks: React.FunctionComponent<Props> = (props: Props) => {
  const projectContext = useContext(ProjectContext);
  const projectsContext = useContext(ProjectsListContext);
  const tagsContext = useContext(TagsContext);
  const tasksContext = useContext(TasksContext);
  const userContext = useContext(UserContext);
  const alertsContext = useContext(AlertsContext);

  const layoutStyles = useLayoutStyles();

  const [filterTags, setFilterTags] = useState<ITag[]>([]);
  const [tasksToShow, setTasksToShow] = useState(props.tasks);
  const [phasesInUI, setPhasesInUI] = useState<IPhase[]>([]);

  useEffect(() => {
    if (props.tasks) {
      const newTasks = filterTasksByTags(props.tasks, filterTags);
      setTasksToShow(newTasks);
    }
  }, [props.tasks, filterTags]);

  useEffect(() => {
    if (props.project.phases) {
      const newPhases = props.project.phases.map(phase => {
        const newTasks = filterTasksByTags(phase.tasks, filterTags);
        return {
          ...phase,
          tasks: newTasks,
        };
      });
      setPhasesInUI(newPhases);
    }
  }, [props.project.phases, filterTags]);

  if (
    !projectContext ||
    !projectsContext ||
    !tasksContext ||
    !userContext ||
    !tagsContext
  ) {
    return null;
  }
  const { tags } = tagsContext;
  const tagsArr: ITag[] = tags ?? [];

  const { saveMultipleTasksInProject, isFetchingTasks } = tasksContext;
  const { isFetchingProject, updateProjectPhases } = projectContext;

  const {
    openAddTaskModal,
    openEditTaskModal,
    project,
    openAddPhaseModal,
    openEditPhaseModal,
    openDeletePhaseModal,
    openAddSessionModal,
    tasks,
  } = props;

  const handleTagSelect = (tag: ITag) => {
    const newListOfFilteredTags = filterTags.slice();
    newListOfFilteredTags.push(tag);
    setFilterTags(newListOfFilteredTags);
  };

  const handleTagRemove = (tagName: string) => {
    const tagToRemove = filterTags.find(tag => tag.name === tagName);
    if (tagToRemove) {
      const newListOfFilteredTags = filterTags.filter(
        tag => tag.id !== tagToRemove.id
      );
      setFilterTags(newListOfFilteredTags);
    }
  };

  const onDragEndTodo = (result: any) => {
    const projectId = project.id;
    const tasksInNewOrder = handleProjectLevelTodoDragDrop(result, tasksToShow);
    if (tasksInNewOrder) {
      setTasksToShow(tasksInNewOrder);
      saveMultipleTasksInProject!(projectId, tasksInNewOrder);
    }
  };

  const onDragEndBoard = async (result: any) => {
    const movedTask: ITask | undefined = tasks?.find(
      task => task.id === result.draggableId
    );
    const movedPhase: IPhase | undefined = phasesInUI?.find(
      phase => phase.id === result.draggableId
    );
    const originalPhases = phasesInUI?.slice();

    try {
      if (phasesInUI && (movedTask || movedPhase)) {
        const newPhases = movedTask
          ? handleBoardDragDrop(result, phasesInUI, movedTask)
          : movedPhase
          ? handleBoardDragDrop(result, phasesInUI, movedPhase)
          : phasesInUI;
        const phasesUpdateType = movedTask
          ? PhasesUpdateType.MoveTask
          : movedPhase
          ? PhasesUpdateType.MovePhase
          : undefined;
        if (
          newPhases &&
          !isArraysEqual(newPhases, phasesInUI) &&
          phasesUpdateType
        ) {
          setPhasesInUI(newPhases.slice());
          if (phasesUpdateType === PhasesUpdateType.MoveTask && movedTask) {
            const updatePhasesRequest: IApiUpdatePhasesRequest = {
              phases_update_type: phasesUpdateType,
              task_move: {
                task_id: movedTask?.id,
                source_phase_id:
                  result.source.droppableId === 'kanban-phase-uncategorised'
                    ? undefined
                    : result.source.droppableId,
                destination_phase_id: result.destination.droppableId,
                destination_index: result.destination.index,
              },
            };
            await updateProjectPhases(project.id, updatePhasesRequest);
          } else if (
            phasesUpdateType === PhasesUpdateType.MovePhase &&
            movedPhase
          ) {
            const updatePhasesRequest: IApiUpdatePhasesRequest = {
              phases_update_type: phasesUpdateType,
              phase_move: {
                phase_id: movedPhase?.id,
                source_index: result.source.index,
                destination_index: result.destination.index,
              },
            };
            await updateProjectPhases(project.id, updatePhasesRequest);
          }
        }
      }
    } catch (err) {
      const errorMessage = movedTask
        ? 'Error moving task'
        : 'Error moving phase';
      alertsContext?.addAlert(errorMessage, Intent.DANGER);
      setPhasesInUI(originalPhases);
    }
  };

  const isProjectLoading = Boolean(
    (isFetchingProject && !project) || (isFetchingTasks && !tasks)
  );

  return (
    <div>
      <div className={layoutStyles.toolbar}>
        <Button icon="plus" onClick={openAddTaskModal} minimal>
          Add task
        </Button>
        <Button icon="plus" onClick={openAddPhaseModal} minimal>
          Add phase
        </Button>
      </div>
      <div className={layoutStyles.toolbar}>
        <TagSelector
          tags={tagsArr}
          selectedTags={filterTags}
          onTagSelect={handleTagSelect}
          onTagRemove={handleTagRemove}
        />
      </div>
      {userContext?.todoPreference === 'todo' && (
        <DragDropContext onDragEnd={onDragEndTodo}>
          <div style={{ marginBottom: 30 }}>
            {tasksToShow && tasksToShow.length ? (
              <Droppable droppableId="tasks-for-project">
                {(provided, snapshot) => (
                  <div ref={provided.innerRef} {...provided.droppableProps}>
                    <TaskGroup
                      tasks={tasksToShow}
                      level="project"
                      type="todo"
                      openEditTaskModal={openEditTaskModal}
                      isDraggingOver={snapshot.isDraggingOver}
                      openAddSessionModal={openAddSessionModal}
                    />
                    {provided.placeholder}
                  </div>
                )}
              </Droppable>
            ) : (
              <div style={{ marginTop: 20 }}>
                No tasks in this project{' '}
                <span role="img" aria-label="sad face">
                  😢
                </span>
                Click the above button to change this.
              </div>
            )}
          </div>
        </DragDropContext>
      )}
      {Boolean(userContext?.todoPreference === 'board' && phasesInUI) && (
        <TaskBoard
          level="project"
          tasks={tasksToShow}
          projectId={project.id}
          phases={phasesInUI}
          onDragEnd={onDragEndBoard}
          openEditTaskModal={openEditTaskModal}
          openEditPhaseModal={openEditPhaseModal}
          openDeletePhaseModal={openDeletePhaseModal}
          openAddSessionModal={openAddSessionModal}
          isLoading={isProjectLoading}
        />
      )}
    </div>
  );
};

export { ProjectTabTasks };

import { createUseStyles } from 'react-jss';

export const useOnboardingStyles = createUseStyles({
  videoContainer: {
    width: 500,
  },
  '@media only screen and (min-width: 320px) and (max-width: 480px)': {
    videoContainer: {
      width: 300,
    },
  },
});

import { Button } from '@blueprintjs/core';
import React, { useContext, useEffect } from 'react';
import { ModalContext } from '../../state/ModalContext';
import { SessionAndUnitContext } from '../../state/SessionAndUnitContext';
import { useLayoutStyles } from '../../style/components/layoutStyles';
import { useAllPageStyles } from '../../style/components/pageStyles';
import {
  DateRangeType,
  SessionsCalendar,
} from '../common/SessionsCalendar/SessionsCalendar';
import { SessionsPageOnboarding } from '../common/onboarding/SessionsPageOnboarding';

const SessionsPage: React.FunctionComponent = () => {
  const sessionAndUnitContext = useContext(SessionAndUnitContext);
  const modalContext = useContext(ModalContext);

  const allPageStyles = useAllPageStyles();
  const layoutStyles = useLayoutStyles();

  const { sessions } = sessionAndUnitContext!;
  const { openAddSessionModal } = modalContext!;

  const handleClickAddSession = () => {
    openAddSessionModal();
  };

  return (
    <div className={allPageStyles.genericPage}>
      <h1>Sessions</h1>
      <div className={layoutStyles.toolbar}>
        <Button icon="plus" minimal onClick={handleClickAddSession}>
          Add session
        </Button>
      </div>
      <div>
        <SessionsCalendar
          dateRangeType={DateRangeType.WEEK}
          sessions={sessions!}
        />
      </div>
      <SessionsPageOnboarding />
    </div>
  );
};

export { SessionsPage };

import React, { useContext } from 'react';
import { Dialog, Button, Intent } from '@blueprintjs/core';
import { PomodoroContext } from '../../state/PomodoroContext';
import { useModalStyles } from '../../style/components/genericStyles';

interface Props {
  onRequestClose: () => void;
  modalIsOpen: boolean;
}

const CompleteSessionModal: React.FunctionComponent<Props> = (props: Props) => {
  const pomodoroContext = useContext(PomodoroContext);
  const modalStyles = useModalStyles();

  const handleStartBreak = () => {
    pomodoroContext?.startBreak();
    props.onRequestClose();
  };

  const handleClose = () => {
    pomodoroContext?.destroyPomodoro();
    props.onRequestClose();
  };

  const { currentPomodoro } = pomodoroContext!;
  if (!currentPomodoro) {
    return null;
  }
  const { project } = currentPomodoro;
  return (
    <Dialog
      isOpen={props.modalIsOpen}
      onClose={handleClose}
      title={`Start break`}
      canOutsideClickClose={false}
    >
      <div className={`bp4-dialog-body ${modalStyles.body}`}>
        <div>
          <p>
            Completed a session for <em>{project.name}</em>!
          </p>
        </div>
      </div>
      <div className={`bp4-dialog-footer ${modalStyles.footer}`}>
        <Button onClick={handleClose}>Close</Button>
        <Button onClick={handleStartBreak} intent={Intent.PRIMARY}>
          Start break
        </Button>
      </div>
    </Dialog>
  );
};

export default CompleteSessionModal;

import { Position, ProgressBar, Tooltip } from '@blueprintjs/core';
import React from 'react';
import { getIntentForHabitProgressBar } from '../../func/ui-utils';
import { useTaskStyles } from '../../style/components/taskStyles';

interface IHabitProgressBarProps {
  habitConsistency: number;
  tooltipText: string;
}

const HabitProgressBar: React.FunctionComponent<IHabitProgressBarProps> = (
  props: IHabitProgressBarProps
) => {
  const { habitConsistency, tooltipText } = props;
  const taskStyles = useTaskStyles();
  const intent = getIntentForHabitProgressBar(habitConsistency);

  return (
    <Tooltip
      targetClassName={taskStyles.taskProgressWrapper}
      popoverClassName={taskStyles.taskProgressPopover}
      content={tooltipText}
      position={Position.TOP}
    >
      <div style={{ marginTop: 5, marginBottom: 5, display: 'flex' }}>
        <div
          style={{ flex: 1, paddingRight: 10, textAlign: 'left' }}
        >{`${Math.round(habitConsistency * 100)}%`}</div>
        <div style={{ flex: 11, paddingTop: 5 }}>
          <ProgressBar
            intent={intent}
            value={habitConsistency}
            animate={true}
            stripes={true}
          />
        </div>
      </div>
    </Tooltip>
  );
};

export { HabitProgressBar };

import React, { useState, useContext, useEffect } from 'react';
import { DateTime } from 'luxon';

import {
  Button,
  FormGroup,
  InputGroup,
  ControlGroup,
  Intent,
} from '@blueprintjs/core';
import { IProject } from '../../common-src/types/Project';
import { AlertsContext } from '../../state/AlertsContext';
import { useGenericStyles } from '../../style/components/genericStyles';
import {
  IApiCreateUnitOfLabourRequest,
  IUnitOfLabour,
  IApiUpdateUnitOfLabourRequest,
} from '../../common-src/types/UnitOfLabour';

interface Props {
  mostUpToDateUnits: number;
  project?: IProject;
  date: DateTime;
  unitName: string;
  unitTypeId: string;
  unitOfLabour?: IUnitOfLabour;
  createUnitOutputAssociatedWithDay: (
    createUnitRequest: IApiCreateUnitOfLabourRequest
  ) => Promise<void>;
  updateOrCreateUnitOutputAssociatedWithDay: (
    unitId: string,
    updateUnitRequest?: IApiUpdateUnitOfLabourRequest
  ) => Promise<void>;
}

const EditUnits: React.FunctionComponent<Props> = (props: Props) => {
  const {
    project,
    date,
    unitOfLabour,
    unitName,
    createUnitOutputAssociatedWithDay,
    updateOrCreateUnitOutputAssociatedWithDay,
    unitTypeId,
  } = props;

  const alertsContext = useContext(AlertsContext);

  const genericStyles = useGenericStyles();

  const [units, setUnits] = useState<number>(props.mostUpToDateUnits);

  useEffect(() => {
    setUnits(props.mostUpToDateUnits);
  }, [props]);

  if (!alertsContext) {
    return null;
  }

  const handleTextChange = event => {
    setUnits(event.target.value);
  };

  const handleUpdateUnits = async () => {
    if (unitOfLabour) {
      const updateUnitOfLabourRequest: IApiUpdateUnitOfLabourRequest = {
        project_id: project?.id,
        units,
        unit_type_id: unitOfLabour.unitTypeId,
      };
      await updateOrCreateUnitOutputAssociatedWithDay(
        unitOfLabour.id,
        updateUnitOfLabourRequest
      );
    } else if (date && unitTypeId) {
      const createUnitOfLabourRequest: IApiCreateUnitOfLabourRequest = {
        unit_type_id: unitTypeId,
        project_id: project?.id ?? undefined,
        date: date.toISO(),
        units: units,
      };
      await createUnitOutputAssociatedWithDay(createUnitOfLabourRequest);
    }
    alertsContext.addAlert(
      `Updated units for ${date.toLocaleString(
        DateTime.DATETIME_MED_WITH_WEEKDAY
      )}`,
      Intent.SUCCESS
    );
  };

  return (
    <div className={genericStyles.genericBox}>
      <h3>{unitName}</h3>
      <div>
        <FormGroup
          label="Enter units done on this day:"
          labelFor="input-units-on-day"
        >
          <ControlGroup fill={true} vertical={false} id="input-units-on-day">
            <InputGroup onChange={handleTextChange} value={units.toString()} />
            <Button
              icon="arrow-right"
              intent="primary"
              onClick={handleUpdateUnits}
            />
          </ControlGroup>
        </FormGroup>
      </div>
    </div>
  );
};

export { EditUnits };

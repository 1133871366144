import React, { useContext, useEffect, useState } from 'react';
import _ from 'lodash';
import { UserContext } from '../../state/UserContext';
import { useLocation } from 'react-router-dom';
import { WindowContext } from '../../state/WindowContext';
import { getStartAndEndDatesForTrackingPeriod } from '../../common-src/productivity';
import { useAllPageStyles } from '../../style/components/pageStyles';
import { useLayoutStyles } from '../../style/components/layoutStyles';
import { GoalContext } from '../../state/GoalContext';
import { ProductivityContext } from '../../state/ProductivityContext';
import { ComponentType, LoadingSkeleton } from '../common/LoadingSkeleton';
import { Button } from '@blueprintjs/core';
import { ModalContext } from '../../state/ModalContext';
import { GoalSummary } from './GoalSummary';
import { AllGoalsSummary } from './AllGoalsSummary';
import { ProjectsListContext } from '../../state/ProjectsListContext';
import { GoalType, IGoal } from '../../common-src/types/Goal';
import { TasksContext } from '../../state/TasksContext';
import { getGoalScoreText } from '../../func/textChunks';
import { renderTextChunks } from '../../func/ui-utils';
import { IStatusOfAllGoals } from '../../common-src/types/Reporting';
import { GoalsPageOnboarding } from '../common/onboarding/GoalsPageOnboarding';
import { useGenericStyles } from '../../style/components/genericStyles';

const GoalsPage: React.FunctionComponent = () => {
  const userContext = useContext(UserContext);
  const goalContext = useContext(GoalContext);
  const windowContext = useContext(WindowContext);
  const productivityContext = useContext(ProductivityContext);
  const projectListContext = useContext(ProjectsListContext);
  const tasksContext = useContext(TasksContext);
  const modalContext = useContext(ModalContext);

  const location = useLocation();
  const allPageStyles = useAllPageStyles();
  const layoutStyles = useLayoutStyles();
  const genericStyles = useGenericStyles();

  const [goalsToShow, setGoalsToShow] = useState<IGoal[]>([]);
  const [statusOfAllGoalsToShow, setStatusOfAllGoalsToShow] = useState<
    IStatusOfAllGoals | undefined
  >(undefined);

  useEffect(() => {
    if (goals) {
      setGoalsToShow(goals);
    }
  }, [goalContext?.goals]);

  useEffect(() => {
    if (butlerAssessment) {
      setStatusOfAllGoalsToShow(butlerAssessment.goalsStatus);
    }
  }, [productivityContext?.butlerAssessment]);

  useEffect(() => {
    window.scrollTo(0, 0);
    document?.getElementById('main-scrollable')?.scrollTo(0, 0);
  }, [location]);

  if (
    !userContext ||
    !goalContext ||
    !windowContext ||
    !productivityContext ||
    !projectListContext ||
    !tasksContext ||
    !modalContext
  ) {
    return null;
  }

  const { trackingPeriod, customTrackingPeriod, labourTypes } = userContext;
  const { goals, isFetchingGoals } = goalContext;
  const { butlerAssessment } = productivityContext;
  const {
    openAddGoalModal,
    openEditGoalModal,
    openDeleteGoalModal,
    openAddSessionModal,
  } = modalContext;

  const goalScoreText = trackingPeriod
    ? getGoalScoreText(trackingPeriod, customTrackingPeriod)
    : [];

  if (!productivityContext || !trackingPeriod) {
    return null;
  }
  const startAndEndDates = getStartAndEndDatesForTrackingPeriod(
    trackingPeriod,
    customTrackingPeriod
  );
  const start = startAndEndDates[0];
  const end = startAndEndDates[1];

  const shouldShowOverallProductivityChart =
    goalsToShow?.filter(goal => goal.isActive).length &&
    goalsToShow?.length > 0;

  return (
    <div className={allPageStyles.genericPage}>
      <h1>Goals</h1>
      {isFetchingGoals ? (
        <div>
          <LoadingSkeleton
            type={ComponentType.DashboardSummary}
            minHeight={320}
            maxHeight={320}
          />
        </div>
      ) : (
        <>
          <div className={layoutStyles.toolbar}>
            <Button icon="plus" minimal onClick={openAddGoalModal}>
              Add goal
            </Button>
          </div>
          <>
            {shouldShowOverallProductivityChart && butlerAssessment ? (
              <AllGoalsSummary
                allGoalsSummaryString={renderTextChunks(goalScoreText)}
                statusOfAllGoals={butlerAssessment.goalsStatus}
              />
            ) : (
              <div>You don't currently have any goals.</div>
            )}
          </>
        </>
      )}

      <div className={`${layoutStyles.grid_3_col} ${layoutStyles.spaced_col}`}>
        {goalsToShow
          ?.filter(
            goal =>
              goal.type === GoalType.SESSION || goal.type === GoalType.UNIT
          )
          .sort((a, b) => a.name.localeCompare(b.name))
          .map(goal => {
            const goalSummary = butlerAssessment?.goalsStatus?.summaries?.find(
              summary => summary.goalId === goal.id
            );
            return (
              <div key={goal.id}>
                <GoalSummary
                  goal={goal}
                  goalSummary={goalSummary}
                  startDate={start}
                  endDate={end}
                  openEditGoalModal={openEditGoalModal}
                  openDeleteGoalModal={openDeleteGoalModal}
                  openAddSessionModal={openAddSessionModal}
                />
              </div>
            );
          })}
        <GoalsPageOnboarding />
      </div>
    </div>
  );
};

export { GoalsPage };

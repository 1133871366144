import _ from 'lodash';
import { DateTime } from 'luxon';
import { BEGINNING_OF_TIME } from './constants';
import { ITask } from '../common-src/types/Task';
import { ISubtask } from '../common-src/types/Subtask';

export function getIsTaskTodo(
  task: ITask,
  todoListTaskIds: (string | number)[]
): boolean {
  return todoListTaskIds.includes(task.id);
}

export function getTasksWithDueDatesBetweenInclusive(
  tasks: ITask[],
  startDate: DateTime,
  endDate: DateTime
): ITask[] {
  let tasksWithDueDates = tasks.filter(
    task =>
      task.dueDate &&
      DateTime.fromISO(task.dueDate) >= startDate &&
      DateTime.fromISO(task.dueDate) <= endDate
    // moment(task.dueDate).isBetween(startDate, endDate, undefined, '[]'
  );
  tasksWithDueDates = _.sortBy(tasksWithDueDates, task =>
    DateTime.fromISO(task.dueDate!).valueOf()
  );
  return tasksWithDueDates;
}

export function getTasksDueAfter(tasks: ITask[], date: DateTime): ITask[] {
  const tasksDueAfter = tasks.filter(
    task => task.dueDate && DateTime.fromISO(task.dueDate) > date
  );
  return tasksDueAfter;
}

export function getOverdueTasks(tasks: ITask[]): ITask[] {
  const start = BEGINNING_OF_TIME;
  const end = DateTime.now().minus({ days: 1 }).endOf('day');
  return getTasksWithDueDatesBetweenInclusive(tasks, start, end);
}

export function getTasksDueToday(tasks: ITask[]): ITask[] {
  const start = DateTime.now().startOf('day');
  const end = DateTime.now().endOf('day');
  return getTasksWithDueDatesBetweenInclusive(tasks, start, end);
}

export function getTasksDueTomorrow(tasks: ITask[]): ITask[] {
  const start = DateTime.now().plus({ days: 1 }).startOf('day');
  const end = DateTime.now().plus({ days: 1 }).endOf('day');
  return getTasksWithDueDatesBetweenInclusive(tasks, start, end);
}

export function getTasksDueLaterThisWeek(tasks: ITask[]): ITask[] {
  // moment.updateLocale('en', {
  //   week: {
  //     dow: 1,
  //     doy: 4,
  //   },
  // });
  const start = DateTime.now()
    .plus({ days: 2 }) // add 2 so as not include tomorrow
    .startOf('day');
  const end = DateTime.now().endOf('week');
  if (start < end) {
    return getTasksWithDueDatesBetweenInclusive(tasks, start, end);
  } else {
    return [];
  }
}

export function getTasksDueNextWeek(tasks: ITask[]): ITask[] {
  // moment.updateLocale('en', {
  //   week: {
  //     dow: 1,
  //     doy: 4,
  //   },
  // });
  let start = DateTime.now().plus({ days: 7 }).startOf('week');
  // if the first day of next week is tomorrow, we should remove tomorrow from the date range
  if (start.day == DateTime.now().plus({ days: 1 }).day) {
    start = start.plus({ days: 1 });
  }
  const end = DateTime.now().plus({ days: 7 }).endOf('week');
  return getTasksWithDueDatesBetweenInclusive(tasks, start, end);
}

export function getTasksDueLater(tasks: ITask[]): ITask[] {
  // moment.updateLocale('en', {
  //   week: {
  //     dow: 1,
  //     doy: 4,
  //   },
  // });
  const date = DateTime.now().plus({ days: 7 }).endOf('week');
  return getTasksDueAfter(tasks, date);
}

/**
 * Orders tasks by `dueDate`.
 * @param tasks an array of tasks to order. Each of these must have a `dueDate`
 * @returns the array of ordered tasks
 */
export const orderTasksByDate = (tasks: ITask[]): ITask[] => {
  const orderedTasks = _.sortBy(tasks, (task: ITask) =>
    DateTime.fromISO(task.dueDate!).valueOf()
  );
  return orderedTasks;
};

export const orderSubtasksByPosition = (subtasks: ISubtask[]): ISubtask[] => {
  const areSubTasksPositioned = subtasks.find(subtask =>
    Object.keys(subtask).includes('position')
  );
  if (areSubTasksPositioned) {
    return subtasks;
  } else {
    subtasks.forEach((subtask, index) => {
      subtask.position = index;
    });
    return subtasks;
  }
};

import { ITheme } from '../common-src/types/Theme';

// export const plainTheme: ITheme = {
//   mainFontColour: 'rgb(105, 110, 110)',
//   mainBackgroundColour: 'rgb(214, 219, 219)',
//   genericComponentPrimaryFontColour: '#433E3F',
//   genericComponentSecondaryFontColour: 'rgb(201, 202, 210)',
//   genericComponentBackgroundColour: 'white',
//   genericComponentBorderColour: 'white',
//   tasks: {
//     taskBackgroundColour: 'white',
//     taskBorderColour: 'lightgrey',
//     taskHoverBackgroundColour: 'rgb(247, 247, 247)',
//     taskDraggingBackgroundColour: 'rgb(226, 226, 226)',
//     taskGroupBackgroundColour: '#E4E2DD',
//     taskGroupDraggingOverBackgroundColour: '#B7B1A4',
//   },
//   navbarSide: {
//     navbarSideBgColourA: '#240B36',
//     navbarSideBgColourB: '#EF626C',
//     navbarSideItemBackground: 'transparent',
//     navbarSideBackground: 'rgb(214, 219, 219)',
//     navbarSideItemActiveBackground: 'rgba(255, 255, 255, 0.3)',
//     navbarSideItemHoverBackground: 'rgba(255, 255, 255, 0.1)',
//     navbarSideItemColour: 'white',
//   },
//   navbarTop: {
//     fontColour: 'rgb(115, 21, 3)',
//     navbarTopBgColour: 'rgba(244, 249, 249, 0.5)',
//   },
//   statusBar: {
//     statusBarBgColour: 'rgba(255, 255, 255, 0.8)',
//     statusBarColour: 'rgb(105, 110, 110)',
//     statusBarAccentColour: 'rgb(155, 167, 167)',
//   },
//   pomodoroClock: {
//     pomodoroClockInWorkModeBgColour: 'rgba(217, 79, 69, 0.7)',
//     pomodoroClockInBreakModeBgColour: 'rgba(165, 79, 125, 0.3)',
//   },
//   log: {
//     borderBottomColour: 'lightgray',
//   },
//   areaCharts: {
//     backgroundColour: '#DB5461',
//     backgroundColour2: '#204051',
//     accentColour: 'lightblue',
//     accentColourDark: 'darkblue',
//     sessionsColour: '#00CECB',
//     sessionsColourDark: '#008F8C',
//     unitsColour: '#FF2C55',
//     unitsColourDark: '#E0002D',
//     tooltipBackground: 'rgba(255, 255, 255, 0.8)',
//   },
// };

export const bureaucratTheme: ITheme = {
  mainFontColour: 'rgb(105, 110, 110)',
  mainBackgroundColour: 'rgb(247, 247, 247)',
  genericComponentPrimaryFontColour: '#433E3F',
  genericComponentSecondaryFontColour: 'rgb(201, 202, 210)',
  genericComponentBackgroundColour: 'white',
  genericComponentBorderColour: 'white',
  genericGroupComponentBackgroundColour: '#E4E2DD',
  genericGroupComponentDraggingOverBackgroundColour: '#B7B1A4',
  tasks: {
    taskBackgroundColour: 'white',
    archivedTaskBackgroundColour: 'rgb(247, 247, 247)',
    taskBorderColour: 'lightgrey',
    taskHoverBackgroundColour: 'rgb(247, 247, 247)',
    archivedTaskHoverBackgroundColour: 'rgb(237, 237, 237)',
    taskDraggingBackgroundColour: 'rgb(226, 226, 226)',
    taskGroupBackgroundColour: '#E4E2DD',
    taskGroupDraggingOverBackgroundColour: '#B7B1A4',
  },
  navbarSide: {
    navbarSideBgColourA: '#240B36',
    navbarSideBgColourB: '#EF626C',
    navbarSideBackground: 'rgb(237, 237, 237)',
    navbarSideItemBackground: 'transparent',
    navbarSideItemActiveBackground: 'rgb(105, 110, 110)',
    navbarSideItemHoverBackground: 'rgb(105, 110, 110)',
    navbarSideItemColour: 'rgb(105, 110, 110)',
  },
  navbarTop: {
    fontColour: 'rgb(115, 21, 3)',
    navbarTopBgColour: 'rgba(244, 249, 249, 0.5)',
  },
  statusBar: {
    statusBarBgColour: 'rgba(255, 255, 255, 0.8)',
    statusBarColour: 'rgb(105, 110, 110)',
    statusBarAccentColour: 'rgb(155, 167, 167)',
  },
  pomodoroClock: {
    pomodoroClockInWorkModeBgColour: 'rgba(217, 79, 69, 0.7)',
    pomodoroClockInBreakModeBgColour: 'rgba(165, 79, 125, 0.3)',
  },
  log: {
    borderBottomColour: 'lightgray',
  },
  areaCharts: {
    backgroundColour: 'white',
    backgroundColour2: 'white',
    accentColour: 'black',
    accentColourDark: 'darkblue',
    sessionsColour: '#00CECB',
    sessionsColourDark: '#008F8C',
    unitsColour: '#FF2C55',
    unitsColourDark: '#E0002D',
    tooltipBackground: 'black',
  },
  calendar: {
    weekBackgroundColour: 'rgb(250, 159, 149)',
    weekBorderColour: 'green',
  },
};

// export const experimentalTheme: ITheme = {
//   mainFontColour: 'rgb(105, 110, 110)',
//   mainBackgroundColour: '#F0B67F',
//   genericComponentBackgroundColour: '#EEF5DB',
//   genericComponentBorderColour: 'white',
//   tasks: {
//     taskBackgroundColour: '#D6D1B1',
//     taskBorderColour: 'black',
//     taskHoverBackgroundColour: 'rgb(247, 247, 247)',
//     taskDraggingBackgroundColour: 'rgb(226, 226, 226)',
//     taskGroupBackgroundColour: '#FE5F55',
//     taskGroupDraggingOverBackgroundColour: '#6D6875',
//   },
//   navbarSide: {
//     navbarSideBgColourA: '#FE5F55',
//     navbarSideBgColourB: '#F0B67F',
//     navbarSideBackground: '#FE5F55',
//     navbarSideItemActiveBackground: 'rgb(142, 170, 170)',
//     navbarSideItemHoverBackground: 'rgb(157, 192, 192)',
//   },
//   navbarTop: {
//     fontColour: 'white',
//     navbarTopBgColour: '#FE5F55',
//   },
//   statusBar: {
//     statusBarBgColour: 'rgba(255, 255, 255, 0.8)',
//     statusBarColour: 'rgb(105, 110, 110)',
//     statusBarAccentColour: 'rgb(155, 167, 167)',
//   },
//   pomodoroClock: {
//     pomodoroClockInWorkModeBgColour: 'rgba(217, 79, 69, 0.7)',
//     pomodoroClockInBreakModeBgColour: 'rgba(165, 79, 125, 0.3)',
//   },
//   log: {
//     borderBottomColour: 'salmon',
//   },
//   areaCharts: {
//     backgroundColour: '#DB5461',
//     backgroundColour2: '#204051',
//     accentColour: 'lightblue',
//     accentColourDark: 'darkblue',
//     sessionsColour: '#00CECB',
//     sessionsColourDark: '#008F8C',
//     unitsColour: '#FF2C55',
//     unitsColourDark: '#E0002D',
//   },
// };

// export const lizTheme: ITheme = {
//   mainFontColour: 'rgb(105, 110, 110)',
//   mainBackgroundColour: '#FFCDB2',
//   genericComponentBackgroundColour: '#FFB4A2',
//   genericComponentBorderColour: 'white',
//   tasks: {
//     taskBackgroundColour: '#E5989B',
//     taskBorderColour: '#6D6875',
//     taskHoverBackgroundColour: 'rgb(247, 247, 247)',
//     taskDraggingBackgroundColour: 'rgb(226, 226, 226)',
//     taskGroupBackgroundColour: '#ffb4a2',
//     taskGroupDraggingOverBackgroundColour: '#cca7b6',
//   },
//   navbarSide: {
//     navbarSideBgColourA: '#6D6875',
//     navbarSideBgColourB: '#B5838D',
//     navbarSideBackground: '#FE5F55',
//     navbarSideItemActiveBackground: 'rgb(142, 170, 170)',
//     navbarSideItemHoverBackground: 'rgb(157, 192, 192)',
//   },
//   navbarTop: {
//     fontColour: 'white',
//     navbarTopBgColour: '#B5838D',
//   },
//   statusBar: {
//     statusBarBgColour: 'rgba(255, 255, 255, 0.8)',
//     statusBarColour: 'rgb(105, 110, 110)',
//     statusBarAccentColour: 'rgb(155, 167, 167)',
//   },
//   pomodoroClock: {
//     pomodoroClockInWorkModeBgColour: 'rgba(217, 79, 69, 0.7)',
//     pomodoroClockInBreakModeBgColour: 'rgba(165, 79, 125, 0.3)',
//   },
//   log: {
//     borderBottomColour: 'darkred',
//   },
//   areaCharts: {
//     backgroundColour: '#DB5461',
//     backgroundColour2: '#204051',
//     accentColour: 'lightblue',
//     accentColourDark: 'darkblue',
//     sessionsColour: '#00CECB',
//     sessionsColourDark: '#008F8C',
//     unitsColour: '#FF2C55',
//     unitsColourDark: '#E0002D',
//   },
// };

// export const tangerineBlueTheme: ITheme = {
//   mainFontColour: 'rgb(105, 110, 110)',
//   mainBackgroundColour: '#FEE4AE',
//   genericComponentBackgroundColour: '#FDC449',
//   genericComponentBorderColour: '#D95D39',
//   tasks: {
//     taskBackgroundColour: '#FDC449',
//     taskBorderColour: '#6D6875',
//     taskHoverBackgroundColour: 'rgb(247, 247, 247)',
//     taskDraggingBackgroundColour: 'rgb(226, 226, 226)',
//     taskGroupBackgroundColour: '#F18805',
//     taskGroupDraggingOverBackgroundColour: '#BC4524',
//   },
//   navbarSide: {
//     navbarSideBgColourA: '#202C59',
//     navbarSideBgColourB: '#D95D39',
//     navbarSideBackground: '#FE5F55',
//     navbarSideItemActiveBackground: 'rgb(142, 170, 170)',
//     navbarSideItemHoverBackground: 'rgb(157, 192, 192)',
//   },
//   navbarTop: {
//     fontColour: 'white',
//     navbarTopBgColour: '#202C59',
//   },
//   statusBar: {
//     statusBarBgColour: 'rgba(255, 255, 255, 0.8)',
//     statusBarColour: 'rgb(105, 110, 110)',
//     statusBarAccentColour: 'rgb(155, 167, 167)',
//   },
//   pomodoroClock: {
//     pomodoroClockInWorkModeBgColour: 'rgba(217, 79, 69, 0.7)',
//     pomodoroClockInBreakModeBgColour: 'rgba(165, 79, 125, 0.3)',
//   },
//   log: {
//     borderBottomColour: 'darkred',
//   },
//   areaCharts: {
//     backgroundColour: '#F18805',
//     backgroundColour2: '#BC4524',
//     accentColour: 'lightblue',
//     accentColourDark: 'darkblue',
//     sessionsColour: '#00CECB',
//     sessionsColourDark: '#008F8C',
//     unitsColour: '#4B64BE',
//     unitsColourDark: '#202C59',
//   },
// };

// export const eightiesTheme: ITheme = {
//   mainFontColour: '#D3D0C8',
//   mainBackgroundColour: '#2D2D2D',
//   genericComponentBackgroundColour: '#747369',
//   genericComponentBorderColour: '#CC99CC',
//   tasks: {
//     taskBackgroundColour: '#D3D0C8',
//     taskBorderColour: '#CC99CC',
//     taskHoverBackgroundColour: 'rgb(247, 247, 247)',
//     taskDraggingBackgroundColour: 'rgb(226, 226, 226)',
//     taskGroupBackgroundColour: '#F18805',
//     taskGroupDraggingOverBackgroundColour: '#BC4524',
//   },
//   navbarSide: {
//     navbarSideBgColourA: '#2D2D2D',
//     navbarSideBgColourB: '#F2777A',
//     navbarSideBackground: '#F2777A',
//     navbarSideItemActiveBackground: 'rgb(142, 170, 170)',
//     navbarSideItemHoverBackground: 'rgb(157, 192, 192)',
//   },
//   navbarTop: {
//     fontColour: '#D3D0C8',
//     navbarTopBgColour: '#202C59',
//   },
//   statusBar: {
//     statusBarBgColour: 'rgba(255, 255, 255, 0.8)',
//     statusBarColour: 'rgb(105, 110, 110)',
//     statusBarAccentColour: 'rgb(155, 167, 167)',
//   },
//   pomodoroClock: {
//     pomodoroClockInWorkModeBgColour: 'rgba(217, 79, 69, 0.7)',
//     pomodoroClockInBreakModeBgColour: 'rgba(165, 79, 125, 0.3)',
//   },
//   log: {
//     borderBottomColour: 'darkred',
//   },
//   areaCharts: {
//     backgroundColour: '#F18805',
//     backgroundColour2: '#BC4524',
//     accentColour: 'lightblue',
//     accentColourDark: 'darkblue',
//     sessionsColour: '#00CECB',
//     sessionsColourDark: '#008F8C',
//     unitsColour: '#4B64BE',
//     unitsColourDark: '#202C59',
//   },
// };

// export const crayolaTheme: ITheme = {
//   mainFontColour: '#2A324B',
//   mainBackgroundColour: '#F9F7F6',
//   genericComponentPrimaryFontColour: '#433E3F',
//   genericComponentSecondaryFontColour: 'rgb(201, 202, 210)',
//   genericComponentBackgroundColour: '#E7DDD9',
//   genericComponentBorderColour: '#CC99CC',
//   tasks: {
//     taskBackgroundColour: '#E7DDD9',
//     taskBorderColour: '#2A324B',
//     taskHoverBackgroundColour: 'rgb(247, 247, 247)',
//     taskDraggingBackgroundColour: 'rgb(226, 226, 226)',
//     taskGroupBackgroundColour: '#D0DDDC',
//     taskGroupDraggingOverBackgroundColour: '#D57A66',
//   },
//   navbarSide: {
//     navbarSideBgColourA: '#BAB5B5',
//     navbarSideBgColourB: '#BAB5B5',
//     navbarSideBackground: '#BAB5B5',
//     navbarSideItemBackground: '#BAB5B5',
//     navbarSideItemActiveBackground: '#713E5A',
//     navbarSideItemHoverBackground: 'salmon',
//     navbarSideItemColour: 'white',
//   },
//   navbarTop: {
//     fontColour: '#D3D0C8',
//     navbarTopBgColour: '#D57A66',
//   },
//   statusBar: {
//     statusBarBgColour: 'rgba(255, 255, 255, 0.8)',
//     statusBarColour: 'rgb(105, 110, 110)',
//     statusBarAccentColour: 'rgb(155, 167, 167)',
//   },
//   pomodoroClock: {
//     pomodoroClockInWorkModeBgColour: 'rgba(217, 79, 69, 0.7)',
//     pomodoroClockInBreakModeBgColour: 'rgba(165, 79, 125, 0.3)',
//   },
//   log: {
//     borderBottomColour: 'darkred',
//   },
//   areaCharts: {
//     backgroundColour: '#D8C99B',
//     backgroundColour2: '#D8C99B',
//     accentColour: 'lightblue',
//     accentColourDark: 'darkblue',
//     sessionsColour: '#00CECB',
//     sessionsColourDark: '#008F8C',
//     unitsColour: '#4B64BE',
//     unitsColourDark: '#202C59',
//     tooltipBackground: 'rgba(255, 255, 255, 0.8)',
//   },
// };

// export const codeEditorTheme: ITheme = {
//   mainFontColour: '#2A324B',
//   mainBackgroundColour: '#F9F7F6',
//   genericComponentPrimaryFontColour: '#433E3F',
//   genericComponentSecondaryFontColour: 'rgb(201, 202, 210)',
//   genericComponentBackgroundColour: '#E7DDD9',
//   genericComponentBorderColour: '#CC99CC',
//   tasks: {
//     taskBackgroundColour: '#E7DDD9',
//     taskBorderColour: '#2A324B',
//     taskHoverBackgroundColour: 'rgb(247, 247, 247)',
//     taskDraggingBackgroundColour: 'rgb(226, 226, 226)',
//     taskGroupBackgroundColour: '#D0DDDC',
//     taskGroupDraggingOverBackgroundColour: '#D57A66',
//   },
//   navbarSide: {
//     navbarSideBgColourA: '#BAB5B5',
//     navbarSideBgColourB: '#BAB5B5',
//     navbarSideBackground: '#BAB5B5',
//     navbarSideItemBackground: 'transparent',
//     navbarSideItemActiveBackground: '#713E5A',
//     navbarSideItemHoverBackground: 'salmon',
//     navbarSideItemColour: 'white',
//   },
//   navbarTop: {
//     fontColour: '#D3D0C8',
//     navbarTopBgColour: '#D57A66',
//   },
//   statusBar: {
//     statusBarBgColour: 'rgba(255, 255, 255, 0.8)',
//     statusBarColour: 'rgb(105, 110, 110)',
//     statusBarAccentColour: 'rgb(155, 167, 167)',
//   },
//   pomodoroClock: {
//     pomodoroClockInWorkModeBgColour: 'rgba(217, 79, 69, 0.7)',
//     pomodoroClockInBreakModeBgColour: 'rgba(165, 79, 125, 0.3)',
//   },
//   log: {
//     borderBottomColour: 'darkred',
//   },
//   areaCharts: {
//     backgroundColour: '#D8C99B',
//     backgroundColour2: '#D8C99B',
//     accentColour: 'lightblue',
//     accentColourDark: 'darkblue',
//     sessionsColour: '#00CECB',
//     sessionsColourDark: '#008F8C',
//     unitsColour: '#4B64BE',
//     unitsColourDark: '#202C59',
//     tooltipBackground: 'rgba(255, 255, 255, 0.8)',
//   },
// };

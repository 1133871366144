import React, { useContext, useState, useEffect } from 'react';
import { useParams, useLocation } from 'react-router-dom';

import { ProjectsListContext } from '../../state/ProjectsListContext';
import { TagsContext } from '../../state/TagsContext';

import { Tab, Tabs, Intent, TabId } from '@blueprintjs/core';

import { EditTaskModal } from '../modals/EditTaskModal';
import PomodoroModal from '../modals/PomodoroModal';

import { AddTaskModal } from '../modals/AddTaskModal';

import { EditProjectModal } from '../modals/EditProjectModal';

import { ProjectSummaryBox } from './ProjectSummaryBox';

import { AddLogModalWithinProjectPage } from '../modals/AddLogModalWithinProjectPage';

import { ProjectTabTasks } from './ProjectTabTasks';
import { ProjectTabReporting } from './ProjectTabReporting';
import { ProjectTabNotes } from './ProjectTabNotes';
import { ProjectTabLog } from './ProjectTabLog';

import { IPhase, IProject } from '../../common-src/types/Project';
import { AlertsContext } from '../../state/AlertsContext';
import { PomodoroContext } from '../../state/PomodoroContext';
import { ModalContext } from '../../state/ModalContext';
import { ITask } from '../../common-src/types/Task';
import { AddPhaseModal } from '../modals/AddPhaseModal';
import { EditPhaseModal } from '../modals/EditPhaseModal';
import { DeletePhaseModal } from '../modals/DeletePhaseModal';
import { useAllPageStyles } from '../../style/components/pageStyles';
import { ProjectContext } from '../../state/ProjectContext';
import { DateTime } from 'luxon';
import { ProductivityContext } from '../../state/ProductivityContext';
import { ComponentType, LoadingSkeleton } from '../common/LoadingSkeleton';
import { UserContext } from '../../state/UserContext';
import { ProjectPageOnboarding } from '../common/onboarding/ProjectPageOnboarding';

const ProjectPage: React.FunctionComponent = () => {
  const userContext = useContext(UserContext);
  const projectContext = useContext(ProjectContext);
  const projectsContext = useContext(ProjectsListContext);
  const tagsContext = useContext(TagsContext);
  const alertsContext = useContext(AlertsContext);
  const pomodoroContext = useContext(PomodoroContext);
  const modalContext = useContext(ModalContext);
  const productivityContext = useContext(ProductivityContext);

  const { projectId } = useParams<{ projectId: string }>();
  const location = useLocation();

  const allPageStyles = useAllPageStyles();

  const [project, setProject] = useState<IProject>();

  useEffect(() => {
    if (projectId && projectContext) {
      projectContext.updateProjectId(projectId);
    } else {
      setProject(undefined);
    }
  }, [projectId]);

  useEffect(() => {
    // TODO: find a way to check if the project in the context is different from the project in the state,
    // and only then update the state
    if (projectContext?.project) {
      setProject(projectContext.project);
    }
  }, [projectContext?.project]);

  useEffect(() => {
    if (projectsContext?.projects && projectId) {
      projectContext?.updateProjectId(projectId);
    }
  }, [projectsContext?.projects]);

  useEffect(() => {
    window.scrollTo(0, 0);
    document?.getElementById('main-scrollable')?.scrollTo(0, 0);
    setCurrentTabId('project-tab-tasks');
  }, [location]);

  const [addTaskModalIsOpen, setAddTaskModalIsOpen] = useState(false);
  const [editTaskModalIsOpen, setEditTaskModalIsOpen] = useState(false);
  const [taskBeingEdited, setTaskBeingEdited] = useState<ITask>();
  const [deleteSessionModalIsOpen, setDeleteSessionModalIsOpen] =
    useState(false);
  const [sessionBeingDeleted, setSessionBeingDeleted] = useState<any>(null);
  const [editDate, setEditDate] = useState(DateTime.now());
  const [editProjectModalIsOpen, setEditProjectModalIsOpen] = useState(false);
  const [addLogModalIsOpen, setAddLogModalIsOpen] = useState(false);
  const [addPhaseModalIsOpen, setAddPhaseModalIsOpen] = useState(false);
  const [editPhaseModalIsOpen, setEditPhaseModalIsOpen] = useState(false);
  const [deletePhaseModalIsOpen, setDeletePhaseModalIsOpen] = useState(false);
  const [phaseBeingEdited, setPhaseBeingEdited] = useState<IPhase | null>(null);
  const [phaseBeingDeleted, setPhaseBeingDeleted] = useState<IPhase | null>(
    null
  );

  const [currentTabId, setCurrentTabId] = useState<string | number>(
    'project-tab-tasks'
  );

  if (
    !userContext ||
    !projectsContext ||
    !tagsContext ||
    !projectContext ||
    !productivityContext ||
    !modalContext ||
    !pomodoroContext ||
    !alertsContext ||
    !projectId
  ) {
    return null;
  }

  const { projects } = projectsContext;

  const {
    projectSummary,
    isFetchingProject,
    addTaskToProject,
    updateTask,
    updateProject,
    addPhase,
  } = projectContext;

  const handleAddTask = async (taskName: string, projectId: string) => {
    await addTaskToProject(taskName, projectId);
  };

  const handleNavbarTabChange = (navbarTabId: TabId) => {
    setCurrentTabId(navbarTabId);
  };

  const updateProjectNote = async (updatedNote: string) => {
    const { updateProject } = projectContext;
    if (project) {
      const projectWithUpdatedNote: IProject = {
        ...project,
        notes: updatedNote,
      };

      try {
        await updateProject(projectWithUpdatedNote);
        alertsContext.addAlert(
          'Successfully updated project notes',
          Intent.SUCCESS
        );
      } catch (err) {
        alertsContext.addAlert('Failed to update project notes', Intent.DANGER);
      }
    }
  };

  const openAddTaskModal = () => {
    setAddTaskModalIsOpen(true);
  };

  const closeAddTaskModal = () => {
    setAddTaskModalIsOpen(false);
  };

  const openEditTaskModal = (task: ITask) => {
    setEditTaskModalIsOpen(true);
    setTaskBeingEdited(task);
  };

  const closeEditTaskModal = () => {
    setEditTaskModalIsOpen(false);
    setTaskBeingEdited(undefined);
  };

  const handleOpenEditSessionModal = session => {
    openEditSessionModal(session);
  };

  const handleCloseEditSessionModal = () => {
    closeEditSessionModal();
  };

  const openDeleteSessionModal = session => {
    setDeleteSessionModalIsOpen(true);
    setSessionBeingDeleted(session);
  };

  const closeDeleteSessionModal = () => {
    setDeleteSessionModalIsOpen(false);
    setSessionBeingDeleted(null);
  };

  const openEditProjectModal = () => {
    setEditProjectModalIsOpen(true);
  };

  const closeEditProjectModal = () => {
    setEditProjectModalIsOpen(false);
  };

  const handleEditDateChange = editDate => {
    setEditDate(editDate);
  };

  const openAddLogModal = () => {
    setAddLogModalIsOpen(true);
  };

  const closeAddLogModal = () => {
    setAddLogModalIsOpen(false);
  };

  const openAddPhaseModal = () => {
    setAddPhaseModalIsOpen(true);
  };

  const closeAddPhaseModal = () => {
    setAddPhaseModalIsOpen(false);
  };

  const openEditPhaseModal = (phase: IPhase) => {
    setPhaseBeingEdited(phase);
    setEditPhaseModalIsOpen(true);
  };

  const closeEditPhaseModal = () => {
    setPhaseBeingEdited(null);
    setEditPhaseModalIsOpen(false);
  };

  const openDeletePhaseModal = (phaseToDelete: IPhase) => {
    setPhaseBeingDeleted(phaseToDelete);
    setDeletePhaseModalIsOpen(true);
  };

  const closeDeletePhaseModal = () => {
    setPhaseBeingDeleted(null);
    setDeletePhaseModalIsOpen(false);
  };

  const {
    openDeleteProjectModal,
    openArchiveProjectModal,
    openAddSessionModal,
    openEditSessionModal,
    closeEditSessionModal,
  } = modalContext;
  const { openPomodoroModal, closePomodoroModal, pomodoroModalIsOpen } =
    pomodoroContext;

  const { trackingPeriod, customTrackingPeriod, labourTypes } = userContext;

  const isProjectLoading = Boolean(
    (isFetchingProject && !project) || (project && project.id !== projectId)
  );

  return (
    <div className={allPageStyles.genericPage}>
      <div>
        <ProjectSummaryBox
          project={project}
          trackingPeriod={trackingPeriod}
          customTrackingPeriodInDays={customTrackingPeriod}
          openEditProjectModal={openEditProjectModal}
          openPomodoroModal={openPomodoroModal}
          openDeleteProjectModal={() => openDeleteProjectModal(project)}
          openArchiveProjectModal={() => openArchiveProjectModal(project)}
          projectSummary={projectSummary}
          isLoading={isProjectLoading}
        />
      </div>
      <div>
        {isProjectLoading ? (
          <>
            <div>
              <LoadingSkeleton type={ComponentType.TaskBoard} />
            </div>
          </>
        ) : (
          <>
            {project && (
              <>
                <Tabs
                  selectedTabId={currentTabId}
                  id="project-tabs"
                  renderActiveTabPanelOnly={true}
                  onChange={handleNavbarTabChange}
                >
                  <Tab
                    id="project-tab-tasks"
                    title="Tasks"
                    style={{ outline: 'none' }}
                    panel={
                      <ProjectTabTasks
                        tasks={project.tasks}
                        project={project}
                        openAddTaskModal={openAddTaskModal}
                        openEditTaskModal={openEditTaskModal}
                        openAddPhaseModal={openAddPhaseModal}
                        openEditPhaseModal={openEditPhaseModal}
                        openDeletePhaseModal={openDeletePhaseModal}
                        openAddSessionModal={openAddSessionModal}
                      />
                    }
                  />
                  <Tab
                    id="project-tab-reporting"
                    title="Reporting"
                    style={{ outline: 'none' }}
                    panel={
                      <ProjectTabReporting
                        project={project}
                        handleEditDateChange={handleEditDateChange}
                        editDate={editDate}
                        openAddSessionModal={openAddSessionModal}
                        openEditSessionModal={handleOpenEditSessionModal}
                        closeEditSessionModal={handleCloseEditSessionModal}
                        openDeleteSessionModal={openDeleteSessionModal}
                        closeDeleteSessionModal={closeDeleteSessionModal}
                      />
                    }
                  />
                  <Tab
                    id="project-tab-notes"
                    title="Notes"
                    style={{ outline: 'none' }}
                    panel={
                      <ProjectTabNotes
                        initialNote={project.notes || ''}
                        updateProjectNote={updateProjectNote}
                      />
                    }
                  />
                  <Tab
                    id="project-tab-log"
                    title="Log"
                    style={{ outline: 'none' }}
                    panel={
                      <ProjectTabLog
                        openAddLogModal={openAddLogModal}
                        project={project}
                      />
                    }
                  />
                </Tabs>
              </>
            )}
          </>
        )}
      </div>
      <ProjectPageOnboarding />
      {project && (
        <>
          <PomodoroModal
            modalIsOpen={pomodoroModalIsOpen}
            onRequestClose={closePomodoroModal}
            project={project}
          />
          <EditProjectModal
            modalIsOpen={editProjectModalIsOpen}
            onRequestClose={closeEditProjectModal}
            project={project}
            updateProject={updateProject}
          />
          <AddLogModalWithinProjectPage
            modalIsOpen={addLogModalIsOpen}
            onRequestClose={closeAddLogModal}
            project={project}
          />
        </>
      )}
      {projects && (
        <EditTaskModal
          modalIsOpen={editTaskModalIsOpen}
          onRequestClose={closeEditTaskModal}
          task={taskBeingEdited!}
          saveTask={updateTask}
          tags={tagsContext.tags ?? []}
          simplifiedProjectList={projects}
        />
      )}
      <AddTaskModal
        modalIsOpen={addTaskModalIsOpen}
        onRequestClose={closeAddTaskModal}
        project={project}
        addStandardTask={handleAddTask}
      />

      <AddPhaseModal
        level="project"
        modalIsOpen={addPhaseModalIsOpen}
        onRequestClose={closeAddPhaseModal}
        projectId={projectId}
        addProjectPhase={addPhase}
      />
      {phaseBeingEdited && project && (
        <EditPhaseModal
          modalIsOpen={editPhaseModalIsOpen}
          onRequestClose={closeEditPhaseModal}
          phase={phaseBeingEdited}
          currentPhases={project.phases}
        />
      )}
      {phaseBeingDeleted && project && (
        <DeletePhaseModal
          modalIsOpen={deletePhaseModalIsOpen}
          onRequestClose={closeDeletePhaseModal}
          phaseToDelete={phaseBeingDeleted}
          currentPhases={project.phases}
          projectId={project.id}
        />
      )}
    </div>
  );
};

export { ProjectPage };

import React, { useContext } from 'react';
import _ from 'lodash';
import {
  Tag,
  Menu,
  MenuItem,
  Popover,
  Button,
  Position,
} from '@blueprintjs/core';

import { ProjectsListContext } from '../../../state/ProjectsListContext';
import { ModalContext } from '../../../state/ModalContext';
import { isEqual } from '../../../func/utils';
import { useLogStyles } from '../../../style/components/logStyles';
import { ILog, LogType } from '../../../common-src/types/Log';
import { DateTime } from 'luxon';
import { TasksContext } from '../../../state/TasksContext';
import { SessionAndUnitContext } from '../../../state/SessionAndUnitContext';

interface Props {
  log: ILog;
}

function getTypeTextFromLogType(logType: LogType): string {
  switch (logType) {
    case LogType.LOG:
      return '';
    case LogType.TASK:
      return 'Completed task';
    case LogType.SESSION:
      return 'Session';
    default:
      return '';
  }
}

function getEditTextFromLogType(logType: LogType): string {
  switch (logType) {
    case LogType.LOG:
      return 'Edit log';
    case LogType.TASK:
      return 'Edit task';
    case LogType.SESSION:
      return 'Edit session';
    default:
      return '';
  }
}

function getDeleteTextFromLogType(logType: LogType): string {
  switch (logType) {
    case LogType.LOG:
      return 'Delete log';
    case LogType.TASK:
      return 'Delete task';
    case LogType.SESSION:
      return 'Delete session';
    default:
      return '';
  }
}

const LogItem: React.FunctionComponent<Props> = (props: Props) => {
  const projectsContext = useContext(ProjectsListContext);
  const tasksContext = useContext(TasksContext);
  const sessionAndUnitContext = useContext(SessionAndUnitContext);
  const modalContext = useContext(ModalContext);
  const logStyles = useLogStyles();

  const { log } = props;
  const { id, note, projectId, date } = log;
  const {
    openEditLogModal,
    openDeleteLogModal,
    openEditSessionModal,
    openDeleteSessionModal,
    openEditTaskModal,
    openDeleteTaskModal,
  } = modalContext!;
  const { projects } = projectsContext!;
  const { tasks } = tasksContext!;
  const { sessions } = sessionAndUnitContext!;

  const readableDate = DateTime.fromISO(date).toLocaleString(
    DateTime.DATETIME_MED_WITH_WEEKDAY
  );

  const project = _.find(projects, project => isEqual(project.id, projectId));
  const typeText = getTypeTextFromLogType(log.type);
  const editText = getEditTextFromLogType(log.type);
  const deleteText = getDeleteTextFromLogType(log.type);

  const openEditModal = () => {
    if (log.type === LogType.SESSION) {
      const session = sessions?.find(session => isEqual(session.id, log.id));
      if (session) {
        openEditSessionModal(session);
      }
    } else if (log.type === LogType.TASK) {
      const task = tasks?.find(task => isEqual(task.id, log.id));
      if (task) {
        openEditTaskModal(task);
      }
    } else {
      openEditLogModal(
        id,
        new Date(date),
        note,
        projectId ? projectId.toString() : ''
      );
    }
  };

  const openDeleteModal = () => {
    if (log.type === LogType.SESSION) {
      const session = sessions?.find(session => isEqual(session.id, log.id));
      if (session) {
        openDeleteSessionModal(session);
      }
    } else if (log.type === LogType.TASK) {
      const task = tasks?.find(task => isEqual(task.id, log.id));
      if (task) {
        openDeleteTaskModal(task);
      }
    } else {
      openDeleteLogModal(
        id,
        new Date(date),
        note,
        projectId ? projectId.toString() : ''
      );
    }
  };

  const dropdown = (
    <Menu>
      <MenuItem icon="cog" text={editText} onClick={openEditModal} />
      <MenuItem icon="trash" text={deleteText} onClick={openDeleteModal} />
    </Menu>
  );

  return (
    <div className={logStyles.individualLog}>
      <div className={logStyles.individualLogText}>
        <p>{note}</p>
        <p>
          <em>{typeText}</em>
        </p>
      </div>
      <div className={logStyles.individualLogMeta}>
        <span>{readableDate}</span>
        {project ? <Tag style={{ marginLeft: 5 }}>{project?.name}</Tag> : null}
        <Popover content={dropdown} position={Position.RIGHT_BOTTOM}>
          <Button icon="more" minimal={true} />
        </Popover>
      </div>
    </div>
  );
};

export { LogItem };

import React, { ReactNode, useState, useContext } from 'react';
import { IProject } from '../common-src/types/Project';
import { notify } from '../func/notifications';
import { IPomodoro, PomodoroMode } from '../common-src/types/Pomodoro';
import { SessionAndUnitContext } from './SessionAndUnitContext';
import { DateTime } from 'luxon';

interface IPomodoroContext {
  currentPomodoro?: IPomodoro;
  pomodoroModalIsOpen: boolean;
  isCompleteSessionModalOpen: boolean;
  startPomodoro: (
    project: IProject,
    workDurationInMilliseconds: number,
    breakDurationInMilliseconds: number
  ) => void;
  endPomodoro: () => void;
  startBreak: () => void;
  endBreak: () => void;
  destroyPomodoro: () => void;
  openPomodoroModal: () => void;
  closePomodoroModal: () => void;
  openCompleteSessionModal: () => void;
  closeCompleteSessionModal: () => void;
}

export const PomodoroContext = React.createContext<IPomodoroContext | null>(
  null
);

interface Props {
  children?: ReactNode;
}

const PomodoroContextProvider: React.FunctionComponent<Props> = (
  props: Props
) => {
  const sessionAndUnitContext = useContext(SessionAndUnitContext);

  const [currentPomodoro, setCurrentPomodoro] = useState<IPomodoro>();
  const [pomodoroModalIsOpen, setPomodoroModalIsOpen] = useState(false);
  const [isCompleteSessionModalOpen, setIsCompleteSessionModalOpen] =
    useState(false);

  const startPomodoro = (
    project: IProject,
    workDurationInMilliseconds: number,
    breakDurationInMilliseconds: number
  ) => {
    setCurrentPomodoro({
      mode: PomodoroMode.WORK,
      project,
      workDurationInMilliseconds,
      breakDurationInMilliseconds,
    });
  };

  const endPomodoro = () => {
    const rightNow = DateTime.now().toISO();
    // @TODO: fix the below!!!!!!!!!!!!!!!
    const workDurationInMinutes = parseInt(
      String(currentPomodoro!.workDurationInMilliseconds / (60 * 1000))
    );
    sessionAndUnitContext?.addSession(
      workDurationInMinutes,
      rightNow,
      currentPomodoro!.project.id
    );

    const updatedPomodoro: IPomodoro = Object.assign({}, currentPomodoro, {
      mode: PomodoroMode.WORK_COMPLETE,
    });
    setCurrentPomodoro(updatedPomodoro);
    openCompleteSessionModal();
    notify('Completed a session!', `Time to put your feet up for a bit.`);
  };

  const startBreak = () => {
    const updatedPomodoro: IPomodoro = Object.assign({}, currentPomodoro, {
      mode: PomodoroMode.BREAK,
    });
    setCurrentPomodoro(updatedPomodoro);
  };

  const endBreak = () => {
    destroyPomodoro();
    setPomodoroModalIsOpen(true);
    notify('Break over!', `Time to get back to work.`);
  };

  const destroyPomodoro = () => {
    setCurrentPomodoro(undefined);
  };

  const openPomodoroModal = () => {
    setPomodoroModalIsOpen(true);
  };

  const closePomodoroModal = () => {
    setPomodoroModalIsOpen(false);
  };

  const openCompleteSessionModal = () => {
    setIsCompleteSessionModalOpen(true);
  };

  const closeCompleteSessionModal = () => {
    setIsCompleteSessionModalOpen(false);
  };

  return (
    <PomodoroContext.Provider
      value={{
        currentPomodoro,
        pomodoroModalIsOpen,
        isCompleteSessionModalOpen,
        startPomodoro,
        endPomodoro,
        startBreak,
        endBreak,
        destroyPomodoro,
        openPomodoroModal,
        closePomodoroModal,
        openCompleteSessionModal,
        closeCompleteSessionModal,
      }}
    >
      {props.children}
    </PomodoroContext.Provider>
  );
};

export { PomodoroContextProvider };
export type { IPomodoroContext };

import React, { useContext } from 'react';
import { getAverageProductivityTooltipForMultipleGoalsInPieChart } from '../../common-src/productivity';
import { ProductivityContext } from '../../state/ProductivityContext';
import { UserContext } from '../../state/UserContext';
import { useGenericStyles } from '../../style/components/genericStyles';
import { WhatNeedsToBeDone } from '../common/WhatNeedsToBeDone';
import { PieChartType, PieChart } from '../common/charts/PieChart';
import { IStatusOfAllGoals } from '../../common-src/types/Reporting';

interface IAllGoalsSummaryProps {
  allGoalsSummaryString: JSX.Element | string;
  statusOfAllGoals: IStatusOfAllGoals;
}

const AllGoalsSummary: React.FunctionComponent<IAllGoalsSummaryProps> = (
  props: IAllGoalsSummaryProps
) => {
  const userContext = useContext(UserContext);
  const productivityContext = useContext(ProductivityContext);

  const { allGoalsSummaryString, statusOfAllGoals } = props;

  const genericStyles = useGenericStyles();

  const { trackingPeriod, customTrackingPeriod } = userContext!;

  if (!statusOfAllGoals) {
    return null;
  }

  return (
    <div
      style={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        flexDirection: 'column',
      }}
      className={genericStyles.genericBox}
    >
      <div>{allGoalsSummaryString}</div>
      <div>
        {trackingPeriod && (
          <PieChart
            type={PieChartType.SINGLE_METRIC}
            tooltip={getAverageProductivityTooltipForMultipleGoalsInPieChart(
              statusOfAllGoals,
              trackingPeriod,
              customTrackingPeriod
            )}
            chartMetricsForPrimaryPie={[
              {
                id: 'overall_goal_score',
                label: 'Overall work',
                value: statusOfAllGoals.score,
              },
              {
                id: 'anti_overall_goal_score',
                label: 'Overall slacking',
                value: 1 - statusOfAllGoals.score,
                colour: 'rgb(255 255 255 / .1)',
              },
            ]}
            trackingPeriod={trackingPeriod}
            customTrackingPeriodInDays={customTrackingPeriod}
            width={300}
            height={200}
            margin={{ top: 20, right: 20, bottom: 20, left: 20 }}
            animate={true}
            centreText={`${Math.round(statusOfAllGoals.score * 100)}%`}
          />
        )}
      </div>
      {trackingPeriod && (
        <div>
          <WhatNeedsToBeDone
            trackingPeriod={trackingPeriod}
            customTrackingPeriodInDays={customTrackingPeriod}
            type="MULTIPLE_GOALS"
            statusOfAllGoals={
              productivityContext?.butlerAssessment?.goalsStatus
            }
          />
        </div>
      )}
    </div>
  );
};

export { AllGoalsSummary };

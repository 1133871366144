import React, { useState, useEffect } from 'react';
import { Button, Intent } from '@blueprintjs/core';
import { MarkdownTextEditor } from '../common/MarkdownTextEditor';
import { useProjectPageStyles } from '../../style/pages/projectPageStyles';

interface Props {
  updateProjectNote: (notes: string) => void;
  initialNote: string;
}

const ProjectTabNotes: React.FunctionComponent<Props> = (props: Props) => {
  const [notes, setNotes] = useState(props.initialNote);

  const projectPageStyles = useProjectPageStyles();

  const handleNotesChange = value => {
    setNotes(value);
  };

  useEffect(() => {
    setNotes(props.initialNote);
  }, [props.initialNote]);

  return (
    <div style={{ minHeight: 400 }}>
      <div className={projectPageStyles.projectNotesTextAreaWrapper}>
        <MarkdownTextEditor
          initialValue={notes}
          onTextChange={handleNotesChange}
          placeholder="Enter your insightful notes here"
        />
      </div>
      <Button
        onClick={() => props.updateProjectNote(notes)}
        icon="arrow-right"
        intent={Intent.PRIMARY}
      >
        Update
      </Button>
    </div>
  );
};

export { ProjectTabNotes };

import { createUseStyles } from 'react-jss';
import { chosenTheme } from '../styleHooks';

export const useStatusBarStyles = createUseStyles({
  statusBar: {
    backgroundColor: chosenTheme.statusBar.statusBarBgColour,
    color: chosenTheme.statusBar.statusBarColour,
    paddingLeft: 10,
    paddingRight: 10,
    height: 30,
    width: '100%',
    position: 'fixed',
    bottom: '0',
    right: '0',
    borderTopWidth: 1,
    borderTopColor: chosenTheme.statusBar.statusBarAccentColour,
    borderTopStyle: 'solid',
  },
  contentWrapper: {
    display: 'flex',
    justifyContent: 'right',
    textAlign: 'right',
  },
  contentWrapperMobile: {
    display: 'flex',
    justifyContent: 'center',
    textAlign: 'center',
  },
  content: {
    marginTop: 5,
  },
  pomodoroClockInWorkMode: {
    backgroundColor: chosenTheme.pomodoroClock.pomodoroClockInWorkModeBgColour,
  },
  pomodoroClockInBreakMode: {
    backgroundColor: chosenTheme.pomodoroClock.pomodoroClockInBreakModeBgColour,
  },
});

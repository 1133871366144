import { createUseStyles } from 'react-jss';
import { chosenTheme } from '../styleHooks';

export const useDashboardStyles = createUseStyles({
  summary: {
    padding: '20px 0 20px 0',
    borderRadius: 5,
    'margin-bottom': 20,
    'background-color': chosenTheme.genericComponentBackgroundColour,
    '-webkit-box-shadow': '6px 22px 39px -14px rgba(0, 0, 0, 0.15)',
    '-moz-box-shadow': '6px 22px 39px -14px rgba(0, 0, 0, 0.15)',
    'box-shadow': '6px 22px 39px -14px rgba(0, 0, 0, 0.15)',
    '& h1, h2, p, span': {
      textAlign: 'left',
    },
  },
});

import React from 'react';

interface IProps {
  text: string;
  backgroundColour: string;
}

const Badge: React.FunctionComponent<IProps> = (props: IProps) => {
  const { text, backgroundColour } = props;
  return (
    <span
      className={`bp4-tag bp4-minimal bp4-round`}
      style={{
        marginRight: 5,
        marginLeft: 5,
        backgroundColor: backgroundColour,
      }}
    >
      <span
        className={`bp4-text-overflow-ellipsis bp4-fill`}
        style={{ color: 'white' }}
      >
        {text}
      </span>
    </span>
  );
};

export { Badge };

import React from 'react';
import { useGenericStyles } from '../../../style/components/genericStyles';
import { IUnitType } from '../../../common-src/types/UnitOfLabour';
import { Button, Menu, MenuItem, Popover, Position } from '@blueprintjs/core';

interface IUnitTypeProps {
  unitType: IUnitType;
  openEditUnitTypeModal: (unitType: IUnitType) => void;
  openDeleteUnitTypeModal: (unitType: IUnitType) => void;
}

const UnitType: React.FunctionComponent<IUnitTypeProps> = (
  props: IUnitTypeProps
) => {
  const { unitType, openEditUnitTypeModal, openDeleteUnitTypeModal } = props;
  const genericStyles = useGenericStyles();

  const dropdown = (
    <Menu>
      <MenuItem
        icon="cog"
        text="Edit unit type"
        onClick={() => openEditUnitTypeModal(unitType)}
      />
      <MenuItem
        icon="trash"
        text="Delete unit type"
        onClick={() => openDeleteUnitTypeModal(unitType)}
      />
    </Menu>
  );

  return (
    <div className={genericStyles.genericBox} style={{ display: 'flex' }}>
      <div style={{ flex: 11 }}>{unitType.name}</div>
      <div style={{ flex: 1 }}>
        <Popover content={dropdown} position={Position.RIGHT_BOTTOM}>
          <Button icon="more" minimal={true} />
        </Popover>
      </div>
    </div>
  );
};

export { UnitType };

import React, { useContext } from 'react';
import { useLayoutStyles } from '../../style/components/layoutStyles';
import { useLandingStyles } from '../../style/pages/landingPageStyles';
import { WindowContext } from '../../state/WindowContext';

interface ILandingSectionProps {
  text: string;
  textAlignment: 'left' | 'right';
  imgSrc?: string;
  imgAlt?: string;
  height?: number;
}

const LandingSection: React.FunctionComponent<ILandingSectionProps> = (
  props: ILandingSectionProps
) => {
  const { text, textAlignment, imgSrc, imgAlt, height } = props;
  const windowContext = useContext(WindowContext);
  const landingStyles = useLandingStyles();
  const layoutStyles = useLayoutStyles();

  return (
    <div
      className={`${landingStyles.landingSection} ${layoutStyles.grid_2_col}`}
    >
      {textAlignment === 'left' ? (
        <>
          <div className={layoutStyles.padded_col}>
            <h3 className={landingStyles.sectionHeader}>{text}</h3>
          </div>
          <div className={layoutStyles.padded_col}>
            {imgSrc ? (
              <img
                src={imgSrc}
                height={
                  windowContext?.currentWidth! > 1085 ? height : undefined
                }
                width={windowContext?.currentWidth! < 1085 ? '100%' : undefined}
                alt={imgAlt}
              />
            ) : null}
          </div>
        </>
      ) : (
        <>
          <div className={layoutStyles.padded_col}>
            {imgSrc ? (
              <img
                src={imgSrc}
                height={
                  windowContext?.currentWidth! > 1085 ? height : undefined
                }
                width={windowContext?.currentWidth! < 1085 ? '100%' : undefined}
                alt={imgAlt}
              />
            ) : null}
          </div>
          <div className={layoutStyles.padded_col}>
            <h3
              className={landingStyles.sectionHeader}
              style={{ textAlign: 'right' }}
            >
              {text}
            </h3>
          </div>
        </>
      )}
    </div>
  );
};

export { LandingSection };

/**
 * Keeps only what is relevant for the project currently being viewed, to be used in the project page.
 */

import React, { ReactNode, useContext, useEffect, useState } from 'react';
import {
  apiCreatePhase,
  apiCreateSession,
  apiCreateTask,
  apiCreateUnitOfLabour,
  apiDeletePhase,
  apiGetProjectById,
  apiGetProductivityForProject,
  apiGetSessionsInProject,
  apiGetUnitsInProject,
  apiUpdatePhase,
  apiUpdateProject,
  apiUpdateProjectPhases,
  apiUpdateTask,
  apiUpdateUnitOfLabour,
} from '../client/apiClient';
import { mapPhaseToPhaseInRequest } from '../client/apiMapper';
import { getStartAndEndDatesForTrackingPeriod } from '../common-src/productivity';
import {
  IApiPhaseInRequest,
  IProject,
  IApiUpdateProjectRequest,
  IApiUpdatePhaseRequest,
  ISimplifiedPhase,
  IApiCreatePhaseRequest,
  PhasesUpdateType,
  IApiUpdatePhasesRequest,
} from '../common-src/types/Project';
import {
  IApiCreateSessionRequest,
  ISession,
} from '../common-src/types/Session';
import {
  IApiCreateTaskRequest,
  ITask,
  IApiUpdateTaskRequest,
} from '../common-src/types/Task';
import {
  IApiCreateUnitOfLabourRequest,
  IUnitOfLabour,
  IApiUpdateUnitOfLabourRequest,
} from '../common-src/types/UnitOfLabour';
import { AuthenticationContext } from './AuthenticationContext';
import { UserContext } from './UserContext';
import { useQuery, useQueryClient } from '@tanstack/react-query';
import { IProjectSummary } from '../common-src/types/Reporting';

interface IProjectContext {
  projectId?: string;
  project?: IProject;
  sessions?: ISession[];
  units?: IUnitOfLabour[];
  updateProjectId: (projectId: string) => void;
  projectSummary?: IProjectSummary;
  isLoading: boolean;
  isFetchingProject: boolean;
  isAddingTask: boolean;
  isFetchingSessions: boolean;
  isAddingSession: boolean;
  isFetchingUnits: boolean;
  isUpdatingTask: boolean;
  isUpdatingMultiplePhases: boolean;
  isUpdatingProject: boolean;
  addTaskToProject: (taskName: string, projectId: string) => Promise<void>;
  addPhase: (projectId: string, phaseName: string) => Promise<void>;
  updatePhase: (phase: ISimplifiedPhase) => Promise<void>;
  updateProjectPhases: (
    projectId: string,
    updatePhaseRequest: IApiUpdatePhasesRequest
  ) => Promise<void>;
  deletePhase: (phaseToDeleteId: string) => Promise<void>;
  updateTask: (task: ITask) => Promise<void>;
  addSessionToProject: (
    projectId: string,
    workDurationMinutes: number,
    timeOfCompletion: string,
    goalId?: string,
    taskId?: string,
    labourTypeId?: string,
    notes?: string
  ) => Promise<void>;
  createUnitOutputAssociatedWithDay: (
    createUnitRequest: IApiCreateUnitOfLabourRequest
  ) => Promise<void>;
  updateOrCreateUnitOutputAssociatedWithDay: (
    unitId: string,
    updateUnitRequest?: IApiUpdateUnitOfLabourRequest
  ) => Promise<void>;
  updateProject: (project: IProject) => Promise<void>;
  archiveProject: (project: IProject) => Promise<void>;
}

export const ProjectContext = React.createContext<IProjectContext | null>(null);

interface IProps {
  children: ReactNode;
}

const ProjectContextProvider: React.FunctionComponent<IProps> = (
  props: IProps
) => {
  const authenticationContext = useContext(AuthenticationContext);
  const userContext = useContext(UserContext);

  const queryClient = useQueryClient();

  const [projectId, setProjectId] = useState<string>('');
  const [isAddingTask, setIsAddingTask] = useState(false);
  const [isAddingSession, setIsAddingSession] = useState(false);
  const [isUpdatingTask, setIsUpdatingTask] = useState(false);
  const [isAddingPhase, setIsAddingPhase] = useState(false);
  const [isUpdatingPhase, setIsUpdatingPhase] = useState(false);
  const [isDeletingPhase, setIsDeletingPhase] = useState(false);
  const [isUpdatingMultiplePhases, setIsUpdatingMultiplePhases] =
    useState(false);
  const [isUpdatingProject, setIsUpdatingProject] = useState(false);
  const [isArchivingProject, setIsArchivingProject] = useState(false);

  const isLoading =
    isAddingTask ||
    isAddingSession ||
    isUpdatingTask ||
    isUpdatingMultiplePhases;

  const { customTrackingPeriod, trackingPeriod } = userContext!;

  const updateProjectId = async (projectId: string) => {
    setProjectId(projectId);
  };

  useEffect(() => {
    if (projectId) {
      queryClient.invalidateQueries({
        queryKey: ['getProjectById', projectId],
      });
      queryClient.invalidateQueries({
        queryKey: ['getSessionsInProject', projectId],
      });
      queryClient.invalidateQueries({
        queryKey: ['getUnitsInProject', projectId],
      });
      queryClient.invalidateQueries({
        queryKey: ['getRawProductivityForProject', projectId],
      });
    }
  }, [trackingPeriod, customTrackingPeriod]);

  const {
    isLoading: isFetchingProject,
    isError: isFetchProjectError,
    data: project,
    error: fetchProjectError,
  } = useQuery({
    queryKey: ['getProjectById', projectId],
    queryFn: async () => {
      if (projectId) {
        try {
          if (
            authenticationContext &&
            authenticationContext.isUserAuthenticated
          ) {
            const accessToken = await authenticationContext.getToken();
            const project = await apiGetProjectById(projectId, accessToken);
            return project;
          }
        } catch (err) {
          throw err;
        }
      }
    },
    enabled: !!projectId,
  });

  const {
    isLoading: isFetchingSessions,
    isError: isFetchSessionsError,
    data: sessions,
    error: fetchSessionsError,
  } = useQuery({
    queryKey: ['getSessionsInProject', projectId],
    queryFn: async () => {
      if (projectId) {
        try {
          if (
            authenticationContext &&
            authenticationContext.isUserAuthenticated
          ) {
            const accessToken = await authenticationContext.getToken();
            const sessions = await apiGetSessionsInProject(
              projectId,
              accessToken
            );
            return sessions;
          }
        } catch (err) {
          throw err;
        }
      }
    },
    enabled: !!projectId,
  });

  const {
    isLoading: isFetchingUnits,
    isError: isFetchUnitsError,
    data: units,
    error: fetchUnitsError,
  } = useQuery({
    queryKey: ['getUnitsInProject', projectId],
    queryFn: async () => {
      if (projectId) {
        try {
          if (
            authenticationContext &&
            authenticationContext.isUserAuthenticated
          ) {
            const accessToken = await authenticationContext.getToken();
            const units = await apiGetUnitsInProject(accessToken, projectId);
            queryClient.invalidateQueries({
              queryKey: ['getProjectById', projectId],
            });
            return units;
          }
        } catch (err) {
          throw err;
        }
      }
    },
    enabled: !!projectId,
  });

  const {
    isLoading: isFetchingProjectProductivity,
    isError: isFetchProductivityError,
    data: projectSummary,
    error: fetchProductivityError,
  } = useQuery({
    queryKey: ['apiGetRawProductivityForProjectGoals', projectId],
    queryFn: async () => {
      if (projectId) {
        try {
          if (
            authenticationContext &&
            authenticationContext.isUserAuthenticated &&
            trackingPeriod
          ) {
            const accessToken = await authenticationContext.getToken();
            const startAndEnd = getStartAndEndDatesForTrackingPeriod(
              trackingPeriod,
              customTrackingPeriod
            );
            const rawProd = await apiGetProductivityForProject(
              accessToken,
              projectId,
              startAndEnd[0].toISO(),
              startAndEnd[1].toISO()
            );
            return rawProd;
          }
        } catch (err) {
          throw err;
        }
      }
    },
    enabled:
      !!projectId &&
      !!authenticationContext?.isUserAuthenticated &&
      !!trackingPeriod,
  });

  const addTaskToProject = async (taskName: string, projectId: string) => {
    setIsAddingTask(true);
    try {
      if (authenticationContext && authenticationContext.isUserAuthenticated) {
        const accessToken = await authenticationContext.getToken();
        const createTaskRequest: IApiCreateTaskRequest = {
          name: taskName,
          project_id: projectId,
        };
        const newTask = await apiCreateTask(createTaskRequest, accessToken);
        queryClient.invalidateQueries({
          queryKey: ['getProjectById', projectId],
        });
        queryClient.invalidateQueries({
          queryKey: ['getButlerAssessment'],
        });
      }
    } catch (err) {
      throw err;
    } finally {
      setIsAddingTask(false);
    }
  };

  const updateTask = async (task: ITask) => {
    setIsUpdatingTask(true);
    try {
      if (
        authenticationContext &&
        authenticationContext.isUserAuthenticated &&
        project
      ) {
        const accessToken = await authenticationContext.getToken();
        const updateTaskRequest: IApiUpdateTaskRequest = {
          name: task.name,
          project_id: task.projectId,
          deleted: task.deleted,

          // completion
          done: task.done,
          completion_datetime: task.completionDateTime,

          // dates and reminders
          due_date: task.dueDate,
          start_date: task.startDate,
          reminder_time: task.reminderTime,

          // notes
          notes: task.notes,

          // checklists and subtasks
          is_tracking_units: task.isTrackingUnits,
          subtasks: task.subtasks,
          unit_name: task.unitName || undefined,
          unit_target: task.unitTarget || undefined,
          units_completed: task.unitsCompleted || undefined,

          // tags
          tag_ids: task.tagIds,

          // estimates
          estimated_minutes: task.estimatedMinutes,
        };
        const updatedTask = await apiUpdateTask(
          task.id,
          updateTaskRequest,
          accessToken
        );
        queryClient.invalidateQueries({
          queryKey: ['getProjectById', projectId],
        });
        queryClient.invalidateQueries({
          queryKey: ['getButlerAssessment'],
        });
      }
    } catch (err) {
      throw err;
    } finally {
      setIsUpdatingTask(false);
    }
  };

  const addPhase = async (projectId: string, phaseName: string) => {
    setIsAddingPhase(true);
    try {
      if (authenticationContext && authenticationContext.isUserAuthenticated) {
        const accessToken = await authenticationContext.getToken();
        const createPhaseRequest: IApiCreatePhaseRequest = {
          name: phaseName,
          is_user_level: false,
          project_id: projectId,
        };
        const newPhase = await apiCreatePhase(createPhaseRequest, accessToken);
        queryClient.invalidateQueries({ queryKey: ['getProjects'] });
        queryClient.invalidateQueries({
          queryKey: ['getProjectById', projectId],
        });
        queryClient.invalidateQueries({
          queryKey: ['getButlerAssessment'],
        });
      }
    } catch (err) {
      throw err;
    } finally {
      setIsAddingPhase(false);
    }
  };

  const updatePhase = async (phase: ISimplifiedPhase) => {
    setIsUpdatingPhase(true);
    try {
      if (authenticationContext && authenticationContext.isUserAuthenticated) {
        const accessToken = await authenticationContext.getToken();
        const updatePhaseRequest: IApiUpdatePhaseRequest = {
          name: phase.name,
          id: phase.id,
        };
        const updatedPhase = await apiUpdatePhase(
          phase.id,
          updatePhaseRequest,
          accessToken
        );
        queryClient.invalidateQueries({
          queryKey: ['getProjectById', projectId],
        });
        queryClient.invalidateQueries({
          queryKey: ['getButlerAssessment'],
        });
      }
    } catch (err) {
      throw err;
    } finally {
      setIsUpdatingPhase(false);
    }
  };

  const updateProjectPhases = async (
    projectId: string,
    updatePhaseRequest: IApiUpdatePhasesRequest
  ) => {
    setIsUpdatingMultiplePhases(true);
    try {
      if (authenticationContext && authenticationContext.isUserAuthenticated) {
        const accessToken = await authenticationContext.getToken();
        const updatedPhases = await apiUpdateProjectPhases(
          accessToken,
          projectId,
          updatePhaseRequest
        );
        queryClient.invalidateQueries({
          queryKey: ['getProjectById', projectId],
        });
        queryClient.invalidateQueries({
          queryKey: ['getButlerAssessment'],
        });
      }
    } catch (err) {
      throw err;
    } finally {
      setIsUpdatingMultiplePhases(false);
    }
  };

  const deletePhase = async (phaseToDeleteId: string) => {
    setIsDeletingPhase(true);
    try {
      if (authenticationContext && authenticationContext.isUserAuthenticated) {
        const accessToken = await authenticationContext.getToken();
        await apiDeletePhase(phaseToDeleteId, accessToken);
        queryClient.invalidateQueries({ queryKey: ['getProjects'] });
        queryClient.invalidateQueries({
          queryKey: ['getProjectById', projectId],
        });
        queryClient.invalidateQueries({
          queryKey: ['getButlerAssessment'],
        });
      }
    } catch (err) {
      throw err;
    } finally {
      setIsDeletingPhase(false);
    }
  };

  const addSessionToProject = async (
    projectId: string,
    workDurationMinutes: number,
    timeOfCompletion: string,
    goalId?: string,
    taskId?: string,
    labourTypeId?: string,
    notes?: string
  ) => {
    setIsAddingSession(true);
    try {
      if (authenticationContext && authenticationContext.isUserAuthenticated) {
        const accessToken = await authenticationContext.getToken();
        const createSessionRequest: IApiCreateSessionRequest = {
          date: timeOfCompletion,
          project_id: projectId,
          goal_id: goalId,
          deleted: false,
          minutes: workDurationMinutes,
          notes: notes,
          task_id: taskId,
          labour_type_id: labourTypeId,
        };
        const newSession = await apiCreateSession(
          accessToken,
          createSessionRequest
        );
        queryClient.invalidateQueries({
          queryKey: ['getProjectById', projectId],
        });
        queryClient.invalidateQueries({
          queryKey: ['getSessionsInProject', projectId],
        });
        queryClient.invalidateQueries({
          queryKey: ['getRawProductivityForProject', projectId],
        });
        queryClient.invalidateQueries({
          queryKey: ['getButlerAssessment'],
        });
      }
    } catch (err) {
      throw err;
    } finally {
      setIsAddingSession(false);
    }
  };

  const createUnitOutputAssociatedWithDay = async (
    createUnitRequest: IApiCreateUnitOfLabourRequest
  ) => {
    try {
      if (
        authenticationContext &&
        authenticationContext.isUserAuthenticated &&
        createUnitRequest?.project_id
      ) {
        const accessToken = await authenticationContext.getToken();
        const newUnit = await apiCreateUnitOfLabour(
          accessToken,
          createUnitRequest
        );
        queryClient.invalidateQueries({
          queryKey: ['getProjectById', projectId],
        });
        queryClient.invalidateQueries({
          queryKey: ['getUnitsInProject', projectId],
        });
        queryClient.invalidateQueries({
          queryKey: ['getRawProductivityForProject', projectId],
        });
        queryClient.invalidateQueries({
          queryKey: ['getButlerAssessment'],
        });
      }
    } catch (err) {
      throw err;
    }
  };

  const updateOrCreateUnitOutputAssociatedWithDay = async (
    unitId: string,
    updateUnitRequest?: IApiUpdateUnitOfLabourRequest
  ) => {
    try {
      if (
        unitId &&
        updateUnitRequest &&
        updateUnitRequest.project_id &&
        authenticationContext &&
        authenticationContext.isUserAuthenticated
      ) {
        const accessToken = await authenticationContext.getToken();
        const updatedUnit = await apiUpdateUnitOfLabour(
          accessToken,
          unitId,
          updateUnitRequest
        );
        queryClient.invalidateQueries({
          queryKey: ['getProjectById', projectId],
        });
        queryClient.invalidateQueries({
          queryKey: ['getUnitsInProject', projectId],
        });
        queryClient.invalidateQueries({
          queryKey: ['getRawProductivityForProject', projectId],
        });
        queryClient.invalidateQueries({
          queryKey: ['getButlerAssessment'],
        });
      }
    } catch (err) {
      throw err;
    }
  };

  const updateProject = async (project: IProject) => {
    setIsUpdatingProject(true);
    try {
      if (authenticationContext && authenticationContext.isUserAuthenticated) {
        const accessToken = await authenticationContext.getToken();
        const updateProjectRequest: IApiUpdateProjectRequest = {
          name: project.name,
          notes: project.notes,
          deleted: project.deleted,
          phases: project.phases.map(mapPhaseToPhaseInRequest),
          standard_session_break: project.standardPomodoroBreak,
          standard_session_time: project.standardPomodoroTime,
          unit_type_name: project.unitName,
          units_per_day: project.customTarget,
          minutes_per_day: project.targetMinutes,
          is_tracking_units: project.trackUnits,
          is_tracking_sessions: project.trackTime,
          start_date: project.startDate?.toISO(),
          end_date: project.endDate?.toISO(),
        };
        const updatedProject = await apiUpdateProject(
          project.id,
          updateProjectRequest,
          accessToken
        );
        queryClient.invalidateQueries({
          queryKey: ['getProjectById', projectId],
        });
        queryClient.invalidateQueries({
          queryKey: ['getSessionsInProject', projectId],
        });
        queryClient.invalidateQueries({
          queryKey: ['getButlerAssessment'],
        });
      }
    } catch (err) {
      throw err;
    } finally {
      setIsUpdatingProject(false);
    }
  };

  const archiveProject = async (project: IProject) => {
    setIsArchivingProject(true);
    try {
      if (authenticationContext && authenticationContext.isUserAuthenticated) {
        const accessToken = await authenticationContext.getToken();

        const isArchiving = project.archived === false;
        await apiUpdateProject(
          project.id,
          {
            archived: isArchiving,
            name: project.name,
            notes: project.notes,
            deleted: project.deleted,
            standard_session_break: project.standardPomodoroBreak,
            standard_session_time: project.standardPomodoroTime,
          },
          accessToken
        );
        queryClient.invalidateQueries({ queryKey: ['getProjects'] });
        queryClient.invalidateQueries({
          queryKey: ['getProjectById', projectId],
        });
        queryClient.invalidateQueries({
          queryKey: ['getTasks'],
        });
        queryClient.invalidateQueries({
          queryKey: ['getButlerAssessment'],
        });
      }
    } catch (err) {
      throw err;
    } finally {
      setIsArchivingProject(false);
    }
  };

  return (
    <ProjectContext.Provider
      value={{
        project,
        projectId,
        updateProjectId,
        isLoading,
        projectSummary,
        isFetchingProject,
        isAddingTask,
        isUpdatingProject,
        addTaskToProject,
        updateTask,
        addPhase,
        updatePhase,
        updateProjectPhases,
        deletePhase,
        addSessionToProject,
        sessions,
        isFetchingSessions,
        isAddingSession,
        isFetchingUnits,
        isUpdatingTask,
        isUpdatingMultiplePhases,
        units,
        createUnitOutputAssociatedWithDay,
        updateOrCreateUnitOutputAssociatedWithDay,
        updateProject,
        archiveProject,
      }}
    >
      {props.children}
    </ProjectContext.Provider>
  );
};

export { ProjectContextProvider };
export type { IProjectContext };

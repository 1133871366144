import React, { useState, useContext, useEffect } from 'react';
import { IPomodoro, PomodoroMode } from '../../common-src/types/Pomodoro';
import Timer from 'react-compound-timer';
import { Button, Position, Menu, MenuItem } from '@blueprintjs/core';
import { Popover2 } from '@blueprintjs/popover2';
import { PomodoroContext } from '../../state/PomodoroContext';
import { UserContext } from '../../state/UserContext';
import { useStatusBarStyles } from '../../style/components/statusBarStyles';
import {
  TaskViewPreference,
  TrackingPeriod,
} from '../../common-src/types/User';
import { WindowContext } from '../../state/WindowContext';

const getTrackingString = (
  trackingPeriod: TrackingPeriod,
  customTrackingPeriodInDays?: number
): string => {
  let trackingString = '';
  switch (trackingPeriod) {
    case TrackingPeriod.CUSTOM:
      if (customTrackingPeriodInDays) {
        trackingString = `last ${customTrackingPeriodInDays} days`;
      } else {
        trackingString = '';
      }
      break;
    case TrackingPeriod.TODAY:
      trackingString = 'today';
      break;
    case TrackingPeriod.LAST_7_DAYS:
      trackingString = 'last 7 days';
      break;
    case TrackingPeriod.THIS_WEEK:
      trackingString = 'this week';
      break;
    case TrackingPeriod.THIS_MONTH:
      trackingString = 'this month';
      break;
    case TrackingPeriod.THIS_QUARTER:
      trackingString = 'this quarter';
      break;
    case TrackingPeriod.THIS_YEAR:
      trackingString = 'this year';
      break;
    case TrackingPeriod.ALL_TIME:
      trackingString = 'all time';
      break;
    default:
      trackingString = '';
      break;
  }

  return trackingString;
};

const StatusBar: React.FunctionComponent = () => {
  const userContext = useContext(UserContext);
  const pomodoroContext = useContext(PomodoroContext);
  const windowContext = useContext(WindowContext);
  const statusBarStyles = useStatusBarStyles();

  const {
    todoPreference,
    updateTodoPreference,
    updateShowCompletedTasks,
    showCompletedTasks,
    trackingPeriod,
    customTrackingPeriod,
    isTrackingPeriodCustom,
    updateTrackingPeriod,
  } = userContext!;

  const [currentMode, setCurrentMode] = useState<'default' | 'pomodoro'>(
    'default'
  );
  const [isRunning, setIsRunning] = useState(false);
  const [pomodoro, setPomodoro] = useState<IPomodoro | null>(null);

  useEffect(() => {
    const { currentPomodoro } = pomodoroContext!;
    if (currentPomodoro) {
      setPomodoro(currentPomodoro);
      setCurrentMode('pomodoro');
      setIsRunning(true);
    } else {
      setPomodoro(null);
      setCurrentMode('default');
    }
  }, [pomodoroContext?.currentPomodoro]);

  const handlePause = () => {
    setIsRunning(false);
  };

  const handleResume = () => {
    setIsRunning(true);
  };

  const handleEndSessionOrBreak = () => {
    const { endPomodoro, endBreak } = pomodoroContext!;
    if (pomodoro?.mode === PomodoroMode.WORK) {
      endPomodoro();
    } else {
      endBreak();
    }
  };

  const taskViewSettingsMenu = (
    <Menu>
      <MenuItem
        text="Board"
        icon={userContext?.todoPreference === 'board' ? 'tick' : undefined}
        onClick={() =>
          userContext?.updateTodoPreference(TaskViewPreference.BOARD)
        }
      />
      <MenuItem
        text="To-do list"
        icon={userContext?.todoPreference === 'todo' ? 'tick' : undefined}
        onClick={() =>
          userContext?.updateTodoPreference(TaskViewPreference.TODO)
        }
      />
    </Menu>
  );

  const trackingString = trackingPeriod
    ? getTrackingString(trackingPeriod, customTrackingPeriod)
    : '';

  const trackingPeriodSettingsMenu = (
    <Menu>
      <MenuItem
        text="Today"
        icon={trackingPeriod === TrackingPeriod.TODAY ? 'tick' : undefined}
        onClick={() => updateTrackingPeriod(TrackingPeriod.TODAY, false)}
      />
      <MenuItem
        text="Last 7 days"
        icon={
          trackingPeriod === TrackingPeriod.LAST_7_DAYS ? 'tick' : undefined
        }
        onClick={() => updateTrackingPeriod(TrackingPeriod.LAST_7_DAYS, false)}
      />
      <MenuItem
        text="This week"
        icon={trackingPeriod === TrackingPeriod.THIS_WEEK ? 'tick' : undefined}
        onClick={() => updateTrackingPeriod(TrackingPeriod.THIS_WEEK, false)}
      />
      <MenuItem
        text="This month"
        icon={trackingPeriod === TrackingPeriod.THIS_MONTH ? 'tick' : undefined}
        onClick={() => updateTrackingPeriod(TrackingPeriod.THIS_MONTH, false)}
      />
      <MenuItem
        text="This quarter"
        icon={
          trackingPeriod === TrackingPeriod.THIS_QUARTER ? 'tick' : undefined
        }
        onClick={() => updateTrackingPeriod(TrackingPeriod.THIS_QUARTER, false)}
      />
      <MenuItem
        text="This year"
        icon={trackingPeriod === TrackingPeriod.THIS_YEAR ? 'tick' : undefined}
        onClick={() => updateTrackingPeriod(TrackingPeriod.THIS_YEAR, false)}
      />
      <MenuItem
        text="All time"
        icon={trackingPeriod === TrackingPeriod.ALL_TIME ? 'tick' : undefined}
        onClick={() => updateTrackingPeriod(TrackingPeriod.ALL_TIME, false)}
      />
      <MenuItem
        text={`Custom (set to ${customTrackingPeriod} days)`} // TODO: should show the actual number of days, and allow you to change it
        icon={isTrackingPeriodCustom ? 'tick' : undefined}
        onClick={() =>
          updateTrackingPeriod(
            TrackingPeriod.CUSTOM,
            true,
            customTrackingPeriod
          )
        }
      />
    </Menu>
  );

  const pomodoroSection = () => {
    if (pomodoro) {
      let pomodoroMessage = '';
      if (windowContext?.isMobile) {
        pomodoroMessage = '';
      } else if (pomodoro.mode === PomodoroMode.WORK) {
        pomodoroMessage = `Currently doing a session for project "${pomodoro.project.name}". 🧐`;
      } else {
        pomodoroMessage = `Chilling out for project "${pomodoro.project.name}". 😜`;
      }
      const startTime =
        pomodoro.mode === PomodoroMode.WORK
          ? pomodoro.workDurationInMilliseconds
          : pomodoro.breakDurationInMilliseconds;
      const icon = isRunning ? 'pause' : 'play';
      const startImmediately = isRunning;
      return (
        <Timer
          initialTime={startTime}
          startImmediately={startImmediately}
          onPause={handlePause}
          onResume={handleResume}
          direction="backward"
          checkpoints={[{ time: 0, callback: () => handleEndSessionOrBreak() }]}
          formatValue={(value: number) => value.toString().padStart(2, '0')}
        >
          {({ start, resume, pause, stop, reset, timerState }) => (
            <div>
              <div
                style={{
                  display: 'inline-block',
                  width: 70,
                }}
              >
                <Timer.Minutes /> : <Timer.Seconds />
              </div>
              <span>
                <Button
                  onClick={() => {
                    if (isRunning) {
                      pause();
                    } else {
                      resume();
                    }
                  }}
                  icon={icon}
                  style={{
                    backgroundColor: 'rgba(255, 255, 255, 0)',
                    borderRadius: 0,
                    boxShadow: 'none',
                  }}
                />
              </span>
              <span>{pomodoroMessage}</span>
            </div>
          )}
        </Timer>
      );
    } else {
      return null;
    }
  };

  if (!windowContext) {
    return null;
  }

  const statusBarClass = statusBarStyles.statusBar;
  const contentWrapperClass = windowContext.isMobile
    ? statusBarStyles.contentWrapperMobile
    : statusBarStyles.contentWrapper;

  let pomodoroClass = '';
  if (pomodoro) {
    if (pomodoro.mode === PomodoroMode.WORK) {
      pomodoroClass = statusBarStyles.pomodoroClockInWorkMode;
    } else if (pomodoro.mode === PomodoroMode.BREAK) {
      pomodoroClass = statusBarStyles.pomodoroClockInBreakMode;
    }
  }

  const shouldShowTrackingPeriod =
    userContext?.trackingPeriod &&
    ((windowContext?.isMobile && currentMode !== 'pomodoro') ||
      !windowContext?.isMobile);

  return (
    <div
      className={`${statusBarClass} ${pomodoroClass ? pomodoroClass : ''}`}
      id="gp-status-bar"
    >
      <div className={contentWrapperClass}>
        <div className={statusBarStyles.content}>
          {shouldShowTrackingPeriod ? (
            <Popover2
              autoFocus={false}
              content={trackingPeriodSettingsMenu}
              position={Position.BOTTOM_RIGHT}
            >
              <span style={{ cursor: 'pointer' }} id="tracking-period-button">
                Tracking period: {trackingString}
              </span>
            </Popover2>
          ) : null}
        </div>
        {currentMode === 'pomodoro' && pomodoro?.mode === PomodoroMode.WORK ? (
          <div>{pomodoroSection()}</div>
        ) : null}
        {currentMode === 'pomodoro' && pomodoro?.mode === PomodoroMode.BREAK ? (
          <div>{pomodoroSection()}</div>
        ) : null}
      </div>
    </div>
  );
};

export { StatusBar };

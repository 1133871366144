import React from 'react';
import _ from 'lodash';
import { Button } from '@blueprintjs/core';
import { DateTime } from 'luxon';
import { SessionListElement } from './SessionListElement';
import { ISession } from '../../common-src/types/Session';
import { useGenericStyles } from '../../style/components/genericStyles';
import { IOpenAddSessionModalOpts } from '../../common-src/types/UiTypes';

interface Props {
  title?: string;
  openEditSessionModal: (session: ISession) => void;
  openAddSessionModal: (opts?: IOpenAddSessionModalOpts) => void;
  closeEditSessionModal: () => void;
  openDeleteSessionModal: (session: ISession) => void;
  closeDeleteSessionModal: () => void;
  date: DateTime;
  sessions: ISession[];
}

const SessionList: React.FunctionComponent<Props> = (props: Props) => {
  const genericStyles = useGenericStyles();

  const {
    title,
    openEditSessionModal,
    openAddSessionModal,
    openDeleteSessionModal,
    date,
    sessions,
  } = props;

  const handleOpenAddSessionModal = () => {
    openAddSessionModal();
  };

  return (
    <div className={genericStyles.genericBox}>
      <h3>{title ?? 'Sessions'}</h3>
      <Button icon="plus" intent="primary" onClick={handleOpenAddSessionModal}>
        Add session
      </Button>
      <p style={{ marginTop: 10 }}>Sessions completed on this day:</p>
      {sessions?.map(sesh => (
        <SessionListElement
          session={sesh}
          openEditSessionModal={openEditSessionModal}
          openDeleteSessionModal={openDeleteSessionModal}
          key={`session-${sesh.id}`}
        />
      ))}
    </div>
  );
};

export { SessionList };

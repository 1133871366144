interface IInspirationalQuote {
  words: string;
  who: string;
}

export const generateInspirationalQuote = () => {
  const inspirationalQuotes: IInspirationalQuote[] = [
    {
      words:
        '"Concentration, see. I suspect that\'s the key with you hugely successful types."',
      who: 'Al Swearengen, Deadwood',
    },
    {
      words: '"Life. Sometimes it seems like one vile task after another."',
      who: 'Al Swearengen, Deadwood',
    },
    {
      words: '"Make haste slowly."',
      who: 'Emperor Augustus',
    },
    {
      words: '"Look on my works, ye mighty, and despair."',
      who: "Shelley's Ozymandias",
    },
    {
      words: '"Make time, consigliere."',
      who: 'The Godfather',
    },
    {
      words: '"Just do it."',
      who: 'Nike',
    },
  ];
  const randomNumber = Math.floor(Math.random() * inspirationalQuotes.length);
  return inspirationalQuotes[randomNumber];
};

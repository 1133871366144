import React, { useEffect, useContext, useState } from 'react';
import _ from 'lodash';
import { Button, Menu, MenuItem, Position } from '@blueprintjs/core';
import { Popover2 } from '@blueprintjs/popover2';
import { TagsContext } from '../../state/TagsContext';
import { AddTagModal } from '../modals/AddTagModal';
import { EditTagModal } from '../modals/EditTagModal';
import { ColouredTag } from '../common/tags/Tag';
import { ITag } from '../../common-src/types/Tag';
import { useLocation } from 'react-router-dom';
import { useAllPageStyles } from '../../style/components/pageStyles';
import { ModalContext } from '../../state/ModalContext';
import { DeleteTagModal } from '../modals/DeleteTagModal';

const Tags: React.FunctionComponent = () => {
  const tagsContext = useContext(TagsContext);
  const modalContext = useContext(ModalContext);
  const allPageStyles = useAllPageStyles();
  const [addTagModalIsOpen, setAddTagModalIsOpen] = useState(false);
  const [editTagModalIsOpen, setEditTagModalIsOpen] = useState(false);
  const [tagBeingEdited, setTagBeingEdited] = useState<ITag | undefined>(
    undefined
  );

  const { tagToDelete, deleteTagModalIsOpen, closeDeleteTagModal } =
    modalContext!;
  const { deleteTag } = tagsContext!;

  const location = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
    document?.getElementById('main-scrollable')?.scrollTo(0, 0);
  }, [location]);

  const getTags = (): ITag[] => {
    const { tags } = tagsContext!;
    const tagsArr = _.values(tags).sort((a, b) => a.name.localeCompare(b.name));
    return tagsArr || [];
  };

  const openAddTagModal = () => {
    setAddTagModalIsOpen(true);
  };

  const closeAddTagModal = () => {
    setAddTagModalIsOpen(false);
  };

  const openEditTagModal = (tag: ITag) => {
    setTagBeingEdited(tag);
    setEditTagModalIsOpen(true);
  };

  const closeEditTagModal = () => {
    setTagBeingEdited(undefined);
    setEditTagModalIsOpen(false);
  };

  const handleClickDeleteTag = (tag: ITag) => {
    const { openDeleteTagModal } = modalContext!;
    openDeleteTagModal(tag);
  };

  const dropdown = (tag: ITag) => (
    <Menu>
      <MenuItem
        icon="cog"
        text="Edit tag"
        onClick={() => openEditTagModal(tag)}
      />
      <MenuItem
        icon="trash"
        text="Delete tag"
        onClick={() => handleClickDeleteTag(tag)}
      />
    </Menu>
  );

  const tags = getTags();

  return (
    <div className={allPageStyles.genericPage}>
      <h1>Tags</h1>
      <AddTagModal
        modalIsOpen={addTagModalIsOpen}
        onRequestClose={closeAddTagModal}
      />
      {tagBeingEdited && (
        <EditTagModal
          tag={tagBeingEdited!}
          modalIsOpen={editTagModalIsOpen}
          onRequestClose={closeEditTagModal}
        />
      )}
      {tagToDelete && (
        <DeleteTagModal
          modalIsOpen={deleteTagModalIsOpen}
          onRequestClose={closeDeleteTagModal}
          deleteTag={deleteTag}
        />
      )}

      <div>
        <Button
          icon="plus"
          onClick={openAddTagModal}
          style={{ marginBottom: 10 }}
        >
          Add tag
        </Button>
      </div>
      <div>
        {tags &&
          tags.map(tag => {
            const tagDropdown = dropdown(tag);
            return (
              <div style={{ marginTop: 10 }} key={tag.id}>
                <Popover2
                  content={tagDropdown}
                  position={Position.RIGHT_BOTTOM}
                >
                  <ColouredTag
                    tagName={tag.name}
                    tagColour={tag.colour || 'grey'}
                  />
                </Popover2>
              </div>
            );
          })}
      </div>
    </div>
  );
};

export { Tags };

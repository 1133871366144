import React, { ReactNode } from 'react';

interface ITempMobileMenuContext {
  isMobileMenuOpen: boolean;
  toggleMobileMenu: () => void;
}

export const TempMobileMenuContext =
  React.createContext<ITempMobileMenuContext | null>(null);

interface Props {
  children: ReactNode;
}

interface State {
  isMobileMenuOpen: boolean;
}

class TempMobileMenuContextProvider extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      isMobileMenuOpen: false,
    };
  }

  toggleMobileMenu = () => {
    const currentlyOpen = this.state.isMobileMenuOpen;
    this.setState({ isMobileMenuOpen: !currentlyOpen });
  };

  render() {
    return (
      <TempMobileMenuContext.Provider
        value={{
          isMobileMenuOpen: this.state.isMobileMenuOpen,
          toggleMobileMenu: this.toggleMobileMenu,
        }}
      >
        {this.props.children}
      </TempMobileMenuContext.Provider>
    );
  }
}

export { TempMobileMenuContextProvider };
export type { ITempMobileMenuContext };

import React, { ReactNode, useState, useEffect, useContext } from 'react';
import { IApiUpdateUserRequest } from '../common-src/types/User';
import { AuthenticationContext } from './AuthenticationContext';
import { TaskViewPreference, TrackingPeriod } from '../common-src/types/User';
import {
  IApiCreatePhaseRequest,
  IPhase,
  IApiUpdatePhaseRequest,
} from '../common-src/types/Project';
import {
  apiAddLabourType,
  apiCreatePhase,
  apiDeletePhase,
  apiGetCurrentUser,
  apiGetLabourTypes,
  apiGetPhases,
  apiGetTodoList,
  apiGetUnitTypes,
  apiUpdatePhase,
  apiUpdateTodoList,
  apiUpdateUserPhases,
  apiUpdateUser,
  apiCreateUnitType,
  apiUpdateUnitType,
  apiDeleteUnitType,
  apiUpdateUnitTypesOrder,
  apiSubscribeToNotifications,
} from '../client/apiClient';
import {
  IApiCreateLabourTypeRequest,
  ILabourType,
} from '../common-src/types/LabourType';
import {
  IApiCreateUnitTypeRequest,
  IApiUpdateUnitTypeRequest,
  IUnitType,
} from '../common-src/types/UnitOfLabour';
import { useQuery, useQueryClient } from '@tanstack/react-query';
import { registerServiceWorker } from '../serviceWorker';
import { getConfig } from '../config';

interface IUserContext {
  email?: string;
  firstName: string;
  lastName: string;
  showCompletedTasks: boolean;
  trackingPeriod?: TrackingPeriod;
  customTrackingPeriod?: number;
  isTrackingPeriodCustom: boolean;
  shouldReceiveReminders: boolean;
  reminderType?: 'email' | 'sms' | 'push';
  phoneNumber?: string;
  todoPreference: TaskViewPreference | null;
  phases?: IPhase[];
  todoListTaskIds?: string[];
  labourTypes?: ILabourType[];
  unitTypes?: IUnitType[];
  viewedOnboarding: boolean;
  viewedDashboardOnboarding: boolean;
  viewedTasksPageOnboarding: boolean;
  viewedGoalsPageOnboarding: boolean;
  viewedHabitsPageOnboarding: boolean;
  viewedLogPageOnboarding: boolean;
  viewedSessionsPageOnboarding: boolean;
  viewedProjectPageOnboarding: boolean;
  timezone?: string;
  addPhase: (phaseName: string) => Promise<void>;
  // updatePhase: (updatedPhase: IPhase, currentPhases: IPhase[]) => Promise<void>;
  deletePhase: (
    phaseToDeleteId: string,
    currentPhases: IPhase[]
  ) => Promise<void>;
  updatePhases: (phases: IApiUpdatePhaseRequest[]) => Promise<void>;
  updateShowCompletedTasks: (showCompletedTasks: boolean) => Promise<void>;
  updateTrackingPeriod: (
    trackingPeriod: TrackingPeriod,
    isTrackingPeriodCustom?: boolean,
    customTrackingPeriod?: number
  ) => Promise<void>;
  messagingTokenRef: string | undefined;
  updateUser: (user: IApiUpdateUserRequest) => Promise<void>;
  updateTodoPreference: (todoPreference: TaskViewPreference) => Promise<void>;
  updateTodoListTaskIds: (todoListTaskIds: string[]) => Promise<void>;
  addTaskToTodoList: (task: string) => Promise<void>;
  removeTaskFromTodoList: (task: string) => Promise<void>;
  addLabourType: (labourType: IApiCreateLabourTypeRequest) => Promise<void>;
  addUnitType: (unitType: IApiCreateUnitTypeRequest) => Promise<void>;
  updateUnitType: (
    unitTypeId: string,
    updateUnitTypeRequest: IApiUpdateUnitTypeRequest
  ) => Promise<void>;
  updateUnitTypesOrder: (unitTypeIds: string[]) => Promise<void>;
  deleteUnitType: (unitTypeId: string) => Promise<void>;
  subscribeToNotifications: () => Promise<void>;
  isFetchingUser: boolean;
}

export const UserContext = React.createContext<IUserContext | null>(null);

interface Props {
  children: ReactNode;
}

const UserContextProvider: React.FunctionComponent<Props> = (props: Props) => {
  const authenticationContext = useContext(AuthenticationContext);

  const queryClient = useQueryClient();

  const [email, setEmail] = useState<string | undefined>(undefined);
  const [phoneNumber, setPhoneNumber] = useState<string | undefined>('');
  const [firstName, setFirstName] = useState<string>('');
  const [lastName, setLastName] = useState<string>('');
  const [todoPreference, setTodoPreference] =
    useState<TaskViewPreference | null>(null);
  const [trackingPeriod, setTrackingPeriod] = useState<
    TrackingPeriod | undefined
  >(undefined);
  const [customTrackingPeriod, setCustomTrackingPeriod] = useState<
    number | undefined
  >(undefined);
  const [isTrackingPeriodCustom, setIsTrackingPeriodCustom] = useState(false);
  const [showCompletedTasks, setShowCompletedTasks] = useState(true);
  const [reminderType, setReminderType] = useState(undefined);
  const [shouldReceiveReminders, setShouldReceiveReminders] = useState(false);
  const [messagingTokenRef, setMessagingTokenRef] = useState<
    string | undefined
  >('');
  const [viewedOnboarding, setViewedOnboarding] = useState(true);
  const [viewedDashboardOnboarding, setViewedDashboardOnboarding] =
    useState(true);
  const [viewedTasksPageOnboarding, setViewedTasksPageOnboarding] =
    useState(true);
  const [viewedGoalsPageOnboarding, setViewedGoalsPageOnboarding] =
    useState(true);
  const [viewedHabitsPageOnboarding, setViewedHabitsPageOnboarding] =
    useState(true);
  const [viewedLogPageOnboarding, setViewedLogPageOnboarding] = useState(true);
  const [viewedSessionsPageOnboarding, setViewedSessionsPageOnboarding] =
    useState(true);
  const [viewedProjectPageOnboarding, setViewedProjectPageOnboarding] =
    useState(true);
  const [timezone, setTimezone] = useState<string | undefined>('');

  const {
    isLoading: isFetchingUser,
    isError: isFetchUserError,
    data: user,
    error: fetchUserError,
  } = useQuery({
    queryKey: ['getCurrentUser'],
    queryFn: async () => {
      try {
        if (authenticationContext?.isUserAuthenticated) {
          const accessToken = await authenticationContext.getToken();
          const user = await apiGetCurrentUser(accessToken);
          return user;
        }
      } catch (err) {
        throw err;
      }
    },
    enabled: !!authenticationContext?.isUserAuthenticated,
  });

  useEffect(() => {
    if (user) {
      setEmail(user.email);
      setPhoneNumber(user.phoneNumber);
      setFirstName(user.firstName);
      setLastName(user.lastName);
      setTodoPreference(user.todoPreference);
      setTrackingPeriod(user.trackingPeriod);
      setCustomTrackingPeriod(user.customTrackingPeriod ?? undefined);
      setIsTrackingPeriodCustom(user.isTrackingPeriodCustom ?? false);
      setShowCompletedTasks(user.showCompletedTasks);
      setReminderType(user.reminderType);
      setShouldReceiveReminders(user.shouldReceiveReminders);
      // setMessagingTokenRef(user.messagingTokenRef);
      setViewedOnboarding(user.viewedOnboarding);
      setViewedDashboardOnboarding(user.viewedDashboardOnboarding);
      setViewedTasksPageOnboarding(user.viewedTasksPageOnboarding);
      setViewedGoalsPageOnboarding(user.viewedGoalsPageOnboarding);
      setViewedHabitsPageOnboarding(user.viewedHabitsPageOnboarding);
      setViewedLogPageOnboarding(user.viewedLogPageOnboarding);
      setViewedSessionsPageOnboarding(user.viewedSessionsPageOnboarding);
      setViewedProjectPageOnboarding(user.viewedProjectPageOnboarding);
      setTimezone(user.timezone);
      const browserTimezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
      if (!user.timezone || user.timezone !== browserTimezone) {
        const updateUserSettingsRequest: IApiUpdateUserRequest = {
          timezone: browserTimezone,
        };
        updateUser(updateUserSettingsRequest);
      }
    }
  }, [
    user,
    user?.email,
    user?.phoneNumber,
    user?.firstName,
    user?.lastName,
    user?.todoPreference,
    user?.trackingPeriod,
    user?.customTrackingPeriod,
    user?.isTrackingPeriodCustom,
    user?.showCompletedTasks,
    user?.reminderType,
    user?.shouldReceiveReminders,
    user?.viewedOnboarding,
    user?.viewedDashboardOnboarding,
    user?.viewedTasksPageOnboarding,
    user?.viewedGoalsPageOnboarding,
    user?.viewedHabitsPageOnboarding,
    user?.viewedLogPageOnboarding,
    user?.viewedSessionsPageOnboarding,
    user?.viewedProjectPageOnboarding,
    user?.timezone,
  ]);

  const {
    isLoading: isFetchingTodoList,
    isError: isFetchTodoListError,
    data: todoListTaskIds,
    error: fetchTodoListError,
  } = useQuery({
    queryKey: ['getTodoList'],
    queryFn: async () => {
      try {
        if (authenticationContext?.isUserAuthenticated) {
          const accessToken = await authenticationContext.getToken();
          const todoList = await apiGetTodoList(accessToken);
          const taskIds = todoList.map(todo => todo.id);
          return taskIds;
        }
      } catch (err) {
        throw err;
      }
    },
    enabled: !!authenticationContext?.isUserAuthenticated,
  });

  const {
    isLoading: isFetchingPhases,
    isError: isFetchPhasesError,
    data: phases,
    error: fetchPhasesError,
  } = useQuery({
    queryKey: ['getPhases'],
    queryFn: async () => {
      try {
        if (authenticationContext?.isUserAuthenticated) {
          const accessToken = await authenticationContext.getToken();
          const phasesResponse = await apiGetPhases(accessToken);
          return phasesResponse;
        }
      } catch (err) {
        throw err;
      }
    },
    enabled: !!authenticationContext?.isUserAuthenticated,
  });

  const {
    isLoading: isFetchingLabourTypes,
    isError: isFetchLabourTypesError,
    data: labourTypes,
    error: fetchLabourTypesError,
  } = useQuery({
    queryKey: ['getLabourTypes'],
    queryFn: async () => {
      try {
        if (authenticationContext?.isUserAuthenticated) {
          const accessToken = await authenticationContext.getToken();
          const labourTypesResponse = await apiGetLabourTypes(accessToken);
          return labourTypesResponse;
        }
      } catch (err) {
        throw err;
      }
    },
    enabled: !!authenticationContext?.isUserAuthenticated,
  });

  const {
    isLoading: isFetchingUnitTypes,
    isError: isFetchUnitTypesError,
    data: unitTypes,
    error: fetchUnitTypesError,
  } = useQuery({
    queryKey: ['getUnitTypes'],
    queryFn: async () => {
      try {
        if (authenticationContext?.isUserAuthenticated) {
          const accessToken = await authenticationContext.getToken();
          const unitTypesResponse = await apiGetUnitTypes(accessToken);
          return unitTypesResponse;
        }
      } catch (err) {
        throw err;
      }
    },
    enabled: !!authenticationContext?.isUserAuthenticated,
  });

  const updateUser = async (
    updatedUser: IApiUpdateUserRequest
  ): Promise<void> => {
    try {
      if (authenticationContext?.isUserAuthenticated) {
        const accessToken = await authenticationContext.getToken();
        const updateUserResponse = await apiUpdateUser(
          accessToken,
          updatedUser
        );
        queryClient.invalidateQueries({ queryKey: ['getCurrentUser'] });
        queryClient.invalidateQueries({
          queryKey: [
            'getRawProductivityForGoals',
            trackingPeriod,
            customTrackingPeriod,
          ],
        });
        queryClient.invalidateQueries({
          queryKey: ['getGoals'],
        });
        queryClient.invalidateQueries({
          queryKey: [
            'apiGetOverallHabitStatus',
            trackingPeriod,
            customTrackingPeriod,
          ],
        });
      }
    } catch (err) {
      throw err;
    }
    return Promise.resolve();
  };

  const updateShowCompletedTasks = (showCompletedTasks: boolean) => {
    const updateUserSettingsRequest: IApiUpdateUserRequest = {
      show_completed_tasks: showCompletedTasks,
    };
    return updateUser(updateUserSettingsRequest);
  };

  const updateTrackingPeriod = (
    trackingPeriod: TrackingPeriod,
    isTrackingPeriodCustom?: boolean,
    customTrackingPeriod?: number
  ) => {
    const updateUserSettingsRequest: IApiUpdateUserRequest = {
      tracking_period: trackingPeriod,
      is_tracking_period_custom: isTrackingPeriodCustom,
      custom_tracking_period: customTrackingPeriod,
    };
    queryClient.invalidateQueries({
      queryKey: ['getButlerAssessment'],
    });
    return updateUser(updateUserSettingsRequest);
  };

  const updateTodoPreference = (todoPreference: TaskViewPreference) => {
    const updateUserSettingsRequest: IApiUpdateUserRequest = {
      todo_preference: todoPreference,
    };
    return updateUser(updateUserSettingsRequest);
  };

  const addPhase = async (phaseName: string) => {
    try {
      if (authenticationContext?.isUserAuthenticated) {
        const accessToken = await authenticationContext.getToken();
        const createPhaseRequest: IApiCreatePhaseRequest = {
          name: phaseName,
          is_user_level: true,
        };
        const newPhase = await apiCreatePhase(createPhaseRequest, accessToken);
        queryClient.invalidateQueries({ queryKey: ['getPhases'] });
      }
    } catch (err) {
      throw err;
    }
  };

  const subscribeToNotifications = async () => {
    try {
      if (authenticationContext?.isUserAuthenticated) {
        const accessToken = await authenticationContext.getToken();
        const config = getConfig();
        const registration = await registerServiceWorker();
        let subscription = await registration.pushManager.getSubscription();
        if (!subscription) {
          subscription = await registration.pushManager.subscribe({
            userVisibleOnly: true,
            applicationServerKey: config.pushNotifications.publicKey,
          });
          await apiSubscribeToNotifications(accessToken, subscription);
        }
      }
    } catch (err) {
      throw err;
    }
  };

  // The below is currently unused

  // const updatePhase = async (phase: IPhase, currentPhases: IPhase[]) => {
  //   try {
  //     if (authenticationContext.isUserAuthenticated) {
  //       const accessToken = await authenticationContext.getToken();
  //       const updatePhaseRequest: IApiUpdatePhaseRequest = {
  //         name: phase.name,
  //         orderedTaskIds: phase.tasks?.map(task => task.id),
  //       };
  //       const updatedPhase = await apiUpdatePhase(
  //         phase.id,
  //         updatePhaseRequest,
  //         accessToken
  //       );
  //       await fetchUserPhases();
  //     }
  //   } catch (err) {
  //     throw err;
  //   }
  // };

  const deletePhase = async (phaseToDeleteId: string) => {
    try {
      if (authenticationContext?.isUserAuthenticated) {
        const accessToken = await authenticationContext.getToken();
        await apiDeletePhase(phaseToDeleteId, accessToken);
        queryClient.invalidateQueries({ queryKey: ['getPhases'] });
      }
    } catch (err) {
      throw err;
    }
  };

  const updatePhases = async (phases: IApiUpdatePhaseRequest[]) => {
    try {
      if (authenticationContext?.isUserAuthenticated) {
        const accessToken = await authenticationContext.getToken();
        const updatedPhases = await apiUpdateUserPhases(accessToken, phases);
        queryClient.invalidateQueries({ queryKey: ['getPhases'] });
      }
    } catch (err) {
      throw err;
    }
  };

  const updateTodoListTaskIds = async (
    todoListTaskIds: (string | number)[]
  ) => {
    try {
      if (authenticationContext?.isUserAuthenticated) {
        const accessToken = await authenticationContext.getToken();
        const updateTodoListResponse = await apiUpdateTodoList(
          accessToken,
          todoListTaskIds.map(id => id.toString())
        );
        queryClient.invalidateQueries({ queryKey: ['getTodoList'] });
      }
    } catch (err) {
      console.error(err);
      throw err;
    }
  };

  const addTaskToTodoList = async (taskId: string) => {
    const updatedTodoListTaskIds = [...(todoListTaskIds ?? []), taskId];
    try {
      if (authenticationContext?.isUserAuthenticated) {
        const accessToken = await authenticationContext.getToken();
        const updateTodoListResponse = await apiUpdateTodoList(
          accessToken,
          updatedTodoListTaskIds.map(id => id.toString())
        );
        queryClient.invalidateQueries({ queryKey: ['getTodoList'] });
      }
    } catch (err) {
      console.error(err);
      throw err;
    }
  };

  const removeTaskFromTodoList = async (taskId: string) => {
    const updatedTodoListTaskIds =
      todoListTaskIds?.filter(id => id !== taskId) ?? [];
    try {
      if (authenticationContext?.isUserAuthenticated) {
        const accessToken = await authenticationContext.getToken();
        const updateTodoListResponse = await apiUpdateTodoList(
          accessToken,
          updatedTodoListTaskIds?.map(id => id.toString())
        );
        queryClient.invalidateQueries({ queryKey: ['getTodoList'] });
      }
    } catch (err) {
      console.error(err);
      throw err;
    }
  };

  const addLabourType = async (labourType: IApiCreateLabourTypeRequest) => {
    try {
      if (authenticationContext?.isUserAuthenticated) {
        const accessToken = await authenticationContext.getToken();
        const newLabourType = await apiAddLabourType(accessToken, labourType);
        queryClient.invalidateQueries({ queryKey: ['getLabourTypes'] });
      }
    } catch (err) {
      throw err;
    }
  };

  const addUnitType = async (unitType: IApiCreateUnitTypeRequest) => {
    try {
      if (authenticationContext?.isUserAuthenticated) {
        const accessToken = await authenticationContext.getToken();
        const newUnitType = await apiCreateUnitType(accessToken, unitType);
        queryClient.invalidateQueries({ queryKey: ['getUnitTypes'] });
      }
    } catch (err) {
      throw err;
    }
  };

  const updateUnitType = async (
    unitTypeId: string,
    updateUnitTypeRequest: IApiUpdateUnitTypeRequest
  ) => {
    try {
      if (authenticationContext?.isUserAuthenticated) {
        const accessToken = await authenticationContext.getToken();
        const updatedUnitType = await apiUpdateUnitType(
          accessToken,
          unitTypeId,
          updateUnitTypeRequest
        );
        queryClient.invalidateQueries({ queryKey: ['getUnitTypes'] });
      }
    } catch (err) {
      throw err;
    }
  };

  const updateUnitTypesOrder = async (unitTypeIds: string[]) => {
    try {
      if (authenticationContext?.isUserAuthenticated) {
        const accessToken = await authenticationContext.getToken();
        const updatedUnitTypes = await apiUpdateUnitTypesOrder(accessToken, {
          unit_type_ids: unitTypeIds,
        });
        queryClient.invalidateQueries({ queryKey: ['getUnitTypes'] });
      }
    } catch (err) {
      throw err;
    }
  };

  const deleteUnitType = async (unitTypeId: string) => {
    try {
      if (authenticationContext?.isUserAuthenticated) {
        const accessToken = await authenticationContext.getToken();
        await apiDeleteUnitType(accessToken, unitTypeId);
        queryClient.invalidateQueries({ queryKey: ['getUnitTypes'] });
      }
    } catch (err) {
      throw err;
    }
  };

  const getUserContext = () => {
    return {
      email,
      firstName,
      lastName,
      phoneNumber,
      todoPreference,
      trackingPeriod,
      phases,
      customTrackingPeriod,
      isTrackingPeriodCustom,
      showCompletedTasks,
      reminderType,
      shouldReceiveReminders,
      messagingTokenRef,
      todoListTaskIds,
      labourTypes,
      unitTypes,
      viewedOnboarding,
      viewedDashboardOnboarding,
      viewedTasksPageOnboarding,
      viewedGoalsPageOnboarding,
      viewedHabitsPageOnboarding,
      viewedLogPageOnboarding,
      viewedSessionsPageOnboarding,
      viewedProjectPageOnboarding,
      timezone,
      addPhase,
      // updatePhase,
      deletePhase,
      updatePhases,
      updateUser,
      updateShowCompletedTasks,
      updateTrackingPeriod,
      updateTodoPreference,
      updateTodoListTaskIds,
      addTaskToTodoList,
      removeTaskFromTodoList,
      addLabourType,
      addUnitType,
      updateUnitType,
      updateUnitTypesOrder,
      deleteUnitType,
      subscribeToNotifications,
      isFetchingUser,
    };
  };

  const userContext = getUserContext();

  return (
    <UserContext.Provider value={userContext}>
      {props.children}
    </UserContext.Provider>
  );
};

export { UserContextProvider };
export type { IUserContext };

import React, { ReactNode, useEffect, useState } from 'react';
import { useAuth0 } from '@auth0/auth0-react';

interface AuthenticationContextType {
  userId: string;
  getToken: () => Promise<string>;
  isUserAuthenticated: boolean;
}

export const AuthenticationContext =
  React.createContext<AuthenticationContextType | null>(null);

interface Props {
  children?: ReactNode;
}

const AuthenticationContextProvider: React.FunctionComponent<Props> = (
  props: Props
) => {
  const {
    isLoading,
    isAuthenticated,
    error,
    user,
    loginWithRedirect,
    logout,
    getAccessTokenSilently,
  } = useAuth0();

  const [userId, setUserId] = useState<any | null>(null);
  const [isUserAuthenticated, setIsUserAuthenticated] = useState(false);

  useEffect(() => {
    if (isAuthenticated && user) {
      setIsUserAuthenticated(true);
    } else {
      setIsUserAuthenticated(false);
    }
  }, [isAuthenticated, user]);

  const getToken = async (): Promise<string> => {
    let token: string = '';
    try {
      token = await getAccessTokenSilently();
    } catch (err) {
      // do nothing -- this is not a failure
    }
    return token;
  };

  return (
    <AuthenticationContext.Provider
      value={{
        userId,
        getToken,
        isUserAuthenticated,
      }}
    >
      {props.children}
    </AuthenticationContext.Provider>
  );
};

export { AuthenticationContextProvider };

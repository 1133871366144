import { createUseStyles } from 'react-jss';
import { chosenTheme } from '../styleHooks';

export const useCalendarStyles = createUseStyles({
  calendar: {
    display: 'flex',
    flexDirection: 'row',
    minHeight: '1000px',
    minWidth: '500px',
    overflowX: 'auto',
  },
  '@media only screen and (min-width: 320px) and (max-width: 480px)': {
    calendar: {
      minWidth: '100%',
    },
  },

  week_col: {
    position: 'relative',
    minWidth: 70,
    display: 'flex',
    flexDirection: 'column',
    flex: '1 1 0',
    backgroundColor: chosenTheme.genericGroupComponentBackgroundColour,
    '& h3': {
      color: 'white',
    },
    marginRight: 5,
    borderRadius: 5,
  },

  sessionCalendarItemContainer: {
    position: 'absolute',
    width: '100%',
  },

  sessionCalendarItem: {
    backgroundColor: chosenTheme.tasks.taskBackgroundColour,
    display: 'flex',
    '&:hover': {
      backgroundColor: chosenTheme.tasks.taskHoverBackgroundColour,
      cursor: 'pointer',
    },
    height: '100%',
    width: '100%',
    overflowY: 'auto',
  },

  sessionCalendarItemTagsContainer: {
    width: '100%',
    textAlign: 'left',
    paddingTop: 1,
    paddingBottom: 7,
    paddingLeft: 50,
  },

  currentTimeMarker: {
    backgroundColor: 'red',
    position: 'absolute',
    width: '100%',
    height: '2px',
  },
});

import { createUseStyles } from 'react-jss';

export const useTaskFilterStyles = createUseStyles({
  taskFilter: {
    display: 'flex',
  },
  taskFilterChild: {
    display: 'inline-block',
    marginRight: 10,
    textAlign: 'left',
  },
  '@media only screen and (min-width: 320px) and (max-width: 750px)': {
    taskFilter: {
      display: 'grid',
      gridTemplateColumns: '1fr',
    },
    taskFilterChild: {
      flex: 1,
      marginRight: 0,
    },
  },
  taskFilterChildHeading: {
    marginBottom: 0,
    paddingTop: 5,
  },
  taskFilterChildSection: {
    padding: 5,
  },
});

import React, { useEffect, useState, ReactNode } from 'react';
import ReactDOM from 'react-dom';
import { DraggableProvided, DraggableStateSnapshot } from 'react-beautiful-dnd';

interface IProps {
  children: ReactNode;
  draggableProvided: DraggableProvided;
  draggableSnapshot: DraggableStateSnapshot;
  innerRef?: string;
}

const PortalWrapper: React.FunctionComponent<IProps> = (props: IProps) => {
  const { draggableSnapshot } = props;
  const usePortal = draggableSnapshot.isDragging;

  const [portal, setPortal] = useState<HTMLElement | null>(null);
  useEffect(() => {
    const newPortal = document.createElement('div');
    newPortal.classList.add('gp-portal');
    if (!document.body) {
      throw new Error('Unable to create portal, as document body is not ready');
    }
    document.body.appendChild(newPortal);
    setPortal(newPortal);
  }, []);

  if (usePortal && portal) {
    return ReactDOM.createPortal(props.children, portal);
  } else {
    return <div>{props.children}</div>;
  }
};

export { PortalWrapper };

import { Button, ButtonGroup } from '@blueprintjs/core';
import { DateTime } from 'luxon';
import React, { useContext } from 'react';
import {
  getAverageProductivityTooltipForMultipleGoalsInPieChart,
  getProductivityTooltipForGoalInPieChart,
} from '../../common-src/productivity';
import { IGoal } from '../../common-src/types/Goal';
import { TrackingPeriod } from '../../common-src/types/User';
import { ProjectsListContext } from '../../state/ProjectsListContext';
import { UserContext } from '../../state/UserContext';
import { WindowContext } from '../../state/WindowContext';
import { useGoalPageStyles } from '../../style/pages/goalPageStyles';
import { WhatNeedsToBeDone } from '../common/WhatNeedsToBeDone';
import { PieChartType, PieChart } from '../common/charts/PieChart';
import { IGoalSummary } from '../../common-src/types/Reporting';

interface IGoalSummaryBoxProps {
  goal: IGoal;
  goalSummary: IGoalSummary;
  trackingPeriod: TrackingPeriod;
  customTrackingPeriod?: number;
  openEditGoalModal: (goal: IGoal) => void;
}

const GoalSummaryBox: React.FunctionComponent<IGoalSummaryBoxProps> = (
  props: IGoalSummaryBoxProps
) => {
  const userContext = useContext(UserContext);
  const windowContext = useContext(WindowContext);
  const projectListContext = useContext(ProjectsListContext);

  const {
    goal,
    goalSummary,
    trackingPeriod,
    customTrackingPeriod,
    openEditGoalModal,
  } = props;

  const goalPageStyles = useGoalPageStyles();

  const { projects } = projectListContext!;
  const { labourTypes } = userContext!;

  const handleClickOpenEditGoalModal = () => {
    openEditGoalModal(goal);
  };

  const associatedProject = goal.projectId
    ? projects?.find(p => p.id === goal.projectId)
    : undefined;
  let associatedProjectText = '';

  if (associatedProject) {
    associatedProjectText = ` (associated with project "${associatedProject.name}")`;
  }

  const formattedStartDate = goal.startDate
    ? DateTime.fromISO(goal.startDate).toLocaleString(
        DateTime.DATE_MED_WITH_WEEKDAY
      )
    : '';
  const formattedEndDate = goal.endDate
    ? DateTime.fromISO(goal.endDate).toLocaleString(
        DateTime.DATE_MED_WITH_WEEKDAY
      )
    : '';

  const associatedLabourType = labourTypes?.find(
    lt => lt.id === goal.labourTypeId
  );

  let associatedLabourTypeText = '';

  if (associatedLabourType) {
    associatedLabourTypeText = ` (associated with work type "${associatedLabourType.name}")`;
  }

  return (
    <div className={goalPageStyles.goalSummaryBox}>
      <div className="gp-flex-grid">
        <div className="gp-flex-grid-col-1">
          <div style={{ textAlign: 'left', marginBottom: 10 }}>
            <h1>{goal.name}</h1>
            <ButtonGroup>
              <>
                {windowContext?.isMobile ? (
                  <>
                    <Button
                      icon="edit"
                      onClick={handleClickOpenEditGoalModal}
                      style={{ minWidth: 50 }}
                      minimal
                    />
                  </>
                ) : (
                  <>
                    <Button
                      icon="edit"
                      onClick={handleClickOpenEditGoalModal}
                      minimal
                    >
                      Edit
                    </Button>
                  </>
                )}
              </>
            </ButtonGroup>
            <WhatNeedsToBeDone
              trackingPeriod={trackingPeriod}
              customTrackingPeriodInDays={customTrackingPeriod}
              type="GOAL"
              goalSummary={goalSummary}
            />
            <div>
              {associatedProjectText && associatedProject ? (
                <p>
                  <em>
                    This goal is associated with project{' '}
                    <strong>{associatedProject.name}</strong>
                  </em>
                </p>
              ) : null}
              {formattedStartDate ? (
                <p>
                  <em>
                    This goal started on <strong>{formattedStartDate}</strong>
                  </em>
                </p>
              ) : null}
              {formattedEndDate ? (
                <p>
                  <em>
                    This goal ended on <strong>{formattedEndDate}</strong>
                  </em>
                </p>
              ) : null}
              {associatedLabourTypeText && associatedLabourType ? (
                <p>
                  <em>
                    This goal is associated with work type{' '}
                    <strong>{associatedLabourType.name}</strong>
                  </em>
                </p>
              ) : null}
            </div>
          </div>
        </div>
        <div className="gp-flex-grid-col-1">
          <div>
            <PieChart
              type={PieChartType.SINGLE_METRIC}
              chartMetricsForPrimaryPie={[
                {
                  id: goalSummary.goalId,
                  label: 'Overall work',
                  value: goalSummary.goalScore,
                  tooltip: getProductivityTooltipForGoalInPieChart(
                    goalSummary,
                    trackingPeriod,
                    customTrackingPeriod
                  ),
                },
                {
                  id: `${goalSummary.goalId}_anti`,
                  label: 'Overall slacking',
                  value: 1 - goalSummary.goalScore,
                  tooltip: getProductivityTooltipForGoalInPieChart(
                    goalSummary,
                    trackingPeriod,
                    customTrackingPeriod
                  ),
                  colour: 'rgb(255 255 255 / .1)',
                },
              ]}
              trackingPeriod={trackingPeriod}
              customTrackingPeriodInDays={customTrackingPeriod}
              width={300}
              height={200}
              margin={
                windowContext?.isMobile
                  ? { top: 0, right: 0, bottom: 0, left: 0 }
                  : { top: 20, right: 20, bottom: 20, left: 20 }
              }
              animate={true}
              centreText={`${Math.round(goalSummary.goalScore * 100)}%`}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export { GoalSummaryBox };

import { Button, Intent } from '@blueprintjs/core';
import { DateTime } from 'luxon';
import React from 'react';
import {
  DatetimeFormat,
  DatetimePicker,
  DatetimePickerType,
} from '../DatetimePicker/DatetimePicker';
import { Completion } from './Completion';

interface IHabitCompletionsProps {
  completions: string[];
  onEnterAddCompletionMode: () => void;
  onEnterEditCompletionMode: (dateString: string) => void;
  onNewCompletionChange: (newCompletion?: DateTime) => void;
  onNewCompletionError: () => void;
  onEditCompletionError: () => void;
  onAddNewCompletion: () => void;
  onSaveEditedCompletion: (
    originalDateString: string,
    newDateString: string
  ) => void;
  inAddCompletionMode: boolean;
  inEditCompletionMode: boolean;
  completionDateToAdd?: DateTime;
  completionDateToEdit?: string;
  onDismissEditingCompletion: () => void;
}

const HabitCompletions: React.FunctionComponent<IHabitCompletionsProps> = (
  props: IHabitCompletionsProps
) => {
  const {
    completions,
    onEnterAddCompletionMode,
    onEnterEditCompletionMode,
    onNewCompletionChange,
    onNewCompletionError,
    onEditCompletionError,
    onAddNewCompletion,
    onSaveEditedCompletion,
    inAddCompletionMode,
    inEditCompletionMode,
    completionDateToAdd,
    completionDateToEdit,
    onDismissEditingCompletion,
  } = props;

  const handleClickCompletion = (e: React.MouseEvent<HTMLLIElement>) => {
    if (!inEditCompletionMode) {
      onEnterEditCompletionMode(e.currentTarget.id);
    }
  };

  return (
    <div>
      <div>Past completions:</div>
      {!completions || completions.length === 0 ? (
        <p>You don't have any past completions yet.</p>
      ) : (
        <ul>
          {completions
            .map(comp => DateTime.fromISO(comp))
            .sort((a, b) => b.toMillis() - a.toMillis())
            .map(completionDateTime => (
              <li
                key={completionDateTime.toString()}
                onClick={handleClickCompletion}
                id={completionDateTime.toString()}
              >
                <Completion
                  inEditCompletionMode={
                    inEditCompletionMode &&
                    completionDateToEdit === completionDateTime.toString()
                  }
                  completionDatetimeString={completionDateTime.toString()}
                  onSaveEditedCompletion={onSaveEditedCompletion}
                  onDismissEditingCompletion={onDismissEditingCompletion}
                />
              </li>
            ))}
        </ul>
      )}
      <Button onClick={onEnterAddCompletionMode}>Add completion</Button>
      {inAddCompletionMode && (
        <div>
          <DatetimePicker
            id="habit_completion_new_datetime_picker"
            type={DatetimePickerType.datetime}
            label="New completion:"
            datetime={completionDateToAdd}
            onDatetimeChange={onNewCompletionChange}
            datetimeFormat={DatetimeFormat.DATETIME}
            canClear={true}
          />
          <Button intent={Intent.SUCCESS} onClick={onAddNewCompletion}>
            Add
          </Button>
        </div>
      )}
    </div>
  );
};

export { HabitCompletions };

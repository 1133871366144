import React, { ReactNode, useContext } from 'react';
import { apiGetButlerAssessment } from '../client/apiClient';
import { AuthenticationContext } from './AuthenticationContext';
import { UserContext } from './UserContext';
import { useQuery } from '@tanstack/react-query';
import { IButlerAssessment } from '../common-src/types/Reporting';

interface IProductivityContextType {
  butlerAssessment: IButlerAssessment | undefined;
  isFetchingButlerAssessment: boolean;
}

export const ProductivityContext =
  React.createContext<IProductivityContextType | null>(null);

interface Props {
  children: ReactNode;
}

const ProductivityContextProvider: React.FunctionComponent<Props> = (
  props: Props
) => {
  const authenticationContext = useContext(AuthenticationContext);
  const userContext = useContext(UserContext);

  const { customTrackingPeriod, trackingPeriod } = userContext!;

  const {
    isLoading: isFetchingButlerAssessment,
    isError: isFetchButlerAssessmentError,
    data: butlerAssessment,
    error: fetchButlerAssessmentError,
  } = useQuery({
    queryKey: ['getButlerAssessment', trackingPeriod, customTrackingPeriod],
    queryFn: async () => {
      try {
        if (
          authenticationContext &&
          authenticationContext.isUserAuthenticated &&
          trackingPeriod
        ) {
          const accessToken = await authenticationContext.getToken();
          const combinedProductivity = await apiGetButlerAssessment(
            accessToken
          );
          return combinedProductivity;
        }
      } catch (err) {
        throw err;
      }
    },
    enabled: !!authenticationContext?.isUserAuthenticated && !!trackingPeriod,
  });

  return (
    <ProductivityContext.Provider
      value={{
        butlerAssessment,
        isFetchingButlerAssessment,
      }}
    >
      {props.children}
    </ProductivityContext.Provider>
  );
};

export { ProductivityContextProvider };
export type { IProductivityContextType };

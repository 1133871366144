import React, { ReactNode, useContext } from 'react';
import {
  IApiCreateLogRequest,
  ILog,
  IApiUpdateLogRequest,
} from '../common-src/types/Log';
import {
  apiCreateLog,
  apiDeleteLog,
  apiGetLogs,
  apiGetLogsInProject,
  apiUpdateLog,
} from '../client/apiClient';
import { AuthenticationContext } from './AuthenticationContext';
import { DateTime } from 'luxon';
import { useQuery, useQueryClient } from '@tanstack/react-query';

interface ILogContext {
  allLogs?: ILog[];
  addLog: (
    projectId: string,
    logDate: string,
    logText: string
  ) => Promise<void>;
  saveLog: (
    logId: string,
    projectId: string,
    logDate: Date,
    logText: string
  ) => Promise<void>;
  deleteLog: (projectId: string, logDate: Date, logId: string) => Promise<void>;
  getLogsByProjectId: (projectId: string) => Promise<ILog[]>;
  fetchLogsInProject: (projectId: string) => Promise<ILog[]>;
}

export const LogContext = React.createContext<ILogContext | null>(null);

interface Props {
  children: ReactNode;
}

const LogContextProvider: React.FunctionComponent<Props> = (props: Props) => {
  const authenticationContext = useContext(AuthenticationContext);

  const queryClient = useQueryClient();

  const {
    isLoading: isFetchingLogs,
    isError: isFetchLogsError,
    data: allLogs,
    error: fetchLogsError,
  } = useQuery({
    queryKey: ['getLogs'],
    queryFn: async () => {
      try {
        if (
          authenticationContext &&
          authenticationContext.isUserAuthenticated
        ) {
          const accessToken = await authenticationContext.getToken();
          const unsortedLogs = await apiGetLogs(accessToken);
          const logs = unsortedLogs.sort((a, b) => {
            if (DateTime.fromISO(a.date) > DateTime.fromISO(b.date)) {
              return -1;
            } else if (DateTime.fromISO(a.date) < DateTime.fromISO(b.date)) {
              return 1;
            } else {
              return 0;
            }
          });
          return logs;
        }
      } catch (err) {
        throw err;
      }
    },
    enabled: !!authenticationContext?.isUserAuthenticated,
  });

  const fetchLogsInProject = async (projectId: string): Promise<ILog[]> => {
    let projectLogs: ILog[] = [];
    try {
      if (authenticationContext && authenticationContext.isUserAuthenticated) {
        const accessToken = await authenticationContext.getToken();
        projectLogs = await apiGetLogsInProject(projectId, accessToken);
      }
      return projectLogs;
    } catch (err) {
      throw err;
    }
  };

  const getLogsByProjectId = async (projectId: string): Promise<ILog[]> => {
    const logsInProject = await fetchLogsInProject(projectId.toString());
    return logsInProject;
  };

  // TODO: refactor the below log functions (and functions that call them), so logDate is of type DateTime
  const addLog = async (
    projectId: string,
    logDate: string,
    logText: string
  ) => {
    try {
      if (authenticationContext && authenticationContext.isUserAuthenticated) {
        const accessToken = await authenticationContext.getToken();
        const createLogRequest: IApiCreateLogRequest = {
          datetime: logDate,
          note: logText,
          project_id: projectId,
        };
        const newLog = await apiCreateLog(accessToken, createLogRequest);
        queryClient.invalidateQueries({ queryKey: ['getLogs'] });
      }
    } catch (err) {
      throw err;
    }
  };

  const saveLog = async (
    logId: string,
    projectId: string,
    logDate: Date,
    logText: string
  ) => {
    try {
      if (authenticationContext && authenticationContext.isUserAuthenticated) {
        const accessToken = await authenticationContext.getToken();
        const updateLogRequest: IApiUpdateLogRequest = {
          datetime: logDate.toISOString(),
          note: logText,
          project_id: projectId,
        };
        const newLog = await apiUpdateLog(accessToken, logId, updateLogRequest);
        queryClient.invalidateQueries({ queryKey: ['getLogs'] });
      }
    } catch (err) {
      throw err;
    }
  };

  // @TODO: fix typing of logDate
  const deleteLog = async (projectId: string, logDate: Date, logId: string) => {
    try {
      if (authenticationContext && authenticationContext.isUserAuthenticated) {
        const accessToken = await authenticationContext.getToken();
        await apiDeleteLog(accessToken, logId);
        queryClient.invalidateQueries({ queryKey: ['getLogs'] });
      }
    } catch (err) {
      throw err;
    }
  };

  const getLogContext = () => {
    return {
      allLogs,
      addLog,
      saveLog,
      deleteLog,
      getLogsByProjectId,
      fetchLogsInProject,
    };
  };

  const logContext = getLogContext();

  return (
    <LogContext.Provider value={logContext}>
      {props.children}
    </LogContext.Provider>
  );
};

export { LogContextProvider };
export type { ILogContext };

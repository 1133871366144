import React, { useContext, useState } from 'react';
import { useModalStyles } from '../../style/components/genericStyles';
import { Intent, Dialog, ButtonGroup, Button } from '@blueprintjs/core';
import { AlertsContext } from '../../state/AlertsContext';
import { ModalContext } from '../../state/ModalContext';
import { ProjectsListContext } from '../../state/ProjectsListContext';
import { ITag } from '../../common-src/types/Tag';

interface IDeleteTagModalProps {
  onRequestClose: () => void;
  modalIsOpen: boolean;
  deleteTag: (tag: ITag) => Promise<void>;
}

const DeleteTagModal: React.FunctionComponent<IDeleteTagModalProps> = (
  props: IDeleteTagModalProps
) => {
  const alertsContext = useContext(AlertsContext);
  const modalContext = useContext(ModalContext);
  const projectContext = useContext(ProjectsListContext);
  const modalStyles = useModalStyles();

  const [isDeleting, setIsDeleting] = useState<boolean>(false);

  if (!modalContext || !projectContext || !alertsContext) {
    return null;
  }

  const handleDeleteTag = async event => {
    event.preventDefault();
    setIsDeleting(true);
    const { tagToDelete } = modalContext;
    const { deleteTag } = props;

    if (!tagToDelete) {
      return;
    }

    try {
      await deleteTag(tagToDelete);
      alertsContext.addAlert(
        `Deleted tag "${tagToDelete!.name}"`,
        Intent.SUCCESS
      );
    } catch (err) {
      alertsContext.addAlert(
        `Error deleting tag "${tagToDelete!.name}"`,
        Intent.DANGER
      );
    } finally {
      setIsDeleting(false);
      handleClose();
    }
  };

  const handleClose = () => {
    props.onRequestClose();
  };

  const { tagToDelete } = modalContext!;

  return (
    <Dialog
      isOpen={props.modalIsOpen}
      onClose={props.onRequestClose}
      title={tagToDelete && `Delete tag "${tagToDelete.name}"`}
      canOutsideClickClose={false}
    >
      <div className={`bp4-dialog-body ${modalStyles.body}`}>
        <p>Are you sure you want to delete this tag?</p>
      </div>
      <div className={`bp4-dialog-footer ${modalStyles.footer}`}>
        <ButtonGroup>
          <Button onClick={handleClose}>Cancel</Button>
          <Button
            onClick={handleDeleteTag}
            intent={Intent.DANGER}
            loading={isDeleting}
          >
            Do it!
          </Button>
        </ButtonGroup>
      </div>
    </Dialog>
  );
};

export { DeleteTagModal };

import React, { useState, useEffect, useContext } from 'react';
import { DateTime } from 'luxon';
import { Dialog, Button, Intent } from '@blueprintjs/core';
import { ISimplifiedProject } from '../../common-src/types/Project';
import {
  IApiUpdateSessionRequest,
  ISession,
} from '../../common-src/types/Session';
import { AlertsContext } from '../../state/AlertsContext';
import { useModalStyles } from '../../style/components/genericStyles';
import { SessionAndUnitContext } from '../../state/SessionAndUnitContext';
import {
  DatetimeFormat,
  DatetimePicker,
  DatetimePickerType,
} from '../common/DatetimePicker/DatetimePicker';
import { GoalType, IGoal } from '../../common-src/types/Goal';
import { ILabourType } from '../../common-src/types/LabourType';
import { MarkdownTextEditor } from '../common/MarkdownTextEditor';
import { ITask } from '../../common-src/types/Task';
import { ITag } from '../../common-src/types/Tag';
import { TagSelector } from '../common/tags/TagSelector';

interface Props {
  simplifiedProjectList?: ISimplifiedProject[];
  tasks: ITask[];
  goals?: IGoal[];
  labourTypes?: ILabourType[];
  session: ISession;
  tags: ITag[];
  onRequestClose: () => void;
  modalIsOpen: boolean;
}

const EditSessionModal: React.FunctionComponent<Props> = (props: Props) => {
  const alertsContext = useContext(AlertsContext);
  const sessionAndUnitContext = useContext(SessionAndUnitContext);

  const modalStyles = useModalStyles();

  const [sessionDate, setSessionDate] = useState<DateTime | undefined>(
    undefined
  );
  const [sessionDuration, setSessionDuration] = useState(0);
  const [sessionNotes, setSessionNotes] = useState<string>('');
  const [sessionProjectId, setSessionProjectId] = useState<string>();
  const [sessionTaskId, setSessionTaskId] = useState<string>();
  const [sessionGoalId, setSessionGoalId] = useState<string>();
  const [sessionLabourTypeId, setSessionLabourTypeId] = useState<string>();
  const [sessionTagIds, setSessionTagIds] = useState<string[] | undefined>(
    undefined
  );

  const [focused, setFocused] = useState<boolean>(false);

  const [isSaving, setIsSaving] = useState<boolean>(false);

  useEffect(() => {
    if (props.session) {
      setSessionDate(DateTime.fromISO(props.session.date));
      setSessionDuration(props.session.minutes);
      setSessionProjectId(props.session.projectId ?? '');
      setSessionTaskId(props.session.taskId ?? '');
      setSessionGoalId(props.session.goalId ?? '');
      setSessionLabourTypeId(props.session.labourTypeId ?? '');
      setSessionNotes(props.session.notes ?? '');
      setSessionTagIds(props.session.tagIds ?? []);
    }
  }, [props.session]);

  const handleSessionDatetimeChange = (datetime?: DateTime) => {
    setSessionDate(datetime);
  };

  const handleSessionDurationChange = event => {
    setSessionDuration(event.target.value);
  };

  const handleNotesChange = (value: string) => {
    setSessionNotes(value);
  };

  const handleProjectChange = event => {
    setSessionProjectId(event.target.value ?? '');
  };

  const handleGoalChange = event => {
    setSessionGoalId(event.target.value ?? '');
  };

  const handleLabourTypeChange = event => {
    setSessionLabourTypeId(event.target.value ?? '');
  };

  const handleSaveSession = async () => {
    try {
      setIsSaving(true);
      const updateSessionRequest: IApiUpdateSessionRequest = {
        date: sessionDate!.toISO(),
        deleted: false,
        minutes: sessionDuration,
        notes: sessionNotes,
        project_id: sessionProjectId,
        task_id: sessionTaskId,
        goal_id: sessionGoalId,
        labour_type_id: sessionLabourTypeId,
        tag_ids: sessionTagIds,
      };
      await sessionAndUnitContext!.updateSession(
        props.session.id.toString(),
        updateSessionRequest
      );

      alertsContext!.addAlert('Updated session', Intent.SUCCESS);
    } catch (err) {
      alertsContext!.addAlert('Failed to update session', Intent.DANGER);
    } finally {
      setIsSaving(false);
      handleClose();
    }
  };

  const handleClose = () => {
    setSessionDate(undefined);
    setSessionDuration(0);
    setSessionNotes('');
    setSessionProjectId(undefined);
    setSessionTaskId(undefined);
    setSessionGoalId(undefined);
    setSessionLabourTypeId(undefined);
    setSessionTagIds(undefined);
    props.onRequestClose();
  };

  const handleTagSelect = (tag: ITag) => {
    let newTagIds = sessionTagIds?.slice();
    const tagId = tag.id;
    if (newTagIds) {
      if (!newTagIds.includes(tagId)) {
        newTagIds.push(tagId);
      } else {
        newTagIds = newTagIds.filter(id => id !== tagId);
      }
    } else {
      newTagIds = [tagId];
    }
    setSessionTagIds(newTagIds.slice());
  };

  const handleTagRemove = (tagName: string, index: number) => {
    const selectedTags = getSelectedTags();
    // @TODO: blueprint doesn't appear to have a way to remove a tag by id. Find a way around this
    const tagToRemove = selectedTags.find(tag => tag.name === tagName);
    if (tagToRemove) {
      const newListOfSelectedTags = selectedTags.filter(
        tag => tag.id !== tagToRemove.id
      );
      const newTagIds = newListOfSelectedTags.map(tag => tag.id);
      setSessionTagIds(newTagIds.slice());
    }
  };

  const getSelectedTags = (): ITag[] => {
    const { tags } = props;
    if (sessionTagIds) {
      const selectedTags = tags!.filter(
        tag => sessionTagIds && sessionTagIds.includes(tag.id)
      );
      return selectedTags || [];
    } else {
      return [];
    }
  };

  const { session, simplifiedProjectList, tasks, goals, labourTypes, tags } =
    props;

  const projectsToShow: JSX.Element[] = [];

  projectsToShow.push(
    <option value="" key="None">
      None
    </option>
  );

  const sortedProjectList = simplifiedProjectList?.slice().sort((a, b) => {
    return a.name.localeCompare(b.name);
  });

  sortedProjectList?.forEach((project: ISimplifiedProject) => {
    projectsToShow.push(
      <option value={project.id ?? ''} key={project.id}>
        {project.name}
      </option>
    );
  });

  const tasksToShow: JSX.Element[] = [];

  tasksToShow.push(
    <option value="" key="None">
      None
    </option>
  );

  const sortedTasks = tasks?.slice().sort((a, b) => {
    return a.name.localeCompare(b.name);
  });

  sortedTasks?.forEach((task: ITask) => {
    if (sessionProjectId) {
      if (task.projectId === sessionProjectId) {
        tasksToShow.push(
          <option value={task.id} key={task.id}>
            {task.name}
          </option>
        );
      }
    } else {
      tasksToShow.push(
        <option value={task.id} key={task.id}>
          {task.name}
        </option>
      );
    }
  });

  const sessionGoals = goals?.filter(
    goal => goal.type === GoalType.SESSION && goal.isActive
  );

  const sortedSessionGoals = sessionGoals?.sort((a, b) => {
    return a.name.localeCompare(b.name);
  });
  const goalsToShow: JSX.Element[] = [];

  goalsToShow.push(
    <option value="" key="None">
      None
    </option>
  );

  sortedSessionGoals?.forEach((goal: IGoal) => {
    goalsToShow.push(
      <option value={goal.id} key={goal.id}>
        {goal.name}
      </option>
    );
  });

  const sortedLabourTypes = labourTypes?.sort((a, b) => {
    return a.name.localeCompare(b.name);
  });

  const labourTypesToShow: JSX.Element[] = [];

  labourTypesToShow.push(
    <option value="" key="None">
      None
    </option>
  );

  sortedLabourTypes?.forEach((labourType: ILabourType) => {
    labourTypesToShow.push(
      <option value={labourType.id} key={labourType.id}>
        {labourType.name}
      </option>
    );
  });

  if (!session) {
    return null;
  }

  const selectedTags = getSelectedTags();

  return (
    <Dialog
      isOpen={props.modalIsOpen}
      onClose={handleClose}
      title="Edit session"
      canOutsideClickClose={false}
    >
      <div className={`bp4-dialog-body ${modalStyles.body}`}>
        <div>
          <DatetimePicker
            id="edit_session_datetime_picker"
            type={DatetimePickerType.datetime}
            label="Choose datetime"
            placeholder="The datetime on which this session was complete"
            datetime={sessionDate}
            onDatetimeChange={handleSessionDatetimeChange}
            isFocused={focused}
            datetimeFormat={DatetimeFormat.DATETIME}
            canClear={false}
          />
        </div>

        <div>
          <label className="bp4-label">
            Length of session (in minutes):
            <input
              className="bp4-input bp4-fill"
              type="text"
              onChange={handleSessionDurationChange}
              value={sessionDuration}
              placeholder="in minutes"
            />
          </label>
        </div>

        {simplifiedProjectList && simplifiedProjectList.length > 0 && (
          <label className="bp4-label">
            Associated project
            <div className="bp4-select">
              <select value={sessionProjectId} onChange={handleProjectChange}>
                {projectsToShow}
              </select>
            </div>
          </label>
        )}

        {tasksToShow && tasksToShow.length > 0 ? (
          <label className="bp4-label">
            Associated task
            <div className="bp4-select">
              <select
                value={sessionTaskId ?? ''}
                onChange={event => setSessionTaskId(event.target.value)}
              >
                {tasksToShow}
              </select>
            </div>
          </label>
        ) : null}

        {goals && goals.length > 0 && (
          <label className="bp4-label">
            Associated goal
            <div className="bp4-select">
              <select value={sessionGoalId} onChange={handleGoalChange}>
                {goalsToShow}
              </select>
            </div>
          </label>
        )}

        <div>
          <label className="bp4-label">
            Notes:
            <MarkdownTextEditor
              initialValue={sessionNotes}
              value={sessionNotes}
              onTextChange={handleNotesChange}
              placeholder="What did you do in this session?"
            />
          </label>
        </div>

        <div className={modalStyles.fieldDiv}>
          <TagSelector
            tags={tags}
            selectedTags={selectedTags}
            onTagSelect={handleTagSelect}
            onTagRemove={handleTagRemove}
          />
        </div>
      </div>
      <div className={`bp4-dialog-footer ${modalStyles.footer}`}>
        <Button onClick={handleClose}>Close</Button>
        <Button
          onClick={handleSaveSession}
          intent={Intent.PRIMARY}
          loading={isSaving}
        >
          Save changes
        </Button>
      </div>
    </Dialog>
  );
};

export { EditSessionModal };

import React, { useContext, useEffect, useState } from 'react';
import _ from 'lodash';
import { ITask } from '../../common-src/types/Task';
import { handleGenericStringIdsDragDrop } from '../../func/task-ui-utils';
import { TagsContext } from '../../state/TagsContext';
import { TasksContext } from '../../state/TasksContext';
import { UserContext } from '../../state/UserContext';
import { useLayoutStyles } from '../../style/components/layoutStyles';
import { useAllPageStyles } from '../../style/components/pageStyles';
import { ToDoList } from '../common/ToDoList/ToDoList';
import { EditTaskModal } from '../modals/EditTaskModal';
import { ProjectsListContext } from '../../state/ProjectsListContext';
import { ComponentType, LoadingSkeleton } from '../common/LoadingSkeleton';
import { ModalContext } from '../../state/ModalContext';

const TodoListPage: React.FunctionComponent = () => {
  const userContext = useContext(UserContext);
  const tasksContext = useContext(TasksContext);
  const tagsContext = useContext(TagsContext);
  const projectsContext = useContext(ProjectsListContext);
  const modalContext = useContext(ModalContext);

  const allPageStyles = useAllPageStyles();
  const layoutStyles = useLayoutStyles();

  const [todoListTaskIds, setTodoListTaskIds] = useState<string[] | null>(null);
  const [editTaskModalIsOpen, setEditTaskModalIsOpen] = useState(false);
  const [taskBeingEdited, setTaskBeingEdited] = useState<ITask | null>(null);

  useEffect(() => {
    if (userContext?.todoListTaskIds) {
      setTodoListTaskIds(userContext.todoListTaskIds);
    }
  }, [userContext?.todoListTaskIds]);

  if (
    !userContext ||
    !tasksContext ||
    !tagsContext ||
    !projectsContext ||
    !modalContext
  ) {
    return null;
  }

  const { updateTodoListTaskIds } = userContext;
  const { saveTask, isFetchingTasks } = tasksContext;
  const { tags } = tagsContext;
  const { projects } = projectsContext;
  const { openAddSessionModal } = modalContext;

  const tagsArr = tags ?? [];

  const openEditTaskModal = (task: ITask) => {
    setEditTaskModalIsOpen(true);
    setTaskBeingEdited(task);
  };

  const closeEditTaskModal = () => {
    setEditTaskModalIsOpen(false);
    setTaskBeingEdited(null);
  };

  const onDragEndTodoList = (result: any) => {
    if (todoListTaskIds) {
      const newTodoListTaskIds = handleGenericStringIdsDragDrop(
        result,
        todoListTaskIds
      );
      if (newTodoListTaskIds) {
        setTodoListTaskIds(newTodoListTaskIds);
        updateTodoListTaskIds(newTodoListTaskIds);
      }
    }
  };

  return (
    <div className={allPageStyles.genericPage}>
      <h1>Your todo list</h1>
      <div className={`${layoutStyles.col}`}>
        {isFetchingTasks ? (
          <div>
            <LoadingSkeleton type={ComponentType.TodoList} maxWidth={700} />
          </div>
        ) : (
          <>
            {todoListTaskIds && (
              <ToDoList
                todoListTaskIds={todoListTaskIds}
                openEditTaskModal={openEditTaskModal}
                openAddSessionModal={openAddSessionModal}
                onDragEnd={onDragEndTodoList}
              />
            )}
          </>
        )}
      </div>
      <EditTaskModal
        modalIsOpen={editTaskModalIsOpen}
        onRequestClose={closeEditTaskModal}
        task={taskBeingEdited!}
        saveTask={saveTask!}
        tags={tagsArr}
        simplifiedProjectList={projects ?? []}
      />
    </div>
  );
};

export { TodoListPage };

import { Button, Menu, MenuItem, Popover, Position } from '@blueprintjs/core';
import { DateTime } from 'luxon';
import React, { useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import { getProductivityTooltipForGoalInPieChart } from '../../common-src/productivity';
import { GoalType, IGoal } from '../../common-src/types/Goal';
import { UserContext } from '../../state/UserContext';
import { WindowContext } from '../../state/WindowContext';
import { useGenericStyles } from '../../style/components/genericStyles';
import { WhatNeedsToBeDone } from '../common/WhatNeedsToBeDone';
import { IOpenAddSessionModalOpts } from '../../common-src/types/UiTypes';
import { PieChartType, PieChart } from '../common/charts/PieChart';
import { IGoalSummary } from '../../common-src/types/Reporting';

interface IGoalSummaryProps {
  goal: IGoal;
  goalSummary?: IGoalSummary;
  startDate: DateTime;
  endDate: DateTime;
  openEditGoalModal: (goal: IGoal) => void;
  openAddSessionModal: (opts?: IOpenAddSessionModalOpts) => void;
  openDeleteGoalModal: (goal: IGoal) => void;
}

const GoalSummary: React.FunctionComponent<IGoalSummaryProps> = (
  props: IGoalSummaryProps
) => {
  const userContext = useContext(UserContext);
  const windowContext = useContext(WindowContext);
  const genericStyles = useGenericStyles();

  const navigate = useNavigate();

  const { trackingPeriod, customTrackingPeriod, labourTypes } = userContext!;
  const {
    goal,
    goalSummary,
    openEditGoalModal,
    openDeleteGoalModal,
    openAddSessionModal,
  } = props;

  if (!goal) {
    return <div style={{ flex: 1 }} key="no-proj" />;
  }

  const handleNavigateToGoalDetails = () => {
    navigate(`/goals/${goal.id}`);
  };

  const handleOpenEditGoalModal = () => {
    openEditGoalModal(goal);
  };

  const handleOpenDeleteGoalModal = () => {
    const goalToDelete: IGoal = goal;
    if (!goalToDelete) {
      return;
    }
    openDeleteGoalModal(goalToDelete);
  };

  const handleOpenAddSessionModal = () => {
    openAddSessionModal({ associatedGoal: goal });
  };

  const dropdown = (
    <Menu>
      <MenuItem
        icon="info-sign"
        text="Goal details"
        onClick={handleNavigateToGoalDetails}
      />
      <MenuItem icon="cog" text="Edit goal" onClick={handleOpenEditGoalModal} />
      {goal.type === GoalType.SESSION ? (
        <MenuItem
          icon="pulse"
          text="Add session for this goal"
          onClick={handleOpenAddSessionModal}
        />
      ) : null}
      <MenuItem
        icon="trash"
        text="Delete goal"
        onClick={handleOpenDeleteGoalModal}
      />
    </Menu>
  );

  const getStartDateText = (startDate: DateTime): string => {
    const today = DateTime.now().startOf('day');
    const startDateAtStartOfDay = startDate.startOf('day');
    if (startDateAtStartOfDay < today) {
      return `This goal started on ${startDateAtStartOfDay.toLocaleString(
        DateTime.DATE_MED
      )}`;
    } else if (startDateAtStartOfDay > today) {
      return `This goal starts on ${startDateAtStartOfDay.toLocaleString(
        DateTime.DATE_MED
      )}`;
    } else {
      return 'This goal starts today';
    }
  };

  const getEndDateText = (endDate: DateTime): string => {
    const today = DateTime.now().startOf('day');
    const endDateAtStartOfDay = endDate.startOf('day');
    if (endDateAtStartOfDay < today) {
      return `This goal ended on ${endDateAtStartOfDay.toLocaleString(
        DateTime.DATE_MED
      )}`;
    } else if (endDateAtStartOfDay > today) {
      return `This goal ends on ${endDateAtStartOfDay.toLocaleString(
        DateTime.DATE_MED
      )}`;
    } else {
      return 'This goal ends today';
    }
  };

  const associatedLabourType = labourTypes?.find(
    lt => lt.id === goal.labourTypeId
  );

  let associatedLabourTypeText = '';

  if (associatedLabourType) {
    associatedLabourTypeText = ` (associated with work type "${associatedLabourType.name}")`;
  }

  const startDateText = goal.startDate
    ? getStartDateText(DateTime.fromISO(goal.startDate))
    : '';

  const endDateText = goal.endDate
    ? getEndDateText(DateTime.fromISO(goal.endDate))
    : '';

  return (
    <div key={goal.id} style={{ flex: 1, marginTop: 40 }}>
      <div className={genericStyles.genericBox}>
        <h3>
          <em>{goal.name}</em>
        </h3>
        <div>
          <Popover content={dropdown} position={Position.RIGHT_BOTTOM}>
            <Button icon="more" minimal={true} />
          </Popover>
        </div>
        {!goal.isActive ? (
          <p>
            <em>This goal is inactive</em>
          </p>
        ) : null}
        {trackingPeriod && goal.isActive && goalSummary ? (
          <PieChart
            type={PieChartType.SINGLE_METRIC}
            tooltip={getProductivityTooltipForGoalInPieChart(
              goalSummary,
              trackingPeriod,
              customTrackingPeriod
            )}
            chartMetricsForPrimaryPie={[
              {
                id: goalSummary.goalId,
                label: 'Overall work',
                value: goalSummary?.goalScore,
              },
              {
                id: `${goalSummary.goalId}_anti`,
                label: 'Overall slacking',
                value: 1 - goalSummary?.goalScore,
                colour: 'rgb(255 255 255 / .1)',
              },
            ]}
            trackingPeriod={trackingPeriod}
            customTrackingPeriodInDays={customTrackingPeriod}
            width={250}
            height={150}
            margin={
              windowContext?.isMobile
                ? { top: 0, right: 0, bottom: 0, left: 0 }
                : { top: 20, right: 20, bottom: 20, left: 20 }
            }
            animate={true}
            centreText={`${Math.round(goalSummary?.goalScore * 100)}%`}
          />
        ) : null}
        {trackingPeriod && goal.isActive && goalSummary ? (
          <WhatNeedsToBeDone
            trackingPeriod={trackingPeriod}
            customTrackingPeriodInDays={customTrackingPeriod}
            type="GOAL"
            goalSummary={goalSummary}
          />
        ) : null}
        <div>
          {startDateText ? (
            <p>
              <em>{startDateText}</em>
            </p>
          ) : null}
          {endDateText ? (
            <p>
              <em>{endDateText}</em>
            </p>
          ) : null}
          {associatedLabourTypeText && associatedLabourType ? (
            <p>
              <em>
                This goal is associated with work type{' '}
                <strong>{associatedLabourType.name}</strong>
              </em>
            </p>
          ) : null}
        </div>
      </div>
    </div>
  );
};

export { GoalSummary };

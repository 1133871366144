import { Button } from '@blueprintjs/core';
import { DateTime } from 'luxon';
import React, { useContext, useState } from 'react';
import { ISession } from '../../../common-src/types/Session';
import { GoalContext } from '../../../state/GoalContext';
import { ProjectsListContext } from '../../../state/ProjectsListContext';
import { TasksContext } from '../../../state/TasksContext';
import { UserContext } from '../../../state/UserContext';
import { useCalendarStyles } from '../../../style/components/calendarStyles';
import { useLayoutStyles } from '../../../style/components/layoutStyles';
import { SessionCalendarItem } from './SessionCalendarItem';
import { TagsContext } from '../../../state/TagsContext';

enum DateRangeType {
  WEEK = 'week',
}

interface IDayWithSessions {
  date: DateTime;
  sessions: ISession[];
}

interface ISessionCalendarProps {
  dateRangeType: DateRangeType;
  sessions: ISession[];
}

const SessionsCalendar: React.FunctionComponent<ISessionCalendarProps> = (
  props: ISessionCalendarProps
) => {
  const { dateRangeType, sessions } = props;
  const userContext = useContext(UserContext);
  const projectsListContext = useContext(ProjectsListContext);
  const goalContext = useContext(GoalContext);
  const tasksContext = useContext(TasksContext);
  const tagsContext = useContext(TagsContext);

  const calendarStyles = useCalendarStyles();
  const layoutStyles = useLayoutStyles();

  const today = DateTime.local();
  const startOfWeek = today.startOf('week');
  const endOfWeek = today.endOf('week');

  const [currentDateRange, setCurrentDateRange] = useState({
    start: startOfWeek,
    end: endOfWeek,
  });

  const daysOfWeek: IDayWithSessions[] = [];

  for (let i = 0; i < 7; i++) {
    const sessionsOnDay = sessions?.filter(session =>
      DateTime.fromISO(session.date)
        .toLocal()
        .startOf('day')
        .equals(currentDateRange.start.plus({ days: i }).startOf('day'))
    );
    daysOfWeek.push({
      date: currentDateRange.start.plus({ days: i }),
      sessions: sessionsOnDay,
    });
  }

  const calendarHeight = 1000; // TODO: get this dynamically

  const getSessionPosition = (
    session: ISession
  ): { top: number; bottom: number } => {
    const sessionEnd = DateTime.fromISO(session.date);
    const sessionStart = sessionEnd.minus({ minutes: session.minutes });

    const sessionStartMinutes = sessionStart.hour * 60 + sessionStart.minute;
    const sessionEndMinutes = sessionEnd.hour * 60 + sessionEnd.minute;

    const sessionStartPercentage = sessionStartMinutes / (24 * 60);
    const sessionEndPercentage = sessionEndMinutes / (24 * 60);

    const sessionTop = sessionStartPercentage * calendarHeight;
    const sessionBottom =
      calendarHeight - sessionEndPercentage * calendarHeight;

    return { top: sessionTop, bottom: sessionBottom };
  };

  const getPositionForCurrentTimeLine = (): { top: number } => {
    const currentTime = DateTime.local();
    const currentTimeMinutes = currentTime.hour * 60 + currentTime.minute;
    const currentTimePercentage = currentTimeMinutes / (24 * 60);
    const currentTimeTop = currentTimePercentage * calendarHeight;
    return { top: currentTimeTop };
  };

  const handleClickPrevious = () => {
    setCurrentDateRange({
      start: currentDateRange.start.minus({ weeks: 1 }),
      end: currentDateRange.end.minus({ weeks: 1 }),
    });
  };

  const handleClickNext = () => {
    setCurrentDateRange({
      start: currentDateRange.start.plus({ weeks: 1 }),
      end: currentDateRange.end.plus({ weeks: 1 }),
    });
  };

  const currentTime = DateTime.local();

  return (
    <div>
      {dateRangeType === DateRangeType.WEEK ? (
        <>
          <div className={layoutStyles.toolbar}>
            <Button onClick={handleClickPrevious} minimal>
              ← Previous
            </Button>
            <Button
              onClick={handleClickNext}
              style={{ float: 'right' }}
              minimal
            >
              → Next
            </Button>
          </div>
          <div className={calendarStyles.calendar}>
            {daysOfWeek.map(day => {
              let shouldShowMarkerForCurrentTime = false;
              if (currentTime.startOf('day').equals(day.date.startOf('day'))) {
                shouldShowMarkerForCurrentTime = true;
              }
              const positionForCurrentTimeLine =
                getPositionForCurrentTimeLine();
              return (
                <div
                  className={calendarStyles.week_col}
                  key={day.date.valueOf()}
                >
                  <h3>
                    {day.date.toLocaleString(DateTime.DATE_MED_WITH_WEEKDAY)}
                  </h3>
                  <div>
                    {shouldShowMarkerForCurrentTime ? (
                      <div
                        className={calendarStyles.currentTimeMarker}
                        style={positionForCurrentTimeLine}
                      ></div>
                    ) : null}
                    {day.sessions?.map(session => {
                      const position = getSessionPosition(session);
                      const associatedProject =
                        projectsListContext?.projects?.find(
                          project => project.id === session.projectId
                        );
                      const associatedTask = tasksContext?.tasks?.find(
                        task => task.id === session.taskId
                      );
                      const associatedGoal = goalContext?.goals?.find(
                        goal => goal.id === session.goalId
                      );
                      const associatedLabourType =
                        userContext?.labourTypes?.find(
                          labourType => labourType.id === session.labourTypeId
                        );
                      const associatedTags = tagsContext?.tags?.filter(tag =>
                        session.tagIds?.includes(tag.id)
                      );

                      return (
                        <div
                          className={
                            calendarStyles.sessionCalendarItemContainer
                          }
                          style={position}
                          key={session.date}
                        >
                          <SessionCalendarItem
                            session={session}
                            associatedProject={associatedProject}
                            associatedTask={associatedTask}
                            associatedGoal={associatedGoal}
                            associatedLabourType={associatedLabourType}
                            associatedTags={associatedTags}
                          />
                        </div>
                      );
                    })}
                  </div>
                </div>
              );
            })}
          </div>
        </>
      ) : null}
    </div>
  );
};

export { SessionsCalendar, DateRangeType };

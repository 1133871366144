import { DateTime } from 'luxon';
import {
  getDatesInRange,
  getDateSessionTuplesInRange,
  getDateUnitTuplesInRange,
} from '../common-src/productivity';
import {
  DateAndValueTuple,
  IAreaChartData,
  IAreaChartMetric,
  IProductivityLineChartData,
  ISessionData,
  ISessionDataV2,
  IUnitData,
  IUnitDataV2,
} from '../common-src/types/ChartTypes';
import { scaleLinear } from '@visx/scale';
import { ScaleLinear } from 'd3';
import * as d3 from 'd3';
import { ISession } from '../common-src/types/Session';
import { IUnitOfLabour } from '../common-src/types/UnitOfLabour';
import { IGoalSummary } from '../common-src/types/Reporting';
import { GoalType } from '../common-src/types/Goal';

export function getAreaChartData(
  startDate: DateTime,
  endDate: DateTime,
  opts: {
    sessions?: ISession[];
    sessionsTarget?: number;
    units?: IUnitOfLabour[];
    unitsTarget?: number;
    name?: string;
    title?: string;
  }
): IAreaChartData {
  const { sessions, sessionsTarget, units, unitsTarget, name, title } = opts;
  const dateSeries = getDatesInRange(startDate, endDate);
  const metrics: IAreaChartMetric[] = [];
  let sessionDateTuples: DateAndValueTuple[] = [];

  if (sessions) {
    sessionDateTuples = getDateSessionTuplesInRange(sessions, dateSeries);
    metrics.push({
      name: name ?? 'Sessions',
      type: 'SESSIONS',
      dateValueTuples: sessionDateTuples,
      isTarget: false,
      colour: '#8884d8',
    });
  }

  if (sessionsTarget) {
    metrics.push({
      name: 'Sessions Target',
      type: 'SESSIONS',
      dateValueTuples: dateSeries.map(d => [d, sessionsTarget]),
      isTarget: true,
      colour: '#8884d8',
    });
  }

  if (units) {
    const unitDateTuples = getDateUnitTuplesInRange(units, dateSeries);
    metrics.push({
      name: name ?? 'Units',
      type: 'UNITS',
      dateValueTuples: unitDateTuples,
      isTarget: false,
      colour: '#82ca9d',
    });
  }

  if (unitsTarget) {
    metrics.push({
      name: 'Units Target',
      type: 'UNITS',
      dateValueTuples: dateSeries.map(d => [d, unitsTarget]),
      isTarget: true,
      colour: '#82ca9d',
    });
  }

  return {
    metrics,
    dateSeries,
    title,
  };
}

// TODO: add tests for this function
export function getProductivityLineChartDataForGoalInDateRange(
  goalSummary: IGoalSummary,
  startDate: DateTime,
  endDate: DateTime
): IProductivityLineChartData | null {
  let sessionData: ISessionData[] = [];
  let unitData: IUnitData[] = [];
  let dateSeries: DateTime[] = [];

  const {
    goalType,
    units,
    sessions,
    targetMinutes,
    customTarget,
    unitName,
    unitTypeId,
  } = goalSummary;

  const diff = endDate.diff(startDate, 'days').toObject().days!;
  if (diff && diff > 0) {
    for (let i = 0; i < diff; i++) {
      const dateToAdd = startDate.plus({ days: i }).startOf('day');
      dateSeries.push(dateToAdd);
    }
  }

  const datesInRange = getDatesInRange(startDate, endDate);

  if (goalType === GoalType.SESSION && sessions && targetMinutes) {
    sessionData.push({
      sessions: getDateSessionTuplesInRange(sessions, datesInRange),
      sessionTargets: dateSeries.map(d => [d, targetMinutes]),
    });
  } else if (goalType === GoalType.UNIT && units && customTarget && unitName) {
    unitData.push({
      unitName,
      units: getDateUnitTuplesInRange(units, datesInRange),
      unitTargets: dateSeries.map(d => [d, customTarget]),
    });
  }

  return {
    sessionData,
    unitData,
    dateSeries,
  };
}

// @TODO: add tests for this function
/**
 * Provides the data needed to render a productivity line chart for a project (or more generally for
 * any set of goal productivity data) in a given date range.
 * @param goalSummaries
 * @param start
 * @param end
 * @returns
 */
export function getProductivityLineChartDataForMultipleGoalsInDateRange(
  goalSummaries: IGoalSummary[],
  start: DateTime,
  end: DateTime
): IProductivityLineChartData | null {
  let sessionData: ISessionData[] = [];
  let unitData: IUnitData[] = [];
  let dateSeries: DateTime[] = [];

  const diff = end.diff(start, 'days').toObject().days!;
  if (diff && diff > 0) {
    for (let i = 0; i < diff; i++) {
      const dateToAdd = start.plus({ days: i }).startOf('day');
      dateSeries.push(dateToAdd);
    }
  }

  const datesInRange = getDatesInRange(start, end);

  goalSummaries.forEach(goalData => {
    const {
      goalType,
      units,
      sessions,
      targetMinutes,
      customTarget,
      unitName,
      unitTypeId,
      // projectId,
    } = goalData;
    if (goalType === GoalType.UNIT && unitName && units && customTarget) {
      unitData.push({
        unitName,
        units: getDateUnitTuplesInRange(units, datesInRange),
        unitTargets: dateSeries.map(d => [d, customTarget]),
      });
    } else if (goalType === GoalType.SESSION && sessions && targetMinutes) {
      sessionData.push({
        sessions: getDateSessionTuplesInRange(sessions, datesInRange),
        sessionTargets: dateSeries.map(d => [d, targetMinutes]),
      });
    }
  });
  return {
    sessionData,
    unitData,
    dateSeries,
  };
}

export function getYScales(
  productivityLineChartData: IProductivityLineChartData,
  yMax: number
): (ScaleLinear<number, number, never> | undefined)[] {
  const yScales: (ScaleLinear<number, number, never> | undefined)[] = [];

  productivityLineChartData.sessionData?.forEach(sessionData => {
    const yScaleForSessions = getYScaleForSessions(sessionData, yMax);
    yScales.push(yScaleForSessions);
  });

  productivityLineChartData.unitData?.map(unitData => {
    const yScaleForUnits = getYScaleForUnits(unitData, yMax);
    yScales.push(yScaleForUnits);
  });

  return yScales;
}

export function getYScaleForSessions(
  sessionData: ISessionData,
  yMax: number
): ScaleLinear<number, number, never> | undefined {
  if (sessionData) {
    const { sessions, sessionTargets } = sessionData;
    const maxSessions =
      Math.max(
        d3.max(sessions?.map(session => session[1])) || 0,
        sessionTargets?.[0]?.[1] || 0
      ) + 50;
    const yScaleForSessions = scaleLinear({
      domain: [0, maxSessions!],
      range: [yMax, 0],
      nice: true,
    });
    return yScaleForSessions;
  }
}

export function getYScaleForAllSessions(
  sessionDataArr: ISessionData[],
  yMax: number
): ScaleLinear<number, number, never> | undefined {
  if (sessionDataArr) {
    const maxSessions = Math.max(
      ...sessionDataArr.map(sessionData => {
        const { sessions, sessionTargets } = sessionData;
        return Math.max(
          d3.max(sessions?.map(session => session[1])) || 0,
          sessionTargets?.[0]?.[1] || 0
        );
      })
    );
    const yScaleForSessions = scaleLinear({
      domain: [0, maxSessions!],
      range: [yMax, 0],
      nice: true,
    });
    return yScaleForSessions;
  }
}

export function getYScaleForSessionsV2(
  sessionData: ISessionDataV2,
  yMax: number
): ScaleLinear<number, number, never> | undefined {
  if (sessionData) {
    const { sessions } = sessionData;
    const maxSessions = Math.max(
      d3.max(sessions?.map(session => session[1])) || 0
    );
    const yScaleForSessions = scaleLinear({
      domain: [0, maxSessions!],
      range: [yMax, 0],
      nice: true,
    });
    return yScaleForSessions;
  }
}

export function getYScaleForAllSessionsV2(
  sessionDataArr: ISessionDataV2[],
  yMax: number
): ScaleLinear<number, number, never> | undefined {
  if (sessionDataArr) {
    const maxSessions = Math.max(
      ...sessionDataArr.map(sessionData => {
        const { sessions } = sessionData;
        return Math.max(d3.max(sessions?.map(session => session[1])) || 0);
      })
    );
    const yScaleForAllSessions = scaleLinear({
      domain: [0, maxSessions!],
      range: [yMax, 0],
      nice: true,
    });
    return yScaleForAllSessions;
  }
}

export function getYScaleForUnits(
  unitData: IUnitData,
  yMax: number
): ScaleLinear<number, number, never> | undefined {
  if (unitData) {
    const { units, unitTargets } = unitData;
    const maxUnits =
      Math.max(d3.max(units?.map(unit => unit[1]))!, unitTargets[0][1]) + 5; // TODO: fix exclam
    const yScaleForUnits = scaleLinear({
      domain: [0, maxUnits!],
      range: [yMax, 0],
      nice: true,
    });
    return yScaleForUnits;
  }
}

export function getYScaleForUnitsV2(
  unitData: IUnitDataV2,
  yMax: number
): ScaleLinear<number, number, never> | undefined {
  if (unitData) {
    const { units } = unitData;
    const maxUnits = Math.max(d3.max(units?.map(unit => unit[1]))!) + 5; // TODO: fix exclam
    const yScaleForUnits = scaleLinear({
      domain: [0, maxUnits!],
      range: [yMax, 0],
      nice: true,
    });
    return yScaleForUnits;
  }
}

export function getYScaleForUnitAndTarget(
  unitData: IUnitDataV2,
  unitTarget: number,
  yMax: number
): ScaleLinear<number, number, never> | undefined {
  const { units } = unitData;
  const maxUnits =
    Math.max(d3.max(units?.map(unit => unit[1]))!, unitTarget) + 5; // TODO: fix exclam
  const yScaleForUnits = scaleLinear({
    domain: [0, maxUnits!],
    range: [yMax, 0],
    nice: true,
  });
  return yScaleForUnits;
}

import { DateTime } from 'luxon';

export const BEGINNING_OF_TIME = DateTime.fromObject({
  year: 1970,
  month: 1,
  day: 1,
});

export const ItemTypes = {
  TASK: 'task',
  PROJECT: 'project',
};

// The following are not currently used because different libraries use different formats.
// Ultimately we should only use one (maximum) library for date/time pickers.
export const DATE_FORMAT = 'MMM Do, YYYY';
export const TIME_FORMAT = 'HH:mm';
export const DATE_TIME_FORMAT = `MMM dd, yyyy ${TIME_FORMAT}`;

import React, { useState, useContext, useEffect } from 'react';
import uuid from 'uuid/v4';
import { DateTime } from 'luxon';
import {
  Dialog,
  Checkbox,
  Button,
  Intent,
  Divider,
  NumericInput,
} from '@blueprintjs/core';
import { UserContext } from '../../state/UserContext';
import { isEqual, capitalise } from '../../func/utils';
import { ISimplifiedProject } from '../../common-src/types/Project';
import { ISubtask } from '../../common-src/types/Subtask';
import { ITag } from '../../common-src/types/Tag';
import TaskProgressBar from '../common/TaskProgressBar';
import {
  IHabitConsistencyData,
  ITask,
  TaskType,
} from '../../common-src/types/Task';
import { IReminderOptions } from '../../common-src/types/Reminder';
import { orderSubtasksByPosition } from '../../func/functions';
import { AlertsContext } from '../../state/AlertsContext';
import { MarkdownTextEditor } from '../common/MarkdownTextEditor';
import { TagSelector } from '../common/tags/TagSelector';
import { TaskChecklist } from '../common/tasks/TaskChecklist';
import {
  getHabitConsistency,
  getHabitConsistencyData,
} from '../../common-src/tasks';
import { useModalStyles } from '../../style/components/genericStyles';
import { HabitCadenceUnit } from '../../common-src/types/Goal';
import {
  DatetimeFormat,
  DatetimePicker,
  DatetimePickerType,
} from '../common/DatetimePicker/DatetimePicker';

interface Props {
  task: ITask;
  onRequestClose: () => void;
  modalIsOpen: boolean;
  saveTask:
    | ((task: ITask, reminderOptions?: IReminderOptions) => Promise<void>)
    | null;
  tags?: ITag[];
  simplifiedProjectList: ISimplifiedProject[];
}

const EditTaskModal: React.FunctionComponent<Props> = (props: Props) => {
  const userContext = useContext(UserContext);
  const alertsContext = useContext(AlertsContext);
  const modalStyles = useModalStyles();

  // Properties of the task itself, reflected in state. All of these should be initialised in the main useEffect.
  const [taskName, setTaskName] = useState('');
  const [projectId, setProjectId] = useState<string | undefined>(undefined);
  const [phase, setPhase] = useState(''); // TODO: remove
  const [dueDate, setDueDate] = useState<DateTime | undefined>(undefined);
  const [startDate, setStartDate] = useState<DateTime | undefined>(undefined);
  const [reminderTime, setReminderTime] = useState<DateTime | undefined>(
    undefined
  );
  const [notes, setNotes] = useState('');
  const [done, setDone] = useState(false);
  const [subtasks, setSubtasks] = useState<ISubtask[]>([]);
  const [containsChecklist, setContainsChecklist] = useState(false);
  const [isTrackingUnits, setIsTrackingUnits] = useState(false);
  const [unitName, setUnitName] = useState<string | undefined>(undefined);
  const [unitTarget, setUnitTarget] = useState<number | undefined>(undefined);
  const [unitsCompleted, setUnitsCompleted] = useState<number | undefined>(
    undefined
  );
  const [tagIds, setTagIds] = useState<string[] | undefined>(undefined);
  const [completionDateTime, setCompletionDateTime] = useState<
    DateTime | undefined
  >(undefined);

  const [estimatedMinutes, setEstimatedMinutes] = useState<number>();

  const [taskType, setTaskType] = useState<TaskType | undefined>(undefined);

  // transient generic state
  const [errors, setErrors] = useState([]);
  const [isSaving, setIsSaving] = useState(false);

  // adding/editing subtasks
  const [inAddSubtaskMode, setInAddSubtaskMode] = useState(false);
  const [newSubtaskName, setNewSubtaskName] = useState<string | undefined>(
    undefined
  );
  const [inEditSubtaskMode, setInEditSubtaskMode] = useState(false);
  const [subtaskBeingEditedId, setSubtaskBeingEditedId] = useState<
    string | undefined
  >(undefined);
  const [editedSubtaskName, setEditedSubtaskName] = useState('');

  // habit completions
  const [completions, setCompletions] = useState<string[]>([]);

  // habit cadence and dates
  const [cadenceUnit, setCadenceUnit] = useState<HabitCadenceUnit | undefined>(
    undefined
  );
  const [cadenceRegularity, setCadenceRegularity] = useState<
    number | undefined
  >(undefined);
  const [habitStartDate, setHabitStartDate] = useState<string | undefined>();
  const [habitEndDate, setHabitEndDate] = useState<string | undefined>();

  const [habitConsistency, setHabitConsistency] = useState<number | undefined>(
    undefined
  );
  const [habitConsistencyData, setHabitConsistencyData] = useState<
    IHabitConsistencyData | undefined
  >(undefined);

  // due date focus
  const [dueDateFocused, setDueDateFocused] = useState<boolean>(false);
  const [startDateFocused, setStartDateFocused] = useState<boolean>(false);

  const handleSaveTask = async event => {
    event.preventDefault();

    const { reminderType, email, phoneNumber, messagingTokenRef } =
      userContext!;

    if (taskName !== '') {
      setIsSaving(true);
      const containsHabit: boolean = Boolean(
        task.type === TaskType.HABIT &&
          cadenceUnit &&
          cadenceRegularity &&
          habitStartDate
      );
      const updatedTask: ITask = {
        type: task.type ?? TaskType.STANDARD,
        id: task.id,
        name: taskName,
        projectId: projectId,
        phase: phase,
        dueDate: dueDate ? dueDate.toISO() : null,
        startDate: startDate ? startDate.toISO() : null,
        reminderTime: reminderTime ? reminderTime.toISO() : null,
        notes: notes,
        done: done,
        containsChecklist: containsChecklist ? containsChecklist : false,
        subtasks: subtasks,
        isTrackingUnits: isTrackingUnits ? isTrackingUnits : false,
        unitName: unitName ? unitName : null,
        unitTarget: unitTarget ? unitTarget : null,
        unitsCompleted: unitsCompleted ? unitsCompleted : null,
        tagIds: tagIds ? tagIds : null,
        completionDateTime: completionDateTime
          ? completionDateTime.toISO()
          : null,
        habit: containsHabit
          ? {
              cadence: {
                unit: cadenceUnit ?? HabitCadenceUnit.DAY,
                regularity: cadenceRegularity ?? 1,
              },
              completions: completions,
              startDate: habitStartDate ?? '',
              endDate: habitEndDate,
            }
          : null,
        estimatedMinutes: estimatedMinutes,
      };
      try {
        await props.saveTask!(updatedTask);
        alertsContext!.addAlert('Updated task', Intent.SUCCESS);
      } catch (err) {
        alertsContext!.addAlert('Error updating task', Intent.DANGER);
      } finally {
        setIsSaving(false);
        handleClose();
      }
    }
  };

  const handleClose = () => {
    setTaskName('');
    setProjectId('');
    setPhase('');
    setDueDate(undefined);
    setStartDate(undefined);
    setReminderTime(undefined);
    setNotes('');
    setDone(false);
    setInAddSubtaskMode(false);
    setContainsChecklist(false);
    setSubtasks([]);
    setInEditSubtaskMode(false);
    setIsTrackingUnits(false);
    setUnitName(undefined);
    setUnitTarget(undefined);
    setUnitsCompleted(undefined);
    setTagIds(undefined);
    setCompletionDateTime(undefined);
    setTaskType(undefined);
    setCadenceUnit(undefined);
    setCadenceRegularity(undefined);
    setHabitStartDate(undefined);
    setHabitEndDate(undefined);
    setCompletions([]);
    setEstimatedMinutes(undefined);
    props.onRequestClose();
  };

  const handleTextChange = event => {
    setTaskName(event.target.value);
  };

  const handleProjectChange = event => {
    setProjectId(event.target.value);
  };

  const onEstimatedMinutesChange = value => {
    setEstimatedMinutes(value);
  };

  const handleNotesChange = value => {
    setNotes(value);
  };

  const handleCheck = () => {
    const prevDone = done;
    const isDone = !prevDone;
    setDone(isDone);
    setCompletionDateTime(isDone ? DateTime.now() : undefined);
  };

  const handleDueDateChange = (date?: DateTime) => {
    const newDueDate = date ?? undefined;
    setDueDate(newDueDate);
  };

  useEffect(() => {
    if (reminderTime) {
      const newDueDate = dueDate;
      if (newDueDate) {
        const updatedReminderTime = reminderTime.set({
          year: newDueDate.year,
          month: newDueDate.month,
          day: newDueDate.day,
          second: 0,
        });
        setReminderTime(updatedReminderTime);
      } else {
        setReminderTime(undefined);
      }
    }
  }, [dueDate]);

  const handleStartDateChange = (date?: DateTime) => {
    const newStartDate = date ?? undefined;
    setStartDate(newStartDate);
  };

  const handleDueDateFocusChange = (arg: { focused: boolean }) => {
    setDueDateFocused(arg.focused);
  };

  const handleStartDateFocusChange = (arg: { focused: boolean }) => {
    setStartDateFocused(arg.focused);
  };

  const handleReminderTimeChange = (reminderTime?: DateTime) => {
    const attachedDueDate = dueDate;
    if (reminderTime && attachedDueDate) {
      // ensure that the date of the reminder is the same as the due date (at least for now)
      const normalisedReminderTime = reminderTime.set({
        year: attachedDueDate.year,
        month: attachedDueDate.month,
        day: attachedDueDate.day,
        second: 0,
        millisecond: 0,
      });
      setReminderTime(normalisedReminderTime);
    } else {
      setReminderTime(undefined);
    }
  };

  const handleClickTrackUnits = () => {
    setIsTrackingUnits(true);
    setUnitName('');
    setUnitTarget(0);
    setUnitsCompleted(0);
  };

  const handleUnitNameChange = event => {
    setUnitName(event.target.value);
  };

  const handleUnitTargetChange = event => {
    setUnitTarget(event.target.value);
  };

  const handleUnitsCompletedChange = event => {
    setUnitsCompleted(event.target.value);
  };

  const handleDeleteUnitTracking = () => {
    // TODO: should check if the user really wants to do this
    setIsTrackingUnits(false);
  };

  // checklist-related functions

  const handleClickAddChecklist = () => {
    setInAddSubtaskMode(true);
    setNewSubtaskName('');
    setContainsChecklist(true);
  };

  const handleEditNewSubtaskName = event => {
    setNewSubtaskName(event.target.value);
  };

  const handleAddNewSubtask = () => {
    if (newSubtaskName) {
      const id = uuid();
      const newSubtask: ISubtask = {
        name: newSubtaskName,
        id,
        done: false,
      };
      const currentSubtasks = subtasks.slice();
      currentSubtasks.push(newSubtask);
      setSubtasks(currentSubtasks.slice());
      setNewSubtaskName('');
    }
  };

  const handleDismissNewSubtask = () => {
    setInAddSubtaskMode(false);
  };

  const handleEnterSubtaskMode = () => {
    setInAddSubtaskMode(true);
  };

  const handleDeleteChecklist = () => {
    // TODO: should check if the user really wants to do this
    setContainsChecklist(false);
    setSubtasks([]);
  };

  const handleCheckSubtask = e => {
    const subtask: ISubtask = subtasks.find(
      (sub: ISubtask) => sub.id === e.target.name
    )!;
    const updatedSubtask = Object.assign(subtask, { done: !subtask!.done });
    const updatedSubtasks = Object.assign(subtasks, updatedSubtask);
    setSubtasks(updatedSubtasks.slice());
  };

  const handleSubtaskClick = e => {
    const subtaskId = e.target.id.replace('-text', '');
    setInEditSubtaskMode(true);
    setInAddSubtaskMode(false);
    setSubtaskBeingEditedId(subtaskId);
    setEditedSubtaskName(
      subtasks.find((sub: ISubtask) => sub.id === subtaskId)!.name
    );
  };

  const handleSaveEditedSubtask = () => {
    if (editedSubtaskName) {
      const subtask = subtasks.find(sub => sub.id === subtaskBeingEditedId);
      const updatedSubtask = Object.assign({}, subtask, {
        name: editedSubtaskName,
      });

      const updatedSubtasks = subtasks.map(sub => {
        if (sub.id === subtaskBeingEditedId) {
          return updatedSubtask;
        }
        return sub;
      });

      setSubtasks(updatedSubtasks.slice());
      setInEditSubtaskMode(false);
    }
  };

  const handleDismissEditingSubtask = () => {
    setInEditSubtaskMode(false);
  };

  const handleEditOldSubtaskName = event => {
    setEditedSubtaskName(event.target.value);
  };

  const handleDeleteSubtask = e => {
    if (subtaskBeingEditedId) {
      const updatedSubtasks = subtasks.filter(
        (sub: ISubtask) => sub.id !== subtaskBeingEditedId
      );
      setSubtasks(updatedSubtasks.slice());
      setInEditSubtaskMode(false);
    }
  };

  const handleDragSubtaskEnd = result => {
    const { destination, source, draggableId } = result;
    const initialSubtasks = orderSubtasksByPosition(subtasks);

    if (!destination) {
      return;
    }

    if (
      destination.droppableId === source.droppableId &&
      destination.index === source.index
    ) {
      return;
    }

    const movedSubtask = initialSubtasks.find(task =>
      isEqual(task.id, draggableId)
    );

    const newSubtasks = Array.from(initialSubtasks);
    newSubtasks.splice(source.index, 1);
    newSubtasks.splice(destination.index, 0, movedSubtask!);

    newSubtasks.forEach((subtask, index) => {
      subtask.position = index;
    });

    setSubtasks(newSubtasks);
  };

  const isOutsideStartDateRange = (day: DateTime) => {
    if (!(day < dueDate!)) {
      return true;
    }
    return false;
  };

  const getSelectedTags = (): ITag[] => {
    const { tags } = props;
    if (tagIds) {
      const selectedTags = tags!.filter(
        tag => tagIds && tagIds.includes(tag.id)
      );
      return selectedTags || [];
    } else {
      return [];
    }
  };

  const handleTagSelect = (tag: ITag) => {
    let newTagIds = tagIds;
    const tagId = tag.id;
    if (newTagIds) {
      if (!newTagIds.includes(tagId)) {
        newTagIds.push(tagId);
      } else {
        newTagIds = newTagIds.filter(id => id !== tagId);
      }
    } else {
      newTagIds = [tagId];
    }
    setTagIds(newTagIds.slice());
  };

  const handleTagRemove = (tagName: string, index: number) => {
    const selectedTags = getSelectedTags();
    // @TODO: blueprint doesn't appear to have a way to remove a tag by id. Find a way around this
    const tagToRemove = selectedTags.find(tag => tag.name === tagName);
    if (tagToRemove) {
      const newListOfSelectedTags = selectedTags.filter(
        tag => tag.id !== tagToRemove.id
      );
      const newTagIds = newListOfSelectedTags.map(tag => tag.id);
      setTagIds(newTagIds.slice());
    }
  };

  const { task, simplifiedProjectList, tags } = props;

  useEffect(() => {
    if (task) {
      setTaskName(task.name);
      setProjectId(task.projectId);
      setDueDate(task.dueDate ? DateTime.fromISO(task.dueDate) : undefined);
      setStartDate(
        task.startDate ? DateTime.fromISO(task.startDate) : undefined
      );
      setReminderTime(
        task.reminderTime ? DateTime.fromISO(task.reminderTime) : undefined
      );
      setNotes(task.notes!);
      setDone(task.done);
      setContainsChecklist(task.containsChecklist);
      setSubtasks(task.subtasks ? task.subtasks.slice() : []);
      setIsTrackingUnits(task.isTrackingUnits);
      setUnitName(task.unitName || undefined);
      setUnitTarget(task.unitTarget || undefined);
      setUnitsCompleted(task.unitsCompleted || undefined);
      setTagIds(task.tagIds ? task.tagIds.slice() : undefined);
      setCompletionDateTime(
        task.completionDateTime
          ? DateTime.fromISO(task.completionDateTime)
          : undefined
      );
      setTaskType(task.type);
      // Habits
      setCompletions(task.habit?.completions || []);
      setCadenceUnit(task.habit?.cadence.unit || undefined);
      setCadenceRegularity(task.habit?.cadence.regularity || undefined);
      setHabitStartDate(task.habit?.startDate ?? '');
      setHabitEndDate(task.habit?.endDate ?? '');

      const newHabitConsistency = getHabitConsistency(task);
      const newHabitConsistencyData = getHabitConsistencyData(task);
      setHabitConsistency(newHabitConsistency);
      setHabitConsistencyData(newHabitConsistencyData);

      // Estimates
      setEstimatedMinutes(task.estimatedMinutes);
    }
  }, [task]);

  if (!simplifiedProjectList) {
    return null;
  }
  if (!task) {
    return null;
  }
  const associatedProject = simplifiedProjectList.find(
    p => p.id === task.projectId
  );

  const projectsToShow: JSX.Element[] = [];
  const phasesToShow: any[] = [];

  simplifiedProjectList.forEach((project: ISimplifiedProject) => {
    projectsToShow.push(
      <option value={project.id} key={project.id}>
        {project.name}
      </option>
    );
  });

  associatedProject?.phases?.forEach(phase => {
    phasesToShow.push(
      <option value={phase.name} key={`${associatedProject.id}-${phase}`}>
        {phase.name}
      </option>
    );
  });

  const selectedTags = getSelectedTags();

  return (
    <Dialog
      isOpen={props.modalIsOpen}
      onClose={handleClose}
      title="Edit your task"
      canOutsideClickClose={false}
    >
      <div className={`bp4-dialog-body ${modalStyles.body}`}>
        {errors &&
          errors.map(errorMsg => (
            <div className="bp4-callout bp4-intent-danger bp4-icon-info-sign .modifier">
              {errorMsg}
            </div>
          ))}

        <div className={modalStyles.fieldDiv}>
          <label className="bp4-label">
            Task name:
            <input
              className="bp4-input bp4-fill"
              type="text"
              onChange={handleTextChange}
              value={taskName}
            />
          </label>
        </div>
        <div>
          <p>
            Type:{' '}
            <strong>
              {taskType
                ? taskType.toLowerCase()
                : TaskType.STANDARD.toLowerCase()}
            </strong>
          </p>
        </div>

        <Divider />

        {simplifiedProjectList && simplifiedProjectList.length > 0 && (
          <div className={modalStyles.fieldDiv}>
            <label className="bp4-label">
              Associated project
              <div className="bp4-select">
                <select value={projectId} onChange={handleProjectChange}>
                  {projectsToShow}
                </select>
              </div>
            </label>
          </div>
        )}

        <div className={modalStyles.fieldDiv}>
          <label className="bp4-label">
            Estimated time to complete (minutes):
            <NumericInput
              min={0}
              value={estimatedMinutes}
              fill
              onValueChange={onEstimatedMinutesChange}
            />
          </label>
        </div>

        <DatetimePicker
          id="task_duedate_picker"
          type={DatetimePickerType.date}
          label="Set due date"
          placeholder="Enter the due date here"
          datetime={dueDate}
          onDatetimeChange={handleDueDateChange}
          handleFocusChange={handleDueDateFocusChange}
          isFocused={dueDateFocused}
          datetimeFormat={DatetimeFormat.DATE}
          canClear={true}
        />

        {dueDate ? (
          <DatetimePicker
            id="task_reminder_time_picker"
            type={DatetimePickerType.time}
            label="Set reminder time (on due date)"
            placeholder="Enter reminder time here"
            datetime={reminderTime}
            onDatetimeChange={handleReminderTimeChange}
            datetimeFormat={DatetimeFormat.TIME}
            canClear={true}
            timeOpts={{
              timeIntervals: 15,
            }}
          />
        ) : null}

        {dueDate ? (
          <DatetimePicker
            id="task_startdate_picker"
            type={DatetimePickerType.date}
            label="Set start date (if you'd like to track progress)"
            placeholder="Enter the start date here"
            datetime={startDate}
            onDatetimeChange={handleStartDateChange}
            handleFocusChange={handleStartDateFocusChange}
            isFocused={startDateFocused}
            datetimeFormat={DatetimeFormat.DATE}
            canClear={true}
            isOutsideRange={isOutsideStartDateRange}
          />
        ) : null}

        <div style={{ paddingBottom: 10 }}>
          {!containsChecklist && (
            <Button onClick={handleClickAddChecklist}>Add checklist</Button>
          )}
          {containsChecklist && (
            <TaskChecklist
              task={task}
              subtaskBeingEditedId={subtaskBeingEditedId!}
              subtasks={subtasks}
              editedSubtaskName={editedSubtaskName!}
              newSubtaskName={newSubtaskName!}
              startDate={startDate!}
              dueDate={dueDate!}
              inEditSubtaskMode={inEditSubtaskMode}
              inAddSubtaskMode={inAddSubtaskMode}
              onEditNewSubtaskName={handleEditNewSubtaskName}
              onAddNewSubtask={handleAddNewSubtask}
              onDismissNewSubtask={handleDismissNewSubtask}
              onEnterSubtaskMode={handleEnterSubtaskMode}
              onDeleteChecklist={handleDeleteChecklist}
              onCheckSubtask={handleCheckSubtask}
              onSubtaskClick={handleSubtaskClick}
              onSaveEditedSubtask={handleSaveEditedSubtask}
              onDismissEditingSubtask={handleDismissEditingSubtask}
              onEditOldSubtaskName={handleEditOldSubtaskName}
              onDeleteSubtask={handleDeleteSubtask}
              onDragSubtaskEnd={handleDragSubtaskEnd}
            />
          )}
        </div>

        <div className={modalStyles.fieldDiv}>
          {!isTrackingUnits && (
            <Button onClick={handleClickTrackUnits}>Track units</Button>
          )}
          {isTrackingUnits && (
            <>
              <Divider />
              <div style={{ display: 'flex' }}>
                <h3 style={{ flex: 1 }}>
                  {unitName ? capitalise(unitName) : 'Units'}
                </h3>
                <div style={{ flex: 1 }}>
                  <Button
                    intent={Intent.DANGER}
                    minimal={true}
                    onClick={handleDeleteUnitTracking}
                    style={{ float: 'right' }}
                    icon="cross"
                  >
                    Delete
                  </Button>
                </div>
              </div>
              <TaskProgressBar
                task={Object.assign(task, {
                  startDate: startDate,
                  dueDate: dueDate,
                  unitsCompleted: unitsCompleted,
                  unitTarget: unitTarget,
                })}
                trackingType="UNITS_ONLY"
              />
              <div>
                <label className="bp4-label" style={{ marginTop: 10 }}>
                  Unit name:
                  <input
                    className="bp4-input bp4-fill"
                    type="text"
                    onChange={handleUnitNameChange}
                    value={unitName}
                  />
                </label>
                <label className="bp4-label">
                  Unit target:
                  <input
                    className="bp4-input bp4-fill"
                    type="text"
                    onChange={handleUnitTargetChange}
                    value={unitTarget}
                  />
                </label>
                <label className="bp4-label">
                  Units done:
                  <input
                    className="bp4-input bp4-fill"
                    type="text"
                    onChange={handleUnitsCompletedChange}
                    value={unitsCompleted}
                  />
                </label>
              </div>
              <Divider />
            </>
          )}
        </div>

        <div className={modalStyles.fieldDiv}>
          <label className="bp4-label">
            Notes:
            <MarkdownTextEditor
              initialValue={notes}
              value={notes}
              onTextChange={handleNotesChange}
              placeholder="Enter your insightful notes here"
            />
          </label>
        </div>

        <div className={modalStyles.fieldDiv}>
          <TagSelector
            tags={tags!}
            selectedTags={selectedTags}
            onTagSelect={handleTagSelect}
            onTagRemove={handleTagRemove}
          />
        </div>

        <div className={modalStyles.fieldDiv}>
          <Checkbox
            checked={done}
            label="Have you completed the task?"
            onChange={handleCheck}
          />
          {completionDateTime && (
            <p>{`Completed on ${completionDateTime.toLocaleString(
              DateTime.DATETIME_MED_WITH_WEEKDAY
            )}`}</p>
          )}
        </div>
      </div>
      <div className={`bp4-dialog-footer ${modalStyles.footer}`}>
        <Button onClick={handleClose}>Cancel</Button>
        <Button
          onClick={handleSaveTask}
          intent={Intent.PRIMARY}
          loading={isSaving}
        >
          Save
        </Button>
      </div>
    </Dialog>
  );
};

export { EditTaskModal };

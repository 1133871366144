import { Button, Menu, MenuItem, Popover, Position } from '@blueprintjs/core';
import React from 'react';
import { IGoal } from '../../../common-src/types/Goal';
import { IHabitSummary } from '../../../common-src/types/Reporting';
import { TrackingPeriod } from '../../../common-src/types/User';
import { useGenericStyles } from '../../../style/components/genericStyles';
import { useLayoutStyles } from '../../../style/components/layoutStyles';
import { HabitProgressBar } from '../HabitProgressBar';

interface IProps {
  habitGoal: IGoal;
  habitSummary: IHabitSummary;
  openEditHabitModal: (habit: IGoal) => void;
  openDeleteHabitModal: (habit: IGoal) => void;
  trackingPeriod: TrackingPeriod;
  customTrackingPeriod?: number;
}

const getTextForTrackingPeriod = (
  habitScorePercent: number,
  trackingPeriod: TrackingPeriod,
  customTrackingPeriod?: number
): string => {
  let text = '';
  switch (trackingPeriod) {
    case TrackingPeriod.CUSTOM:
      text = `Over the past ${customTrackingPeriod} days, you've been about ${Math.round(
        habitScorePercent
      )}% consistent with your habit.`;
      break;
    case TrackingPeriod.TODAY:
      text = `So far today, you've been about ${Math.round(
        habitScorePercent
      )}% consistent with your habit.`;
      break;
    case TrackingPeriod.LAST_7_DAYS:
      text = `Over the past 7 days, you've been about ${Math.round(
        habitScorePercent
      )}% consistent with your habit.`;
      break;
    case TrackingPeriod.THIS_WEEK:
      text = `So far this week, you've been about ${Math.round(
        habitScorePercent
      )}% consistent with your habit.`;
      break;
    case TrackingPeriod.THIS_MONTH:
      text = `So far this month, you've been about ${Math.round(
        habitScorePercent
      )}% consistent with your habit.`;
      break;
    case TrackingPeriod.THIS_QUARTER:
      text = `So far this quarter, you've been about ${Math.round(
        habitScorePercent
      )}% consistent with your habit.`;
      break;
    case TrackingPeriod.THIS_YEAR:
      text = `So far this year, you've been about ${Math.round(
        habitScorePercent
      )}% consistent with your habit.`;
      break;
    case TrackingPeriod.ALL_TIME:
      text = `Across the whole of time, you've been about ${Math.round(
        habitScorePercent
      )}% consistent with your habit.`;
      break;
    default:
      text = '';
      break;
  }
  return text;
};

const HabitSummary: React.FunctionComponent<IProps> = (props: IProps) => {
  const {
    habitGoal,
    habitSummary,
    openEditHabitModal,
    openDeleteHabitModal,
    trackingPeriod,
    customTrackingPeriod,
  } = props;

  const genericStyles = useGenericStyles();
  const layoutStyles = useLayoutStyles();

  if (!habitGoal || !habitSummary) {
    return null;
  }

  const textForTrackingPeriod = getTextForTrackingPeriod(
    habitSummary.habitScore * 100,
    trackingPeriod,
    customTrackingPeriod
  );

  const handleOpenDeleteHabitModal = () => {
    const habitToDelete: IGoal = habitGoal;
    if (!habitToDelete) {
      return;
    }
    openDeleteHabitModal(habitToDelete);
  };

  const dropdown = (
    <Menu>
      <MenuItem
        icon="cog"
        text="Edit habit"
        onClick={() => openEditHabitModal(habitGoal)}
      />
      <MenuItem
        icon="trash"
        text="Delete habit"
        onClick={handleOpenDeleteHabitModal}
      />
    </Menu>
  );

  return (
    <div className={`${genericStyles.genericBox}`}>
      <div style={{ display: 'flex' }}>
        <div style={{ flex: 11 }}>
          <h3>{habitGoal.name}</h3>
        </div>
        <div style={{ flex: 1 }}>
          <Popover content={dropdown} position={Position.RIGHT_BOTTOM}>
            <Button icon="more" minimal={true} />
          </Popover>
        </div>
      </div>
      <div>
        You aim to do this {habitGoal.cadence.regularity} time(s) per{' '}
        {habitGoal.cadence.unit.toLocaleLowerCase()}
      </div>
      <div className={layoutStyles.grid_2_thirds_col}>
        <div>
          <HabitProgressBar
            habitConsistency={habitSummary.habitScore}
            tooltipText={textForTrackingPeriod}
          />
        </div>
      </div>
    </div>
  );
};

export { HabitSummary };

import React, { useContext, useState } from 'react';
import { Dialog, Button, ButtonGroup, Intent } from '@blueprintjs/core';
import { IProject } from '../../common-src/types/Project';
import { AlertsContext } from '../../state/AlertsContext';
import { ISession } from '../../common-src/types/Session';
import { ModalContext } from '../../state/ModalContext';
import { SessionAndUnitContext } from '../../state/SessionAndUnitContext';
import { useModalStyles } from '../../style/components/genericStyles';

interface Props {
  onRequestClose: () => void;
  modalIsOpen: boolean;
  session: ISession;
}

const DeleteSessionModal: React.FunctionComponent<Props> = (props: Props) => {
  const alertsContext = useContext(AlertsContext);
  const modalContext = useContext(ModalContext);
  const sessionAndUnitContext = useContext(SessionAndUnitContext);

  const [isDeleting, setIsDeleting] = useState<boolean>(false);

  const modalStyles = useModalStyles();

  const handleDeleteSession = async event => {
    event.preventDefault();
    setIsDeleting(true);
    const { session } = props;
    try {
      await sessionAndUnitContext!.deleteSession(session.id.toString());
      alertsContext!.addAlert('Deleted session', Intent.SUCCESS);
    } catch (err) {
      alertsContext!.addAlert('Error deleting session', Intent.DANGER);
    } finally {
      setIsDeleting(false);
      handleClose();
    }
  };

  const handleClose = () => {
    props.onRequestClose();
  };

  return (
    <Dialog
      isOpen={props.modalIsOpen}
      onClose={props.onRequestClose}
      // title={sessionToDelete && `Delete session "${sessionToDelete!.name}"`}
      canOutsideClickClose={false}
    >
      <div className={`bp4-dialog-body ${modalStyles.body}`}>
        <p>Are you sure you want to delete this session?</p>
      </div>
      <div className={`bp4-dialog-footer ${modalStyles.footer}`}>
        <ButtonGroup>
          <Button onClick={handleClose}>Cancel</Button>
          <Button
            onClick={handleDeleteSession}
            intent={Intent.DANGER}
            loading={isDeleting}
          >
            Do it!
          </Button>
        </ButtonGroup>
      </div>
    </Dialog>
  );
};

export { DeleteSessionModal };

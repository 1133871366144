import React, { useEffect, useState } from 'react';
import SimpleMDEReact from 'react-simplemde-editor';
import 'easymde/dist/easymde.min.css';

interface IProps {
  initialValue?: string;
  value?: string;
  onTextChange: (text: string) => void;
  placeholder?: string;
}

const MarkdownTextEditor: React.FunctionComponent<IProps> = (props: IProps) => {
  const [text, setText] = useState<string>(props.initialValue || '');

  useEffect(() => {
    if (props.value && !text) {
      setText(props.value);
    }
  }, [props.value]);

  const handleTextChange = value => {
    props.onTextChange(value);
    setText(value);
  };

  return (
    <SimpleMDEReact
      className={'markdown-text markdown-editor'}
      value={text}
      onChange={handleTextChange}
      options={{
        status: false,
        blockStyles: {
          italic: '_',
        },
        spellChecker: true,
        hideIcons: ['image', 'side-by-side', 'fullscreen'],
        autofocus: false,
        placeholder: props.placeholder,
      }}
    />
  );
};

export { MarkdownTextEditor };

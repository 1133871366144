import { IProject } from './Project';

export interface IPomodoro {
  /**
   * The mode that the pomodoro is currently in.
   */
  mode: PomodoroMode;
  workDurationInMilliseconds: number;
  breakDurationInMilliseconds: number;
  project: IProject;
}

export enum PomodoroMode {
  WORK = 'work',
  WORK_COMPLETE = 'workComplete',
  BREAK = 'break',
}

import { createUseStyles } from 'react-jss';

export const useLayoutStyles = createUseStyles({
  grid_1_col: {
    display: 'grid',
    gridTemplateColumns: '1fr',
    gridTemplateRows: '1fr 1fr 1fr',
    textAlign: 'left',
  },
  grid_2_col: {
    display: 'grid',
    gridTemplateColumns: '1fr 1fr',
    textAlign: 'left',
  },
  grid_3_col: {
    display: 'grid',
    gridTemplateColumns: '1fr 1fr 1fr',
    textAlign: 'left',
  },
  grid_2_thirds_col: {
    display: 'grid',
    gridTemplateColumns: '2fr 1fr',
    textAlign: 'left',
  },
  grid_4_col: {
    display: 'grid',
    gridTemplateColumns: '1fr 1fr 1fr 1fr',
    textAlign: 'left',
  },
  grid_cell: {},
  '@media only screen and (min-width: 1000px) and (max-width: 1700px)': {
    grid_1_col: { gridTemplateColumns: '1fr', gridTemplateRows: '1fr' },
    grid_3_col: { gridTemplateColumns: '1fr 1fr' },
    grid_2_thirds_col: {
      gridTemplateColumns: '2fr 1fr',
      gridTemplateRows: '1fr',
    },
    grid_4_col: { gridTemplateColumns: '1fr', gridTemplateRows: '1fr' },
  },
  '@media only screen and (min-width: 320px) and (max-width: 1000px)': {
    grid_1_col: { gridTemplateColumns: '1fr', gridTemplateRows: '1fr' },
    grid_2_col: { gridTemplateColumns: '1fr' },
    grid_3_col: { gridTemplateColumns: '1fr' },
    grid_2_thirds_col: { gridTemplateColumns: '1fr', gridTemplateRows: '1fr' },
    grid_4_col: { gridTemplateColumns: '1fr', gridTemplateRows: '1fr' },
  },
  '@media only screen and (min-width: 320px) and (max-width: 1300px)': {
    grid_2_thirds_col: { gridTemplateColumns: '1fr' },
  },
  col: {},
  col_cell: { paddingBottom: 20 },
  col_cell_padded: {
    paddingTop: 20,
    paddingLeft: 20,
    paddingRight: 20,
    paddingBottom: 0,
  },
  col_cell_padded_bottom: {
    paddingBottom: 20,
    paddingLeft: 20,
    paddingRight: 20,
    paddingTop: 20,
  },
  spaced_col: {
    gridColumnGap: '20px',
    gridRowGap: '20px',
  },
  padded_col: {
    paddingTop: 20,
    paddingLeft: 20,
    paddingRight: 20,
    paddingBottom: 20,
  },
  toolbar: {
    textAlign: 'left',
    paddingBottom: 7,
  },
});

import React from 'react';
import { Tag } from '@blueprintjs/core';

interface Props {
  tagName: string;
  tagColour: string;
  marginRight?: boolean;
  onClick?: () => void;
}

class ColouredTag extends React.Component<Props> {
  render() {
    const { tagName, tagColour, marginRight, onClick } = this.props;
    return (
      <Tag
        style={{
          backgroundColor: tagColour || 'grey',
          color: tagColour === 'yellow' ? 'black' : 'white',
          marginRight: marginRight ? 5 : 0
        }}
        onClick={onClick}
      >
        {tagName}
      </Tag>
    );
  }
}

export { ColouredTag };

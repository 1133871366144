import React, { ReactNode, useContext } from 'react';
import { AuthenticationContext } from './AuthenticationContext';
import {
  apiCreateGoal,
  apiDeleteGoal,
  apiGetGoals,
  apiGetOverallHabitStatus,
  apiUpdateGoal,
} from '../client/apiClient';
import {
  GoalType,
  HabitCadenceUnit,
  IApiCreateGoalRequest,
  IGoal,
  IApiUpdateGoalRequest,
} from '../common-src/types/Goal';
import { IStatusOfAllHabits } from '../common-src/types/Reporting';
import { UserContext } from './UserContext';
import { getStartAndEndDatesForTrackingPeriod } from '../common-src/productivity';
import { DateTime } from 'luxon';
import { useQuery, useQueryClient } from '@tanstack/react-query';

interface IGoalContext {
  goals: IGoal[] | undefined;
  statusOfAllHabits?: IStatusOfAllHabits;
  createHabit: (
    habitName: string,
    cadenceUnit: HabitCadenceUnit,
    habitRegularity: number,
    habitStartDate: DateTime,
    habitEndDate?: DateTime
  ) => Promise<void>;
  createGoal: (createGoalRequest: IApiCreateGoalRequest) => Promise<IGoal>;
  updateGoal: (
    goalId: string,
    updateGoalRequest: IApiUpdateGoalRequest
  ) => Promise<IGoal>;
  updateHabit: (habit: IGoal) => Promise<void>;
  deleteGoal: (goal: IGoal) => Promise<void>;
  deleteHabit: (habit: IGoal) => Promise<void>;
  isFetchingGoals: boolean;
}

export const GoalContext = React.createContext<IGoalContext | null>(null);

interface Props {
  children: ReactNode;
}

const GoalContextProvider: React.FunctionComponent<Props> = (props: Props) => {
  const authenticationContext = useContext(AuthenticationContext);
  const userContext = useContext(UserContext);

  const queryClient = useQueryClient();

  const { trackingPeriod, customTrackingPeriod } = userContext!;

  const {
    isLoading: isFetchingGoals,
    isError: isFetchGoalsError,
    data: goals,
    error: fetchGoalsError,
  } = useQuery({
    queryKey: ['getGoals'],
    queryFn: async () => {
      try {
        if (
          authenticationContext &&
          authenticationContext.isUserAuthenticated
        ) {
          const accessToken = await authenticationContext.getToken();
          const goals = await apiGetGoals(accessToken);
          return goals;
        }
      } catch (err) {
        throw err;
      }
    },
    enabled: !!authenticationContext?.isUserAuthenticated,
  });

  const {
    isLoading: isFetchingHabitStatus,
    isError: isFetchHabitStatusError,
    data: statusOfAllHabits,
    error: fetchHabitStatusError,
  } = useQuery({
    queryKey: [
      'apiGetOverallHabitStatus',
      trackingPeriod,
      customTrackingPeriod,
    ],
    queryFn: async () => {
      try {
        if (
          authenticationContext &&
          authenticationContext.isUserAuthenticated &&
          trackingPeriod
        ) {
          const accessToken = await authenticationContext.getToken();
          const startAndEnd = getStartAndEndDatesForTrackingPeriod(
            trackingPeriod,
            customTrackingPeriod
          );
          const start_date = startAndEnd[0].toUTC().toISO();
          const end_date = startAndEnd[1].toUTC().toISO();
          const overallHabitStatus = await apiGetOverallHabitStatus(
            accessToken,
            start_date,
            end_date
          );
          return overallHabitStatus;
        }
      } catch (err) {
        throw err;
      }
    },
    enabled: !!authenticationContext?.isUserAuthenticated && !!trackingPeriod,
  });

  const createHabit = async (
    habitName: string,
    cadenceUnit: HabitCadenceUnit,
    habitRegularity: number,
    habitStartDate: DateTime,
    habitEndDate?: DateTime
  ) => {
    try {
      if (authenticationContext && authenticationContext.isUserAuthenticated) {
        const accessToken = await authenticationContext.getToken();
        const createGoalRequest: IApiCreateGoalRequest = {
          name: habitName,
          goal_type: GoalType.HABIT,
          start_date: habitStartDate.toUTC().toISO(),
          end_date: habitEndDate ? habitEndDate?.toUTC().toISO() : '',
          cadence: {
            unit: cadenceUnit,
            regularity: habitRegularity,
          },
        };
        const habitGoal = await apiCreateGoal(createGoalRequest, accessToken);
        queryClient.invalidateQueries({ queryKey: ['getGoals'] });
        queryClient.invalidateQueries({
          queryKey: [
            'apiGetOverallHabitStatus',
            trackingPeriod,
            customTrackingPeriod,
          ],
        });
        queryClient.invalidateQueries({
          queryKey: ['getRawProductivityForGoals'],
        });
      }
    } catch (err) {
      throw err;
    }
  };

  const createGoal = async (
    createGoalRequest: IApiCreateGoalRequest
  ): Promise<IGoal> => {
    try {
      if (authenticationContext && authenticationContext.isUserAuthenticated) {
        const accessToken = await authenticationContext.getToken();
        const goal = await apiCreateGoal(createGoalRequest, accessToken);
        queryClient.invalidateQueries({ queryKey: ['getGoals'] });
        queryClient.invalidateQueries({
          queryKey: [
            'apiGetOverallHabitStatus',
            trackingPeriod,
            customTrackingPeriod,
          ],
        });
        queryClient.invalidateQueries({
          queryKey: ['getRawProductivityForGoals'],
        });
        return goal;
      } else {
        throw new Error('User is not authenticated');
      }
    } catch (err) {
      throw err;
    }
  };

  const updateGoal = async (
    goalId: string,
    updateGoalRequest: IApiUpdateGoalRequest
  ): Promise<IGoal> => {
    try {
      if (authenticationContext && authenticationContext.isUserAuthenticated) {
        const accessToken = await authenticationContext.getToken();
        const goal = await apiUpdateGoal(
          goalId,
          updateGoalRequest,
          accessToken
        );
        queryClient.invalidateQueries({ queryKey: ['getGoals'] });
        queryClient.invalidateQueries({
          queryKey: [
            'apiGetOverallHabitStatus',
            trackingPeriod,
            customTrackingPeriod,
          ],
        });
        queryClient.invalidateQueries({
          queryKey: ['getRawProductivityForGoals'],
        });
        return goal;
      } else {
        throw new Error('User is not authenticated');
      }
    } catch (err) {
      throw err;
    }
  };

  const updateHabit = async (habit: IGoal) => {
    try {
      if (authenticationContext && authenticationContext.isUserAuthenticated) {
        const accessToken = await authenticationContext.getToken();
        const updateGoalRequest: IApiUpdateGoalRequest = {
          name: habit.name,
          goal_type: GoalType.HABIT,
          start_date: habit.startDate,
          end_date: habit.endDate,
          cadence: {
            unit: habit.cadence.unit,
            regularity: habit.cadence.regularity,
          },
          completions: habit.completions,
        };
        const updatedHabit = await apiUpdateGoal(
          habit.id,
          updateGoalRequest,
          accessToken
        );
        queryClient.invalidateQueries({ queryKey: ['getGoals'] });
        queryClient.invalidateQueries({
          queryKey: [
            'apiGetOverallHabitStatus',
            trackingPeriod,
            customTrackingPeriod,
          ],
        });
        queryClient.invalidateQueries({
          queryKey: ['getRawProductivityForGoals'],
        });
      }
    } catch (err) {
      throw err;
    }
  };

  const deleteGoal = async (goal: IGoal) => {
    try {
      if (authenticationContext && authenticationContext.isUserAuthenticated) {
        const accessToken = await authenticationContext.getToken();
        await apiDeleteGoal(goal.id, accessToken);
        queryClient.invalidateQueries({ queryKey: ['getGoals'] });
        queryClient.invalidateQueries({
          queryKey: [
            'apiGetOverallHabitStatus',
            trackingPeriod,
            customTrackingPeriod,
          ],
        });
        queryClient.invalidateQueries({
          queryKey: ['getRawProductivityForGoals'],
        });
      }
    } catch (err) {
      throw err;
    }
  };

  const deleteHabit = async (habit: IGoal) => {
    try {
      if (authenticationContext && authenticationContext.isUserAuthenticated) {
        const accessToken = await authenticationContext.getToken();
        await apiDeleteGoal(habit.id, accessToken);
        queryClient.invalidateQueries({ queryKey: ['getGoals'] });
        queryClient.invalidateQueries({
          queryKey: [
            'apiGetOverallHabitStatus',
            trackingPeriod,
            customTrackingPeriod,
          ],
        });
        queryClient.invalidateQueries({
          queryKey: ['getRawProductivityForGoals'],
        });
      }
    } catch (err) {
      throw err;
    }
  };

  return (
    <GoalContext.Provider
      value={{
        goals,
        statusOfAllHabits,
        createHabit,
        createGoal,
        updateGoal,
        updateHabit,
        deleteGoal,
        deleteHabit,
        isFetchingGoals,
      }}
    >
      {props.children}
    </GoalContext.Provider>
  );
};

export { GoalContextProvider };
export type { IGoalContext };

import React, { useState } from 'react';
import { useLayoutStyles } from '../../../style/components/layoutStyles';
import { DateTime } from 'luxon';
import {
  DatetimeFormat,
  DatetimePicker,
  DatetimePickerType,
} from '../DatetimePicker/DatetimePicker';

interface IProps {
  startDate: DateTime;
  endDate: DateTime;
  handleStartDateChange: (datetime?: DateTime) => void;
  handleEndDateChange: (datetime?: DateTime) => void;
}

const AreaChartPanel: React.FunctionComponent<IProps> = (props: IProps) => {
  const layoutStyles = useLayoutStyles();
  const { startDate, endDate } = props;

  const [isStartDateFocused, setIsStartDateFocused] = useState(false);
  const [isEndDateFocused, setIsEndDateFocused] = useState(false);

  const handleStartDateChange = (datetime?: DateTime) => {
    props.handleStartDateChange(datetime);
  };

  const handleEndDateChange = (datetime?: DateTime) => {
    props.handleEndDateChange(datetime);
  };

  const handleStartDateFocusChange = (arg: { focused: boolean }) => {
    setIsStartDateFocused(arg.focused);
  };

  const handleEndDateFocusChange = (arg: { focused: boolean }) => {
    setIsEndDateFocused(arg.focused);
  };

  return (
    <div className={`${layoutStyles.grid_3_col} ${layoutStyles.spaced_col}`}>
      <div className={layoutStyles.col}>
        <div className={`${layoutStyles.col_cell_padded}`}>
          <DatetimePicker
            id="area_chart_startdate_picker"
            type={DatetimePickerType.date}
            label="Start date"
            datetime={startDate}
            onDatetimeChange={handleStartDateChange}
            handleFocusChange={handleStartDateFocusChange}
            isFocused={isStartDateFocused}
            datetimeFormat={DatetimeFormat.DATE}
            canClear={false}
            isOutsideRange={day => day > endDate}
          />
        </div>
        <div className={`${layoutStyles.col_cell_padded_bottom}`}>
          <DatetimePicker
            id="area_chart_enddate_picker"
            type={DatetimePickerType.date}
            label="End date"
            datetime={endDate}
            onDatetimeChange={handleEndDateChange}
            handleFocusChange={handleEndDateFocusChange}
            isFocused={isEndDateFocused}
            datetimeFormat={DatetimeFormat.DATE}
            canClear={false}
            isOutsideRange={day => day < startDate}
          />
        </div>
      </div>
      <div></div>
      <div></div>
    </div>
  );
};

export { AreaChartPanel };

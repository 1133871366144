import React, { useContext, useState, useEffect } from 'react';
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';

import { ProjectsListContext } from '../../../state/ProjectsListContext';

import NavBarItem from './NavBarItem';
import { ISimplifiedProject } from '../../../common-src/types/Project';
import { Classes, Tree, TreeNodeInfo } from '@blueprintjs/core';
import { useNavbarSideStyles } from '../../../style/components/navbarStyles';

interface Props {
  isMobile?: boolean;
}

const NavBarProjectList: React.FunctionComponent<Props> = (props: Props) => {
  const projectsContext = useContext(ProjectsListContext);
  const navbarSideStyles = useNavbarSideStyles();

  const [projects, setProjects] = useState<ISimplifiedProject[]>([]);
  const [isArchivedProjectsOpen, setIsArchivedProjectsOpen] = useState(false);

  const archivedProjects = projects?.filter(project => project.archived);

  useEffect(() => {
    if (projectsContext && projectsContext.projects) {
      setProjects(projectsContext.projects);
    }
  }, [projectsContext?.projects]);

  if (!projectsContext || !projectsContext.projects) {
    return null;
  }

  const onDragEnd = result => {
    const { destination, source, draggableId } = result;
    const { saveMultipleProjects } = projectsContext;

    if (!destination) {
      return;
    }

    if (
      destination.droppableId === source.droppableId &&
      destination.index === source.index
    ) {
      return;
    }

    if (!projects) {
      return;
    }

    const movedProject = projects?.find(project => project.id === draggableId);

    const projectsInNewOrder = projects.slice();
    projectsInNewOrder.splice(source.index, 1);
    projectsInNewOrder.splice(destination.index, 0, movedProject!);

    const projectsWithCorrectPositions = projectsInNewOrder!.map(
      (project, index) => {
        const updatedProject = { ...project, position: index };
        return updatedProject;
      }
    );

    setProjects(projectsWithCorrectPositions);
    saveMultipleProjects(projectsWithCorrectPositions);
  };

  const getProjectsToRender = () => {
    const projectsToRender = projects.map((project, index) => {
      let linkDest = `/projects/${project.id}`;

      return (
        <div key={index}>
          {project.archived ? (
            <Draggable draggableId={project.id} index={index} key={project.id}>
              {provided => (
                <div
                  ref={provided.innerRef}
                  {...provided.draggableProps}
                  {...provided.dragHandleProps}
                ></div>
              )}
            </Draggable>
          ) : (
            <Draggable draggableId={project.id} index={index} key={project.id}>
              {provided => (
                <div
                  ref={provided.innerRef}
                  {...provided.draggableProps}
                  {...provided.dragHandleProps}
                >
                  <NavBarItem
                    to={linkDest}
                    text={project.name}
                    key={index}
                    numberOfIncompleteTasks={project.remainingTaskCount}
                    isMobile={props.isMobile}
                    position={index}
                    type="projectlist"
                  />
                </div>
              )}
            </Draggable>
          )}
        </div>
      );
    });

    return projectsToRender;
  };

  const projectsToRender = getProjectsToRender();

  const handleNodeCollapse = (_node: TreeNodeInfo, nodePath: number[]) => {
    setIsArchivedProjectsOpen(false);
  };

  const handleNodeExpand = (_node: TreeNodeInfo, nodePath: number[]) => {
    setIsArchivedProjectsOpen(true);
  };

  const nodesForArchivedProjects: TreeNodeInfo[] = [
    {
      id: 'root',
      icon: 'folder-close',
      isExpanded: isArchivedProjectsOpen,
      label: (
        <div className={navbarSideStyles.navbarItem}>Archived projects</div>
      ),
      childNodes: archivedProjects.map((project, index) => {
        let linkDest = `/projects/${project.id}`;
        return {
          id: project.id,
          label: (
            <div key={index}>
              <div>
                <NavBarItem
                  to={linkDest}
                  text={project.name}
                  key={index}
                  numberOfIncompleteTasks={project.remainingTaskCount}
                  isMobile={props.isMobile}
                  position={index}
                  type="projectlist"
                />
              </div>
            </div>
          ),
        };
      }),
    },
  ];

  return (
    <>
      <DragDropContext onDragEnd={onDragEnd}>
        <Droppable droppableId="nav-project-list">
          {provided => (
            <div ref={provided.innerRef} {...provided.droppableProps}>
              {projectsToRender}
              {provided.placeholder}
            </div>
          )}
        </Droppable>
      </DragDropContext>
      {archivedProjects.length > 0 && (
        <div
          style={{
            paddingTop: 5,
            paddingBottom: 5,
            paddingLeft: 5,
            paddingRight: 5,
          }}
        >
          <Tree
            contents={nodesForArchivedProjects}
            onNodeCollapse={handleNodeCollapse}
            onNodeExpand={handleNodeExpand}
            className={Classes.ELEVATION_0}
          />
        </div>
      )}
    </>
  );
};

export default NavBarProjectList;

import { IApiTag, ITag } from './Tag';

export enum TrackingPeriod {
  TODAY = 'TODAY',
  LAST_7_DAYS = 'LAST_7_DAYS',
  THIS_WEEK = 'THIS_WEEK',
  THIS_MONTH = 'THIS_MONTH',
  THIS_QUARTER = 'THIS_QUARTER',
  THIS_YEAR = 'THIS_YEAR',
  ALL_TIME = 'ALL_TIME',
  CUSTOM = 'CUSTOM',
}

export interface IUser {
  email: string;
  firstName: string;
  lastName: string;
  messagingTokenRef?: string;
  phoneNumber?: string;
  reminderType?: any; // TODO: fix
  shouldReceiveReminders: boolean;
  showCompletedTasks: boolean;
  tags?: ITag[];
  todoListTaskIds?: (string | number)[];
  todoPreference: TaskViewPreference;
  trackingPeriod: TrackingPeriod;
  isTrackingPeriodCustom?: boolean;
  customTrackingPeriod?: number;
  verified?: boolean;
  version?: string;
  created: string;
  updated?: string;
  viewedOnboarding: boolean;
  viewedDashboardOnboarding: boolean;
  viewedTasksPageOnboarding: boolean;
  viewedGoalsPageOnboarding: boolean;
  viewedHabitsPageOnboarding: boolean;
  viewedLogPageOnboarding: boolean;
  viewedSessionsPageOnboarding: boolean;
  viewedProjectPageOnboarding: boolean;
  timezone?: string;
}

export interface IApiUser {
  email: string;
  first_name: string;
  last_name: string;
  messaging_token_ref?: string;
  phone_number?: string;
  reminder_type?: any; // TODO: fix
  should_receive_reminders: boolean;
  show_completed_tasks: boolean;
  tags?: IApiTag[];
  todo_list_task_ids?: (string | number)[];
  todo_preference: TaskViewPreference;
  tracking_period: TrackingPeriod;
  is_tracking_period_custom?: boolean;
  custom_tracking_period?: number;
  verified?: boolean;
  version?: string;
  created: string;
  updated?: string;
  viewed_onboarding: boolean;
  viewed_dashboard_onboarding: boolean;
  viewed_tasks_page_onboarding: boolean;
  viewed_goals_page_onboarding: boolean;
  viewed_habits_page_onboarding: boolean;
  viewed_log_page_onboarding: boolean;
  viewed_sessions_page_onboarding: boolean;
  viewed_project_page_onboarding: boolean;
  timezone?: string;
}

export interface IApiUpdateUserRequest {
  first_name?: string;
  last_name?: string;
  email?: string;
  show_completed_tasks?: boolean;
  tracking_period?: TrackingPeriod;
  is_tracking_period_custom?: boolean;
  custom_tracking_period?: number;
  should_receive_reminders?: boolean;
  reminder_type?: string;
  phone_number?: string;
  todo_preference?: TaskViewPreference;
  viewed_onboarding?: boolean;
  viewed_dashboard_onboarding?: boolean;
  viewed_tasks_page_onboarding?: boolean;
  viewed_goals_page_onboarding?: boolean;
  viewed_habits_page_onboarding?: boolean;
  viewed_log_page_onboarding?: boolean;
  viewed_sessions_page_onboarding?: boolean;
  viewed_project_page_onboarding?: boolean;
  timezone?: string;
}

export enum TaskViewPreference {
  TODO = 'todo',
  BOARD = 'board',
  DUE_DATE = 'dueDate',
}

import React, { ReactNode, useEffect, useState, useContext } from 'react';
import {
  IApiCreatePhaseRequest,
  IApiCreateProjectRequest,
  IProject,
  ISimplifiedPhase,
  IApiUpdatePhaseRequest,
  ISimplifiedProject,
} from '../common-src/types/Project';
import { ISession } from '../common-src/types/Session';
import { IUnitOfLabour } from '../common-src/types/UnitOfLabour';
import { isEqual } from '../func/utils';
import {
  apiCreateProject,
  apiDeleteProject,
  apiGetProjects,
  apiUpdatePhase,
  apiUpdateUserProjects,
} from '../client/apiClient';
import { AuthenticationContext } from './AuthenticationContext';
import { useQuery, useQueryClient } from '@tanstack/react-query';

interface IProjectsListContext {
  projects?: ISimplifiedProject[];
  addProject: (project: any) => Promise<void>;
  saveMultipleProjects: (projects: ISimplifiedProject[]) => Promise<void>;
  deleteProject: (project: IProject) => Promise<void>;
  sessionsForProject: ISession[] | null;
  unitsForProject: IUnitOfLabour[] | null;
  getSessionsForProject: (projectId: string, allSessions: ISession[]) => void;
  getUnitsForProject: (projectId: string, allUnits: IUnitOfLabour[]) => void;
  updatePhase: (updatedPhase: ISimplifiedPhase) => Promise<void>;
  isFetchingProjects: boolean;
  isAddingProject: boolean;
  isUpdatingProject: boolean;
  isUpdatingMultipleProjects: boolean;
  isDeletingProject: boolean;
  isArchivingProject: boolean;
  isAddingPhase: boolean;
  isUpdatingPhase: boolean;
  isDeletingPhase: boolean;
  isUpdatingMultiplePhases: boolean;
}

export const ProjectsListContext =
  React.createContext<IProjectsListContext | null>(null);

interface Props {
  children: ReactNode;
}

const ProjectsListContextProvider: React.FunctionComponent<Props> = (
  props: Props
) => {
  const authenticationContext = useContext(AuthenticationContext);

  const queryClient = useQueryClient();

  const [sessionsForProject, setSessionsForProject] = useState<
    ISession[] | null
  >(null);
  const [unitsForProject, setUnitsForProject] = useState<
    IUnitOfLabour[] | null
  >(null);

  const [isAddingProject, setIsAddingProject] = useState(false);
  const [isUpdatingProject, setIsUpdatingProject] = useState(false);
  const [isUpdatingMultipleProjects, setIsUpdatingMultipleProjects] =
    useState(false);
  const [isDeletingProject, setIsDeletingProject] = useState(false);
  const [isArchivingProject, setIsArchivingProject] = useState(false);
  const [isAddingPhase, setIsAddingPhase] = useState(false);
  const [isUpdatingPhase, setIsUpdatingPhase] = useState(false);
  const [isDeletingPhase, setIsDeletingPhase] = useState(false);
  const [isUpdatingMultiplePhases, setIsUpdatingMultiplePhases] =
    useState(false);

  const getSessionsForProject = (
    projectId: string,
    allSessions: ISession[]
  ) => {
    setSessionsForProject(
      allSessions.filter(session => isEqual(session.projectId, projectId))
    );
  };

  const getUnitsForProject = (projectId: string, allUnits: IUnitOfLabour[]) => {
    setUnitsForProject(
      allUnits.filter(unit => isEqual(unit.projectId, projectId))
    );
  };

  const {
    isLoading: isFetchingProjects,
    isError: isFetchProjectsError,
    data: projects,
    error: fetchProjectsError,
  } = useQuery({
    queryKey: ['getProjects'],
    queryFn: async () => {
      try {
        if (
          authenticationContext &&
          authenticationContext.isUserAuthenticated
        ) {
          const accessToken = await authenticationContext.getToken();
          const projects = await apiGetProjects(accessToken);
          return projects;
        }
      } catch (err) {
        throw err;
      }
    },
    enabled: !!authenticationContext?.isUserAuthenticated,
  });

  // TODO: add a listener for individual projects, which again only retrieves relevant data (likely everything except sessions and units outside the tracking period)

  const addProject = async (project: IProject) => {
    setIsAddingProject(true);
    try {
      if (authenticationContext && authenticationContext.isUserAuthenticated) {
        const accessToken = await authenticationContext.getToken();
        const createProjectRequest: IApiCreateProjectRequest = {
          name: project.name,
        };
        const newProject = await apiCreateProject(
          createProjectRequest,
          accessToken
        );
        queryClient.invalidateQueries({ queryKey: ['getProjects'] });
      }
    } catch (err) {
      throw err;
    } finally {
      setIsAddingProject(false);
    }
  };

  const saveMultipleProjects = async (projects: ISimplifiedProject[]) => {
    setIsUpdatingMultipleProjects(true);
    const projectIds = projects.map(project => project.id);
    try {
      if (authenticationContext && authenticationContext.isUserAuthenticated) {
        const accessToken = await authenticationContext.getToken();
        await apiUpdateUserProjects(accessToken, projectIds);
        queryClient.invalidateQueries({ queryKey: ['getProjects'] });
      }
    } catch (err) {
      throw err;
    } finally {
      setIsUpdatingMultipleProjects(false);
    }
  };

  const deleteProject = async (project: IProject) => {
    setIsDeletingProject(true);
    try {
      if (authenticationContext && authenticationContext.isUserAuthenticated) {
        const accessToken = await authenticationContext.getToken();
        await apiDeleteProject(project.id.toString(), accessToken);
        queryClient.invalidateQueries({ queryKey: ['getProjects'] });
      }
    } catch (err) {
      throw err;
    } finally {
      setIsDeletingProject(false);
    }

    window.history.pushState({}, '', '/');
    const popStateEvent = new PopStateEvent('popstate', {
      state: {},
    });
    dispatchEvent(popStateEvent);
  };

  const updatePhase = async (phase: ISimplifiedPhase) => {
    setIsUpdatingPhase(true);
    try {
      if (authenticationContext && authenticationContext.isUserAuthenticated) {
        const accessToken = await authenticationContext.getToken();
        const updatePhaseRequest: IApiUpdatePhaseRequest = {
          name: phase.name,
          id: phase.id,
        };
        const updatedPhase = await apiUpdatePhase(
          phase.id,
          updatePhaseRequest,
          accessToken
        );
        queryClient.invalidateQueries({ queryKey: ['getProjects'] });
      }
    } catch (err) {
      throw err;
    } finally {
      setIsUpdatingPhase(false);
    }
  };

  return (
    <ProjectsListContext.Provider
      value={{
        projects,
        addProject,
        saveMultipleProjects,
        deleteProject,
        sessionsForProject,
        unitsForProject,
        getSessionsForProject,
        getUnitsForProject,
        updatePhase,
        isFetchingProjects,
        isAddingProject,
        isUpdatingProject,
        isDeletingProject,
        isArchivingProject,
        isUpdatingMultipleProjects,
        isAddingPhase,
        isDeletingPhase,
        isUpdatingPhase,
        isUpdatingMultiplePhases,
      }}
    >
      {props.children}
    </ProjectsListContext.Provider>
  );
};

export { ProjectsListContextProvider };
export type { IProjectsListContext };

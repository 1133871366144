const notify = (message, body?) => {
  var options = {
    body:
      body ||
      'The central economic planning committee insists you get this done now.',
    icon: '/favicon.png'
  };

  if ('Notification' in window) {
    // Let's check whether notification permissions have already been granted
    if (Notification.permission === 'granted') {
      // If it's okay let's create a notification
      new Notification(message, options);
    }

    // Otherwise, we need to ask the user for permission
    else if (Notification.permission !== 'denied') {
      Notification.requestPermission(function(permission) {
        // If the user accepts, let's create a notification
        if (permission === 'granted') {
          new Notification(message, options);
        }
      });
    }
  }
};

export { notify };

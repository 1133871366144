import React, { ReactNode, useState, useEffect, useContext } from 'react';
import { UserContext } from './UserContext';
import { Placement, Step } from 'react-joyride';
import { useOnboardingStyles } from '../style/components/onboardingStyles';

interface IOnboardingContext {
  dashboardOnboardingSteps: Step[];
  tasksPageOnboardingSteps: Step[];
  goalsPageOnboardingSteps: Step[];
  habitsPageOnboardingSteps: Step[];
  logPageOnboardingSteps: Step[];
  sessionsPageOnboardingSteps: Step[];
  projectPageOnboardingSteps: Step[];
  isDashboardOnboardingRunning: boolean;
  isTasksPageOnboardingRunning: boolean;
  isGoalsPageOnboardingRunning: boolean;
  isHabitsPageOnboardingRunning: boolean;
  isLogPageOnboardingRunning: boolean;
  isSessionsPageOnboardingRunning: boolean;
  isProjectPageOnboardingRunning: boolean;
  updateIsDashboardOnboardingRunning: (isRunning: boolean) => void;
  updateIsTasksPageOnboardingRunning: (isRunning: boolean) => void;
  updateIsGoalsPageOnboardingRunning: (isRunning: boolean) => void;
  updateIsHabitsPageOnboardingRunning: (isRunning: boolean) => void;
  updateIsLogPageOnboardingRunning: (isRunning: boolean) => void;
  updateIsSessionsPageOnboardingRunning: (isRunning: boolean) => void;
  updateIsProjectPageOnboardingRunning: (isRunning: boolean) => void;
}

export const OnboardingContext = React.createContext<IOnboardingContext | null>(
  null
);

interface Props {
  children: ReactNode;
}

const OnboardingContextProvider: React.FunctionComponent<Props> = (
  props: Props
) => {
  const userContext = useContext(UserContext);
  const {
    viewedDashboardOnboarding,
    viewedTasksPageOnboarding,
    viewedGoalsPageOnboarding,
    viewedHabitsPageOnboarding,
    viewedLogPageOnboarding,
    viewedSessionsPageOnboarding,
    viewedProjectPageOnboarding,
  } = userContext!;
  const onboardingStyles = useOnboardingStyles();

  const [isDashboardOnboardingRunning, setIsDashboardOnboardingRunning] =
    useState(false);
  const [isTasksPageOnboardingRunning, setIsTasksPageOnboardingRunning] =
    useState(false);
  const [isGoalsPageOnboardingRunning, setIsGoalsPageOnboardingRunning] =
    useState(false);
  const [isHabitsPageOnboardingRunning, setIsHabitsPageOnboardingRunning] =
    useState(false);
  const [isLogPageOnboardingRunning, setIsLogPageOnboardingRunning] =
    useState(false);
  const [isSessionsPageOnboardingRunning, setIsSessionsPageOnboardingRunning] =
    useState(false);
  const [isProjectPageOnboardingRunning, setIsProjectPageOnboardingRunning] =
    useState(false);

  useEffect(() => {
    if (viewedDashboardOnboarding === false) {
      setIsDashboardOnboardingRunning(true);
    } else if (viewedDashboardOnboarding === true) {
      setIsDashboardOnboardingRunning(false);
    }
  }, [viewedDashboardOnboarding]);

  useEffect(() => {
    if (viewedTasksPageOnboarding === false) {
      setIsTasksPageOnboardingRunning(true);
    } else if (viewedTasksPageOnboarding === true) {
      setIsTasksPageOnboardingRunning(false);
    }
  }, [viewedTasksPageOnboarding]);

  useEffect(() => {
    if (viewedGoalsPageOnboarding === false) {
      setIsGoalsPageOnboardingRunning(true);
    } else if (viewedGoalsPageOnboarding === true) {
      setIsGoalsPageOnboardingRunning(false);
    }
  }, [viewedGoalsPageOnboarding]);

  useEffect(() => {
    if (viewedHabitsPageOnboarding === false) {
      setIsHabitsPageOnboardingRunning(true);
    } else if (viewedHabitsPageOnboarding === true) {
      setIsHabitsPageOnboardingRunning(false);
    }
  }, [viewedHabitsPageOnboarding]);

  useEffect(() => {
    if (viewedLogPageOnboarding === false) {
      setIsLogPageOnboardingRunning(true);
    } else if (viewedLogPageOnboarding === true) {
      setIsLogPageOnboardingRunning(false);
    }
  }, [viewedLogPageOnboarding]);

  useEffect(() => {
    if (viewedSessionsPageOnboarding === false) {
      setIsSessionsPageOnboardingRunning(true);
    } else if (viewedSessionsPageOnboarding === true) {
      setIsSessionsPageOnboardingRunning(false);
    }
  }, [viewedSessionsPageOnboarding]);

  useEffect(() => {
    if (viewedProjectPageOnboarding === false) {
      setIsProjectPageOnboardingRunning(true);
    } else if (viewedProjectPageOnboarding === true) {
      setIsProjectPageOnboardingRunning(false);
    }
  }, [viewedProjectPageOnboarding]);

  if (!userContext) {
    return null;
  }

  const updateIsDashboardOnboardingRunning = (isRunning: boolean) => {
    setIsDashboardOnboardingRunning(isRunning);
  };

  const updateIsTasksPageOnboardingRunning = (isRunning: boolean) => {
    setIsTasksPageOnboardingRunning(isRunning);
  };

  const updateIsGoalsPageOnboardingRunning = (isRunning: boolean) => {
    setIsGoalsPageOnboardingRunning(isRunning);
  };

  const updateIsHabitsPageOnboardingRunning = (isRunning: boolean) => {
    setIsHabitsPageOnboardingRunning(isRunning);
  };

  const updateIsLogPageOnboardingRunning = (isRunning: boolean) => {
    setIsLogPageOnboardingRunning(isRunning);
  };

  const updateIsSessionsPageOnboardingRunning = (isRunning: boolean) => {
    setIsSessionsPageOnboardingRunning(isRunning);
  };

  const updateIsProjectPageOnboardingRunning = (isRunning: boolean) => {
    setIsProjectPageOnboardingRunning(isRunning);
  };

  const dashboardOnboardingSteps = [
    {
      target: 'body',
      content: (
        <div style={{ textAlign: 'left' }}>
          <p>
            <strong>Welcome to Chekhov!</strong>
          </p>
          <p>
            Chekhov is an all-in-one productivity app that helps you to track{' '}
            <strong>projects</strong>, <strong>tasks</strong>,{' '}
            <strong>goals</strong>, and <strong>habits</strong> meaningfully.
          </p>
        </div>
      ),
      placement: 'center' as Placement,
    },
    {
      target: 'body',
      content: (
        <div style={{ textAlign: 'left' }}>
          <p>
            <strong>This is the dashboard.</strong>
          </p>
          <p>
            Here you'll see a summary of how well you're doing overall, as well
            as suggestions for what to focus on next.
          </p>
          <p>
            Right now it doesn't tell you much, but as you make progress towards
            your goals, it'll start to show you more.
          </p>
        </div>
      ),
      placement: 'center' as Placement,
    },
  ];

  const tasksPageOnboardingSteps = [
    {
      target: 'body',
      content: (
        <div style={{ textAlign: 'left' }}>
          <p>
            <strong>This is the tasks page.</strong>
          </p>
          <p>As you add tasks to your projects, they'll appear in this page.</p>
          <div className={onboardingStyles.videoContainer}>
            <video width="100%" loop autoPlay>
              <source src="todo.mp4" type="video/mp4" />
              Your browser does not support the video tag.
            </video>
          </div>
        </div>
      ),
      placement: 'center' as Placement,
    },
    {
      target: 'body',
      content: (
        <div style={{ textAlign: 'left' }}>
          <p>
            You can view your tasks in whatever way makes sense to you. For
            instance, view only tasks with the "important" tag, or show only
            those tasks with due dates.
          </p>
          <div className={onboardingStyles.videoContainer}>
            <video width="100%" loop autoPlay>
              <source src="task-tag.mp4" type="video/mp4" />
              Your browser does not support the video tag.
            </video>
          </div>
        </div>
      ),
      placement: 'center' as Placement,
    },
  ];

  const goalsPageOnboardingSteps = [
    {
      target: 'body',
      content: (
        <div style={{ textAlign: 'left' }}>
          <p>
            <strong>This is the goals page.</strong>
          </p>
          <p>
            This page is a bird's eye view of the progress you've made towards
            your goals.
          </p>
          <p>
            A goal can be anything you want to achieve, and is either measured
            against time, like "Practice piano for 15 minutes a day", or against
            some unit that you want to track, like "Write 1000 words a day".
          </p>
          <div className={onboardingStyles.videoContainer}>
            <video width="100%" loop autoPlay>
              <source src="add-goal.mp4" type="video/mp4" />
              Your browser does not support the video tag.
            </video>
          </div>
        </div>
      ),
      placement: 'center' as Placement,
    },
    {
      target: 'body',
      content: (
        <div style={{ textAlign: 'left' }}>
          <p>
            Get a more detailed view of your progress towards a goal by clicking
            on "goal details".
          </p>
          <div className={onboardingStyles.videoContainer}>
            <video width="100%" loop autoPlay>
              <source src="goal-details.mp4" type="video/mp4" />
              Your browser does not support the video tag.
            </video>
          </div>
        </div>
      ),
      placement: 'center' as Placement,
    },
  ];

  const habitsPageOnboardingSteps = [
    {
      target: 'body',
      content: (
        <div style={{ textAlign: 'left' }}>
          <p>
            <strong>This is the habits page.</strong>
          </p>
          <p>
            This is where you keep track of how well you're sticking to your
            habits.
          </p>
          <p>
            A habit is something you want to do regularly, and is measured
            against time, for instance, "Read two books per month" or "Meditate
            once a day".
          </p>
        </div>
      ),
      placement: 'center' as Placement,
    },
  ];

  const logPageOnboardingSteps = [
    {
      target: 'body',
      content: (
        <div style={{ textAlign: 'left' }}>
          <p>
            <strong>This is the log page.</strong>
          </p>
          <p>
            This page lets you view any logs you explicitly write, as well as
            any sessions and tasks that you have completed.
          </p>
          <div className={onboardingStyles.videoContainer}>
            <video width="100%" loop autoPlay>
              <source src="add-log.mp4" type="video/mp4" />
              Your browser does not support the video tag.
            </video>
          </div>
        </div>
      ),
      placement: 'center' as Placement,
    },
  ];

  const sessionsPageOnboardingSteps = [
    {
      target: 'body',
      content: (
        <div style={{ textAlign: 'left' }}>
          <p>
            <strong>This is the sessions page.</strong>
          </p>
          <p>
            It centres around a calendar view showing how you've spent your
            time.
          </p>
          <p>
            Sessions can be associated with anything that makes sense, like
            tasks or goals.
          </p>
          <div className={onboardingStyles.videoContainer}>
            <video width="100%" loop autoPlay>
              <source src="sessions-page.mp4" type="video/mp4" />
              Your browser does not support the video tag.
            </video>
          </div>
        </div>
      ),
      placement: 'center' as Placement,
    },
  ];

  const projectPageOnboardingSteps = [
    {
      target: 'body',
      content: (
        <div style={{ textAlign: 'left' }}>
          <p>
            <strong>This is the project page.</strong>
          </p>
          <p>
            Much of what you do in Chekhov will happen in projects. Projects let
            you group related tasks together, and to keep track of the project's
            completion as you make progress.
          </p>
        </div>
      ),
      placement: 'center' as Placement,
    },
    // {
    //   target: '#bp4-tab-title_project-tabs_project-tab-tasks',
    //   content: (
    //     <div style={{ textAlign: 'left' }}>
    //       <p>
    //         <strong>Tasks tab.</strong>
    //       </p>
    //     </div>
    //   ),
    //   placement: 'left' as Placement,
    // },
  ];

  const getOnboardingContext = () => {
    return {
      dashboardOnboardingSteps,
      tasksPageOnboardingSteps,
      goalsPageOnboardingSteps,
      habitsPageOnboardingSteps,
      logPageOnboardingSteps,
      sessionsPageOnboardingSteps,
      projectPageOnboardingSteps,
      isDashboardOnboardingRunning,
      isTasksPageOnboardingRunning,
      isGoalsPageOnboardingRunning,
      isHabitsPageOnboardingRunning,
      isLogPageOnboardingRunning,
      isSessionsPageOnboardingRunning,
      isProjectPageOnboardingRunning,
      updateIsDashboardOnboardingRunning,
      updateIsTasksPageOnboardingRunning,
      updateIsGoalsPageOnboardingRunning,
      updateIsHabitsPageOnboardingRunning,
      updateIsLogPageOnboardingRunning,
      updateIsSessionsPageOnboardingRunning,
      updateIsProjectPageOnboardingRunning,
    };
  };

  const onboardingContext = getOnboardingContext();

  return (
    <OnboardingContext.Provider value={onboardingContext}>
      {props.children}
    </OnboardingContext.Provider>
  );
};

export { OnboardingContextProvider };
export type { IOnboardingContext };
